import React, { useState, useEffect, useRef } from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import CloseIcon from "@mui/icons-material/Close";
import Fiskalizimi from "./Fiskalizim/Fiskalizimi";
import FiskalizimiDalje from "../flete-dalje/Fiskalizim/FiskalizimiDalje";
import { ButtonGroup } from "@mui/material";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";
import ArrowCircleRightOutlinedIcon from "@mui/icons-material/ArrowCircleRightOutlined";

export default function RightBar({
  openRight,
  onCloseRight,
  buttonClicked,
  setDisabled,
  disabled,
  currentId,
  state,
  pdfDisabled,
  fetchFShitje,
  fisVerificationUrl,
  setState,
  handleChange,
  contextKeyFatureShitje,
  contextKeyFleteDalje,
  fetchFDalje,
  formatDate,
  isFsh,
  prvBtnDisable,
  nxtBtnDisable,
  showToast,
  currentAction,
  fatureDisabled,
}) {
  const [number, setNumber] = useState(1);
  const [toggle, setToggle] = useState({
    right: openRight || false,
  });

  useEffect(() => {
    setToggle((prev) => ({
      ...prev,
      right: openRight,
    }));
  }, [openRight]);

  const drawerRef = useRef(null);

  const toggleDrawer = (open) => () => {
    setToggle({
      right: open,
    });
  };

  const shikoFaturen = (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    const currentNumber = Number(number);

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      isFsh === "true" ? fetchFShitje(nextNumber) : fetchFDalje(nextNumber);
      setDisabled(true);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      isFsh === "true" ? fetchFShitje(prevNumber) : fetchFDalje(prevNumber);
      setDisabled(true);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (drawerRef.current && !drawerRef.current.contains(event.target)) {
        setToggle({
          right: false,
        });
      }
    };

    if (toggle.right) {
      document.addEventListener("mousedown", handleClickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [toggle.right]);

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1450);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1450);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const list = (
    <Box
      ref={drawerRef}
      sx={{
        width: isMobile ? 320 : 400,
        height: "100%",
        // overflow: "hidden",
        paddingLeft: 2,
        paddingRight: 2,
      }}
      role="presentation"
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Typography variant="h6" sx={{ fontWeight: "bold" }}>
          Fiskalizimi
        </Typography>
        ID: {currentId ? currentId : "No Data"}
        <ButtonGroup variant="contained" aria-label="text button group">
          <Button
            style={{
              backgroundColor: nxtBtnDisable ? "#D7D9DA" : "#1564ad",
              margin: "2px",
            }}
            onClick={() => shikoFaturen("increment")}
            disabled={nxtBtnDisable}
          >
            <ArrowCircleLeftOutlinedIcon />
          </Button>
          <Button
            style={{
              backgroundColor: prvBtnDisable ? "#D7D9DA" : "#1564ad",
              margin: "2px",
            }}
            onClick={() => shikoFaturen("decrement")}
            disabled={prvBtnDisable}
          >
            <ArrowCircleRightOutlinedIcon />
          </Button>
        </ButtonGroup>
        <IconButton onClick={toggleDrawer(false)}>
          <CloseIcon />
        </IconButton>
      </Box>
      <Divider />
      {contextKeyFatureShitje ? (
        <Fiskalizimi
          state={state}
          setState={setState}
          disabled={disabled}
          fatureDisabled={fatureDisabled}
          currentId={currentId}
          pdfDisabled={pdfDisabled}
          handleChange={handleChange}
          fisVerificationUrl={fisVerificationUrl}
          fetchFShitje={fetchFShitje}
          buttonClicked={buttonClicked}
        />
      ) : null}
      {contextKeyFleteDalje ? (
        <FiskalizimiDalje
          state={state}
          formatDate={formatDate}
          setState={setState}
          disabled={disabled}
          currentId={currentId}
          handleChange={handleChange}
          fisVerificationUrl={fisVerificationUrl}
          fetchFDalje={fetchFDalje}
          buttonClicked={buttonClicked}
        />
      ) : null}
    </Box>
  );

  return (
    <>
      <Button onClick={toggleDrawer(true)} size="small" variant="contained">
        <strong>Tabela e Fiskalizimit</strong>
      </Button>
      <Drawer anchor="right" open={toggle.right} onClose={onCloseRight}>
        {list}
      </Drawer>
    </>
  );
}
