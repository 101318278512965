import React from "react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import RemoveCircleOutlineIcon from "@mui/icons-material/RemoveCircleOutline";
import HighlightOffIcon from "@mui/icons-material/HighlightOff";
import { useToast } from "../../../../../components/context/ToastContext";

const HandleItemClick = ({
  showLoading,
  hideLoading,
  setDtlState,
  dtlState,
  item,
  setDeletedRowIds,
  currentAction,
  gjeneroFatureAnulluese,
}) => {
  const showToast = useToast();
  const handleItemClick = (index, operation) => {
    try {
      showLoading();

      if (operation === 0) {
        // Handle item removal based on currentAction
        if (currentAction === "Add") {
          const updatedItems = dtlState.filter(
            (clickedItem) =>
              !(
                clickedItem.Pershkrim === index.Pershkrim &&
                clickedItem.Kodi === index.Kodi &&
                clickedItem.Id === index.Id
              )
          );
          setDtlState(updatedItems);
        } else if (currentAction === "Modify") {
          let updatedItems;

          // Only add the ID to deletedRowIds and filter if Printuar !== 1
          if (index.Printuar !== 1) {
            setDeletedRowIds((prevIds) => [...prevIds, index.Id]);

            // Filter items to remove those with the same Pershkrim and Printuar !== 1
            updatedItems = dtlState.filter(
              (clickedItem) =>
                !(
                  clickedItem.Pershkrim === index.Pershkrim &&
                  clickedItem.Printuar !== 1
                )
            );
          } else {
            if (gjeneroFatureAnulluese) {
              setDeletedRowIds((prevIds) => [...prevIds, index.Id]);
              updatedItems = dtlState.filter(
                (clickedItem) =>
                  !(
                    clickedItem.Pershkrim === index.Pershkrim &&
                    clickedItem.Printuar === 1
                  )
              );
            } else {
              // If Printuar is 1, keep dtlState unchanged
              updatedItems = dtlState;
            }
          }

          setDtlState(updatedItems);
        }
      } else {
        // Handle updating the item's quantity
        const existingItemIndex = dtlState.findIndex(
          (clickedItem) => clickedItem.Pershkrim === index.Pershkrim
        );

        if (existingItemIndex !== -1) {
          const updatedItems = [...dtlState];
          updatedItems[existingItemIndex].Sasia += operation;
          updatedItems[existingItemIndex].Vlera =
            updatedItems[existingItemIndex].Sasia *
            updatedItems[existingItemIndex].Cmimi;

          setDtlState(updatedItems); // Update the state
        }
      }
    } catch (error) {
      console.error("Error handling item:", error);
      showToast("Problem during item update");
    } finally {
      hideLoading();
    }
  };

  return (
    <div direction="row" className="stack_Buttons">
      <button onClick={() => handleItemClick(item, 1)}>
        <AddCircleOutlineIcon sx={{ color: "#000" }} />
      </button>

      <button
        sx={{
          backgroundColor:
            item.Sasia === 1 || (item.Sasia >= 0 && item.Sasia <= 1)
              ? "#fff"
              : "#dbdbdbaf",
        }}
        onClick={() => handleItemClick(item, -1)}
        disabled={item.Sasia === 1 || (item.Sasia >= 0 && item.Sasia <= 1)}
      >
        <RemoveCircleOutlineIcon
          sx={{
            color:
              item.Sasia === 1 || (item.Sasia >= 0 && item.Sasia <= 1)
                ? "gray"
                : "#000",
          }}
        />
      </button>

      <button onClick={() => handleItemClick(item, 0)}>
        <HighlightOffIcon sx={{ color: "#E14D2A" }} />
      </button>
    </div>
  );
};

export default HandleItemClick;
