import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import PrimeGridKF from "../PrimeGridKlient";
import useStorage from "../../../hooks/useStorage";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import mainAxios from "../../../services/axios";
import NavBar from "../../../components/Navbar/NavBar";
import SideBar from "../../../components/Navbar/SideBar";
import PaneliTab2 from "../../fature-blerje/tabet/PaneliTab2";
import "../Celje.scss";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import SmallButtonGroupC from "../SmallButtonGroupC";
import { getArkaBanka, getBankaList } from "../../../store/globalSlice";
import AccountBalanceIcon from "@mui/icons-material/AccountBalance";
import TabTjeraC from "../ListaArkave/TabTjeraC";
import MainTabCArka from "../ListaArkave/MainTabCArka";
import SidebarCelje from "../SideBarCelje";
import { useUser } from "../../../zustand/common";
import TabTjeraCelje from "../PaneliTabeveCelje/TabTjera";
import { useToast } from "../../../components/context/ToastContext";

const BankaC = ({
  isMobile,
  disabled,
  setDisabled,
  currentAction,
  setCurrentAction,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { user } = useUser();
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setdata] = useState([]);
  const [lastData, setLastData] = useState({});
  const showToast = useToast();
  const [lastKodi, setLastKodi] = useState("");

  // Redux: Get client data from global slice
  // const klientiRedux = useSelector((state) => state.globalSlice.klienti);
  const bankaRedux = useSelector((state) => state.globalSlice.bankaList);

  // Function to increment the lastKodi string
  function incrementString(str) {
    const match = str.match(/\d+$/);
    const number = match ? parseInt(match[0]) : 0;
    const total = number + 1;
    const length = match ? match[0].length : 0;

    return str.replace(/\d+$/, total.toString().padStart(length, "0"));
  }

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        setdata(bankaRedux);
        if (bankaRedux.length > 0) {
          const lastKodi = bankaRedux[bankaRedux.length - 1].Kodi;
          const newKodi = incrementString(lastKodi);
          setLastKodi(newKodi);
        } else {
          setLastKodi("B01"); // Start with F1 if there are no existing entries
        }
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
        hideLoading();
      } finally {
        hideLoading();
      }
    };
    fetchData();
  }, [bankaRedux, showLoading, hideLoading]);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "Id", title: "ID" },
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "NrLL", title: "Llogari" },
    { field: "Aktiv", title: "Aktiv" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatBanka"
  );

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };
  let defaultState = {
    Adresa: "",
    Aktiv: "",
    Cel: "",
    Db_Org: "",
    Dega: "",
    Email: "",
    EmerKontakti: "",
    Iban: "",
    Kodi: `${lastKodi}`,
    Mon: "",
    NrLL: "512",
    Nr_Llogarise: "",
    POS: "",
    Pershkrim: "",
    Punonjes_Id: "",
    Rendit: "",
    Selektuar: "",
    ShfaqFature: "",
    Sportel_kodi: "",
    Swift: "",
    Tel: "",
  };

  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [visibleRight, setVisibleRight] = useState(false);

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} flex justify-content-between`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Celje Banka
          <br />
        </span>
        <Button
          size="small"
          onClick={() => setVisibleRight(true)}
          className="mr-2 border"
        >
          <MenuOpenIcon size="small" />
        </Button>
        <SidebarCelje
          currentAction={currentAction}
          visible={visibleRight}
          position="right"
          onHide={() => setVisibleRight(false)}
        />
      </div>
      // 11-10-2023 - Fundi i shtimit te sidebar nga e djathta - Arseni
    );
  };

  const deleteMethod = async (e) => {
    try {
      const response = await mainAxios.get(
        `/fshirje/check?db_name=${nipt}&table_name=BANKA_LIST&kodi=${state.Kodi}`
      );

      if (response.data.Status !== 0) {
        showToast("Ka te dhena nuk mund te fshihet", {
          severity: "warn",
        });

        return;
      } else {
        const response = await mainAxios.delete(`/banka/list/${state.Id}`);

        if (response && response.status === 200) {
          setState(defaultState);
          setDisabled(true);
          setButtonClicked(false);
          dispatch(getBankaList("/banka/list"));
          showToast("U fshi", {
            severity: "info",
          });
        }
      }
    } catch (error) {
      console.error(error);
      showToast("Ndodhi problem gjat fshirjes" + error.message, {
        severity: "error",
      });
    }
  };

  const handleAdd = async (e) => {
    if (
      !state.Kodi.trim() ||
      !state.Pershkrim.trim() ||
      !state.NrLL.trim() ||
      !state.Mon.trim()
    ) {
      showToast(
        "Plotesoni fushat: " +
          (!state.Kodi.trim() ? "Kodi " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim " : "") +
          (!state.NrLL.trim() ? "Llogari " : "") +
          (!state.Mon.trim() ? "Monedha" : ""),
        {
          severity: "warn",
        }
      );
      return;
    }
    try {
      const response = await mainAxios.post(`/banka/list`, {
        ...state,
        Adresa: state.Adresa,
        Aktiv: Number(state.Aktiv),
        Cel: state.Cel,
        Db_Org: state.Db_Org,
        Dega: state.Dega,
        Email: state.Email,
        EmerKontakti: state.EmerKontakti,
        Iban: state.Iban,
        Kodi: state.Kodi,
        Mon: state.Mon,
        NrLL: state.NrLL,
        Nr_Llogarise: state.Nr_Llogarise,
        POS: Number(state.POS),
        Pershkrim: state.Pershkrim,
        Punonjes_Id: Number(state.Punonjes_Id),
        Rendit: Number(state.Rendit),
        Selektuar: Number(state.Selektuar),
        ShfaqFature: Number(state.ShfaqFature),
        Sportel_kodi: state.Sportel_kodi,
        Swift: state.Swift,
        Tel: state.Tel,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getBankaList("/banka/list"));
        dispatch(getArkaBanka("/arbalist"));
        setCurrentAction(null);
        showToast("U rregjistrua", {
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      showToast("Nuk u regjistrua." + error.message, {
        severity: "error",
      });
    }
  };

  const handleModify = async (e) => {
    if (
      !state.Kodi.trim() ||
      !state.Pershkrim.trim() ||
      !state.NrLL.trim() ||
      !state.Mon.trim()
    ) {
      showToast(
        "Plotesoni fushat: " +
          (!state.Kodi.trim() ? "Kodi " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim " : "") +
          (!state.NrLL.trim() ? "Llogari " : "") +
          (!state.Mon.trim() ? "Monedha" : ""),
        {
          severity: "warn",
        }
      );
      return;
    }
    try {
      const response = await mainAxios.put(`/banka/list/${state.Id}`, {
        ...state,
        Adresa: state.Adresa,
        Aktiv: Number(state.Aktiv),
        Cel: state.Cel,
        Db_Org: state.Db_Org,
        Dega: state.Dega,
        Email: state.Email,
        EmerKontakti: state.EmerKontakti,
        Iban: state.Iban,
        Kodi: state.Kodi,
        Mon: state.Mon,
        NrLL: state.NrLL,
        Nr_Llogarise: state.Nr_Llogarise,
        POS: Number(state.POS),
        Pershkrim: state.Pershkrim,
        Punonjes_Id: Number(state.Punonjes_Id),
        Rendit: Number(state.Rendit),
        Selektuar: Number(state.Selektuar),
        ShfaqFature: Number(state.ShfaqFature),
        Sportel_kodi: state.Sportel_kodi,
        Swift: state.Swift,
        Tel: state.Tel,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getBankaList("/banka/list"));
        dispatch(getArkaBanka("/arbalist"));
        setCurrentAction(null);
        showToast("U modifikua", {
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);
      showToast("Nuk u modifikua." + error.message, {
        severity: "error",
      });
    }
  };

  const submitHanlder = async (e) => {
    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };

  const PaneliKryesor = (
    <div className="d-flex justify-content-between">
      {/* {isChecked && ( */}
      <SmallButtonGroupC
        isMobile={isMobile}
        lastData={lastData}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        setDisabled={setDisabled}
        disabled={disabled}
        setState={setState}
        defaultState={defaultState}
        submitHandler={submitHanlder}
        selectedRowId={state.Id}
        deleteMethod={deleteMethod}
        state={state}
        setCurrentAction={setCurrentAction}
        // deleteHandler={deleteHandler}
      />
      {/* )} */}
      {/* <FormControlLabel
        control={
          <Checkbox
            checked={isChecked}
            onChange={editCheckbox}
            inputProps={{ "aria-label": "controlled" }}
          />
        }
        label="Modifiko"
      /> */}
    </div>
  );

  // Paneli Dytesor
  const button6Text = isMobile ? "" : "Te Dhenat";
  const button7Text = isMobile ? "" : "Te Tjera";

  const PaneliDytesor = (
    <PaneliTab2
      isFromCelje={true}
      includeButton6={true}
      includeButton7={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AccountBalanceIcon />}
      button7Text={button7Text}
      button7Icon={<AutoAwesomeMotionIcon />}
    />
  );

  const TabiKryesor = (
    <MainTabCArka
      disabled={disabled}
      state={state}
      setState={setState}
      handleChange={handleChange}
      setSearchString={setSearchString}
      searchString={searchString}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab2 */}
      <TabTjeraC
        toggleState={toggleState === 1}
        disabled={disabled}
        handleChange={handleChange}
        formatDate={formatDate}
        state={state}
        TextNrLlogarie={true}
        TextIBAN={true}
        TextSWIFT={true}
        TextDega={true}
      />
      <TabTjeraCelje
        isAgjent={true}
        TextCel={true}
        setState={setState}
        TextAdresa={true}
        TextEmail={true}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
        state={state}
      />
      {/* <TabTjeraCPrv
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        formatDate={formatDate}
        state={state}
        TextAdresaEmerKontaktit={true}
        TextAdresa={true}
      /> */}
    </div>
  );

  return (
    <div className={`mainDiv_ftsh-c ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          <div className="p-1 main-container-c">
            <div className="top_panel_fsh-c">
              <Panel headerTemplate={template} toggleable>
                <div className="bg-white" style={{ height: "100%" }}>
                  {!isMobile ? (
                    <>
                      <div className="paneli-tabeve-c">
                        <div className="paneli-tabeve1-c">{PaneliKryesor}</div>
                        <div className="paneli-tabeve2-c">{PaneliDytesor}</div>

                        <div className="paneli-fis-print-c"></div>
                      </div>
                      <div className={"below-tabs-c"}>
                        <div className="below-tabs1-c">{TabiKryesor}</div>
                        <div className="below-tabs2-c">{smallTabsKM}</div>
                        <div className="below-tabs3-c"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliKryesor}
                      </div>
                      <div style={{ margin: "5px" }}>{TabiKryesor}</div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "10px 5px",
                        }}
                      >
                        {PaneliDytesor}
                      </div>
                      <div style={{ margin: "5px" }}>{smallTabsKM}</div>
                    </>
                  )}
                </div>
              </Panel>
            </div>
            <div className="middle_fsh-c"></div>

            <div className="dataTable_fsh-c">
              <PrimeGridKF
                gridKey="Banka"
                data={data}
                defaultState={defaultState}
                isEditable={true}
                isMobile={isMobile}
                defaultColumnsStorage={defaultColumnsStorage}
                columns={columns}
                setColumns={setColumns}
                disabled={disabled}
                setState={setState}
                rows={rows}
                state={state}
                setRows={setRows}
                setdata={setdata}
                setLastData={setLastData}
                selectedColumns={selectedColumns}
                setselectedcolumns={setselectedcolumns}
                currentAction={currentAction}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {!isMobile && <Footer className="footer-position" />} */}
    </div>
  );
};

export default memo(BankaC);
