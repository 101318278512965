import React, { useState, useRef, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import MenuItem from "@mui/material/MenuItem";
import IconButton from "@mui/material/IconButton";
import InputAdornment from "@mui/material/InputAdornment";
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useDispatch, useSelector } from "react-redux";
import { getPerdorues } from "../../../../../../store/globalSlice";

import { setAuthToken } from "../../../../../../services/axios";
import { getCookie } from "../../../../../../services/helpers";
import CloseIcon from "@mui/icons-material/Close";
import { useUser } from "../../../../../../zustand/common";
import { LoadingOverlayContext } from "../../../../../../components/LoadingOverlay";
import { useToast } from "../../../../../../components/context/ToastContext";

export default function DialogConfirm({
  open,
  onClose,
  title,
  contentText,
  onConfirm,
  setUserAutomatikisht,
}) {
  const showToast = useToast();
  const dispatch = useDispatch();

  const perdorues = useSelector((state) => state.globalSlice.perdorues);

  useEffect(() => {
    if (open && perdorues.length === 0) {
      dispatch(getPerdorues("/get/perdoruesi"));
    }
  }, [dispatch, open, perdorues.length]);

  const { user, login } = useUser();
  const nipt = user.nipt;
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      showLoading();
      const res = await login({ username, nipt, password });

      if (res.success) {
        onConfirm();
        setAuthToken(getCookie("access_token"));
        const { automatikisht } = res;
        setUserAutomatikisht(automatikisht);
        onClose();
      } else {
        showToast("Te dhenat jane gabim");
      }
    } catch (error) {
      console.error("Login failed:", error);
      showToast("Te dhenat gabim");
    } finally {
      setPassword("");
      hideLoading();
    }
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle
        style={{
          height: "10%",
          display: "flex",
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <>{title} </>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent style={{ textAlign: "center", justifyContent: "center" }}>
        <DialogContentText>{contentText}</DialogContentText>
        <form onSubmit={handleSubmit}>
          <TextField
            margin="normal"
            fullWidth
            id="outlined-select-currency"
            select
            label="Select User"
            value={username}
            onChange={(e) => {
              setUsername(e.target.value);
            }}
            helperText="Ju lutem zgjidhni userin"
            autoComplete="username"
          >
            {perdorues.map((option) => (
              <MenuItem key={option.Id} value={option.Operator}>
                {option.Operator}
              </MenuItem>
            ))}
          </TextField>
          <TextField
            margin="normal"
            required
            fullWidth
            type={showPassword ? "text" : "password"}
            label="Password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            autoComplete="current-password"
            autoFocus
            helperText="Ju lutem vendosni passwordin"
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton
                    onClick={() => setShowPassword((prev) => !prev)}
                    edge="end"
                  >
                    {showPassword ? <VisibilityIcon /> : <VisibilityOffIcon />}
                  </IconButton>
                </InputAdornment>
              ),
            }}
          />
          <Button
            type="submit"
            color="primary"
            variant="contained"
            style={{
              width: "50%",
              textAlign: "center",
              justifyContent: "center",
            }}
          >
            Login
          </Button>
        </form>
      </DialogContent>
    </Dialog>
  );
}
