// globalReportsSlice.js
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  yearReport: [],
  weeklyReport: [],
  clientReport: [],
  furnitorReport: [],
  artData: [],
  purchaseReport: [],
  posReport: [],
  agentReport: [],
  dataReportArka: [],
  dataReportBanka: [],
  loading: false, // Manage loading state
  error: null, // Track error state
};

const globalReportsSlice = createSlice({
  name: "globalReports",
  initialState,
  reducers: {
    setReports: (state, action) => {
      return {
        ...state,
        ...action.payload,
        loading: false,
      };
    },
    setLoading: (state) => {
      state.loading = true;
    },
    setError: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
  },
});

export const { setReports, setLoading, setError } = globalReportsSlice.actions;

export default globalReportsSlice.reducer;
