// Importing necessary dependencies
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import mainAxios from "../services/axios";
import { retryRequest } from "./retryRequest";

const initialState = {
  // Arrays to store data from various API calls
  cert: [],
  gjendjeArke: [],
  getPos: [],
  punonjes: [],
  references: [],
  nextKodiFsh: [],
  nextKodiOferte: [],
  nextKodiVeprimeKontabel: [],
  nextKodiFb: [],
  totalArt: [],
  monedha: [],
  magazina: [],
  furnitor: [],
  klienti: [],
  agjenti: [],
  konfigurime: [],
  perdorues: [],
  tarifa: [],
  getFirstFtSh: [],
  getFirstFtB: [],
  getFirstOferte: [],
  getFirstVeprimeKontabel: [],
  getFirstAr: [],
  getFirstBa: [],
  getFirstFD: [],
  getFirstFH: [],
  veprimi: [],
  departamenti: [],
  klasifikim1: [],
  klasifikim2: [],
  klasifikim3: [],
  klasifikim4: [],
  transportuesi: [],
  sherbime: [],
  qytetet: [],
  shtetet: [],
  menyraPageses: [],
  arkabanka: [],
  arka: [],
  llogari: [],
  artikuj: [],
  artikujPag: [],
  arkaList: [],
  arkaWithTCR: [],
  bankaList: [],
  vetefaturim: [],
  procesi: [],
  grupi: [],
  docType: [],
  // Additional predefined arrays
  tipi0: [
    { Kodi: "AR", Pershkrim: "Artikuj" },
    { Kodi: "LL", Pershkrim: "Llogari" },
  ],
  tipi: [
    { Kodi: "MA", Pershkrim: "" },
    { Kodi: "MP", Pershkrim: "" },
  ],
  tipi2: [
    { Kodi: "FTBL", Pershkrim: "Fature Blerje" },
    { Kodi: "MP", Pershkrim: "" },
  ],
  gjendje: [
    { Kodi: "<0", Pershkrim: "" },
    { Kodi: "=0", Pershkrim: "" },
    { Kodi: ">0", Pershkrim: "" },
    { Kodi: "<>0", Pershkrim: "" },
  ],
  oper: [
    { Kodi: "INITIAL", Pershkrim: "Deklarim fillestar" },
    { Kodi: "DEPOSIT", Pershkrim: "Depozitim" },
    { Kodi: "WITHDRAW", Pershkrim: "Terheqje" },
  ],
  // ... (skema array elements)
  skema: [
    { Kodi: "001", Pershkrim: "Mallra" },
    { Kodi: "002", Pershkrim: "Materiale te para" },
    { Kodi: "003", Pershkrim: "Materiale te tjera" },
    { Kodi: "004", Pershkrim: "Amballazh i kthyeshem" },
    { Kodi: "005", Pershkrim: "Inventari i Imet" },
    { Kodi: "006", Pershkrim: "Material ndihmes" },
    { Kodi: "007", Pershkrim: "Lende djegese" },
    { Kodi: "008", Pershkrim: "Pjese nderimi" },
    { Kodi: "009", Pershkrim: "Material ambalazhimi" },
    { Kodi: "010", Pershkrim: "Ndertesa" },
    {
      Kodi: "011",
      Pershkrim:
        "Instalime teknike, makineri, pajisje,instrumente dhe vegla pune",
    },
    { Kodi: "012", Pershkrim: "Mjete transporti" },
    { Kodi: "013", Pershkrim: "Mobilje dhe pajisje zyre" },
    {
      Kodi: "014",
      Pershkrim: "Koncensione dhe të drejta të ngjashme, licensa",
    },
    { Kodi: "015", Pershkrim: "Të tjera AA jomateriale" },
    { Kodi: "016", Pershkrim: "Pajisje informative" },
    { Kodi: "017", Pershkrim: "Te tjera" },
    { Kodi: "018", Pershkrim: "Toka" },
    { Kodi: "019", Pershkrim: "Sherbime" },
  ],
};

// Async Thunks for different API calls
export const getVetefaturim = createAsyncThunk("Get_Faturim", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

//dummy operation Fiskalizim
export const getOper = createAsyncThunk("GET_Oper", async () => {
  return ["INITIAL", "DEPOSIT", "WITHDRAW"];
});

//array tipi raporte
export const getTipi = createAsyncThunk("GET_Tipi", async () => {
  return ["MA", "MP"];
});
//array tipi0 raporte
export const getTipi0 = createAsyncThunk("GET_Tipi0", async () => {
  return ["AR", "LL"];
});
//array tipi2 raporte
export const getTipi2 = createAsyncThunk("GET_Tipi2", async () => {
  return ["FTBL", "MP"];
});

//array gjendje raporte
export const getGjendje = createAsyncThunk("GET_Gjendje", async () => {
  return ["<0", "=0", ">0", "<>0"];
});

//array skema artikuj
export const getSkema = createAsyncThunk("GET_Skema", async () => {
  return [
    "001",
    "002",
    "003",
    "004",
    "005",
    "006",
    "007",
    "008",
    "009",
    "010",
    "011",
    "012",
    "013",
    "014",
    "015",
    "016",
    "017",
    "018",
    "019",
  ];
});

export const getIndexFirstFtB = createAsyncThunk(
  "Get_First_FtSh",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getIndexFirstFtSh = createAsyncThunk(
  "Get_First_FtB",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getIndexFirstOferte = createAsyncThunk(
  "Get_First_Oferte",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getIndexFirstVeprimeKontabel = createAsyncThunk(
  "Get_First_VeprimeKontabel",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getIndexFirstBa = createAsyncThunk("Get_First_Ba", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getIndexFirstAr = createAsyncThunk("Get_First_Ar", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getIndexFirstFh = createAsyncThunk("Get_First_Fh", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getIndexFirstFd = createAsyncThunk("Get_First_Fd", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getCert = createAsyncThunk("get_Cert", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getGjendjeArke = createAsyncThunk(
  "get_GjendjeArke",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getPosById = createAsyncThunk("get_PosById", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getPunonjes = createAsyncThunk("Get_Punonjes", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getReferences = createAsyncThunk("Get_Reference", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getNextKodiFb = createAsyncThunk(
  "Get_Next_Kodi_Fb",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getNextKodiFsh = createAsyncThunk(
  "Get_Next_Kodi_Fsh",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getNextKodiOferte = createAsyncThunk(
  "Get_Next_Kodi_Oferte",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getNextKodiVeprimeKontabel = createAsyncThunk(
  "Get_Next_Kodi_VeprimeKontabel",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getTotalArt = createAsyncThunk("Get_Total_Art", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getProcesi = createAsyncThunk("GET_Procesi", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});
export const getGrupi = createAsyncThunk("GET_Grupi", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getDocType = createAsyncThunk("GET_DocType", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getMonedha = createAsyncThunk("GET_MONEDHA", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getMenyraPageses = createAsyncThunk(
  "GET_MenyraPageses",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getArka = createAsyncThunk("GET_Arka", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getArkaWithTcr = createAsyncThunk(
  "Get_ArkaWithTcr",
  async (url) => {
    const response = await retryRequest(() => mainAxios(url));
    return response.data;
  }
);

export const getArkaList = createAsyncThunk("GET_ArkaList", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getBankaList = createAsyncThunk("GET_BankaList", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getArkaBanka = createAsyncThunk("GET_Arka_Banka", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getFurnitor = createAsyncThunk("GET_FURNITOR", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getKlienti = createAsyncThunk("GET_Klienti", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getAgjenti = createAsyncThunk("GET_Agjenti", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});
export const getKonfigurime = createAsyncThunk(
  "GET_Konfigurime",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);
export const getPerdorues = createAsyncThunk("GET_Perdorues", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getTarifa = createAsyncThunk("GET_Tarifa", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getVeprime = createAsyncThunk("GET_Veprime", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getLlogari = createAsyncThunk("GET_Llogari", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getArtikuj = createAsyncThunk("GET_Artikuj", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getArtikujPag = createAsyncThunk("GET_ArtikujPag", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getDepartamenti = createAsyncThunk(
  "GET_Departamenti",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getKlasifikim1 = createAsyncThunk(
  "GET_Klasifikim1",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getKlasifikim2 = createAsyncThunk(
  "GET_Klasifikim2",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getKlasifikim3 = createAsyncThunk(
  "GET_Klasifikim3",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getKlasifikim4 = createAsyncThunk(
  "GET_Klasifikim4",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getTransportuesi = createAsyncThunk(
  "GET_Transportuesi",
  async (url) => {
    const response = await mainAxios(url);
    return response.data;
  }
);

export const getSherbime = createAsyncThunk("GET_Sherbime", async (url) => {
  const response = await mainAxios(url);
  return response.data;
});

export const getMagazina = createAsyncThunk("GET_MAGAZINA", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getQytetet = createAsyncThunk("GET_Qyteti", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const getShtetet = createAsyncThunk("GET_Shteti", async (url) => {
  const response = await retryRequest(() => mainAxios(url));
  return response.data;
});

export const globalSlice = createSlice({
  name: "globalState",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    // artikuj
    builder.addCase(getArtikuj.fulfilled, (state, action) => {
      state.artikuj = action.payload;
    });

    // first banka
    builder.addCase(getIndexFirstBa.fulfilled, (state, action) => {
      state.getFirstBa = action.payload;
    });

    // first arka
    builder.addCase(getIndexFirstAr.fulfilled, (state, action) => {
      state.getFirstAr = action.payload;
    });

    // first flete dalje
    builder.addCase(getIndexFirstFd.fulfilled, (state, action) => {
      state.getFirstFD = action.payload;
    });

    // first flete hyrje
    builder.addCase(getIndexFirstFh.fulfilled, (state, action) => {
      state.getFirstFH = action.payload;
    });

    // first fature shitje
    builder.addCase(getIndexFirstFtSh.fulfilled, (state, action) => {
      state.getFirstFtSh = action.payload;
    });

    // first oferte
    builder.addCase(getIndexFirstOferte.fulfilled, (state, action) => {
      state.getFirstOferte = action.payload;
    });

    // first veprimeKontabel
    builder.addCase(getIndexFirstVeprimeKontabel.fulfilled, (state, action) => {
      state.getFirstVeprimeKontabel = action.payload;
    });

    // first fature blerje
    builder.addCase(getIndexFirstFtB.fulfilled, (state, action) => {
      state.getFirstFtB = action.payload;
    });

    // next Kodi Fature Blerje
    builder.addCase(getNextKodiFb.fulfilled, (state, action) => {
      state.nextKodiFb = action.payload;
    });

    // next Kodi Fature Shitje
    builder.addCase(getNextKodiFsh.fulfilled, (state, action) => {
      state.nextKodiFsh = action.payload;
    });

    // next Kodi Fature Shitje
    builder.addCase(getNextKodiOferte.fulfilled, (state, action) => {
      state.nextKodiOferte = action.payload;
    });

    // next Kodi Fature Shitje
    builder.addCase(getNextKodiVeprimeKontabel.fulfilled, (state, action) => {
      state.nextKodiVeprimeKontabel = action.payload;
    });

    // getCert
    builder.addCase(getCert.fulfilled, (state, action) => {
      state.cert = action.payload;
    });

    // getGjendjeArke
    builder.addCase(getGjendjeArke.fulfilled, (state, action) => {
      state.gjendjeArke = action.payload;
    });

    // getPos
    builder.addCase(getPosById.fulfilled, (state, action) => {
      state.getPos = action.payload;
    });

    // punonjes
    builder.addCase(getPunonjes.fulfilled, (state, action) => {
      state.punonjes = action.payload;
    });

    // references
    builder.addCase(getReferences.fulfilled, (state, action) => {
      state.references = action.payload;
    });

    // total artikuj
    builder.addCase(getTotalArt.fulfilled, (state, action) => {
      state.totalArt = action.payload;
    });

    // artikujPag with pagination
    builder.addCase(getArtikujPag.fulfilled, (state, action) => {
      state.artikujPag = action.payload;
    });

    // llogari
    builder.addCase(getLlogari.fulfilled, (state, action) => {
      state.llogari = action.payload;
    });

    // arka
    builder.addCase(getArka.fulfilled, (state, action) => {
      state.arka = action.payload;
    });

    // lista e arkes me tcr
    builder.addCase(getArkaWithTcr.fulfilled, (state, action) => {
      state.arkaWithTCR = action.payload;
    });

    // lista e arkes
    builder.addCase(getArkaList.fulfilled, (state, action) => {
      state.arkaList = action.payload;
    });

    // lista e arkes
    builder.addCase(getBankaList.fulfilled, (state, action) => {
      state.bankaList = action.payload;
    });

    //dummy operation Fiskalizim
    builder.addCase(getOper.fulfilled, (state, action) => {
      state.oper = action.payload;
    });

    //dummy tipi raporte
    builder.addCase(getTipi.fulfilled, (state, action) => {
      state.tipi = action.payload;
    });

    //dummy tipi0 raporte
    builder.addCase(getTipi0.fulfilled, (state, action) => {
      state.tipi0 = action.payload;
    });

    //dummy tipi2 raporte
    builder.addCase(getTipi2.fulfilled, (state, action) => {
      state.tipi2 = action.payload;
    });

    //array gjendje raporte
    builder.addCase(getGjendje.fulfilled, (state, action) => {
      state.gjendje = action.payload;
    });

    //array Skema artikuj
    builder.addCase(getSkema.fulfilled, (state, action) => {
      state.skema = action.payload;
    });

    // Vetefaturim
    builder.addCase(getVetefaturim.fulfilled, (state, action) => {
      state.vetefaturim = action.payload;
    });

    // Procesi
    builder.addCase(getProcesi.fulfilled, (state, action) => {
      state.procesi = action.payload;
    });
    // Grupi
    builder.addCase(getGrupi.fulfilled, (state, action) => {
      state.grupi = action.payload;
    });

    // DocType
    builder.addCase(getDocType.fulfilled, (state, action) => {
      state.docType = action.payload;
    });

    // arka banka
    builder.addCase(getArkaBanka.fulfilled, (state, action) => {
      state.arkabanka = action.payload;
    });

    // menyra-pageses
    builder.addCase(getMenyraPageses.fulfilled, (state, action) => {
      state.menyraPageses = action.payload;
    });
    // monedha
    builder.addCase(getMonedha.fulfilled, (state, action) => {
      state.monedha = action.payload;
    });

    // furnitori
    builder.addCase(getFurnitor.fulfilled, (state, action) => {
      state.furnitor = action.payload;
    });

    // magazina
    builder.addCase(getMagazina.fulfilled, (state, action) => {
      state.magazina = action.payload;
    });

    // klienti
    builder.addCase(getKlienti.fulfilled, (state, action) => {
      state.klienti = action.payload;
    });

    // agjenti
    builder.addCase(getAgjenti.fulfilled, (state, action) => {
      state.agjenti = action.payload;
    });

    // konfigurime
    builder.addCase(getKonfigurime.fulfilled, (state, action) => {
      state.konfigurime = action.payload;
    });

    // perdorues
    builder.addCase(getPerdorues.fulfilled, (state, action) => {
      state.perdorues = action.payload;
    });

    // tarifa
    builder.addCase(getTarifa.fulfilled, (state, action) => {
      state.tarifa = action.payload;
    });

    // veprimi
    builder.addCase(getVeprime.fulfilled, (state, action) => {
      state.veprimi = action.payload;
    });

    // departamenti
    builder.addCase(getDepartamenti.fulfilled, (state, action) => {
      state.departamenti = action.payload;
    });

    // klasifikimet1
    builder.addCase(getKlasifikim1.fulfilled, (state, action) => {
      state.klasifikim1 = action.payload;
    });

    // klasifikimet2
    builder.addCase(getKlasifikim2.fulfilled, (state, action) => {
      state.klasifikim2 = action.payload;
    });

    // klasifikimet3
    builder.addCase(getKlasifikim3.fulfilled, (state, action) => {
      state.klasifikim3 = action.payload;
    });

    // klasifikimet4
    builder.addCase(getKlasifikim4.fulfilled, (state, action) => {
      state.klasifikim4 = action.payload;
    });

    // transportuesi
    builder.addCase(getTransportuesi.fulfilled, (state, action) => {
      state.transportuesi = action.payload;
    });

    // sherbime
    builder.addCase(getSherbime.fulfilled, (state, action) => {
      state.sherbime = action.payload;
    });

    // qytetet
    builder.addCase(getQytetet.fulfilled, (state, action) => {
      state.qytetet = action.payload;
    });

    // shtetet
    builder.addCase(getShtetet.fulfilled, (state, action) => {
      state.shtetet = action.payload;
    });
  },
});

// Exporting actions generated by createSlice
export const { increment, decrement, incrementByAmount } = globalSlice.actions;

// Exporting the global slice reducer
export default globalSlice.reducer;
