import React, { useState } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import DoneIcon from "@mui/icons-material/Done";
import CloseIcon from "@mui/icons-material/Close";
import PictureAsPdfIcon from "@mui/icons-material/PictureAsPdf";
import { FaFileExcel } from "react-icons/fa";
import { Button } from "@mui/material";

const Buttons = ({
  disabledData,
  setDecission,
  onTestiChange,
  selectedProduct,
}) => {
  const [isOpen, setIsOpen] = useState(false);

  const toggleDropdown = () => {
    setIsOpen(!isOpen);
  };

  const getButtonStyle = (isDisabled) => ({
    ...dropdownButtonStyle,
    opacity: isDisabled ? 0.5 : 1, // Keep it visible when disabled
    cursor: isDisabled ? "not-allowed" : "pointer", // Indicate non-clickable state
  });

  return (
    <div style={{ position: "relative", display: "inline-block" }}>
      <Button
        onClick={toggleDropdown}
        style={{
          padding: "5px",
          cursor: "pointer",
          border: "1px solid #ccc",
          borderRadius: "4px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        <MenuIcon />
      </Button>
      {isOpen && (
        <div
          style={{
            position: "absolute",
            top: "100%",
            left: "-40px",
            backgroundColor: "white",
            border: "1px solid #ccc",
            borderRadius: "4px",
            boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
            zIndex: 1000,
          }}
        >
          <Button
            disabled={disabledData}
            style={getButtonStyle(disabledData)}
            onClick={() => onTestiChange(selectedProduct, "ACCEPTED")} // Wrap in an anonymous function
          >
            <DoneIcon style={{ color: "green" }} /> Prano
          </Button>
          <Button
            disabled={disabledData}
            style={getButtonStyle(disabledData)}
            onClick={() => onTestiChange(selectedProduct, "REFUSED")} // Wrap in an anonymous function
          >
            <CloseIcon style={{ color: "red" }} />
            Refuzo
          </Button>
          <Button disabled={disabledData} style={getButtonStyle(disabledData)}>
            <PictureAsPdfIcon style={{ color: "red" }} />
            PDF
          </Button>
          <Button disabled={disabledData} style={getButtonStyle(disabledData)}>
            <FaFileExcel style={{ color: "green" }} /> Excel
          </Button>
        </div>
      )}
    </div>
  );
};

const dropdownButtonStyle = {
  display: "flex",
  textAlign: "center",
  color: "#000",
  justifyContent: "space-between",
  width: "100%",
  padding: "10px",
  background: "none",
  border: "none",
  outline: "none",
  borderBottom: "1px solid #eee",
};

export default Buttons;
