import React, { useState, useRef } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { InputText } from "primereact/inputtext";
import { MultiSelect } from "primereact/multiselect";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import useStorage from "../../../../../hooks/useStorage";
import { Tooltip } from "primereact/tooltip";
import mainAxios from "../../../../../services/axios";
import "./PrimeGridSuperMarket.scss";
import { useToast } from "../../../../../components/context/ToastContext";

const PrimeGridSuperMarket = ({
  disabled,
  columns,
  setColumns,
  contextKey,
  currentAction,
  currentId,
  rows,
  setRows,
  isEditable = true,
  showHideColumns = true,
  gridKey,
  calculateData,
  Total,
  defaultColumnsStorage,
  missingRowNumbers,
  ...props
}) => {
  const showToast = useToast();
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonat" + gridKey
  );

  const onCellEditComplete = async (e) => {
    let { rowData, newValue, rowIndex, field } = e;
    if (field === "Total") return;
    let cellData = null;
    if (typeof rowData[field] === "number") {
      cellData = parseFloat(newValue);
    } else {
      cellData = newValue;
    }

    const newRowData = calculateData({ [field]: cellData }, { ...rowData });
    let allRows = [...rows];
    allRows[rowIndex] = newRowData;
    setRows(allRows);

    try {
      const itemForSecondRequest = {
        Master_Id: newRowData.Master_Id,
        Pershkrim: newRowData.Pershkrim,
        Vlera: newRowData.Vlera,
        Sasia: newRowData.Sasia,
        Tipi: newRowData.Tipi,
        SkontoAgjent: newRowData.SkontoAgjent,
        Cmimi: newRowData.Cmimi,
        Operator: newRowData.Operator,
        Vlera_Pa_Tvsh: newRowData.Vlera_Pa_Tvsh,
        Kodi: newRowData.Kodi,
        Tvsh: newRowData.Tvsh,
        DateGarancie: newRowData.DateGarancie,
        NrLL: newRowData.NrLL,
        Shenim2: newRowData.Shenim2,
        IdOld: newRowData.IdOld,
        Skonto_VleraAgjent: newRowData.Skonto_VleraAgjent,
        Art_ID: newRowData.Art_ID,
        Punonjes_Id: newRowData.Punonjes_Id,
        BarKod: newRowData.BarKod,
        Njesi_Kodi: newRowData.Njesi_Kodi,
        Skonto_Vlera: newRowData.Skonto_Vlera,
        Total: newRowData.Total,
        Magazina_Kodi: newRowData.Magazina_Kodi,
        TipVlere_ID: newRowData.TipVlere_ID,
        Tvsh_Vlera: newRowData.Tvsh_Vlera,
        NrSerik: newRowData.NrSerik,
        Cmimi_Kosto: newRowData.Cmimi_Kosto,
        Skonto: newRowData.Skonto,
      };
      const response = await mainAxios.put(
        `/pos/dtl/${newRowData.Id}`,
        itemForSecondRequest
      );
      if (response.status !== 200) {
        showToast("Artikulli nuk u modifikua", {
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error updating row:", error);
    }
  };

  const cellEditor = (options) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }
    return textEditor(options);
  };

  const textEditor = (options) => {
    return (
      <InputText
        type="text"
        value={options.value}
        onChange={(e) => options.editorCallback(e.target.value)}
      />
    );
  };

  const onColumnToggle = (event) => {
    let selectedColumns = event?.value;
    let orderedSelectedColumns = columns
      ?.filter((col) =>
        selectedColumns?.some((sCol) => sCol?.field === col?.field)
      )
      .map((col) => col.field);

    // Add the missingRowNumberColumn if it's not already included
    if (!orderedSelectedColumns.includes(missingRowNumberColumn.field)) {
      orderedSelectedColumns.push(missingRowNumberColumn.field);
    }

    setselectedcolumns(orderedSelectedColumns);
  };

  const filterdColumns = showHideColumns
    ? columns?.filter((column) =>
        selectedColumns?.some((field) => field === column?.field)
      )
    : columns;

  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <MultiSelect
        value={filterdColumns}
        options={columns}
        optionLabel="title"
        onChange={onColumnToggle}
        style={{ width: "3em", height: "2rem", marginLeft: "2px" }}
      />
    </div>
  );

  // Find the Kodi column and add a condition to disable selection and editing
  const dynamicColumns = filterdColumns?.map((col, i) => {
    const uniqueKey = `${col?.field}_${i}`;
    if (col.field === "Kodi") {
      return (
        <Column
          key={uniqueKey}
          style={{ height: "2.6rem", textAlign: "right" }}
          field={col?.field}
          header={col?.title}
          calculateData={calculateData}
          decimalPlaces={col?.decimalPlaces || 0}
          body={(rowData) => {
            const value = rowData[col?.field];
            let formattedValue;
            if (typeof value === "number") {
              formattedValue = value.toFixed(2); // Fixed to 2 decimal places
              formattedValue = formattedValue.toLocaleString(); // Format with commas
            } else {
              formattedValue = value;
            }
            return (
              <span
                style={{
                  width: "100%",
                  textAlign: "right",
                  justifyContent: "right",
                  fontSize: "16px",
                }}
              >
                {formattedValue}
              </span>
            );
          }}
        />
      );
    } else if (col.field === "Pershkrim") {
      return (
        <Column
          key={uniqueKey}
          style={{ height: "2.6rem", textAlign: "right" }}
          field={col?.field}
          header={col?.title}
          calculateData={calculateData}
          decimalPlaces={col?.decimalPlaces || 0}
          body={(rowData) => {
            const value = rowData[col?.field];
            let formattedValue;
            if (typeof value === "number") {
              formattedValue = value.toFixed(2); // Fixed to 2 decimal places
              formattedValue = formattedValue.toLocaleString(); // Format with commas
            } else {
              formattedValue = value;
            }
            return (
              <span
                style={{
                  width: "100%",
                  textAlign: "right",
                  justifyContent: "right",
                  fontSize: "16px",
                }}
              >
                {formattedValue}
              </span>
            );
          }}
        />
      );
    } else if (col.field === "Total") {
      return (
        <Column
          key={uniqueKey}
          style={{ height: "2.6rem", textAlign: "right" }}
          field={col?.field}
          header={col?.title}
          calculateData={calculateData}
          decimalPlaces={col?.decimalPlaces || 0}
          body={(rowData) => {
            const value = rowData[col?.field];
            let formattedValue;
            if (typeof value === "number") {
              formattedValue = value.toFixed(2); // Fixed to 2 decimal places
              formattedValue = formattedValue.toLocaleString(); // Format with commas
            } else {
              formattedValue = value;
            }
            return (
              <span
                style={{
                  width: "100%",
                  textAlign: "right",
                  justifyContent: "right",
                  fontSize: "16px",
                }}
              >
                {formattedValue}
              </span>
            );
          }}
        />
      );
    } else if (col.field === "Vlera_Pa_Tvsh") {
      return (
        <Column
          key={uniqueKey}
          style={{ height: "2.6rem", textAlign: "right" }}
          field={col?.field}
          header={col?.title}
          calculateData={calculateData}
          decimalPlaces={col?.decimalPlaces || 0}
          body={(rowData) => {
            const value = rowData[col?.field];
            let formattedValue;
            if (typeof value === "number") {
              formattedValue = value.toFixed(2); // Fixed to 2 decimal places
              formattedValue = formattedValue.toLocaleString(); // Format with commas
            } else {
              formattedValue = value;
            }
            return (
              <span
                style={{
                  width: "100%",
                  textAlign: "right",
                  justifyContent: "right",
                  fontSize: "16px",
                }}
              >
                {formattedValue}
              </span>
            );
          }}
        />
      );
    } else if (col.field === "Tvsh_Vlera") {
      return (
        <Column
          key={uniqueKey}
          style={{ height: "2.6rem", textAlign: "right" }}
          field={col?.field}
          header={col?.title}
          calculateData={calculateData}
          decimalPlaces={col?.decimalPlaces || 0}
          body={(rowData) => {
            const value = rowData[col?.field];
            let formattedValue;
            if (typeof value === "number") {
              formattedValue = value.toFixed(2); // Fixed to 2 decimal places
              formattedValue = formattedValue.toLocaleString(); // Format with commas
            } else {
              formattedValue = value;
            }
            return (
              <span
                style={{
                  width: "100%",
                  textAlign: "right",
                  justifyContent: "right",
                  fontSize: "16px",
                }}
              >
                {formattedValue}
              </span>
            );
          }}
        />
      );
    } else {
      return (
        <Column
          key={uniqueKey}
          style={{ height: "2.6rem", textAlign: "right" }}
          {...(isEditable && {
            onCellEditComplete: (e) => onCellEditComplete(e),
          })}
          {...(isEditable && { editor: (options) => cellEditor(options) })}
          field={col?.field}
          header={col?.title}
          calculateData={calculateData}
          decimalPlaces={col?.decimalPlaces || 0}
          body={(rowData) => {
            const value = rowData[col?.field];
            let formattedValue;
            if (typeof value === "number") {
              if (Number.isInteger(value)) {
                formattedValue = value.toLocaleString();
              } else {
                formattedValue = value.toLocaleString();
                formattedValue = value.toFixed(2);
              }
            } else {
              formattedValue = value;
            }
            return (
              <span
                style={{
                  width: "100%",
                  textAlign: typeof value === "number" ? "right" : "left",
                  justifyContent: typeof value === "number" ? "right" : "left",
                  fontSize: "16px",
                }}
              >
                {formattedValue}
              </span>
            );
          }}
        />
      );
    }
  });

  // Add the barcode button column
  // dynamicColumns.push(
  //   <Column
  //     key="barcodeButton"
  //     style={{ width: "3rem", textAlign: "center" }}
  //     headerStyle={{ width: "3rem", textAlign: "center" }}
  //     body={(rowData) => (
  //       <div
  //         style={{
  //           display: "flex",
  //           alignItems: "center",
  //           justifyContent: "center",
  //         }}
  //       >
  //         <PrintoBarcode rowData={rowData} />
  //       </div>
  //     )}
  //   />
  // );

  const missingRowNumberColumn = {
    field: "MissingRowNumber",
    title: "Nr",
    body: (rowData) => {
      const index = rows.findIndex((row) => row === rowData) + 1;
      return missingRowNumbers.includes(index) ? index : "";
    },
  };

  // Add the "Nr" column as the first column
  dynamicColumns.unshift(
    <Column
      key="missingRowNumberColumn"
      field={missingRowNumberColumn.field}
      header={missingRowNumberColumn.title}
      body={missingRowNumberColumn.body}
      style={{ width: "3rem", textAlign: "center" }}
      headerStyle={{ width: "3rem", textAlign: "center" }}
    />
  );

  // Add the delete button column
  dynamicColumns.push(
    <Column
      key="deleteButton"
      style={{ width: "3rem", textAlign: "center" }}
      headerStyle={{ width: "3rem", textAlign: "center" }}
      body={(rowData) => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <button
            onClick={() => deleteRow(rowData)}
            style={{
              backgroundColor: "#E72929",
              color: "#fff",
              width: "7rem",
              height: "1.5rem",
              justifyContent: "center",
              alignItems: "center",
              cursor: "pointer",
            }}
          >
            Delete
          </button>
        </div>
      )}
    />
  );

  const formatCurrency = (value, currency) => {
    const roundedValue = value.toFixed(2); // Round to two decimal places
    return currency
      ? roundedValue.toLocaleString("en-US", { style: "currency", currency })
      : roundedValue;
  };

  let columnSum = (field) => {
    let Total = 0;
    let isTotalColumn = field.toLowerCase() === "total";

    for (let row of rows) {
      Total += row[field];
    }

    // Check if the field is "Total" and format currency accordingly
    return isTotalColumn
      ? formatCurrency(Total, "ALL")
      : formatCurrency(Total, null);
  };
  let nrTotalLength = rows.length;

  const footerGroup = () => {
    let checkCols = filterdColumns?.some((column) => {
      return column?.allowSum;
    });
    if (!checkCols) return <></>;

    return (
      <ColumnGroup>
        <Row>
          <Column
            key="extraColumn"
            colSpan={1}
            footer={() => `Rekorde ${nrTotalLength}`}
          />

          {filterdColumns?.map((column, i) => {
            if (column?.allowSum) {
              const uniqueKey = `${column?.field}_${i}`;
              return (
                <Column
                  key={uniqueKey}
                  colSpan={1}
                  footer={() => columnSum(column?.field)}
                />
              );
            }
            return <Column key={`empty_${i}`} colSpan={1} />;
          })}

          {/* New column added here */}
          <Column key="extraColumn" colSpan={1} footer={() => ""} />
          <Column key="extraColumn" colSpan={1} footer={() => ""} />
        </Row>
      </ColumnGroup>
    );
  };

  let footerGr = footerGroup();

  const cm = useRef(null);

  const onColReorder = (event) => {
    const { dragIndex, dropIndex } = event;

    let columnsCopy = [...selectedColumns];
    const tempColumn = { ...selectedColumns[dragIndex] };
    columnsCopy[dragIndex] = selectedColumns[dropIndex];
    columnsCopy[dropIndex] = tempColumn;

    setselectedcolumns(columnsCopy);
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const deleteRow = async (row) => {
    try {
      const response = await mainAxios.delete(`/pos/dtl/${row.Id}`);
      if (response.status === 200) {
        let filteredRows = rows.filter((p) => p.Id !== row.Id);
        setRows(filteredRows);
      }
    } catch (error) {
      console.error("Rreshti nuk u fshi:", error);

      showToast("Rreshti nuk u fshi", {
        severity: "error",
      });
    }
  };

  const [selectionRow, setSelectionRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (selectedRowData) => {
    setSelectionRow(selectedRowData);
    setSelectedRowData(selectedRowData);
  };

  return (
    <div className="main_div_data_table_supermarket">
      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="datatable_supermarket">
        <div className="prime-react">
          <DataTable
            // footer={footer}
            reorderablecolumnsdatatable="true"
            cellSelection
            selection={selectionRow}
            // onSelectionChange={
            //   disabled ? undefined : (e) => setSelectionRow(e.value)
            // }
            onContextMenu={
              disabled ? undefined : (e) => cm.current.show(e.originalEvent)
            }
            rowClassName={(rowData) =>
              selectedRowData && selectedRowData.Id === rowData.Id
                ? "selected-row"
                : ""
            }
            onSelectionChange={(e) => {
              const selectedRowData =
                e.value.length > 0 ? e.value[0].rowData : null;
              handleRowClick(selectedRowData);
            }}
            selectionMode="multiple"
            style={{ height: "100%" }}
            contextMenuSelection={selectedProduct}
            scrollable
            contextselection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onColReorder={onColReorder}
            resizableColumns
            columnResizeMode="fit"
            responsiveLayout="scroll"
            size="small"
            value={rows}
            stripedRows
            dataKey="Id"
            autoLayout={true}
            header={header}
            footerColumnGroup={footerGr}
            globalFilterFields={[
              "BarKod",
              "Pershkrim",
              "Njesi_Kodi",
              "Sasia_Print",
              "Sasia",
            ]}
            filterDisplay="menu"
            emptyMessage="Nuk ka te dhena"
            {...props}
          >
            {dynamicColumns.map((col) => {
              return React.cloneElement(col, {
                sortable: !disabled && col.props.sortable,
                editor: disabled ? undefined : col.props.editor,
              });
            })}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PrimeGridSuperMarket;
