import React, { useRef } from "react";
import { Button, Typography } from "@mui/material";
import { PrintSharp } from "@mui/icons-material";
import PopupState, { bindTrigger, bindPopover } from "material-ui-popup-state";
import Popover from "@mui/material/Popover";
import { useReactToPrint } from "react-to-print";
import "./print80Skontrino.scss";
import html2pdf from "html2pdf.js";
import DynamicTableSkontrino80 from "./dynamicSkontrinoTable80";
import Skontrino80 from "./skontrino80";
import DownloadForOfflineIcon from "@mui/icons-material/DownloadForOffline";

import DynamicTable from "./dynamicTable";
import SimpleTableSH from "./simpletable";

import Skontrino80Tatimore from "./Sontrino80Tatimore";

import DynamicUrdhriPunes from "./dynamicUrdhriPunes";
import SkontrinoUrdhriPunes from "./skontrinoUrdhriPunes";
import DynamicTableSkontrino from "./dynamicSkontrinoTable";
import SimpleTableSHDownload from "./simpletableDownload";
import DynamicTableDownload from "./dynamicTableDownload";
import { useToast } from "../context/ToastContext";

const PrintoPdf1 = ({
  fromFShitje,
  rowsData,
  handlePostAllItems,
  handleUpdateAllItems,
  rows,
  bankaInfo,
  rowsPrint1,
  state,
  fisVerificationUrl,
  posBar,
  operator,
  menyrapageses,
  nipt,
  username,
  konfigurimeRedux,
  totalBtnDisabled,
}) => {
  const showToast = useToast();
  const componentsRef = useRef(null);
  const componentsRef2 = useRef(null);
  const componentsRef3 = useRef(null);
  const componentsRef4 = useRef(null);
  const componentsRef5 = useRef(null);
  const componentsRef6 = useRef(null);

  const handlePrint = useReactToPrint({
    content: () => componentsRef.current,
  });

  const handlePrint2 = useReactToPrint({
    content: () => componentsRef2.current,
    documentTitle: "Multi-Page Document",
  });

  const handlePrint3 = useReactToPrint({
    content: () => componentsRef3.current,
  });

  const handlePrint4 = useReactToPrint({
    content: () => componentsRef4.current,
  });

  const handlePrint5 = useReactToPrint({
    content: () => componentsRef5.current,
  });

  const updateState = async (handlePrint4) => {
    try {
      await handleUpdateAllItems(handlePrint4, { fromRegister: false });
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  const regjistro = async (handlePrint4) => {
    try {
      await handleUpdateAllItems(handlePrint4, { fromRegister: true });
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  const handleDownload = () => {
    const sectionToCapture =
      componentsRef6.current?.querySelector(".specific-section");
    if (!sectionToCapture) {
      console.error("No valid section to capture found.");
      return;
    }

    // Clone the section including children and inline styles
    const clonedSection = sectionToCapture.cloneNode(true);

    // Inline all styles for nested components
    const inlineStylesForElement = (element) => {
      const computedStyles = getComputedStyle(element);
      for (let property of computedStyles) {
        element.style[property] = computedStyles.getPropertyValue(property);
      }
      Array.from(element.children).forEach((child) =>
        inlineStylesForElement(child)
      );
    };

    inlineStylesForElement(clonedSection);

    // Retrieve stylesheets
    const stylesheets = Array.from(document.styleSheets);
    const styleText = stylesheets
      .map((sheet) => {
        try {
          return Array.from(sheet.cssRules || [])
            .map((rule) => rule.cssText)
            .join("\n");
        } catch (e) {
          return ""; // Skip inaccessible styles
        }
      })
      .join("\n");

    // Add style tag
    const styleTag = document.createElement("style");
    styleTag.type = "text/css";
    styleTag.textContent = styleText;
    clonedSection.prepend(styleTag);

    // Options for PDF rendering
    const options = {
      margin: 5,
      filename: "content.pdf",
      image: { type: "png", quality: 1 },
      html2canvas: { scale: 2, useCORS: true, allowTaint: true },
      jsPDF: { unit: "mm", format: "a4", orientation: "portrait" },
    };

    html2pdf().from(clonedSection).set(options).save();
  };
  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            {fromFShitje && (
              <Button {...bindTrigger(popupState)}>
                <PrintSharp />
              </Button>
            )}
            {totalBtnDisabled === true && (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  regjistro(handlePrint4);
                }}
                style={{
                  borderRadius: "20px",
                }}
              >
                Regjistro
              </Button>
            )}
            {posBar === true && !totalBtnDisabled && (
              <Button
                variant="contained"
                className="btn_fiskal flex"
                onClick={() => {
                  if (rowsPrint1.length === 0) {
                    showToast("Shtoni artikull", {
                      severity: "warn",
                    });
                    return;
                  } else {
                    updateState(handlePrint4);
                  }
                }}
                style={{
                  justifyContent: "space-between",
                  borderRadius: "20px",
                }}
              >
                Ndaj Punet
              </Button>
            )}

            {posBar && !totalBtnDisabled && (
              <Button
                onClick={() => {
                  handlePostAllItems(handlePrint2);
                }}
                style={{
                  color: "#fff",
                }}
              >
                Mbylle
              </Button>
            )}
            <Popover
              {...bindPopover(popupState)}
              anchorOrigin={{
                vertical: "bottom",
                horizontal: "center",
              }}
              transformOrigin={{
                vertical: "top",
                horizontal: "center",
              }}
            >
              <Typography
                sx={{
                  padding: 1,
                  display: "grid",
                  flexDirection: "column",
                }}
              >
                <Button
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint();
                    }
                  }}
                  color="primary"
                >
                  <PrintSharp /> Printo Fature Shitje
                </Button>
                <Button
                  variant="text"
                  className="btn_fiskal flex"
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handleDownload(); // Trigger the download
                    }
                  }}
                  style={{
                    justifyContent: "space-between",
                    borderRadius: "20px",
                  }}
                >
                  <DownloadForOfflineIcon />
                  Shkarko PDF
                </Button>
                <Button
                  variant="text"
                  className="btn_fiskal flex"
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint3();
                    }
                  }}
                  style={{
                    justifyContent: "space-between",
                    borderRadius: "20px",
                  }}
                >
                  <PrintSharp /> Printo Skotrino 80
                </Button>

                <Button
                  variant="text"
                  className="btn_fiskal flex"
                  onClick={() => {
                    if (rowsData.length === 0) {
                      showToast("Nuk keni asnje artikull", {
                        severity: "warn",
                      });
                    } else {
                      handlePrint5();
                    }
                  }}
                  style={{
                    justifyContent: "space-between",
                    borderRadius: "20px",
                  }}
                >
                  <PrintSharp /> Printo Skotrino 58
                </Button>
              </Typography>
            </Popover>
          </>
        )}
      </PopupState>

      {fromFShitje && (
        <div ref={componentsRef5} className="print">
          <Skontrino80Tatimore
            fromftsh={true}
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicTableSkontrino80
            rows={rowsData}
            state={state}
            fatureThjesht={true}
          />
        </div>
      )}

      {fromFShitje && (
        <div ref={componentsRef3} className="print">
          <Skontrino80Tatimore
            fromftsh={true}
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicTableSkontrino80
            rows={rowsData}
            state={state}
            fatureThjesht={true}
          />
        </div>
      )}

      {fromFShitje && (
        <div ref={componentsRef6} className="print">
          <div
            className="specific-section"
            style={{ fontSize: "12px", color: "#000" }}
          >
            <SimpleTableSHDownload
              state={state}
              konfigurimeRedux={konfigurimeRedux}
              fisVerificationUrl={fisVerificationUrl}
              menyrapageses={menyrapageses}
              nipt={nipt}
              username={username}
            />
            <DynamicTableDownload
              rows={rowsData}
              state={state}
              bankaInfo={bankaInfo}
            />
          </div>
        </div>
      )}

      {fromFShitje && (
        <div ref={componentsRef} className="print">
          <div
            className="specific-section"
            style={{ fontSize: "12px", color: "#000" }}
          >
            <SimpleTableSH
              state={state}
              konfigurimeRedux={konfigurimeRedux}
              fisVerificationUrl={fisVerificationUrl}
              menyrapageses={menyrapageses}
              nipt={nipt}
              username={username}
            />
            <DynamicTable rows={rowsData} state={state} bankaInfo={bankaInfo} />
          </div>
        </div>
      )}

      {posBar && (
        <div ref={componentsRef2} className="print">
          <Skontrino80
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          {konfigurimeRedux[0].PrintAccent ? (
            // <DynamicTableSkontrino rows={rows} state={state} />
            <DynamicTableSkontrino80 rows={rows} state={state} />
          ) : (
            <DynamicTableSkontrino80 rows={rows} state={state} />
          )}

          {rowsPrint1.length !== 0 && !konfigurimeRedux[0].PrintAccent && (
            <>
              <div className="page-break" />
              <div className="page">
                <SkontrinoUrdhriPunes
                  konfigurimeRedux={konfigurimeRedux}
                  state={state}
                />
                <DynamicUrdhriPunes rows={rowsPrint1} state={state} />
              </div>
            </>
          )}
        </div>
      )}

      {posBar && (
        <div ref={componentsRef4} className="print">
          <SkontrinoUrdhriPunes
            konfigurimeRedux={konfigurimeRedux}
            operator={operator}
            state={state}
            fisVerificationUrl={fisVerificationUrl}
            menyrapageses={menyrapageses}
            nipt={nipt}
          />
          <DynamicUrdhriPunes rows={rowsPrint1} state={state} />
        </div>
      )}
    </>
  );
};

export default PrintoPdf1;
