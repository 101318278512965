import React, { createContext, useState, useContext } from "react";
import logo from "../../assets/images/icons/instant.png";
import logo2 from "../../assets/images/icons/instantDark.png";

const LogoContext = createContext();

// Context Provider
export const LogoProvider = ({ children }) => {
  const [currentLogo, setCurrentLogo] = useState(logo);

  const toggleLogo = (theme) => {
    setCurrentLogo(theme === "light" ? logo : logo2);
  };

  return (
    <LogoContext.Provider value={{ currentLogo, toggleLogo }}>
      {children}
    </LogoContext.Provider>
  );
};

// Custom Hook
export const useLogo = () => useContext(LogoContext);
