import React, { useState, useRef } from "react";
import "./Bordero.scss";
import { Button, ButtonGroup, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import mainAxios from "../../../services/axios";
import Checkbox from "@mui/material/Checkbox";
import { getKonfigurime } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../components/context/ToastContext";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const KonfigArtikuj = ({ isMobile }) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  const currentId = {
    Id: konfigurimeRedux[0]?.Id || "",
  };

  const [formData, setFormData] = useState({
    OreNeDite: konfigurimeRedux[0]?.OreNeDite || "",
    DitePuneMujore: konfigurimeRedux[0]?.DitePuneMujore || "",
    PushimeVjetoreDite: konfigurimeRedux[0].PushimeVjetoreDite || "",
    OreNdaluar: konfigurimeRedux[0]?.OreNdaluar || "",
    OrePushimi: konfigurimeRedux[0]?.OrePushimi || "",
    Hene: konfigurimeRedux[0]?.Hene ?? false,
    Marte: konfigurimeRedux[0]?.Marte ?? false,
    Enjte: konfigurimeRedux[0]?.Enjte ?? false,
    Merkure: konfigurimeRedux[0]?.Merkure ?? false,
    Premte: konfigurimeRedux[0]?.Premte ?? false,
    Shtune: konfigurimeRedux[0]?.Shtune ?? false,
    Diele: konfigurimeRedux[0]?.Diele ?? false,
  });

  const handleChangeNdermarrje = async () => {
    const payload = {
      ...formData,
    };

    try {
      const response = await mainAxios.put(
        `/konfigurim/${currentId.Id}`,
        payload
      );

      if (response.status === 200) {
        showToast("U dergua me sukses", {
          severity: "success",
        });
        dispatch(getKonfigurime("/konfigurim"));
      }
    } catch (error) {
      console.error(error);

      showToast("Gabim ne dergimin e te dhenave", {
        severity: "error",
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      <Grid item>
        <Grid container direction="row" sx={{ marginTop: 2 }}>
          {" "}
          <Box sx={{ flexGrow: 2 }}>
            <Grid container direction="column" spacing={1.5}>
              <Typography variant="h6" style={{ color: "blue" }}>
                Pune
              </Typography>

              <Grid container direction="row" item xs={12} alignItems="center">
                <Typography variant="body1">(OD) - Ore ne dite</Typography>
                <TextField
                  label="Ore"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.OreNeDite || ""} // Ensure value is managed in the state
                  onChange={(e) => handleChange("OreNeDite", e.target.value)} // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.OreNeDite !== undefined &&
                      formData.OreNeDite !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Typography variant="body1">(DP) - Dite Pune</Typography>
                  <TextField
                    label="Dite"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "20px",
                    }}
                    value={formData.DitePuneMujore || ""} // Ensure value is managed in the state
                    onChange={(e) =>
                      handleChange("DitePuneMujore", e.target.value)
                    } // Update the state on change
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                    InputLabelProps={{
                      shrink:
                        formData.DitePuneMujore !== undefined &&
                        formData.DitePuneMujore !== "", // Ensure the label stays shrunk when there's a value
                    }}
                  />
                </Grid>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Typography variant="body1">Dite Pushime Vjetore</Typography>
                  <TextField
                    label="Dite"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "20px",
                    }}
                    value={formData.PushimeVjetoreDite || ""} // Ensure value is managed in the state
                    onChange={(e) =>
                      handleChange("PushimeVjetoreDite", e.target.value)
                    } // Update the state on change
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                    InputLabelProps={{
                      shrink:
                        formData.PushimeVjetoreDite !== undefined &&
                        formData.PushimeVjetoreDite !== "", // Ensure the label stays shrunk when there's a value
                    }}
                  />
                </Grid>
              </Grid>
              <Divider sx={{ width: "100%", marginY: 1 }} />
              <Grid container direction="row" item xs={8} alignItems="center">
                <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Typography variant="body1">Ore Ndaluar</Typography>
                  <TextField
                    label="Dite"
                    variant="outlined"
                    type="number"
                    size="small"
                    sx={{
                      width: "100px",
                      marginLeft: "10px",
                      marginRight: "20px",
                    }}
                    value={formData.OreNdaluar || ""} // Ensure value is managed in the state
                    onChange={(e) => handleChange("OreNdaluar", e.target.value)} // Update the state on change
                    inputProps={{
                      min: 1,
                      step: 1,
                    }}
                    InputLabelProps={{
                      shrink:
                        formData.OreNdaluar !== undefined &&
                        formData.OreNdaluar !== "", // Ensure the label stays shrunk when there's a value
                    }}
                  />
                </Grid>
              </Grid>
              <Grid
                container
                direction="row"
                item
                xs={12}
                style={{ marginBottom: 5 }}
                alignItems="center"
              >
                <Typography variant="body1">Ore Pushimi</Typography>
                <TextField
                  label="Dite"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.OrePushimi || ""} // Ensure value is managed in the state
                  onChange={(e) => handleChange("OrePushimi", e.target.value)} // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.OrePushimi !== undefined &&
                      formData.OrePushimi !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
              </Grid>
              <Divider sx={{ width: "100%", marginY: 1 }} />
              <Typography variant="h6" style={{ color: "blue" }}>
                Ditet e punes
              </Typography>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Hene}
                  onChange={(e) => handleChange("Hene", e.target.checked)}
                />
                <Typography variant="body1">Hene</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Marte}
                  onChange={(e) => handleChange("Marte", e.target.checked)}
                />
                <Typography variant="body1">Marte</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Merkure}
                  onChange={(e) => handleChange("Merkure", e.target.checked)}
                />
                <Typography variant="body1">Merkure</Typography>
              </Grid>

              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Enjte}
                  onChange={(e) => handleChange("Enjte", e.target.checked)}
                />
                <Typography variant="body1">Enjte</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Premte}
                  onChange={(e) => handleChange("Premte", e.target.checked)}
                />
                <Typography variant="body1">Premte</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Shtune}
                  onChange={(e) => handleChange("Shtune", e.target.checked)}
                />
                <Typography variant="body1">Shtune</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Diele}
                  onChange={(e) => handleChange("Diele", e.target.checked)}
                />
                <Typography variant="body1">Diele</Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* </Grid> */}
      </Grid>
      {/* </div> */}
      <footer>
        <Grid item xs={8}>
          <ButtonGroup sx={!isMobile ? { marginTop: 5 } : { marginTop: 5 }}>
            <Button
              sx={{ marginRight: 6 }}
              variant="contained"
              component="span"
              onClick={handleChangeNdermarrje}
            >
              Ruaje
            </Button>
          </ButtonGroup>
        </Grid>
      </footer>
    </>
  );
};

export default KonfigArtikuj;
