// TabsContainer.js
import React from "react";
import TabKlienti from "../../fature-blerje/tabet/Klienti/TabKlienti";
import TabPaguar from "../../fature-blerje/tabet/Paguar/TabPaguar";
import TabMagazina from "../../fature-blerje/tabet/Magazina/TabMagazina";
import TabTjera from "../../fature-blerje/tabet/Tjera/TabTjera";
import TabTrans from "../../fature-blerje/tabet/Transportuesi/TabTrans";

const TabsContainer = ({
  toggleState,
  disabled,
  setState,
  state,
  handleChange,
  setSearchString,
  searchString,
  handleFunctionKlienti,
  qytetet,
  shtetet,
  klienti,
  setTipiArkes,
  setSelectedArkaMon,
  rows,
  menyrapageses,
  tipiArkes,
  buttonClicked,
  formatDate,
  updateDepKodi,
  fetchFShitje,
  setBankaInfo,
}) => {
  return (
    <div>
      {/* Tab 1: Klienti */}
      <TabKlienti
        qytetKlienti={true}
        nipt={true}
        shenim={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        state={state}
        handleChange={handleChange}
        setSearchString={setSearchString}
        searchString={searchString}
        handleKlientiType={handleFunctionKlienti}
        qytetet={qytetet}
        shtetet={shtetet}
        klienti={klienti}
      />

      {/* Tab 2: Paguar */}
      <TabPaguar
        fatureType="Fature_Shitje_Id"
        setTipiArkes={setTipiArkes}
        setBankaInfo={setBankaInfo}
        setSelectedArkaMon={setSelectedArkaMon}
        setState={setState}
        popupContextKey="FatureShitje"
        formatDate={formatDate}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
        rows={rows}
        menyrapageses={menyrapageses}
        tipiArkes={tipiArkes}
        buttonClicked={buttonClicked}
      />

      {/* Tab 3: Magazina */}
      <TabMagazina
        fleteDaljeBtn={true}
        disabled={disabled}
        formatDate={formatDate}
        toggleState={toggleState === 3}
        state={state}
        qytetet={qytetet}
        buttonClicked={buttonClicked}
        handleChange={handleChange}
      />

      {/* Tab 4: Tjera */}
      <TabTjera
        formatDate={formatDate}
        toggleState={toggleState === 4}
        disabled={disabled}
        state={state}
        updateDepKodi={updateDepKodi}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
        fetchPost={fetchFShitje}
      />

      {/* Tab 5: Trans */}
      <TabTrans
        formatDate={formatDate}
        toggleState={toggleState === 6}
        disabled={disabled}
        includeShenim={true}
        state={state}
        setState={setState}
        handleChange={handleChange}
        fetchTShitje={fetchFShitje}
      />
    </div>
  );
};

export default TabsContainer;
