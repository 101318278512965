// material-ui
import useMediaQuery from "@mui/material/useMediaQuery";
// import IconButton from "@mui/material/IconButton";
// import Link from "@mui/material/Link";
import Box from "@mui/material/Box";

// project import
import Search from "./Search";
import Profile from "./Profile";
import Notification from "./Notification";
import MobileSection from "./MobileSection";
import { useUser } from "../../../../zustand/common";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// project import
// import { GithubOutlined } from "@ant-design/icons";

// ==============================|| HEADER - CONTENT ||============================== //

export default function HeaderContent() {
  const downLG = useMediaQuery((theme) => theme.breakpoints.down("lg"));
  const { logout } = useUser();
  const navigate = useNavigate();
  const [logoutbtn, setLogout] = useState(false);

  useEffect(() => {
    if (!logoutbtn) {
      const handleBeforeUnload = (e) => {
        e.preventDefault();
        e.returnValue = "";
      };

      const handleUnload = () => {
        navigate("/login");
        logout();
      };

      window.addEventListener("beforeunload", handleBeforeUnload);
      window.addEventListener("unload", handleUnload);

      return () => {
        window.removeEventListener("beforeunload", handleBeforeUnload);
        window.removeEventListener("unload", handleUnload);
      };
    } else {
      logout();
    }
  }, [logout, logoutbtn]);

  return (
    <>
      {!downLG && <Search />}
      {downLG && <Box sx={{ width: "100%", ml: 1 }} />}
      {/* <IconButton
        component={Link}
        href="https://github.com/codedthemes/mantis-free-react-admin-template"
        target="_blank"
        disableRipple
        color="secondary"
        title="Download Free Version"
        sx={{ color: 'text.primary', bgcolor: 'grey.100' }}
      >
        <GithubOutlined />
      </IconButton> */}

      {/* <Notification /> */}
      <Profile setLogout={setLogout} />
      {/* {!downLG && <Profile />} */}
      {/* {downLG && <MobileSection />} */}
    </>
  );
}
