import React, { useEffect, useState, memo, useContext, useRef } from "react";
import PrimeGrid from "../../components/primeReact/PrimeGrid";
import { Panel } from "primereact/panel";
import { TextField, createFilterOptions, Autocomplete } from "@mui/material";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import MainTab from "../fature-blerje/tabet/MainTab/MainTab";
import PaneliTabeve from "../fature-blerje/tabet/PaneliTabeve";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
// import PrintoPdf1 from "../../components/ReactPDF/PrintoPdf1";
import PrintoPdfFleteHyrje from "../../components/ReactPDF/FleteHyrjePdf/PrintoPdf";
import "../fature-blerje/FatureBlerje.scss";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import Template from "../dynamicComponent/Template";
import { shtoArtikull, calculateData } from "../dynamicComponent/FleteArtCalc";
import ConfirmationDialog from "../dynamicComponent/ConfirmationDialog";
import { useLocation } from "react-router-dom";
import { getIndexFirstFh } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import { useToast } from "../../components/context/ToastContext";

const Inventar = ({
  currentAction,
  setCurrentAction,
  isOnline,
  isMobile,
  disabled,
  setDisabled,
  drawerOpen,
  setDrawerOpen,
}) => {
  const showToast = useToast();
  const dispatch = useDispatch();
  // const { globalSmallApi } = useGlobalSmallApi();
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [hidePupUp, setHidePupUp] = useState(false);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const monedhat = useSelector((state) => state.globalSlice.monedha);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [showConfirmation, setShowConfirmation] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const location = useLocation();
  const { nmrFB, fromFatureBlerje } = location.state || {};

  //thirrja e userit aktual
  const [username, setUsername] = useState("");

  // Define fetchData outside the useEffect
  const fetchData = async () => {
    const storedData = localStorage.getItem("loginData");
    if (storedData) {
      const parsedData = JSON.parse(storedData);
      setUsername(parsedData.username);
    }
  };

  // const fetchLastNumberFature = async () => {
  //   const firstFature = await mainAxios.get(`/first/fh`);
  //   setLastNumber(firstFature.data.index);
  // };

  useEffect(() => {
    fetchData();
    // fetchLastNumberFature();
  }, []);

  // redux magazina (get requesti i Magazines)
  const magazina = useSelector((state) => state.globalSlice.magazina);
  const defaultMag = magazina.map((item) => item.Kodi);
  let numMag = magazina.length;

  // redux skema-veprimi (get requesti i veprimit)
  const veprimiRedux = useSelector((state) => state.globalSlice.veprimi);

  // Filter veprimiRedux based on Tabela value
  const fleteHyrjeObjects = veprimiRedux.filter(
    (item) => item.Tabela === "Flete_Hyrje"
  );

  // redux mer indexin e fatures se pare
  const firstFleteHIndex = useSelector((state) => state.globalSlice.getFirstFH);

  const veprimi = fleteHyrjeObjects.map((item) => item.Kodi);

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = () => {
    setCurrentAction("add");
    setCurrentId(lastId);
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    if (state.Fature_Blerje_Id !== null) {
      setShowConfirmation(true); // Show the confirmation dialog
    } else {
      // setFiskalDisable(true)
      setCurrentAction("modify");
      setDisabled(false);
      setButtonClicked(true);
    }
  };

  // Function to handle confirmation
  const handleConfirmation = (confirm) => {
    setShowConfirmation(false); // Hide the confirmation dialog
    if (confirm) {
      setDisabled(false);
      setButtonClicked(true);
      // setFiskalDisable(true)
      setCurrentAction("modify");
    }
  };

  const [rows, setRows] = useState([]);

  const [index, setIndex] = useState(0);

  const columnsSF = [
    { field: "Id", title: "Id" },
    { field: "Kodi", title: "Kodi" },
    { field: "Data", title: "Data" },
    { field: "KLFU_Kodi", title: "Shenim 1" },
    { field: "KLFU_Pershkrim", title: "Shenim 2" },
    { field: "Vlera", title: "Vlera" },
    { field: "Operator", title: "Operator" },
  ];

  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "NrSerik", title: "NrSerik", filter: "agMultiColumnFilter" },
    { field: "Pershkrim", title: "Pershkrim", impMinWidth: true },
    { field: "Shenim_1", title: "Shenim 1" },
    { field: "Shenim_2", title: "Shenim 2" },
    { field: "Sasia", title: "Sasia", allowSum: true },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi", title: "Cmimi" },
    // { field: "Vlera_Fiskal", title: "Vlera Fiskale" },
    // { field: "Cmimi_Fiskal", title: "Cmimi Fiskal" },
    { field: "CmimiShites", title: "Cmimi Shites", allowSum: true },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Dep_Kodi", title: "Departamenti" },
    { field: "Magazina_Kodi", title: "Magazina" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, state);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const formatDate = (dateString, addTime = false) => {
    let date;

    if (dateString instanceof Date) {
      date = dateString;
    } else if (typeof dateString === "string") {
      date = new Date(dateString);

      if (isNaN(date.getTime())) {
        date = new Date();
      }
    } else {
      date = new Date();
    }

    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0");
    const day = String(date.getDate()).padStart(2, "0");
    const formattedDate = `${year}-${month}-${day}`;

    if (addTime) {
      return `${formattedDate}T00:00:00`;
    }

    return formattedDate;
  };

  let defaultState = {
    Aktiv: 1,
    Kodi_ORG: "",
    FLD_Magazina_Kodi: null,
    Magazina_Kodi: defaultMag[0],
    Data: "",
    Kodi: "", //newKodiFsh
    Veprimi: veprimi[0],
    Shenim_1: "",
    Shenim_2: "",
    Dep_Kodi: "",
  };

  const [state, setState] = useState(defaultState);
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);
  const [number, setNumber] = useState(1);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);

  useEffect(() => {
    if (fromFatureBlerje) {
      setNumber(nmrFB);
      fetchFHyrje(nmrFB);
    } else {
      fetchFHyrje(number);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchFHyrje = async (number) => {
    try {
      showLoading();
      const response = await mainAxios(`/last/faturafh/${number}`);

      const responseTotalRows = await dispatch(getIndexFirstFh("/first/fh"));
      const total_data_num = responseTotalRows.payload.total_rows;

      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      handleChangeValue(response.data.Id);

      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      const responseData = response.data;
      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(
          `/flete_hyrje/dtl/${responseData.Id}`
        );
        const dtlData = dtlResponse.data;
        setState(responseData);
        setResponseData(responseData);
        setLastObject(responseData);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = async (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);

    if (type === "first") {
      setNumber(firstFleteHIndex.total_rows);
      fetchFHyrje(firstFleteHIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchFHyrje(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchFHyrje(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "last") {
      setNumber(1);
      fetchFHyrje(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/flete/hyrje/index/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchFHyrje(numberIndex);

        showToast("Flete Hyrja u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e flete hyrjes.", { severity: "error" });

      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  //Butoni "Regjistro" perdor currentAction qe te vendosi, ca requesti te bej.
  const handleRegister = async () => {
    if (!state.Magazina_Kodi) {
      showToast("Cakto Magazinen!", { hideProgressBar: true });
      return;
    }

    if (rows.length === 0) {
      showToast("Shto Artikuj ose anullo faturen!");
      return;
    } else {
      try {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/flete_hyrje/dtl/${id}`);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      } finally {
        setDeletedRowIds([]);
      }
    }

    if (currentAction === "add") {
      handleAdd();
      setCurrentAction(null);
    } else if (currentAction === "modify") {
      handleModify();
      setCurrentAction(null);
    }
  };

  // Kur Klikohet butoni i "SHTIM"
  const handleAdd = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Aktiv: row.Aktiv,
          Cmimi: row.Cmimi,
          CmimiPeshaKG: row.CmimiPeshaKG,
          Cmimi_Kosto: row.Cmimi_Kosto,
          DataSk: row.DataSk,
          Dep_Kodi: row.Dep_Kodi,
          Inserted: row.Inserted,
          Kodi: row.Kodi,
          Kodi1: row.Kodi1,
          Kodi2: row.Kodi2,
          Kodi3: row.Kodi3,
          Kodi4: row.Kodi4,
          Kodi5: row.Kodi5,
          Koeficient: row.Koeficient,
          Vlera_Fiskal: row.Vlera_Fiskal,
          Cmimi_Fiskal: row.Cmimi_Fiskal,
          Cmimi_Ftbl: row.Cmimi_Ftbl,
          CmimiShites: row.CmimiShites,
          Njesi_Kodi: row.Njesi_Kodi,
          Njesi_Kodi_Origjine: row.Njesi_Kodi,
          NrSerik: row.NrSerik,
          Operator: row.Operator,
          OperatorInsert: row.OperatorInsert,
          Origjina_ID: row.Origjina_ID,
          Pershkrim: row.Pershkrim,
          PeshaKg: row.PeshaKg,
          Sasia: row.Sasia,
          SasiaPak: row.SasiaPak,
          Selektuar: row.Selektuar,
          BarKod: row.BarKod,
          Vlera: row.Vlera,
          TipPaketimi_Kodi: row.TipPaketimi_Kodi,
          Updated: row.Updated,
          Volumi: row.Volumi,
        };
        return item;
      });
    };
    try {
      showLoading();
      //  POST request i 1 per FATURE SHITJE
      const response = await mainAxios.post(`/flete_hyrje`, {
        Aktiv: Number(state.Aktiv),
        Kodi_ORG: state.Kodi_ORG,
        Magazina_Kodi: state.Magazina_Kodi,
        Data: formatDate(state.Data, true),
        Kodi: Number(state.Kodi),
        Veprimi: state?.Veprimi,
        Dep_Kodi: state.Dep_Kodi,
        Shenim_1: state.Shenim_1,
        Shenim_2: state.Shenim_2,
        // Flete_hyrje_Id: state.FLDID,
        FLD_Magazina_Kodi: state.FLD_Magazina_Kodi,
        Operator: String(username),
      });
      if (response.status === 201) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;
        showToast("Flete Hyrja u rregjistrua.", {
          severity: "success",
        });

        // POST request i 2 per DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        await mainAxios.post(`/flete_hyrje/dtl/bulk`, dtlDataArray);
        setCurrentAction(null);
        fetchFHyrje(1);
      }
    } catch (error) {
      console.error("Error in POST request:", error);
      showToast("Gabim gjatë regjistrimit të faturës" + error.message, {
        severity: "error",
      });
    } finally {
      // fetchDtl();
      hideLoading();
    }
  };

  // Kur Klikohet butoni i "MODIFIKO"
  const handleModify = async () => {
    const getItemsForSecondRequest = (rows, newEntryID) => {
      return rows.map((row) => {
        let item = {
          Master_Id: newEntryID,
          Aktiv: row.Aktiv,
          Cmimi: row.Cmimi,
          Id: row.Id,
          CmimiPeshaKG: row.CmimiPeshaKG,
          Cmimi_Kosto: row.Cmimi_Kosto,
          DataSk: row.DataSk,
          Dep_Kodi: row.Dep_Kodi,
          Inserted: row.Inserted,
          Kodi: row.Kodi,
          Kodi1: row.Kodi1,
          Kodi2: row.Kodi2,
          Kodi3: row.Kodi3,
          Kodi4: row.Kodi4,
          Kodi5: row.Kodi5,
          Vlera_Fiskal: row.Vlera_Fiskal,
          Cmimi_Fiskal: row.Cmimi_Fiskal,
          CmimiShites: row.CmimiShites,
          Cmimi_Ftbl: row.Cmimi_Ftbl,
          Koeficient: row.Koeficient,
          Njesi_Kodi: row.Njesi_Kodi,
          Njesi_Kodi_Origjine: row.Njesi_Kodi,
          NrSerik: row.NrSerik,
          Operator: row.Operator,
          OperatorInsert: row.OperatorInsert,
          Origjina_ID: row.Origjina_ID,
          Pershkrim: row.Pershkrim,
          PeshaKg: row.PeshaKg,
          Sasia: row.Sasia,
          BarKod: row.BarKod,
          SasiaPak: row.SasiaPak,
          Selektuar: row.Selektuar,
          TipPaketimi_Kodi: row.TipPaketimi_Kodi,
          Updated: row.Updated,
          Vlera: row.Vlera,
          Volumi: row.Volumi,
        };
        return item;
      });
    };
    try {
      showLoading();
      // Requesti i 1 PUT per Flete Hyrjen
      const response = await mainAxios.put(`/flete_hyrje/${currentId}`, {
        Aktiv: Number(state.Aktiv),
        Kodi_ORG: state.Kodi_ORG,
        Magazina_Kodi: state.Magazina_Kodi,
        Data: formatDate(state.Data, true),
        Kodi: Number(state.Kodi),
        Veprimi: state?.Veprimi,
        Dep_Kodi: state.Dep_Kodi,
        Shenim_1: state.Shenim_1,
        Shenim_2: state.Shenim_2,
        // Flete_hyrje_Id: state.FLDID,
        FLD_Magazina_Kodi: state.FLD_Magazina_Kodi,
        Operator: String(username),
      });
      if (response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        const newEntryID = response.data.Id;

        showToast("Inventar u modifikua!", {
          severity: "success",
        });

        //  PUT request i 2 per modifikimin e DTL
        const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
        await mainAxios.put(`/flete_hyrje/dtl/bulk`, dtlDataArray);
      } else {
        showToast("Gabim gjatë modifikimit të artikujve.", {
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error in POST request:", error);
      showToast("Gabim gjatë modifikimit të Flete Hyrjes.", {
        severity: "error",
      });
    } finally {
      fetchFHyrje(number);
      hideLoading();
      setCurrentAction(null);
    }
  };

  const handleMagazinaChange = (event, newValue) => {
    if (newValue) {
      updateMagazinaKodi(newValue);
    }
  };

  const updateMagazinaKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Magazina_Kodi: newKodi,
    }));
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;

    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
  };

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      fatureDisabled={fatureDisabled}
      fleteName={"Inventar"}
      fatureName={"Fature Blerje"}
      popupContextKeyType={true}
      popupContextKey="FleteHyrje"
      contexDelete={"Inventar"}
      onAddClick={onAddClick}
      onModifyClick={onModifyClick}
      // fetchLastNumberFature={fetchLastNumberFature}
      number={number}
      handleRegister={handleRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchFHyrje={fetchFHyrje}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );

  const button6Text = isMobile ? "" : "Tjera";

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AutoAwesomeMotionIcon />}
      isMobile={isMobile}
    />
  );

  const TabiKryesor = (
    <MainTab
      fleteHyrje={true}
      formatDate={formatDate}
      includeDateField={true}
      includeNrOrigjinesField={true}
      includeKursiField={false}
      includeMonedhaSelect={false}
      includeKodiField={true}
      includeModalList={true}
      includeMagazina={true}
      disabled={disabled}
      state={state}
      handleMagazinaChange={handleMagazinaChange}
      magazina={magazina}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
      numMag={numMag}
    />
  );

  const BtnPrint = (
    <PrintoPdfFleteHyrje className="print" state={state} rows={rows} />
  );

  const smallTabsKM = (
    <div>
      {/* tab4 */}
      <div className={"content active-content"}>
        <div className="grid grid-cols-2 gap-2">
          {/* <Form.Select
            disabled={disabled}
            label="Veprimi"
            className="bg-white"
            value={state?.Veprimi}
            onChange={(e) => {
              handleChange("Veprimi", e.target.value);
            }}
          >
            <option label="Veprim" />
            {veprimi.map((kodiValue) => {
              return (
                <option key={kodiValue} value={kodiValue}>
                  {kodiValue}
                </option>
              );
            })}
          </Form.Select> */}

          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-demo13"
            options={fleteHyrjeObjects}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option ? option.Kodi + " - " + option.Pershkrim : ""
            }
            onChange={(e, newValue) =>
              handleChange("Veprimi", newValue ? newValue.Kodi : "")
            }
            value={
              fleteHyrjeObjects.find(
                (qytet) => qytet.Kodi === state?.Veprimi
              ) || null
            }
            size="small"
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Veprimi" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
          />

          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim 1"
            value={state?.Shenim_1 || ""}
            onChange={(e) => handleChange("Shenim_1", e.target.value)}
            size="small"
          />

          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim 2"
            value={state?.Shenim_2 || ""}
            onChange={(e) => handleChange("Shenim_2", e.target.value)}
            size="small"
          />
        </div>
      </div>
    </div>
  );

  return (
    <div className={`mainDiv_ftsh ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          <div className="main-container p-2">
            <div className="top_panel_fsh">
              <Panel
                headerTemplate={
                  <Template
                    options={{
                      collapsed: isPanelToggleable,
                      onTogglerClick: () =>
                        setIsPanelToggleable(!isPanelToggleable),
                    }}
                    createdBy={state.Operator}
                    isMobile={isMobile}
                    fatureName="Inventar"
                    currentId={currentId}
                    nxtBtnDisable={nxtBtnDisable}
                    prvBtnDisable={prvBtnDisable}
                    shikoFaturen={shikoFaturen}
                    handleTestiChange={handleTestiChange}
                    columnsSF={columnsSF}
                    contextKey={"flete/hyrje"}
                    visible={visible}
                    setVisible={setVisible}
                    isOnline={isOnline}
                    gridKey="FleteHyrje"
                  />
                }
                toggleable
                collapsed={isPanelToggleable}
                onToggle={(e) => setIsPanelToggleable(!isPanelToggleable)}
                style={{ height: "100%" }}
              >
                <div className="bg-white">
                  {!isMobile ? (
                    <>
                      <div className="paneli-tabeve">
                        <div className="paneli-tabeve1">{PaneliKryesor}</div>
                        <div className="paneli-tabeve2">{PaneliDytesor}</div>
                        <div className="paneli-fis-print">{BtnPrint}</div>
                      </div>
                      <div className={"below-tabs"}>
                        <div className="below-tabs1">{TabiKryesor}</div>
                        <div className="below-tabs2">{smallTabsKM}</div>
                        <div className="below-tabs3"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliKryesor}
                        {BtnPrint}
                      </div>
                      <div style={{ margin: "5px" }}>{TabiKryesor}</div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliDytesor}
                      </div>
                      <div
                        style={{
                          margin: 5,
                        }}
                      >
                        {smallTabsKM}
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            </div>

            <div className="middle_fsh"></div>

            <div className="dataTable_fsh">
              <PrimeGrid
                deletedRowIds={deletedRowIds}
                setDeletedRowIds={setDeletedRowIds}
                gridKey="FleteHyrje"
                contextKey={"/flete_hyrje/dtl"}
                isEditable={true}
                columns={columns}
                defaultColumnsStorage={defaultColumnsStorage}
                setColumns={setColumns}
                disabled={disabled}
                rows={rows}
                setRows={setRows}
                calculateData={handleCalculateData}
                shtoArtikull={handleShtoArtikull}
                currentAction={currentAction}
                currentId={currentId}
              />
            </div>
          </div>
          <ConfirmationDialog
            fleteName={"Inventar"}
            fatureName={"Fature Blerje"}
            showConfirmation={showConfirmation}
            handleConfirmation={handleConfirmation}
            onClose={() => setShowConfirmation(false)}
          />
        </div>{" "}
      </div>
      {/* {!isMobile && <Footer className="footer-position" />} */}
    </div>
  );
};

export default memo(Inventar);
