import React, { useState, useRef } from "react";
import "./Peshore.scss";
import { Button, ButtonGroup, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import mainAxios from "../../../services/axios";
import Checkbox from "@mui/material/Checkbox";
import { getKonfigurime } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import InsertDataForm from "./InsertDataForm";
import { useToast } from "../../../components/context/ToastContext";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const KonfigArtikuj = ({ isMobile }) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  const currentId = {
    Id: konfigurimeRedux[0]?.Id || "",
  };

  const [formData, setFormData] = useState({
    Peshore_Db: konfigurimeRedux[0]?.Peshore_Db || "",
    Peshore_Sql: konfigurimeRedux[0]?.Peshore_Sql || "",
    Peshore_Kontrollo: konfigurimeRedux[0].Peshore_Kontrollo ?? false,
    Peshore_Kodi_Start: konfigurimeRedux[0]?.Peshore_Kodi_Start || "",
    Peshore_Kodi_Length: konfigurimeRedux[0]?.Peshore_Kodi_Length || "",
    Peshore_Sasia_Start: konfigurimeRedux[0]?.Peshore_Sasia_Start || "",
    Peshore_Sasia_Length: konfigurimeRedux[0]?.Peshore_Sasia_Length || "",
    Peshore_Sasia_Pjesto: konfigurimeRedux[0]?.Peshore_Sasia_Pjesto || "",
  });

  const handleChangeNdermarrje = async () => {
    const payload = {
      ...formData,
    };

    try {
      const response = await mainAxios.put(
        `/konfigurim/${currentId.Id}`,
        payload
      );

      if (response.status === 200) {
        showToast("U dergua me sukses", {
          severity: "success",
        });
        dispatch(getKonfigurime("/konfigurim"));
      }
    } catch (error) {
      console.error(error);
      showToast("Gabim ne dergimin e te dhenave", {
        severity: "error",
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      <Grid item>
        <Grid container direction="row" sx={{ marginTop: 2 }}>
          {" "}
          <Box sx={{ flexGrow: 2 }}>
            <Grid container direction="column" spacing={1.5}>
              <Grid container direction="row" item xs={12} alignItems="center">
                <Typography variant="body1">Database (peshore) </Typography>
                <TextField
                  label="Peshore DB"
                  variant="outlined"
                  size="small"
                  sx={{
                    width: "300px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.Peshore_Db || ""} // Ensure value is managed in the state
                  onChange={(e) => handleChange("Peshore_Db", e.target.value)} // Update the state on change
                />
              </Grid>
              <Grid container direction="row" item xs={12} alignItems="center">
                <Typography variant="body1">SQL (peshore) </Typography>
                <TextField
                  label="Peshore SQL"
                  variant="outlined"
                  size="small"
                  multiline // This makes the TextField allow multiple lines of text
                  minRows={4}
                  maxRows={6}
                  sx={{
                    width: "300px",
                    height: "auto",
                    marginLeft: "40px",
                    marginRight: "10px",
                  }}
                  value={formData.Peshore_Sql || ""}
                  onChange={(e) => handleChange("Peshore_Sql", e.target.value)}
                />
              </Grid>
              <Divider sx={{ width: "100%", marginY: 1 }} />
              <Typography variant="h6" style={{ color: "blue" }}>
                Ndarja e barkodit
              </Typography>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.Peshore_Kontrollo}
                  onChange={(e) =>
                    handleChange("Peshore_Kontrollo", e.target.checked)
                  }
                />
                <Typography variant="body1">Kontrollo (peshore)</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Typography variant="body1">Barkodi (pozicionet)</Typography>
                <TextField
                  label="Fillon"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.Peshore_Kodi_Start || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("Peshore_Kodi_Start", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.Peshore_Kodi_Start !== undefined &&
                      formData.Peshore_Kodi_Start !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <TextField
                  label="Gjatesia"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.Peshore_Kodi_Length || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("Peshore_Kodi_Length", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.Peshore_Kodi_Length !== undefined &&
                      formData.Peshore_Kodi_Length !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
              </Grid>

              <Grid container direction="row" item xs={8} alignItems="center">
                <Typography variant="body1">Sasia (pozicionet)</Typography>
                <TextField
                  label="Fillon"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.Peshore_Sasia_Start || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("Peshore_Sasia_Start", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.Peshore_Sasia_Start !== undefined &&
                      formData.Peshore_Sasia_Start !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <TextField
                  label="Gjatesia"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.Peshore_Sasia_Length || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("Peshore_Sasia_Length", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.Peshore_Sasia_Length !== undefined &&
                      formData.Peshore_Sasia_Length !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <TextField
                  label="Pestohet me"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.Peshore_Sasia_Pjesto || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("Peshore_Sasia_Pjesto", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.Peshore_Sasia_Pjesto !== undefined &&
                      formData.Peshore_Sasia_Pjesto !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Typography variant="body1">Numrat fillestare</Typography>
                <TextField
                  label="Numrat"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.OreNeDite || ""} // Ensure value is managed in the state
                  onChange={(e) => handleChange("OreNeDite", e.target.value)} // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.OreNeDite !== undefined &&
                      formData.OreNeDite !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />{" "}
                <Typography variant="body1">
                  shifrat para se te filloje kodi i produkt
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* </Grid> */}
      </Grid>
      {/* </div> */}
      <footer>
        <Grid item xs={8}>
          <ButtonGroup sx={!isMobile ? { marginTop: 5 } : { marginTop: 5 }}>
            <Button
              sx={{ marginRight: 6 }}
              variant="contained"
              component="span"
              onClick={handleChangeNdermarrje}
            >
              Ruaje
            </Button>
            <InsertDataForm />
          </ButtonGroup>
        </Grid>
      </footer>
    </>
  );
};

export default KonfigArtikuj;
