import React, { useState, useRef } from "react";
import "./PosBar.scss";
import { Button, ButtonGroup, Divider } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import mainAxios from "../../../services/axios";
import Checkbox from "@mui/material/Checkbox";
import { getKonfigurime } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { MenuItem, Select } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../components/context/ToastContext";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const KonfigArtikuj = ({ isMobile }) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  const currentId = {
    Id: konfigurimeRedux[0]?.Id || "",
  };

  const [formData, setFormData] = useState({
    Klient_Shfaq_Ne_Pos: konfigurimeRedux[0]?.Klient_Shfaq_Ne_Pos || "",
    Bar_Kerko_Fjalekalim: konfigurimeRedux[0].Bar_Kerko_Fjalekalim || "",
    Bar_Fshi_Artikullin_e_Printuar:
      konfigurimeRedux[0]?.Bar_Fshi_Artikullin_e_Printuar || "",
    BARButtonDhomaShow: konfigurimeRedux[0]?.BARButtonDhomaShow || "",
    BAR_ShfaqVleren: konfigurimeRedux[0]?.BAR_ShfaqVleren || "",
    BAR_PunonMeTavolina: konfigurimeRedux[0]?.BAR_PunonMeTavolina || "",
    BAR_HapLoginNeFillim: konfigurimeRedux[0]?.BAR_HapLoginNeFillim || "",
    Bar_VetemFaturat_e_Veta: konfigurimeRedux[0]?.Bar_VetemFaturat_e_Veta || "",
    Numri_Tavolinave: konfigurimeRedux[0]?.Numri_Tavolinave || "",
    Menyre_Pagese_Shfaq: konfigurimeRedux[0]?.Menyre_Pagese_Shfaq || "",
    MaxGrup: konfigurimeRedux[0]?.MaxGrup || "",
    PrintAccent: konfigurimeRedux[0]?.PrintAccent || false,
    HapFormPagese: konfigurimeRedux[0]?.HapFormPagese ?? false,
    BurimeNjerezoreEmail: konfigurimeRedux[0]?.BurimeNjerezoreEmail || "",
    Financier: konfigurimeRedux[0]?.Financier || "",
    FinancierEmail: konfigurimeRedux[0]?.FinancierEmail || "",
    Administrator: konfigurimeRedux[0]?.Administrator || "",
    AdministratorEmail: konfigurimeRedux[0]?.AdministratorEmail || "",
  });

  const handleChangeNdermarrje = async () => {
    const payload = {
      ...formData,
    };

    try {
      const response = await mainAxios.put(
        `/konfigurim/${currentId.Id}`,
        payload
      );

      if (response.status === 200) {
        showToast("U dergua me sukses", {
          severity: "success",
        });
        dispatch(getKonfigurime("/konfigurim"));
      }
    } catch (error) {
      console.error(error);
      showToast("Gabim ne dergimin e te dhenave", {
        severity: "error",
      });
    }
  };

  const navigate = useNavigate();

  const handleNavigate = () => {
    navigate("/celje/perdorues");
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      <Grid item>
        <Grid container direction="row" sx={{ marginTop: 2 }}>
          {" "}
          <Box sx={{ flexGrow: 2 }}>
            <Grid container direction="column" spacing={1.5}>
              <Grid
                container
                direction="row"
                alignItems="center"
                spacing={1} // Adds spacing between child items
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Grid item>
                  <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    Kujdes:
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography
                    variant="body1"
                    sx={{
                      textAlign: "center",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    Disa konfigurime për formen e BARIT gjenden tek{" "}
                    <span
                      onClick={handleNavigate}
                      style={{
                        color: "blue",
                        textDecoration: "underline", // Optional: for better visibility
                        fontWeight: "bold",
                        cursor: "pointer", // Makes it clear that it's clickable
                      }}
                    >
                      Grup Perdorues
                    </span>
                  </Typography>
                </Grid>
              </Grid>

              {/* <Box sx={{ flexGrow: 1 }}> */}
              {/* <Grid
                  container
                  direction="row"
                  item
                  xs={12}
                  alignItems="center"
                >
                  <Typography variant="body1">Pika shitjes</Typography>

                  <TextField
                    label="Pika shitjes"
                    variant="outlined"
                    size="small"
                    sx={{
                      width: "200px",
                      marginLeft: "10px",
                      marginRight: "20px",
                    }}
                    value={formData.PrintAccent || ""} // Ensure value is managed in the state
                    onChange={(e) =>
                      handleChange("PrintAccent", e.target.value)
                    } // Update the state on change
                  />
                </Grid> */}
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.PrintAccent}
                  onChange={(e) =>
                    handleChange("PrintAccent", e.target.checked)
                  }
                />
                <Typography variant="body1">Printer 58 mm</Typography>
              </Grid>
              {/* </Box> */}
              <Divider sx={{ width: "100%", marginY: 1 }} />
              <Typography variant="h6" style={{ color: "blue" }}>
                BAR
              </Typography>
              <Grid
                container
                direction="row"
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Box sx={{ flexGrow: 2 }}>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.Bar_Kerko_Fjalekalim}
                      onChange={(e) =>
                        handleChange("Bar_Kerko_Fjalekalim", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      BAR - Kerko Fjalekalimin
                    </Typography>
                    <Checkbox
                      {...label}
                      checked={formData.BAR_HapLoginNeFillim}
                      onChange={(e) =>
                        handleChange("BAR_HapLoginNeFillim", e.target.checked)
                      }
                    />
                    <Typography variant="body1">ne fillim</Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.Bar_Fshi_Artikullin_e_Printuar}
                      onChange={(e) =>
                        handleChange(
                          "Bar_Fshi_Artikullin_e_Printuar",
                          e.target.checked
                        )
                      }
                    />
                    <Typography variant="body1">
                      BAR - Lejo fshirjen e artikullit te printuar
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      BAR - Printo permbledhesen per KLIENTIN{" "}
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      BAR - Printo jofiskal ne mbyllje
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.Bar_VetemFaturat_e_Veta}
                      onChange={(e) =>
                        handleChange(
                          "Bar_VetemFaturat_e_Veta",
                          e.target.checked
                        )
                      }
                    />
                    <Typography variant="body1">
                      BAR - Kamarieri printon vetem tavolinat qe ka hapur vete
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <TextField
                      label="nivele"
                      variant="outlined"
                      type="number"
                      size="small"
                      sx={{
                        width: "100px",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                      value={formData.MaxGrup || ""} // Ensure value is managed in the state
                      onChange={(e) => handleChange("MaxGrup", e.target.value)} // Update the state on change
                      inputProps={{
                        min: 1,
                        step: 1,
                      }}
                      InputLabelProps={{
                        shrink:
                          formData.MaxGrup !== undefined &&
                          formData.MaxGrup !== "", // Ensure the label stays shrunk when there's a value
                      }}
                    />
                    <Typography variant="body1">
                      nivele grupimi per artikujt
                    </Typography>
                  </Grid>
                  <Divider sx={{ width: "100%", marginY: 1 }} />
                  <Box flexGrow={2}>
                    <Grid
                      container
                      direction="row"
                      item
                      xs={8}
                      alignItems="center"
                    >
                      <Checkbox
                        {...label}
                        checked={formData.BAR_PunonMeTavolina}
                        onChange={(e) =>
                          handleChange("BAR_PunonMeTavolina", e.target.checked)
                        }
                      />
                      <Typography variant="body1">Punon me tavolina</Typography>
                      <Divider sx={{ height: "100%", marginX: 1 }} />
                      <TextField
                        label="nivele"
                        variant="outlined"
                        type="number"
                        size="small"
                        sx={{
                          width: "100px",
                          marginLeft: "10px",
                          marginRight: "5px",
                        }}
                        value={formData.Numri_Tavolinave || ""} // Ensure value is managed in the state
                        onChange={(e) =>
                          handleChange("Numri_Tavolinave", e.target.value)
                        } // Update the state on change
                        inputProps={{
                          min: 1,
                          step: 1,
                        }}
                        InputLabelProps={{
                          shrink:
                            formData.Numri_Tavolinave !== undefined &&
                            formData.Numri_Tavolinave !== "", // Ensure the label stays shrunk when there's a value
                        }}
                      />
                      <Typography variant="body1">
                        numri i tavolinave
                      </Typography>
                      <Checkbox
                        {...label}
                        checked={formData.Numri_Tavolinave}
                        onChange={(e) =>
                          handleChange("Numri_Tavolinave", e.target.checked)
                        }
                        style={{ marginLeft: "10px" }}
                      />
                      <Typography variant="body1">
                        Tavolinat me sistemim
                      </Typography>
                    </Grid>
                  </Box>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.BAR_ShfaqVleren}
                      onChange={(e) =>
                        handleChange("BAR_ShfaqVleren", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Mos shfaq vleren ne Tavoline
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.BARButtonDhomaShow}
                      onChange={(e) =>
                        handleChange("BARButtonDhomaShow", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      BAR - Dhomat (shfaq butonin e dhomave te BARI)
                    </Typography>
                  </Grid>

                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">Kerkimi Imediat</Typography>
                  </Grid>
                </Box>
                {/* <Box flexGrow={3}>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">Touch mode</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">Rrumbullakos vleren</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Shfaq shkronjat per kerkim
                    </Typography>
                  </Grid>
                </Box> */}
              </Grid>
              <Divider sx={{ width: "100%", marginY: 1 }} />
              <Typography variant="h6" style={{ color: "blue" }}>
                POS
              </Typography>
              <Grid
                container
                direction="row"
                sx={{ marginTop: 2, marginBottom: 2 }}
              >
                <Box sx={{ flexGrow: 1 }}>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.HapFormPagese}
                      onChange={(e) =>
                        handleChange("HapFormPagese", e.target.checked)
                      }
                    />
                    <Typography variant="body1">POS Hap Form Pagese</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Shfaq pagesen vetem nese ka klient
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.Klient_Shfaq_Ne_Pos}
                      onChange={(e) =>
                        handleChange("Klient_Shfaq_Ne_Pos", e.target.checked)
                      }
                    />
                    <Typography variant="body1">Pos shfaq klientin</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.Menyre_Pagese_Shfaq}
                      onChange={(e) =>
                        handleChange("Menyre_Pagese_Shfaq", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Shfaq menyren e pageses
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Pos ne MDI Child mode
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Select
                      style={{ width: "120px" }}
                      value={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.value)
                      }
                      displayEmpty
                      inputProps={{ "aria-label": "Without label" }}
                    >
                      <MenuItem value={true}>Grid</MenuItem>
                      <MenuItem value={false}>Banded Grid</MenuItem>
                    </Select>
                    <Typography variant="body1" style={{ marginLeft: "8px" }}>
                      paraqitja e artikujve ne liste
                    </Typography>
                  </Grid>
                </Box>
                <Box flexGrow={2}>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Shfaq 3 llojet e cmimeve te shitjes
                    </Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">Touch mode</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">Rrumbullakos vleren</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox
                      {...label}
                      checked={formData.AplikacioniHapet}
                      onChange={(e) =>
                        handleChange("AplikacioniHapet", e.target.checked)
                      }
                    />
                    <Typography variant="body1">
                      Shfaq imazhin kur artikulli selektohet
                    </Typography>
                  </Grid>
                </Box>
              </Grid>
              <Typography variant="h6" style={{ color: "blue" }}>
                Piket
              </Typography>
              <Grid container direction="row" item xs={12} alignItems="center">
                <Typography variant="body1">Per vleren </Typography>
                <TextField
                  required
                  label="Dite"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "70px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.DiteSkadenceLajmero || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("DiteSkadenceLajmero", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.DiteSkadenceLajmero !== undefined &&
                      formData.DiteSkadenceLajmero !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <Typography variant="body1">fiton</Typography>
                <TextField
                  required
                  label="Dite"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "70px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.DiteSkadenceLajmero || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("DiteSkadenceLajmero", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.DiteSkadenceLajmero !== undefined &&
                      formData.DiteSkadenceLajmero !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <Typography variant="body1">pike</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">Shfaq piket e klientit</Typography>
              </Grid>
              <Grid container direction="row" item xs={12} alignItems="center">
                <TextField
                  required
                  label="Dite"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "70px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.DiteSkadenceLajmero || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("DiteSkadenceLajmero", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.DiteSkadenceLajmero !== undefined &&
                      formData.DiteSkadenceLajmero !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <Typography variant="body1">
                  Paguan me pike mbi vleren e caktuar
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
      <footer>
        <Grid item xs={8}>
          <ButtonGroup sx={!isMobile ? { marginTop: 5 } : { marginTop: 5 }}>
            <Button
              sx={{ marginRight: 6 }}
              variant="contained"
              component="span"
              onClick={handleChangeNdermarrje}
            >
              Ruaje
            </Button>
          </ButtonGroup>
        </Grid>
      </footer>
    </>
  );
};

export default KonfigArtikuj;
