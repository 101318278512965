import React, { useEffect } from "react";
import { Autocomplete, TextField } from "@mui/material";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import mainAxios from "../../../../services/axios";

const TabPaguar = ({
  setState,
  toggleState,
  disabled,
  state,
  rows,
  menyrapageses,
  showToast,
  setTipiArkes,
}) => {
  const sumTotal = rows.reduce((acc, row) => acc + row.Total, 0);

  const handleChange = async (key, value) => {
    if (key === "Menyra_Pageses_ID" && value === 6) {
      // Update Paguar and Menyra_Pageses_ID fields
      state.Paguar = sumTotal.toFixed(2);

      try {
        await mainAxios.put(`/fature/shitje/${state.Id}`, {
          [key]: value,
          Paguar: state.Paguar,
        });

        setState((state) => ({
          ...state,
          [key]: value,
          Paguar: state.Paguar,
        }));
      } catch (error) {
        showToast("Ndodhi një gabim gjatë përditësimit!");
      }
    } else {
      // Regular PUT request if Menyra_Pageses_ID is not 6
      try {
        await mainAxios.put(`/fature/shitje/${state.Id}`, {
          [key]: value,
        });

        setState((state) => ({
          ...state,
          [key]: value,
        }));
      } catch (error) {
        showToast("Ndodhi një gabim gjatë përditësimit!");
      }
    }

    return state;
  };

  // redux arka/banka (get requesti i Arka/Banka)
  const arka = useSelector((state) => state.globalSlice.arkabanka);

  useEffect(() => {
    const foundArkaP = arka.find(
      (ArkaP) => ArkaP.Kodi === state?.Arka_Banka_Kodi
    );
    if (foundArkaP) {
      setTipiArkes(foundArkaP.Tip);
    }
  }, [arka, state?.Arka_Banka_Kodi]);

  const handleChangeArka = async (key, value) => {
    if (value.Mon !== arka[0].Mon) {
      showToast("Monedha e arkes e ndryshme nga monedha baze!");
      return;
    }

    try {
      setState((state) => {
        return {
          ...state,
          Arka_Banka_Kodi: value.Kodi || null,
        };
      });

      await mainAxios.put(`/fature/shitje/${state.Id}`, {
        [key]: value,
        Arka_Banka_Kodi: value.Kodi || null,
      });
    } catch (error) {
      showToast("Ndodhi një gabim gjatë përditësimit!");
    }
  };

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid gap-2 grid-cols-2">
        {/* Button Menyra */}
        <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-menyra1"
          options={menyrapageses}
          getOptionLabel={(option) => option.Id && option.Pershkrim}
          value={
            menyrapageses.find(
              (MenyraP) => MenyraP.Id === state?.Menyra_Pageses_ID
            ) || null
          }
          onChange={(_, newValue) => {
            handleChange("Menyra_Pageses_ID", newValue?.Id || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Menyra" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Id}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
            </div>
          )}
        />

        {/* Button Arke/Banke */}
        <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-arka2"
          options={arka}
          getOptionLabel={(option) =>
            option
              ? option.Kodi + " - " + option.Pershkrim + " - " + option.Mon
              : ""
          }
          value={
            arka.find((ArkaP) => ArkaP.Kodi === state?.Arka_Banka_Kodi) || null
          }
          onChange={(_, newValue) => {
            handleChangeArka("Arka_Banka_Kodi", newValue || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Arke/Banke" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Kodi}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              <div style={{ textAlign: "end" }}>{option.Mon}</div>
            </div>
          )}
          PaperComponent={(props) => (
            <Paper {...props} /> // Adjust the width as needed
          )}
        />

        {/* Buttoni Paguar */}
        <TextField
          label="Paguar"
          fullWidth
          disabled={disabled}
          type="number"
          size="small"
          variant="outlined"
          value={state?.Paguar}
          onChange={(e) => handleChange("Paguar", e.target.value)}
        />
      </div>
    </div>
  );
};

export default TabPaguar;
