import React from "react";
import {
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
} from "@mui/material";
import Tarifat from "../../paneliIartikujve/MiniModalArtikuj/Tepergjithshme/Tarifat";
import { useSelector } from "react-redux";
import ClearIcon from "@mui/icons-material/Clear";
import "../../paneliIartikujve/TePergjithshme.scss";

const ButtonsFunction = ({
  state,
  setState,
  disabled,
  handleChange,
  updateRowData,
  handleChangeTarifa,
  isEditingAllowed,
  initialLength,
  setInitialLength,
}) => {
  // tarifa redux
  const tarifaRedux = useSelector((state) => state.globalSlice.tarifa);
  const tarifa = tarifaRedux;

  const handleChangeSelect = (key, value) => {
    if (isEditingAllowed) {
      updateRowData({ [key]: value });
    }

    // Update local state regardless of whether the value changed
    setState((state) => ({
      ...state,
      [key]: value,
    }));
  };

  const njesia = [
    { Kodi: "A", Pershkrim: "Aktiv" },
    { Kodi: "P", Pershkrim: "Pasiv" },
  ];
  const numTarifa = `${tarifa ? tarifa.length : 0} Rekorde`;

  return (
    <div
      className={`first-border-pergjithshme flex ${
        window.innerWidth <= 768 ? "flex-col gap-4" : "gap-3"
      } border p-4`}
    >
      <div className="kutizat">
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Origjina || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Origjina: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Origjina", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Superior"
          id="Origjina"
        />
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Kodi || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Kodi: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Kodi", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          style={{ backgroundColor: "#F4CE14" }}
          label="Kodi"
          id="Kodi_"
        />
      </div>

      <div className="kutizat">
        <TextField
          value={state.PershkrimSuperior || ""}
          InputLabelProps={{ shrink: true }}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) =>
            setState({ ...state, PershkrimSuperior: e.target.value })
          }
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("PershkrimSuperior", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Superior Pershkrim"
          id="PershkrimSuperior"
        />

        <TextField
          value={state.Pershkrim || ""}
          InputLabelProps={{ shrink: true }}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Pershkrim: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Pershkrim", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Pershkrim"
          style={{ backgroundColor: "#F4CE14" }}
          id="Pershkrim"
        />
      </div>

      <div className="kutizat_Shenim_Llogari">
        {/* <textarea
          type="text"
          value={state.Shenim1 || ""}
          //   onChange={(e) => setState({ ...state, Shenim1: e.target.value })}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          id="Shenim_1"
          placeholder="Shenim 1"
        /> */}
        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Shenim1 || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Shenim1: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Shenim1", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Shenim 1"
          id="Shenim_1"
          inputProps={{ maxLength: 12 }}
        />

        <TextField
          InputLabelProps={{ shrink: true }}
          value={state.Shenim2 || ""}
          onFocus={(e) => setInitialLength(e.target.value.length)}
          onChange={(e) => setState({ ...state, Shenim2: e.target.value })}
          onBlur={(e) => {
            if (initialLength !== e.target.value.length) {
              handleChange("Shenim2", e.target.value);
            }
          }}
          disabled={disabled}
          className="p-inputtext-sm block mb-2"
          label="Shenim 2"
          id="Shenim_2"
        />
      </div>

      <div className="kutizat">
        <div className="drop_down_list">
          <FormControl fullWidth>
            <InputLabel shrink={true} id="tarifa-label">
              Tarifa
            </InputLabel>
            <Select
              labelId="tarifa-label"
              id="tarifa-select"
              className="drop_down_list"
              style={{ backgroundColor: "#fff" }}
              disabled={disabled}
              size="small"
              value={state?.Tarifa_Kodi || ""}
              onChange={(e) => {
                const selectedValue = e.target.value;

                if (selectedValue === "") {
                  // If the clear option is selected
                  handleChangeTarifa("Tarifa_Kodi", "", { Tvsh: null }); // Clear the selection
                } else {
                  const selectedList = tarifa.find(
                    (list) => list.Kodi === selectedValue
                  );
                  handleChangeTarifa(
                    "Tarifa_Kodi",
                    selectedValue,
                    selectedList
                  );
                }
              }}
            >
              <MenuItem
                style={{
                  display: "flex",
                  alignItems: "center",
                }}
                value=""
              >
                Clear
                <ClearIcon style={{ color: "red", marginLeft: "8px" }} />
              </MenuItem>
              {tarifa?.map((list) => (
                <MenuItem key={list.Id} value={list.Kodi}>
                  {list.Pershkrim}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          <span
            style={{
              border: "solid 1px #ddd",
              backgroundColor: "#1971c2",
              color: "#fff",
            }}
          >
            {/* Assuming Tarifat is a component that displays numTarifa */}
            <Tarifat numTarifa={numTarifa} tarifa={tarifa} />
          </span>
        </div>
        <FormControl fullWidth>
          <InputLabel shrink={true} id="njesia-label">
            Aktiv/Pasiv
          </InputLabel>
          <Select
            labelId="njesia-label"
            id="njesia-select"
            className="drop_down_list mb-2"
            disabled={disabled}
            size="small"
            value={state.AP || ""}
            onChange={(e) => {
              handleChangeSelect("AP", e.target.value);
            }}
            style={{ backgroundColor: "#fff" }}
          >
            <MenuItem style={{ display: "flex" }} value="">
              Clear
              <ClearIcon style={{ color: "red" }} />
            </MenuItem>

            {njesia?.map((item) => (
              <MenuItem key={item.Kodi} value={item.Kodi}>
                {item.Pershkrim}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </div>
    </div>
  );
};

export default ButtonsFunction;
