import React, { useState, useRef, useEffect, useContext } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TableListaFaturaveSmSc from "../DataTablePos/TableListaFaturaveSmSc";
import CloseIcon from "@mui/icons-material/Close";
import PrintoSupermarket from "../../../../components/ReactPDF/PrintoSupermarket";
import mainAxios from "../../../../services/axios";
import PrintoFisSupermarket from "../../../../components/ReactPDF/PrintoFisSupermarket";
import PlaylistRemoveIcon from "@mui/icons-material/PlaylistRemove";
import PrintoTurnin from "../../../../components/ReactPDF/PrintoTurnin";
import SkontrinoXhiroMain from "../../../MbylljeTurni/FaqaPrintimitMain/SkontrinoXhiroMain";
import DynamicXhiroMain from "../../../MbylljeTurni/FaqaPrintimitMain/DynamicXhiroMain";
import "./ListaFaturave.scss";
import { useUser } from "../../../../zustand/common";
import { useToast } from "../../../../components/context/ToastContext";

function ListaFaturave({
  open,
  onClose,
  handleFiskalizimi,
  menyra,
  setState,
  state,
  fromListaa,
  setFromLista,
  operator,
}) {
  const { user } = useUser();
  const nipt = user.nipt;
  const [data, setData] = useState([]);
  const [data2, setData2] = useState([]);
  const [selectedId, setSelectedId] = useState("");
  const [loading, setLoading] = useState(false);
  const showToast = useToast();

  // Function to get the current date in the format "YYYY-MM-DD"
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [ngaData] = useState(getFormattedDate());
  const [deriData] = useState(getFormattedDate());

  const fetchDataTabelaFaturat = async (page = 1, perPage = 100) => {
    setLoading(true);
    try {
      const dataPaMbyllura = await mainAxios.get(
        `/pos/faturat/e_pambyllura/operator?operator=${operator}&page=${page}&per_page=${perPage}`
      );
      if (dataPaMbyllura.length === 0) {
        setData2([]);
      }

      setData(dataPaMbyllura.data);
    } catch (error) {
      console.error("Error fetching perdorues:", error);
    } finally {
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);

  useEffect(() => {
    if (fromListaa) {
      fetchDataTabelaFaturat();
      setFromLista(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fromListaa]);

  const handleFis = async (Id) => {
    try {
      const response = await mainAxios.post(`/pos/${Id}`);

      const firstItem = response.data;
      if (firstItem?.ErrorCode !== "0") {
        const faultstring = firstItem.faultstring;
        showToast(faultstring, { severity: "error" });
      }
    } catch (error) {
      console.error("Error fiskalizimi:", error);
    } finally {
      await fetchDataTabelaFaturat();
    }
  };

  const handleAnullim = async () => {
    if (!selectedId) {
      showToast("Nuk ka fature te selektuar.");
      return;
    }
    if (!state.NIVF) {
      showToast("Eshte e pa fiskalizuar.");
      return;
    }
    try {
      const response = await mainAxios(`/pos/anullim/${selectedId}`);
      if (response.status === 201) {
        await handleFis(response.data.Id); // Ensure handleFis is awaited
      }
    } catch (error) {
      console.error("An error occurred:", error);
      showToast("Gabim gjate anullimit!", {
        severity: "error",
        summary: "Error",
      });
    }
  };

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="lg"
      componentsProps={{
        BackdropProps: { invisible: true },
      }}
    >
      <DialogTitle style={{ height: "27vh" }}>
        <div style={{ height: "100%" }}>
          <div className="flex" style={{ justifyContent: "space-between" }}>
            <div className="flex" style={{ flexDirection: "column" }}>
              <h3 style={{ alignSelf: "flex-start", margin: 0 }}>
                Faturat e operatorit {operator}
              </h3>

              <p style={{ alignSelf: "flex-start", margin: 0 }}>
                Xhiroja printohet për OPERATORIN e selektuar!
              </p>
              <p style={{ alignSelf: "flex-start", margin: 0 }}>
                Fatura e selektuar: {selectedId}
              </p>
            </div>
            <DialogActions>
              <Button
                variant="outlined"
                onClick={onClose}
                style={{ color: "red" }}
              >
                <CloseIcon />
              </Button>
            </DialogActions>
          </div>
          <div
            className="title_dialog_faturat"
            style={{ height: "40px", marginTop: "10px", margin: "5px" }}
          >
            <div className="flex">
              <Button
                variant="outlined"
                style={{
                  marginRight: "5px",
                  color: "#fff",
                  backgroundColor: "#D24545",
                }}
                onClick={() => handleAnullim()}
              >
                <PlaylistRemoveIcon />
                Anullim fature
                {/*/pos/anullim/3086*/}
              </Button>
              <PrintoFisSupermarket
                handleFiskalizimi={handleFiskalizimi}
                posSupermarketListaFaturave={true}
                className="print"
                rows={data2}
                state={state}
                nipt={nipt}
                selectedId={selectedId}
                showToast={showToast}
                menyra={menyra}
                operator={operator}
              />
            </div>
            <div className="flex">
              <PrintoSupermarket
                posSupermarketListaFaturave={true}
                className="print"
                rows={data2}
                state={state}
                nipt={nipt}
                selectedId={selectedId}
                showToast={showToast}
                menyra={menyra}
                operator={operator}
              />
              {/* <PrintoXhiron
              posBar={true}
              className="print"
              // rows={data2}
              state={state}
              operator={operator}
            /> */}

              <PrintoTurnin
                className="print"
                state={state}
                rows={data2}
                dataNga={ngaData}
                dataDeri={deriData}
                // rows2={rows2}
                operator={operator}
                fetchDataTabelaFaturat={fetchDataTabelaFaturat}
              />
            </div>
          </div>
        </div>
      </DialogTitle>

      <DialogContent style={{ height: "72vh", overflow: "hidden" }}>
        <DialogContentText className="dialog_context">
          <div className="dialog_bar_datable_print">
            <div className="card_dialog_bar_table">
              <TableListaFaturaveSmSc
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                itemsPerPage={itemsPerPage}
                setData2={setData2}
                operator={operator}
                data={data}
                setData={setData}
                setSelectedId={setSelectedId}
                setState={setState}
                fetchDataTabelaFaturat={fetchDataTabelaFaturat}
                loading={loading}
                setLoading={setLoading}
              />
            </div>
            <div className="card_dialog_bar">
              <SkontrinoXhiroMain
                state={state}
                menyrapageses={menyra}
                nipt={nipt}
              />
              <DynamicXhiroMain rows={data2} state={state} />
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default ListaFaturave;
