import React, { useEffect, useRef } from "react";
import { Autocomplete, Button, TextField } from "@mui/material";
// import Pergjegjes from "../../minimodal/Pergjegjes";
import { useSelector } from "react-redux";
import Paper from "@mui/material/Paper";
import LinkIcon from "@mui/icons-material/Link";
import mainAxios from "../../../../services/axios";
import { useNavigate } from "react-router-dom";
import { useToast } from "../../../../components/context/ToastContext";

const TabPaguar = ({
  setState,
  popupContextKey,
  toggleState,
  disabled,
  handleChange,
  state,
  setBankaInfo,
  formatDate,
  rows,
  menyrapageses,
  setSelectedArkaMon,
  setTipiArkes,
  tipiArkes,
  fatureType,
  buttonClicked,
}) => {
  const navigate = useNavigate();

  const showToast = useToast();
  // redux arka/banka (get requesti i Arka/Banka)
  const arka = useSelector((state) => state.globalSlice.arkabanka);

  useEffect(() => {
    const foundArkaP = arka.find(
      (ArkaP) => ArkaP.Kodi === state?.Arka_Banka_Kodi
    );
    if (foundArkaP) {
      setTipiArkes(foundArkaP.Tip);
    }
  }, [arka, state?.Arka_Banka_Kodi]);

  // redux banka
  const banka = useSelector((state) => state.globalSlice.bankaList);

  // Calculate Total
  useEffect(() => {
    if (state.Menyra_Pageses_Id || state?.Menyra_Pageses_ID) {
      const sumTotal = rows.reduce((acc, row) => acc + row.Total, 0);
      handleChange("Paguar", sumTotal.toFixed(2));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Menyra_Pageses_Id, state?.Menyra_Pageses_ID, rows]);

  // Find and set the Banka info if a match is found for Kodi
  useEffect(() => {
    if (state.Arka_Banka_Kodi) {
      const matchedBanka = banka.find((b) => b.Kodi === state.Arka_Banka_Kodi);
      if (matchedBanka) {
        setTipiArkes(matchedBanka.Tip);
        if (popupContextKey === "FatureShitje") {
          setBankaInfo(matchedBanka);
        }
      } else {
        console.log("No matching Banka found");
      }
    }
  }, [
    state.Arka_Banka_Kodi,
    banka,
    setBankaInfo,
    setTipiArkes,
    popupContextKey,
  ]);

  const handleChangeArka = (key, value) => {
    setSelectedArkaMon(value.Mon);
    setTipiArkes(value.Tip);

    setState((state) => {
      return {
        ...state,
        Arka_Banka_Kodi: value.Kodi || null,
      };
    });
  };

  // this function is to navigate on main Component "Flete Hyrje" which is generated from Fature Blerje
  const handleClickMandat = async () => {
    try {
      if (tipiArkes === "ARKA") {
        const response = await mainAxios.get(
          `/rownum/Arka/Arka_List_Kodi/${state.Arka_Banka_Kodi}/${fatureType}/${state.Id}`
        );

        if (response.status === 200) {
          navigate("/arka", {
            state: {
              nmrFB: response.data.row_num,
              fromFatureBlerje: true,
            },
          });
        }
      } else if (tipiArkes === "BANKA") {
        const response = await mainAxios.get(
          `/rownum/Banka/Banka_List_Kodi/${state.Arka_Banka_Kodi}/${fatureType}/${state.Id}`
        );

        if (response.status === 200) {
          navigate("/banka", {
            state: {
              nmrFB: response.data.row_num,
              fromFatureBlerje: true,
            },
          });
        }
      }
    } catch (error) {
      showToast("Ndodhi problem!");
    }
  };

  return (
    <div className={toggleState ? "content  active-content" : "content"}>
      <div className="grid gap-2 grid-cols-2">
        {/* Button Menyra */}
        <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-menyra1"
          options={menyrapageses}
          getOptionLabel={(option) => option.Id && option.Pershkrim}
          value={
            menyrapageses.find((MenyraP) =>
              popupContextKey === "FatureShitje"
                ? MenyraP.Id === state?.Menyra_Pageses_ID
                : MenyraP.Id === state?.Menyra_Pageses_Id
            ) || null
          }
          onChange={(_, newValue) => {
            popupContextKey === "FatureShitje"
              ? handleChange("Menyra_Pageses_ID", newValue?.Id || "")
              : handleChange("Menyra_Pageses_Id", newValue?.Id || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Menyra" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Id}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
            </div>
          )}
        />

        {/* Button Arke/Banke */}
        <Autocomplete
          disabled={disabled}
          disablePortal
          id="combo-box-arka2"
          options={arka}
          getOptionLabel={(option) =>
            option
              ? option.Kodi + " - " + option.Pershkrim + " - " + option.Mon
              : ""
          }
          value={
            arka.find((ArkaP) => ArkaP.Kodi === state?.Arka_Banka_Kodi) || null
          }
          onChange={(_, newValue) => {
            handleChangeArka("Arka_Banka_Kodi", newValue || "");
          }}
          sx={{ width: "100%" }}
          size="small"
          renderInput={(params) => <TextField {...params} label="Arke/Banke" />}
          renderOption={(props, option) => (
            <div
              style={{ display: "flex", justifyContent: "space-between" }}
              {...props}
            >
              <div style={{ textAlign: "start" }}>{option.Kodi}</div>
              <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              <div style={{ textAlign: "end" }}>{option.Mon}</div>
            </div>
          )}
          PaperComponent={(props) => (
            <Paper {...props} /> // Adjust the width as needed
          )}
        />

        {/* Buttoni Paguar */}
        <TextField
          label="Paguar"
          fullWidth
          disabled={disabled}
          type="number"
          size="small"
          variant="outlined"
          value={state?.Paguar || ""}
          onChange={(e) => handleChange("Paguar", e.target.value)}
        />
        {/* Afati Data */}
        <TextField
          fullWidth
          disabled={disabled}
          type="date"
          label="Afati PagesesData"
          InputLabelProps={{
            shrink: true,
          }}
          variant="outlined"
          value={formatDate(state?.Afati_PagesesData)}
          onChange={(e) => handleChange("Afati_PagesesData", e.target.value)}
          size="small"
        />
        <div>
          <Button
            variant="contained"
            disabled={buttonClicked || !state.Arka_Banka_Kodi}
            onClick={handleClickMandat}
          >
            Mandati <LinkIcon />
          </Button>
        </div>

        {/* <div className="flex gap-2 text-center items-center  ">
          <span>Ose për</span>
          <input
            className="rounded-md w-20 border"
            disabled={disabled}
            type="number"
            value={state?.Afati_PagesesDite}
            onChange={(e) => handleChange("Afati_PagesesDite", e.target.value)}
          />
          <span>ditë</span>
        </div> */}
        {/* <div className="bg-gray-100 rounded-tr-lg rounded-br-lg w-full flex justify-center items-center relative"> */}
        {/* //////////// */}
        {/* <Autocomplete
            disablePortal
            id="combo-box-demo"
            options={menyrapageses}
            sx={{ width: "100%" }}
            size="small"
            renderInput={(params) => (
              <TextField {...params} label="Përgjegjës" />
            )}
          /> */}
        {/* ////// MiniModal */}
        {/* <div className="hover:scale-110 transition-all"></div> */}
        {/* </div> */}
      </div>
    </div>
  );
};

export default TabPaguar;
