import React, { useState } from "react";
import { DataTable } from "primereact/datatable"; // Import DataTable
import {
  Button,
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from "@mui/material";
import { Column } from "primereact/column"; // Import Column from primereact/column
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";

export default function TableData({ data, loading }) {
  console.log(data, "data");
  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BARKOD", title: "BARKOD" },
    { field: "Klienti", title: "Klienti" },
    { field: "PikeTembartura", title: "Pike te mbartura", allowSum: true },
    { field: "TeFituara", title: "Te fituara", allowSum: true },
    { field: "PikeTotal", title: "PikeTotal", allowSum: true },
    { field: "Dhurata", title: "Dhurata", allowSum: true },
    { field: "Pike", title: "Pike", allowSum: true },
  ]);

  // Dynamically create column headers and corresponding fields
  const dynamicColumns = columns.map((col, index) => (
    <Column key={index} field={col.field} header={col.title} />
  ));

  return (
    <>
      {loading ? (
        <div
          className="squares-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="card"
          style={{
            height: "90%",
            width: "100%",
            overflow: "hidden",
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
          }}
        >
          <Grid item>
            <Grid container direction="row" sx={{ marginTop: 2 }}>
              {" "}
              <Box sx={{ flexGrow: 2 }}>
                <Grid container direction="column" spacing={1.5}>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={12}
                    alignItems="center"
                  >
                    <Typography variant="body1">Data </Typography>
                    <TextField
                      required
                      variant="outlined"
                      type="date"
                      size="small"
                      sx={{
                        width: "30%",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                    />
                    <TextField
                      required
                      variant="outlined"
                      type="date"
                      size="small"
                      sx={{
                        width: "30%",
                        marginLeft: "10px",
                        marginRight: "20px",
                      }}
                    />
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={12}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Selekto te gjitha</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    alignItems="center"
                    style={{ justifyContent: "space-between", width: "100%" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      {" "}
                      <Checkbox />
                      <Typography variant="body1">Fature Shitje</Typography>
                    </div>
                    <Divider sx={{ height: "30px" }} />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                      }}
                    >
                      {" "}
                      <Typography variant="body1">0</Typography>
                    </div>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Fature Blerje</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Flete Hyrje</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Flete Dalje</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Arka</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Banka</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Import</Typography>
                  </Grid>
                  <Grid
                    container
                    direction="row"
                    item
                    xs={8}
                    alignItems="center"
                  >
                    <Checkbox />
                    <Typography variant="body1">Veprime Kontabel</Typography>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
            {/* </Grid> */}
          </Grid>
          <DataTable
            reorderablecolumns="true"
            // style={{ position: "relative" }}
            value={data}
            stripedRows
            dataKey="Id"
            autoLayout={true}
            filterDisplay="menu"
            emptyMessage="Nuk ka te dhena"
          >
            {dynamicColumns}
          </DataTable>{" "}
        </div>
      )}
    </>
  );
}
