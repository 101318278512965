import React from "react";
import mainAxios from "../../../services/axios";
import axios from "axios";

const InsertDataForm = () => {
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      // Make GET request to the first API
      const response = await mainAxios("/art/custom");

      // Check if the response is successful
      if (response.status === 200) {
        // Make POST request to the second API
        const response2 = await axios.post(
          "http://localhost:5001/api/insert",
          response.data
        );

        // Check if the second request was successful
        if (response2.status === 200) {
          alert("Data sent successfully");
        }
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <button
        type="submit"
        style={{
          backgroundColor: "blue",
          color: "white",
          padding: "10px 20px",
          border: "none",
          borderRadius: "4px",
          cursor: "pointer",
        }}
      >
        Shto Artikuj ne Peshore
      </button>
    </form>
  );
};

export default InsertDataForm;
