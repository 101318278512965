// CheckboxHandler.js
import React from "react";
import { Checkbox, FormControlLabel } from "@mui/material";
import ModeEditIcon from "@mui/icons-material/ModeEdit";

const CheckboxHandler = ({
  setIsEditingAllowed,
  setCheckModify,
  setButtonClicked,
  setDisabled,
  setCurrentAction,
  buttonClickedModifiko,
  setIsModifying,
  isMobile,
}) => {
  const handleCheckboxChange = (e) => {
    setIsEditingAllowed(e.target.checked);
    setCheckModify(true);

    if (e.target.checked) {
      setCurrentAction("fromArt");
      setButtonClicked(false);
      setIsModifying(true);
      setDisabled(false);
    } else {
      setIsModifying(false);
      setCurrentAction(null);
      setButtonClicked(false);
      setDisabled(true);
    }
  };

  return (
    <FormControlLabel
      control={
        <Checkbox
          disabled={buttonClickedModifiko}
          onChange={handleCheckboxChange}
        />
      }
      label={
        <>
          {isMobile ? (
            <>
              <ModeEditIcon />
              Modifiko
            </>
          ) : (
            <>
              <ModeEditIcon />
              Modifiko ne tabele
            </>
          )}
        </>
      }
    />
  );
};

export default CheckboxHandler;
