import React, { useState, useRef } from "react";
import "./Shitje.scss";
import { Button, ButtonGroup } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import mainAxios from "../../../services/axios";
import Checkbox from "@mui/material/Checkbox";
import { getKonfigurime } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../components/context/ToastContext";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const KonfigArtikuj = ({ isMobile }) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  const currentId = {
    Id: konfigurimeRedux[0]?.Id || "",
  };

  const [formData, setFormData] = useState({
    DiteSkadenceLajmero: konfigurimeRedux[0]?.DiteSkadenceLajmero || "",
    Pershkrim: konfigurimeRedux[0]?.Pershkrim || "",
    Logo: konfigurimeRedux[0].Logo || null,
    NrAmzes: konfigurimeRedux[0]?.NrAmzes || "",
    Adresa1: konfigurimeRedux[0]?.Adresa1 || "",
    Tel: konfigurimeRedux[0]?.Tel || "",
    Cel: konfigurimeRedux[0]?.Cel || "",
    Fax: konfigurimeRedux[0]?.Fax || "",
    www: konfigurimeRedux[0]?.www || "",
    Email: konfigurimeRedux[0]?.Email || "",
    Mon: konfigurimeRedux[0]?.Mon || "",
    Metvsh: konfigurimeRedux[0]?.Metvsh || false,
    PrintAccent: konfigurimeRedux[0]?.PrintAccent || false,
    BurimeNjerezore: konfigurimeRedux[0]?.BurimeNjerezore || "",
    BurimeNjerezoreEmail: konfigurimeRedux[0]?.BurimeNjerezoreEmail || "",
    Financier: konfigurimeRedux[0]?.Financier || "",
    FinancierEmail: konfigurimeRedux[0]?.FinancierEmail || "",
    Administrator: konfigurimeRedux[0]?.Administrator || "",
    AdministratorEmail: konfigurimeRedux[0]?.AdministratorEmail || "",
  });

  const handleChangeNdermarrje = async () => {
    const payload = {
      ...formData,
    };

    try {
      const response = await mainAxios.put(
        `/konfigurim/${currentId.Id}`,
        payload
      );

      if (response.status === 200) {
        showToast("U dergua me sukses", {
          severity: "success",
        });
        dispatch(getKonfigurime("/konfigurim"));
      }
    } catch (error) {
      console.error(error);
      showToast("Gabim ne dergimin e te dhenave", {
        severity: "error",
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  return (
    <>
      <Grid item>
        <Grid container direction="row" sx={{ marginTop: 2 }}>
          {" "}
          <Box sx={{ flexGrow: 2 }}>
            <Grid container direction="column" spacing={1.5}>
              <Grid container direction="row" item xs={12} alignItems="center">
                <Typography variant="body1">
                  Lajmero nese artikulli skadon per{" "}
                </Typography>
                <TextField
                  required
                  label="Dite"
                  variant="outlined"
                  type="number"
                  size="small"
                  sx={{
                    width: "100px",
                    marginLeft: "10px",
                    marginRight: "20px",
                  }}
                  value={formData.DiteSkadenceLajmero || ""} // Ensure value is managed in the state
                  onChange={(e) =>
                    handleChange("DiteSkadenceLajmero", e.target.value)
                  } // Update the state on change
                  inputProps={{
                    min: 1,
                    step: 1,
                  }}
                  InputLabelProps={{
                    shrink:
                      formData.DiteSkadenceLajmero !== undefined &&
                      formData.DiteSkadenceLajmero !== "", // Ensure the label stays shrunk when there's a value
                  }}
                />
                <Typography variant="body1">dite</Typography>
              </Grid>
              <Grid container direction="row" item xs={12} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">Multi BARKOD</Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Kontrollo per date skadence
                </Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Mos lejo shitjen me sasi negative
                </Typography>
              </Grid>

              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Informo nese sasia eshte negative
                </Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Shfaq sasite e kalkuluara tek lista e artikujve
                </Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Rrite sasine e Artikullit me 1 nese ai gjendet ne Trupin e
                  Dokumentit
                </Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Informo kur artikulli gjendet njehere ne Trupin e Dokumentit
                </Typography>
              </Grid>
              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  Ne shitje dhe ne blerje kalkulo sipas cmimit te peshes ne KG
                </Typography>
              </Grid>

              <Grid container direction="row" item xs={8} alignItems="center">
                <Checkbox
                  {...label}
                  checked={formData.AplikacioniHapet}
                  onChange={(e) =>
                    handleChange("AplikacioniHapet", e.target.checked)
                  }
                />
                <Typography variant="body1">
                  BARKODI eshte unik ne shitje (vetem informo)
                </Typography>
              </Grid>
            </Grid>
          </Box>
        </Grid>
        {/* </Grid> */}
      </Grid>
      {/* </div> */}
      <footer>
        <Grid item xs={8}>
          <ButtonGroup sx={!isMobile ? { marginTop: 5 } : { marginTop: 5 }}>
            <Button
              sx={{ marginRight: 6 }}
              variant="contained"
              component="span"
              onClick={handleChangeNdermarrje}
            >
              Ruaje
            </Button>
          </ButtonGroup>
        </Grid>
      </footer>
    </>
  );
};

export default KonfigArtikuj;
