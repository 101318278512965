// Importing necessary dependencies and services
import create from "zustand";
import mainAxios, { setAuthToken } from "../services/axios";
import { expireCookie, setCookie } from "../services/helpers";

export const useUser = create((set) => ({
  user: null,
  userAutomatikishtFromCntext: "", // Added to store automatikisht value
  userGrupId: "",

  logout: () => {
    set({ user: null, userAutomatikishtFromCntext: "", userGrupId: "" }); // Clear both user and userAutomatikisht
    setAuthToken(); // Optionally clear authentication token
    expireCookie("access_token");
    window.location.href = "/login";
  },

  login: async ({ username, nipt, password }) => {
    try {
      const response = await mainAxios.post("/login", {
        username,
        nipt,
        password,
      });

      const {
        Users_Grup_Id,
        access_token,
        automatikisht,
        refresh_token,
        ...user
      } = response?.data;

      if (response.status === 200) {
        set({
          user,
          userAutomatikishtFromCntext: automatikisht, // Set automatikisht in the context

          userGrupId: Users_Grup_Id,
        });
        setAuthToken(access_token);
        setCookie("access_token", access_token);
        return { success: true, Users_Grup_Id, automatikisht, user };
      }
      return { success: false };
    } catch (error) {
      console.error(error);
      return { success: false };
    }
  },
}));
