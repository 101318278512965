import React, { useState, useEffect, useRef, useContext } from "react";
import Button from "@mui/material/Button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import useStorage from "../../hooks/useStorage";
import mainAxios from "../../services/axios";
import { TextField } from "@mui/material";
import ManageSearchRoundedIcon from "@mui/icons-material/ManageSearchRounded";
import KeyboardDoubleArrowRightIcon from "@mui/icons-material/KeyboardDoubleArrowRight";
import KeyboardDoubleArrowLeftIcon from "@mui/icons-material/KeyboardDoubleArrowLeft";
import SplitButton from "./ButtonGroup";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import "./SearchFaturat.scss";
import "../ListaArtikujve/ModalList.scss";
import ContentPasteSearchIcon from "@mui/icons-material/ContentPasteSearch";
import { useToast } from "../context/ToastContext";

const SearchFature = ({
  contextKey,
  onTestiChange,
  disabled,
  columns,
  fatureshitje,
  gridKey,
  visible,
  setVisible,
  isMobile,
}) => {
  const [dataNga, setDataNga] = useState("");
  const [dataDeri, setDataDeri] = useState("");
  const [dataNgaOn, setDataNgaOn] = useState("");
  const [dataDeriOn, setDataDeriOn] = useState("");
  const [rows, setRows] = useState([]);
  const showToast = useToast();
  const [nmrPage, setNmrPage] = useState(1);
  const [nmrPageDisabled, setNmrPageDisabled] = useState(false);
  const [properties, setProperties] = useState([]);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [selectedOption, setSelectedOption] = useState(0);

  useEffect(() => {
    const currentDate = new Date();
    setDataDeri(currentDate.toISOString().split("T")[0]);
    const sevenDaysAgo = new Date(currentDate);
    sevenDaysAgo.setDate(currentDate.getDate() - 7);
    const formattedDate = sevenDaysAgo.toISOString().split("T")[0];
    setDataNga(formattedDate);
  }, []);

  const handleSplitButtonOptionClick = async (index) => {
    setSelectedOption(index);
    switch (index) {
      case 0:
        break;
      case 1:
        await allFisIvc();
        break;
      case 2:
        await allNonFisIvc();
        break;
      case 3:
        await allFis();
        break;
      default:
        break;
    }
  };

  const performSearch = async () => {
    showLoading();
    try {
      const response = await mainAxios(
        `/${contextKey}/${dataNga}/${dataDeri}?page=${nmrPage}&&per_page=100`
      );

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));
      setRows(modifiedDataAR);
      setDataNgaOn(dataNga);
      setDataDeriOn(dataDeri);
      setProperties(response.data);
      setSelectedOption("0"); // Ensure case 0 is selected
      handleSplitButtonOptionClick(0);
      if (response.data.length < 100) {
        setNmrPageDisabled(true);
      } else {
        setNmrPageDisabled(false);
      }
    } catch (err) {
      console.error(err);
      setRows([]);

      showToast("Kerkimi deshtoi.", {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  const nextNum = async (page) => {
    showLoading();
    try {
      const response = await mainAxios(
        `/${contextKey}/${dataNgaOn}/${dataDeriOn}?page=${page}&&per_page=100`
      );

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));
      setProperties(response.data);
      if (response.data.length < 100) {
        setNmrPageDisabled(true);
      } else {
        setNmrPageDisabled(false);
      }

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      showToast("Kerkimi deshtoi.", {
        severity: "error",
      });
      setNmrPageDisabled(true);
    } finally {
      hideLoading();
    }
  };

  const allFisIvc = async (perPage = 100) => {
    try {
      // Replace this URL with your actual API endpoint
      const response = await mainAxios(`/${contextKey}/fiskalizuaraEic`, {
        params: {
          nmrPage,
          per_page: perPage,
        },
      });

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));

      if (response.data.length < 100) {
        setNmrPageDisabled(true);
      } else {
        setNmrPageDisabled(false);
      }

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      showToast("Error: " + err.response.data.message, {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  const allNonFisIvc = async (perPage = 100) => {
    showLoading();
    try {
      const response = await mainAxios(`/${contextKey}/pafiskalizuara`, {
        params: {
          nmrPage,
          per_page: perPage,
        },
      });

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));

      if (response.data.length < 100) {
        setNmrPageDisabled(true);
      } else {
        setNmrPageDisabled(false);
      }

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      showToast("Kerkimi deshtoi.", {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  const allFis = async (perPage = 100) => {
    showLoading();
    try {
      const response = await mainAxios(`/${contextKey}/fiskalizuara`, {
        params: {
          nmrPage,
          per_page: perPage,
        },
      });

      const modifiedDataAR = response.data.map((item) => ({
        ...item,
        Data: item.Data ? item.Data.split("T")[0] : "",
      }));

      if (response.data.length < 100) {
        setNmrPageDisabled(true);
      } else {
        setNmrPageDisabled(false);
      }

      setRows(modifiedDataAR);
    } catch (err) {
      console.error(err);
      setRows([]);
      showToast("Kerkimi deshtoi.", {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  // useEffect(() => {
  //   if (properties.length < 20) {
  //     setNmrPageDisabled(true);
  //   } else {
  //     setNmrPageDisabled(false);
  //   }
  //   // eslint-disable-next-line
  // }, [nmrPage]);

  const handleButton1Click = () => {
    const nextPage = nmrPage + 1;
    setNmrPage(nextPage);
    // nextNum(nextPage);

    if (properties.length < 20) {
      setNmrPageDisabled(true);
      return;
    }
    const hasNextPageData = properties.length >= 20;
    if (!hasNextPageData) {
      setNmrPageDisabled(true);
    }
  };

  const handleButton2Click = () => {
    if (nmrPage > 1) {
      const prevPage = nmrPage - 1;
      setNmrPage(prevPage);
      // nextNum(nmrPage - 1);
    }
    const hasPrevPageData = properties.length >= 20;
    if (!hasPrevPageData) {
      setNmrPageDisabled(true);
    }
  };

  const [selectedColumns, setselectedcolumns] = useStorage(
    columns,
    `kolonatEfaturave_${gridKey}`
  );

  const dynamicColumns = selectedColumns.map((col, i) => {
    return (
      <Column
        key={col.field}
        field={col.field}
        header={col.title}
        className={`truncate-text ${col.field}`} // Add a class name for each column
      />
    );
  });

  const onColumnToggle = (event) => {
    let selectedColumns = event.value;
    let orderedSelectedColumns = columns.filter((col) =>
      selectedColumns.some((sCol) => sCol.field === col.field)
    );
    setselectedcolumns(orderedSelectedColumns);
  };

  const headerMobile = (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        height: "50%",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "center",
          width: "100%",
          justifyContent: "space-between",
          marginBottom: "5px",
        }}
      >
        <Button
          style={{ height: "100%" }}
          variant="contained"
          disabled={!dataNga || !dataDeri}
          onClick={(e) => {
            e.preventDefault();
            performSearch();
            // setDataNga("");
            // setDataDeri("");
          }}
        >
          <ManageSearchRoundedIcon />
        </Button>
        <MultiSelect
          style={{ height: "100%", width: "3em" }}
          // className="selectFature"
          value={selectedColumns}
          options={columns}
          optionLabel="title"
          onChange={onColumnToggle}
        />
      </div>

      <div
        style={{
          display: "flex",
          flexDirection: "column",
          width: "100%",
          alignItems: "flex-start",
        }}
      >
        <TextField
          style={{ height: "100%", marginBottom: "5px", width: "100%" }}
          disabled={disabled}
          type="date"
          label="Nga Data"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataNga}
          onChange={(e) => setDataNga(e.target.value)}
        />

        <TextField
          style={{ height: "100%", width: "100%" }}
          disabled={disabled}
          type="date"
          label="Deri Date"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataDeri}
          onChange={(e) => setDataDeri(e.target.value)}
          // style={isMobile ? { width: "50px" } : ""}
        />
      </div>
    </div>
  );

  const headerDesc = (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        width: "100%",
      }}
    >
      <div className="button_header_datatable_shikoFaturat">
        <TextField
          style={{ height: "100%" }}
          disabled={disabled}
          type="date"
          label="Nga Data"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataNga}
          onChange={(e) => setDataNga(e.target.value)}
        />

        <TextField
          style={{ height: "100%" }}
          disabled={disabled}
          type="date"
          label="Deri Date"
          InputLabelProps={{
            shrink: true,
          }}
          value={dataDeri}
          onChange={(e) => setDataDeri(e.target.value)}
          // style={isMobile ? { width: "50px" } : ""}
        />

        <Button
          variant="contained"
          style={{ height: "100%" }}
          disabled={!dataNga || !dataDeri}
          onClick={(e) => {
            e.preventDefault();
            performSearch();
            // setDataNga("");
            // setDataDeri("");
          }}
        >
          <ManageSearchRoundedIcon />
        </Button>
      </div>

      <MultiSelect
        style={{ height: "100%", width: "3em" }}
        // className="selectFature"
        value={selectedColumns}
        options={columns}
        optionLabel="title"
        onChange={onColumnToggle}
      />
    </div>
  );

  const showDialog = () => {
    performSearch();
    setVisible(true);
  };

  const hideDialog = () => {
    setVisible(false);
  };

  const fontOverlay = {
    fontSize: "11px",
  };

  return (
    <>
      <Button
        className="search-btn"
        type="button"
        icon="pi pi-search"
        variant="outlined"
        onClick={showDialog}
        style={{
          color: "#fff",
          backgroundColor: "#1564ad",
          margin: "2px",
        }}
      >
        {isMobile ? (
          <ContentPasteSearchIcon />
        ) : (
          <>
            <ContentPasteSearchIcon />
            Faturat
          </>
        )}
      </Button>

      <Dialog
        visible={visible}
        onHide={hideDialog}
        header="Shiko Faturat"
        style={{
          width: "70%",
          height: "80%",
          textAlign: "center",
        }}
      >
        <div className="card_shiko_faturat">
          <div className="datatable_faturat_main">
            <DataTable
              size="small"
              className="datatable_faturat_inside_main"
              gridKey="FaturatListe"
              header={isMobile ? headerMobile : headerDesc}
              selectionMode="single"
              onSelectionChange={(e) => onTestiChange(e.value)}
              // scrollable
              // virtualScrollerOptions={{ itemSize: 45 }}
              value={rows}
              responsiveLayout="scroll"
              style={fontOverlay}
              columnResizeMode="fit"
              showGridlines
              autoLayout={true}
              dataKey="Id"
              stickyHeader
              resizableColumns
              emptyMessage="Nuk ka te dhena"
              stripedRows
              reorderablecolumnsdatatable="true"
              scrollable
            >
              {dynamicColumns}
            </DataTable>
          </div>

          <div className="buttonsFaturat">
            <div className="btn_fis">
              {fatureshitje ? (
                <SplitButton
                  options={[
                    "Zgjidhni: ",
                    "Te fiskalizuara EIC",
                    "Te pafiskalizuara",
                    "Te fiskalizuara",
                  ]}
                  selectedIndex={selectedOption} // Bind selectedOption to highlight the selected item
                  onSelect={(index) => handleSplitButtonOptionClick(index)}
                />
              ) : null}
            </div>
            {fatureshitje && (
              <div className="btn_fis">
                <>
                  <Button
                    label="Button 2"
                    onClick={handleButton2Click}
                    disabled={nmrPage <= 1}
                    style={{ marginRight: 5 }}
                  >
                    <KeyboardDoubleArrowLeftIcon />
                  </Button>
                </>
                <>
                  <Button
                    label="Button 1"
                    onClick={handleButton1Click}
                    disabled={nmrPageDisabled}
                  >
                    <KeyboardDoubleArrowRightIcon />
                  </Button>
                </>
              </div>
            )}
          </div>
        </div>
      </Dialog>
    </>
  );
};

export default SearchFature;
