import React, { useState, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import mainAxios from "../../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import TableData from "./TableData";

function PikeKlientash({ open, onClose }) {
  // const [dataPosBak, setDataPosBak] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);

  const fetchDataTabelaFaturat = async () => {
    setLoading(true);
    try {
      const dataPaMbyllura = await mainAxios.get(`/kostomesatare`);

      if (dataPaMbyllura.data.length === 0) {
        setData([]);
        return;
      } else {
        setData(dataPaMbyllura.data);
      }
    } catch (error) {
      console.error("Error fetching perdorues:", error);
    } finally {
      setLoading(false);
    }
  };

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      {/* Updated styles for larger dialog */}
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-between", flexDirection: "column" }}
        >
          <span style={{ fontSize: "20px" }}>Kalkulo Kosto </span>
        </div>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ height: "600px" }}>
            <TableData data={data} loading={loading} />
            <div
              className="flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <div></div>
              <div style={{ marginTop: "10px" }}>
                <Button variant="outlined" onClick={fetchDataTabelaFaturat}>
                  Kalkulim
                </Button>
              </div>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default PikeKlientash;
