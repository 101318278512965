import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import mainAxios from "../../../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import { useToast } from "../../../../components/context/ToastContext";

export default function ArkaPos({ open, onClose, arka }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));
  const showToast = useToast();
  const oper = useSelector((state) => state.globalSlice.oper);
  const [vleraValue, setVleraValue] = useState(null);
  const [veprimiValue, setVeprimiValue] = useState(
    oper.length > 0 ? oper[0] : null
  ); // Set default state
  const [arkaValue, setArkaValue] = useState(arka.length > 0 ? arka[0] : null); // Set default state

  const handleSubmit = async () => {
    try {
      const queryParams = `vlera=${vleraValue || ""}&oper=${
        veprimiValue?.Kodi || ""
      }&TCRCode=${arkaValue?.TCRCode || ""}`;

      const response = await mainAxios.get(`/arka/pfissarka?${queryParams}`);

      if (Array.isArray(response.data) && response.data.length > 0) {
        const firstItem = response.data[0];

        if (response.data[0]?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;
          showToast(faultstring, { severity: "error" });
        } else {
          const message = `${veprimiValue?.Pershkrim || ""} u krye me sukses !`;
          showToast(message, { severity: "success" });
        }
      }
    } catch (err) {
      console.error("Error posting data:", err);

      showToast("Deklarimi i Arkes deshtoi." + err.message, {
        severity: "error",
      });
    }
  };

  return (
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      aria-labelledby="responsive-dialog-title"
    >
      <DialogTitle
        id="responsive-dialog-title"
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          textAlign: "center", // Center align the text
        }}
      >
        <h2>Deklarimi i Arkes</h2>

        <Button
          autoFocus
          onClick={onClose}
          style={{ alignSelf: "self-end", color: "red" }}
        >
          <CloseIcon />
        </Button>
      </DialogTitle>

      <DialogContent>
        {/* First Dropdown */}
        <Autocomplete
          options={oper}
          value={veprimiValue} // Set the default value
          getOptionLabel={(option) => `${option.Pershkrim} - ${option.Kodi}`}
          onChange={(event, value) => setVeprimiValue(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Veprimi"
              variant="outlined"
              style={{ width: 300, padding: 5 }}
            />
          )}
        />

        {/* Second Dropdown */}
        <Autocomplete
          options={arka}
          value={arkaValue} // Set the default value
          getOptionLabel={(option) => `${option.Pershkrim} - ${option.TCRCode}`}
          getOptionSelected={(option, value) =>
            option.TCRCode === value.TCRCode
          }
          onChange={(event, value) => setArkaValue(value)}
          renderInput={(params) => (
            <TextField
              {...params}
              label="Arka"
              variant="outlined"
              style={{ width: 300, padding: 5 }}
            />
          )}
        />

        {/* Value Field*/}
        <TextField
          label="Vlera"
          variant="outlined"
          onChange={(event) => setVleraValue(event.target.value)}
          style={{ width: 300, padding: 5 }}
        />
      </DialogContent>
      <DialogActions style={{ textAlign: "center", justifyContent: "center" }}>
        <Button onClick={handleSubmit} autoFocus variant="contained">
          Deklaro
        </Button>
      </DialogActions>
    </Dialog>
  );
}
