import mainAxios from "../../../services/axios";
import { formatDate } from "../../fatureShitje/Function/formatDate";

// Kur Klikohet butoni i "SHTIM"
const handleAdd = async (
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFBlerje,
  setCurrentAction,
  hideLoading
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Pershkrim: row.Pershkrim,
        Vlera: row.Vlera,
        Sasia: row.Sasia,
        Tipi: row.Tipi,
        Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
        SkontoAgjent: row.SkontoAgjent,
        Cmimi: row.Cmimi,
        Operator: row.Operator,
        Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
        Kodi: row.Kodi,
        Tvsh: row.Tvsh,
        CmimiShites: row.Cmimi,
        DateGarancie: row.DateGarancie,
        NrLL: row.NrLL,
        Shenim2: row.Shenim2,
        IdOld: row.IdOld,
        Skonto_VleraAgjent: row.Skonto_VleraAgjent,
        Art_ID: row.Art_ID,
        Punonjes_Id: row.Punonjes_Id,
        BarKod: row.BarKod,
        Njesi_Kodi: row.Njesi_Kodi,
        Skonto_Vlera: row.Skonto_Vlera,
        Total: row.Total,
        Magazina_Kodi: row.Magazina_Kodi,
        TipVlere_ID: row.TipVlere_ID,
        Tvsh_Vlera: row.Tvsh_Vlera,
        NrSerik: row.NrSerik,
        SasiaPak: row.SasiaPak,
        Skonto: row.Skonto,
      };
      return item;
    });
  };

  try {
    showLoading();
    //  POST request i 1 per FATURE SHITJE
    const response = await mainAxios.post(`/fature/blerje`, {
      ...state,
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      Arka_Banka_Kodi: state.Arka_Banka_Kodi,
      Data: formatDate(state.Data, true),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Flete_Hyrje_DATA: formatDate(state.Data, true),
      Flete_Hyrje_KODI: Number(state.Kodi),
      KLFU_Kodi: state.KLFU_Kodi,
      KLFU_Pershkrim: state.KLFU_Pershkrim,
      Kodi: Number(state.Kodi),
      Kodi_ORG: state.Kodi_ORG,
      Kursi: Number(state.Kursi),
      Magazina_Kodi: state.Magazina_Kodi,
      Mon: state.Mon,
      Dep_Kodi: state.Dep_Kodi,
      NIPT: state.NIPT,
      NrSerik: state.NrSerik,
      Operator: String(username),
      Paguar: Number(state.Paguar),
      Paguar_Ne: tipiArkes === "ARKA" ? "ARKA" : "BANKA",
      Qyteti_Kodi: state.Qyteti_Kodi,
      Shenim: state.Shenim,
      Skonto: Number(state.Skonto),
      Veprimi: state.Veprimi,
      VleraRealeFTBL: null,
      Menyra_Pageses_Id: Number(state.Menyra_Pageses_Id),
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");
      const newEntryID = response.data.Id;

      showToast("Fatura u rregjistrua", {
        severity: "success",
      });
      // POST request i 2 per DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.post(
        `/fature/blerje/dtl/bulk`,
        dtlDataArray
      );
      if (responseDtl.status === 200 && tipiDTL === "AR") {
        // Post request i 3 per gjenerimin e FLETE Hyrjes
        const responseFH = await mainAxios.post(
          `/fature/shitje/fb_insert/${newEntryID}`,
          {}
        );
        if (responseFH.status === 200) {
          if (state.Arka_Banka_Kodi) {
            if (tipiArkes === "ARKA") {
              await mainAxios.post(`/ftbl/mandat/${newEntryID}`);
            } else if (tipiArkes === "BANKA") {
              await mainAxios.post(`/ftbl/mandat/banka/${newEntryID}`);
            }
          }

          fetchFBlerje(1);
          setCurrentAction(null);
          showToast("Flete Hyrje u gjenerua!", { severity: "success" });
        } else {
          showToast("Nuk u gjenerua flete hyrje.", {
            severity: "error",
          });
        }
      }
    } else {
      showToast("Gabim gjatë regjistrimit të artikujve", {
        severity: "error",
      });
    }
  } catch (error) {
    console.error("Error in POST request:", error);

    showToast("Gabim gjatë regjistrimit të faturës.", {
      severity: "error",
    });
  } finally {
    hideLoading();
  }
};
let hasArObject;

// Kur Klikohet butoni i "MODIFIKO"
const handleModify = async (
  showLoading,
  state,
  username,
  setState,
  defaultState,
  setDisabled,
  setButtonClicked,
  setSearchString,
  showToast,
  rows,
  tipiDTL,
  tipiArkes,
  fetchFBlerje,
  setCurrentAction,
  hideLoading,
  number,
  currentId
) => {
  const getItemsForSecondRequest = (rows, newEntryID) => {
    hasArObject = rows.some((row) => row.Tipi === "AR");
    return rows.map((row) => {
      let item = {
        Master_Id: newEntryID,
        Id: row.Id,
        Pershkrim: row.Pershkrim,
        Vlera: row.Vlera,
        Sasia: row.Sasia,
        Tipi: row.Tipi,
        SkontoAgjent: row.SkontoAgjent,
        Cmimi: row.Cmimi,
        Operator: row.Operator,
        Vlera_Pa_Tvsh: row.Vlera_Pa_Tvsh,
        Kodi: row.Kodi,
        Tvsh: row.Tvsh,
        DateGarancie: row.DateGarancie,
        NrLL: row.NrLL,
        Shenim2: row.Shenim2,
        IdOld: row.IdOld,
        CmimiShites: row.Cmimi,
        Skonto_VleraAgjent: row.Skonto_VleraAgjent,
        Art_ID: row.Art_ID,
        Punonjes_Id: row.Punonjes_Id,
        BarKod: row.BarKod,
        Njesi_Kodi: row.Njesi_Kodi,
        Skonto_Vlera: row.Skonto_Vlera,
        Cmimi_Pa_Tvsh: row.Cmimi_Pa_Tvsh,
        Total: row.Total,
        Magazina_Kodi: row.Magazina_Kodi,
        TipVlere_ID: row.TipVlere_ID,
        Tvsh_Vlera: row.Tvsh_Vlera,
        NrSerik: row.NrSerik,
        SasiaPak: row.SasiaPak,
        Skonto: row.Skonto,
      };
      return item;
    });
  };
  try {
    showLoading();
    // Requesti i 1 PUT per Faturen e blerje
    const response = await mainAxios.put(`/fature/blerje/${currentId}`, {
      // ...state,
      Afati_PagesesData: formatDate(state.Afati_PagesesData, true),
      Afati_PagesesDite: Number(state.Afati_PagesesDite),
      Arka_Banka_Kodi: state.Arka_Banka_Kodi,
      Data: formatDate(state.Data, true),
      Data_Deklarimit: formatDate(state.Data_Deklarimit, true),
      Flete_Hyrje_DATA: formatDate(state.Data, true),
      Flete_Hyrje_KODI: Number(state.Kodi),
      KLFU_Kodi: state.KLFU_Kodi,
      KLFU_Pershkrim: state.KLFU_Pershkrim,
      Kodi: Number(state.Kodi),
      Kodi_ORG: state.Kodi_ORG,
      Kursi: Number(state.Kursi),
      Magazina_Kodi: state.Magazina_Kodi,
      Mon: state.Mon,
      Dep_Kodi: state.Dep_Kodi,
      NIPT: state.NIPT,
      NrSerik: state.NrSerik,
      Operator: String(username),
      Paguar: Number(state.Paguar),
      Paguar_Ne: tipiArkes === "ARKA" ? "ARKA" : "BANKA",
      Qyteti_Kodi: state.Qyteti_Kodi,
      Shenim: state.Shenim,
      Skonto: Number(state.Skonto),
      Veprimi: state.Veprimi,
      VleraRealeFTBL: null,
      Menyra_Pageses_Id: Number(state.Menyra_Pageses_Id),
    });
    if (response.status === 200) {
      setState(defaultState);
      setDisabled(true);
      setButtonClicked(false);
      setSearchString("");

      const newEntryID = response.data.Id;

      showToast("Fatura u modifikua!", {
        severity: "success",
      });
      //  PUT request i 2 per modifikimin e DTL
      const dtlDataArray = getItemsForSecondRequest(rows, newEntryID);
      const responseDtl = await mainAxios.put(
        `/fature/blerje/dtl/bulk`,
        dtlDataArray
      );

      if (responseDtl.status === 200 && hasArObject) {
        // PUT request i 3 per gjenerimin e FLETE Hyrjes
        const responseFH = await mainAxios.post(
          `/fature/shitje/fb_insert/${newEntryID}`,
          {}
        );
        if (responseFH.status === 200) {
          if (state.Arka_Banka_Kodi) {
            if (tipiArkes === "ARKA") {
              await mainAxios.post(`/ftbl/mandat/${newEntryID}`);
            } else if (tipiArkes === "BANKA") {
              await mainAxios.post(`/ftbl/mandat/banka/${newEntryID}`);
            }
          }

          fetchFBlerje(number);
          setCurrentAction(null);
          showToast("Flete Hyrje e re u gjenerua!", { severity: "success" });
        } else {
          showToast("Nuk u gjenerua flete hyrje.", {
            severity: "error",
          });
        }
      }
    } else {
      showToast("Gabim gjatë regjistrimit të artikujve", {
        severity: "error",
      });
    }
  } catch (error) {
    console.error("Error in POST request:", error);

    showToast("Gabim gjatë modifikimit.", {
      severity: "error",
    });
    if (tipiDTL === "AR") {
      showToast("Gabim gjatë regjistrimit të faturës", {
        severity: "error",
      });
    }
  } finally {
    hideLoading();
  }
};

export { handleAdd, handleModify };
