import React, { useState, useEffect, useRef } from "react";
import Button from "@mui/material/Button";
import { Dialog } from "primereact/dialog";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { MultiSelect } from "primereact/multiselect";
import useStorage from "../../hooks/useStorage";
import { TabView, TabPanel } from "primereact/tabview";
import { useDispatch, useSelector } from "react-redux";
import { getArtikujPag } from "../../store/globalSlice";
import { InputText } from "primereact/inputtext";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import SherbimeDialog from "../../pages/fature-blerje/minimodal/SherbimeDialog";
import "./ModalList.scss";
import mainAxios from "../../services/axios";
import { useToast } from "../context/ToastContext";

const ModalList = ({
  shtoArtikull,
  gridKey,
  showHideColumns = true,
  disabled,
  rows,
}) => {
  const showToast = useToast();
  const dispatch = useDispatch();
  const [visible, setVisible] = useState(false);
  const [searchTermLlogari, setSearchTermLlogari] = useState("");
  const [searchTermSherbime, setSearchTermSherbime] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage] = useState(100);
  const [disableBtnNext, setDisableBtnNext] = useState(false);
  const [disableBtnPrev, setDisableBtnPrev] = useState(false);
  const [dataArt, setDataArt] = useState([]);

  // redux sherbime (get requesti i sherbimit)
  const sherbime = useSelector((state) => state.globalSlice.sherbime);
  const rowsSherbime = sherbime.map((item) => ({
    ...item,
    Tipi: "SH",
  }));

  const numSherbime = `${sherbime ? sherbime.length : 0} Rekorde`;

  // redux sherbime (get requesti i sherbimit)
  const llogariRedux = useSelector((state) => state.globalSlice.llogari);
  const rowsLlogari = llogariRedux.map((item) => ({
    ...item,
    Tipi: "LL",
  }));

  // redux sherbime (get requesti i sherbimit)
  const artikujRedux = useSelector((state) => state.globalSlice.artikujPag);
  const rowsArtikuj = artikujRedux.map((item) => ({
    ...item,
    Tipi: "AR",
  }));

  const numArtikuj = rowsArtikuj.length;

  useEffect(() => {
    if (visible)
      dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&per_page=${numPerPage}`
        )
      );
  }, [dispatch, currentPage, numPerPage]);

  useEffect(() => {
    if (numArtikuj === 0) {
      dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&per_page=${numPerPage}`
        )
      );
    } else {
      setDataArt(rowsArtikuj);
    }
  }, [dispatch, currentPage, numPerPage]);

  useEffect(() => {
    if (currentPage === 1) {
      setDisableBtnPrev(true);
    } else {
      setDisableBtnPrev(false);
    }

    if (numArtikuj < numPerPage) {
      setDisableBtnNext(true);
    } else {
      setDisableBtnNext(false);
    }
  }, [currentPage, numPerPage, numArtikuj]);

  // Function to generate a unique Id between 0 and 999
  const generateUniqueId = () => {
    return Math.floor(Math.random() * 1000);
  };

  const onSelectionChange = (selected) => {
    const selectedValue = selected.value;
    // Check if selectedValue.Kodi exists in rows
    const exists = rows.some((item) => item.Kodi === selectedValue.Kodi);

    if (exists) {
      if (window.confirm("Artikulli ekziston. Dëshironi ta shtoni?")) {
        // Generate a new Id for selectedValue
        const newId = generateUniqueId();

        // Assign the new Id to selectedValue
        selectedValue.Id = newId;
        shtoArtikull(selectedValue, gridKey);

        // Close the dialog
        setVisible(false);
      } else {
        // User clicked "No" or cancelled
        return;
      }
    } else {
      // Generate a new Id for selectedValue
      const newId = generateUniqueId();

      // Assign the new Id to selectedValue
      selectedValue.Id = newId;
      shtoArtikull(selectedValue, gridKey);

      // Close the dialog
      setVisible(false);
    }
  };

  // Column definitions
  const columns = [
    { field: "Kodi", title: "Kodi" },
    { field: "Barkod", title: "Barkod" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Njesi_Kodi", title: "Njesi_Kodi" },
    { field: "Cmimi", title: "Cmimi" },
  ];

  const columnsLlogari = [
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
  ];

  const columnsSherbime = [
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
    { field: "Cmimi", title: "Cmimi" },
  ];

  // Selected columns with useStorage
  const [selectedColumns, setSelectedColumns] = useStorage(
    columns,
    "kolonatEartikujve" + gridKey
  );

  const [selectedColumnsLlogari, setSelectedColumnsLlogari] = useStorage(
    columnsLlogari,
    "kolonatLlogari" + gridKey
  );

  const [selectedColumnsSherbime, setSelectedColumnsSherbime] = useStorage(
    columnsSherbime,
    "kolonatSherbime" + gridKey
  );

  // Generate dynamic columns based on selected values
  const dynamicColumns = selectedColumns.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsLlogari = selectedColumnsLlogari.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  const dynamicColumnsSherbime = selectedColumnsSherbime.map((col) => (
    <Column key={col.field} field={col.field} header={col.title} />
  ));

  // Column toggle function with type
  const onColumnToggle = (event, type) => {
    const selectedCols = event.value;

    if (type === "columns") {
      const updatedSelectedColumns = columns.filter((col) =>
        selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumns(updatedSelectedColumns);
    } else if (type === "llogari") {
      const updatedSelectedColumnsLlogari = columnsLlogari.filter((col) =>
        selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsLlogari(updatedSelectedColumnsLlogari);
    } else if (type === "sherbime") {
      const updatedSelectedColumnsSherbime = columnsSherbime.filter((col) =>
        selectedCols.some((sCol) => sCol.field === col.field)
      );
      setSelectedColumnsSherbime(updatedSelectedColumnsSherbime);
    }
  };

  const filterData = (data, searchTerm, fieldNames) => {
    if (!searchTerm) return data;
    return data.filter((item) => {
      return fieldNames.some((fieldName) => {
        const fieldValue = item[fieldName];
        return (
          fieldValue != null &&
          fieldValue.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );
      });
    });
  };

  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setDataArt(rowsArtikuj);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      const rowsArtikuj = response.data.map((item) => ({
        ...item,
        Tipi: "AR",
      }));
      setDataArt(rowsArtikuj);
    } catch (error) {
      console.error(error);

      showToast("Artikulli nuk u gjend", {
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSearchClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchTerm]);

  const header = (
    <>
      {showHideColumns && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{ width: "20rem", display: "flex", alignItems: "center" }}
            className="p-input-icon-right"
          >
            <i className="pi pi-search" />
            <InputText
              placeholder="Search in Artikuj..."
              value={searchTerm}
              onChange={handleSearchInputChange}
            />
            <Button
              type="button"
              variant="contained"
              onClick={handleSearchClick}
              style={{
                marginLeft: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              Kerko
            </Button>
          </span>

          <MultiSelect
            className="selectArtikuj"
            value={selectedColumns}
            options={columns}
            optionLabel="title"
            onChange={(e) => onColumnToggle(e, "columns")} // Specify type "columns"
            style={{ width: "3em", height: "2em" }}
          />
        </div>
      )}
    </>
  );

  const headerLlogari = (
    <>
      {showHideColumns && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          {" "}
          <span style={{ width: "20rem" }} className="p-input-icon-right">
            <i className="pi pi-search" />
            <InputText
              placeholder="Search in Llogari..."
              value={searchTermLlogari}
              onChange={(e) => setSearchTermLlogari(e.target.value)}
            />
          </span>
          <MultiSelect
            className="selectLlogari"
            value={selectedColumnsLlogari}
            options={columnsLlogari}
            optionLabel="title"
            onChange={(e) => onColumnToggle(e, "llogari")}
            style={{ width: "3em", height: "2em" }}
          />
        </div>
      )}
    </>
  );

  const headerSherbime = (
    <>
      {showHideColumns && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <span
            style={{ width: "20rem", display: "flex" }}
            className="p-input-icon-right"
          >
            <i className="pi pi-search" />
            <InputText
              placeholder="Search in Sherbime..."
              value={searchTermSherbime}
              onChange={(e) => setSearchTermSherbime(e.target.value)}
            />

            <SherbimeDialog numSherbime={numSherbime} sherbime={sherbime} />
          </span>
          <MultiSelect
            className="selectSherbime"
            value={selectedColumnsSherbime}
            options={columnsSherbime}
            optionLabel="title"
            onChange={(e) => onColumnToggle(e, "sherbime")} // Specify type "sherbime"
            style={{ width: "3em", height: "2em" }}
          />
        </div>
      )}
    </>
  );

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };
    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  const fontOverlay = {
    fontSize: "11px",
  };

  const handleNextClick = () => {
    setCurrentPage((prevPage) => prevPage + 1);
  };

  const handlePrevClick = () => {
    setCurrentPage((prevPage) => prevPage - 1);
  };
  const footerBtn = (
    <>
      <div>
        <Button
          variant="contained"
          onClick={handlePrevClick}
          disabled={disableBtnPrev}
        >
          Prev
        </Button>

        <Button
          style={{ marginLeft: 5 }}
          variant="contained"
          onClick={handleNextClick}
          disabled={disableBtnNext}
        >
          Next
        </Button>
      </div>
    </>
  );

  return (
    <>
      <div style={{ display: "flex" }}>
        <Button
          disabled={disabled}
          type="button"
          style={{ height: "2rem" }}
          variant="contained"
          onClick={(e) => {
            setVisible(true);
          }}
        >
          {!isMobile ? (
            <>
              <AddCircleIcon /> Artikuj/Llogari
            </>
          ) : (
            <AddCircleIcon />
          )}
        </Button>
      </div>
      <Dialog
        visible={visible}
        onHide={() => setVisible(false)}
        style={
          isMobile
            ? { width: "90%", height: "80%" }
            : { width: "40%", height: "70%" }
        }
        className="overlaypanel-demo"
      >
        {(gridKey === "FleteDalje" || gridKey === "FleteHyrje") && (
          <div className="card">
            <TabView>
              <TabPanel header="Artikuj">
                <div className="card_artikuj">
                  <DataTable
                    size="small"
                    gridKey="ArtikujListe"
                    header={header}
                    selectionMode="single"
                    onSelectionChange={onSelectionChange}
                    scrollable
                    value={dataArt}
                    responsiveLayout="scroll"
                    style={{ fontOverlay }}
                  >
                    {dynamicColumns}
                  </DataTable>
                </div>
                {footerBtn}
              </TabPanel>
            </TabView>
          </div>
        )}

        {(gridKey === "FatureShitje" || gridKey === "FatureBlerje") && (
          <div className="card">
            <TabView>
              <TabPanel header="Artikuj">
                <div className="card_artikuj">
                  <DataTable
                    size="small"
                    gridKey="ArtikujListe"
                    header={header}
                    selectionMode="single"
                    onSelectionChange={onSelectionChange}
                    scrollable
                    value={dataArt}
                    responsiveLayout="scroll"
                    style={{ fontOverlay }}
                  >
                    {dynamicColumns}
                  </DataTable>
                </div>
                {footerBtn}
              </TabPanel>

              <TabPanel header="Llogari">
                <div className="card_artikuj">
                  <DataTable
                    size="small"
                    gridKey="Llogari"
                    header={headerLlogari}
                    selectionMode="single"
                    onSelectionChange={onSelectionChange}
                    scrollable
                    // virtualScrollerOptions={{ itemSize: 45 }}
                    value={filterData(rowsLlogari, searchTermLlogari, [
                      "Pershkrim",
                      "Kodi",
                    ])}
                    responsiveLayout="scroll"
                  >
                    {dynamicColumnsLlogari}
                  </DataTable>
                </div>
              </TabPanel>

              <TabPanel header="Sherbime">
                <div className="card_artikuj">
                  <DataTable
                    size="small"
                    gridKey="Sherbime"
                    header={headerSherbime}
                    selectionMode="single"
                    onSelectionChange={onSelectionChange}
                    scrollable
                    style={{ fontOverlay }}
                    value={filterData(rowsSherbime, searchTermSherbime, [
                      "Pershkrim",
                      "Kodi",
                    ])}
                    responsiveLayout="scroll"
                  >
                    {dynamicColumnsSherbime}
                  </DataTable>
                </div>
              </TabPanel>
            </TabView>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default ModalList;
