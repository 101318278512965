import React, { useState, useRef } from "react";
import AddIcon from "@mui/icons-material/Add";
import { TextField } from "@mui/material";
import { Column } from "primereact/column";
import { DataTable } from "primereact/datatable";
import { InputText } from "primereact/inputtext";
import { Dialog } from "primereact/dialog";
import mainAxios from "../../../../services/axios";
import { getGrupi } from "../../../../store/globalSlice";
import { useDispatch } from "react-redux";
import SmallButtonGroup from "../../../fature-blerje/Function/SmallButton";
import { useToast } from "../../../../components/context/ToastContext";

const Grupi = ({ numGrupi, grupi }) => {
  const defaultState = {
    Aktiv: 1,
    AktivPreselection: null,
    Cmimi_Kosto: null,
    DuhetKG: null,
    DuhetSasi: null,
    DuhetVolum: null,
    Hapsira: null,
    ImageIndex: null,
    KGPerNjPerqind: null,
    Kodi: "",
    Master_Kodi: null,
    Pershkrim: "",
    Pershkrim2: null,
    PeshaKg: null,
    Rendit: null,
  };
  const showToast = useToast();
  const [currentAction, setCurrentAction] = useState("");
  const [disabled, setDisabled] = useState(true);
  const [state, setState] = useState(defaultState);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [selectedProduct1, setSelectedProduct1] = useState(null);
  const [check] = useState(false);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [selectedRowId, setSelectedRowId] = useState(null);
  const dispatch = useDispatch();

  const submitHanlder = async () => {
    if (currentAction === "add") {
      if (!state.Pershkrim.trim()) {
        showToast(
          "Plotesoni fushat e kerkuara: " +
            (!state.Pershkrim.trim() ? "Pershkrim " : ""),
          {
            severity: "warn",
          }
        );
        return;
      }

      try {
        const responseTar = await mainAxios.post(`/art/kls01`, {
          Pershkrim: state.Pershkrim,
        });

        if (responseTar.status === 200) {
          setState(defaultState);
          setDisabled(true);
          setButtonClicked(false);
          dispatch(getGrupi("/art/kls01"));

          showToast("U rregjistrua", {
            severity: "success",
          });
        }
      } catch (error) {
        showToast("Nuk u regjistrua." + error.message, {
          severity: "error",
        });
      }
    }
    if (currentAction === "modify") {
      const responseTar = await mainAxios.put(`/art/kls01/${selectedRowId}`, {
        Pershkrim: state.Pershkrim,
      });

      if (responseTar.status === 200) {
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getGrupi("/art/kls01"));
        showToast("U rregjistrua", {
          severity: "success",
        });
      }
    }
  };

  const onAddClick = () => {
    setCurrentAction("add");
  };

  const putMethod = () => {
    setCurrentAction("modify");
  };

  const deleteMethod = async (selectedRowId) => {
    try {
      const resDelete = await mainAxios.delete(`/art/kls01/${selectedRowId}`);
      if (resDelete.status === 200) {
        setSelectedRowId(null);

        showToast("U fshi", {
          severity: "info",
        });
        dispatch(getGrupi("/art/kls01"));
      }
    } catch (err) {
      console.error(err);

      showToast("Nuk mund te fshihet.", {
        severity: "error",
      });
    }
  };

  const isPositiveInteger = (val) => {
    let str = String(val);
    str = str.trim();
    if (!str) {
      return false;
    }
    str = str.replace(/^0+/, "") || "0";
    let n = Math.floor(Number(str));
    return n !== Infinity && String(n) === str && n >= 0;
  };

  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim" },
  ]);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const onCellEditComplete = (e) => {
    let { rowData, newValue, field, originalEvent: event } = e;

    switch (field) {
      case columns:
        if (isPositiveInteger(newValue)) rowData[field] = newValue;
        else event.preventDefault();
        break;

      default:
        if (newValue.length > 0) rowData[field] = newValue;
        else event.preventDefault();
        break;
    }
  };

  const cellEditor = (options, col) => {
    const column = options?.column;
    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return textEditor(options, col);
  };

  const handleRowClick = (rowData) => {
    setState({
      ...defaultState,
      Kodi: rowData.Kodi,
      Pershkrim: rowData.Pershkrim,
      // Tvsh: rowData.Tvsh,
      // Aktiv: rowData.Aktiv,
    });
    setSelectedRowId(rowData.ID);
  };

  const textEditor = (options, col) => {
    <InputText
      disabled={() => setDisabled(true)}
      className="cursor-pointer inp"
      type={col?.fieldType ?? "text"}
      value={options.value}
      onChange={(e) => options.editorCallback(e.target.value)}
    />;
  };

  //  useState i modaleve brenda kategorive ne fletblerje
  const [displayResponsive, setDisplayResponsive] = useState(false);

  const dialogFuncMap = {
    displayResponsive: setDisplayResponsive,
  };

  const onClick = (Pershkrim, position) => {
    dialogFuncMap[`${Pershkrim}`](true);
  };

  const onHide = (Pershkrim) => {
    dialogFuncMap[`${Pershkrim}`](false);
  };

  const cm = useRef(null);

  const dynamicColumns = columns.map((col, i) => {
    return check ? (
      <Column
        editor={(options) => cellEditor(options, col)}
        onCellEditComplete={onCellEditComplete}
        key={col.field}
        field={col.field}
        header={col.title}
      />
    ) : (
      <Column key={col.field} field={col.field} header={col.title} />
    );
  });

  return (
    <div>
      <form>
        <AddIcon
          fontSize="small"
          className="cursor-pointer"
          onClick={() => onClick("displayResponsive")}
        >
          Open
        </AddIcon>

        <Dialog
          header=" Grupi"
          visible={displayResponsive}
          onHide={() => onHide("displayResponsive")}
          breakpoints={{ "960px": "75vw" }}
          style={{ width: "24.5vw" }}
        >
          <div className=" mt-2">
            <SmallButtonGroup
              setSelectedRowId={setSelectedRowId}
              putMethod={putMethod}
              onAddClick={onAddClick}
              buttonClicked={buttonClicked}
              deleteMethod={deleteMethod}
              setButtonClicked={setButtonClicked}
              setDisabled={setDisabled}
              disabled={disabled}
              setState={setState}
              defaultState={defaultState}
              submitHandler={submitHanlder}
              selectedRowId={selectedRowId}
            />
          </div>

          <div className="border flex flex-col p-2 relative mt-3">
            {/* <span className='absolute z-20' style={{top:-15,color:"#1971c2",backgroundColor:"white",fontWeight:500}}>Skema</span> */}

            {/* <TextField
              disabled={true}
              type="text"
              variant="outlined"
              label="Kodi"
              inputProps={{ maxLength: "10" }}
              size="small"
              value={"Kodi eshte automatik"}
              onChange={(e) => handleChange("Kodi", e.target.value)}
              className="mt-2 w-7/12"
            /> */}

            <TextField
              disabled={disabled}
              variant="outlined"
              label="Pershkrim"
              type="text"
              value={state?.Pershkrim}
              onChange={(e) => handleChange("Pershkrim", e.target.value)}
              size="small"
              className="mt-2 w-7/12"
            />

            <div className="border p-2 flex flex-col mt-2">
              <div className="flex items-center">
                <DataTable
                  editMode="cell"
                  selectionMode="single"
                  selection={selectedProduct1}
                  onSelectionChange={() => setSelectedProduct1(defaultState)}
                  size="small"
                  scrollHeight="10rem"
                  scrollable
                  value={grupi}
                  responsiveLayout="scroll"
                  contextMenuSelection={selectedProduct}
                  contextselection={selectedProduct}
                  onContextMenuSelectionChange={(e) =>
                    setSelectedProduct(defaultState)
                  }
                  onContextMenu={(e) => cm.current.show(e.originalEvent)}
                  onRowClick={(e) => handleRowClick(e.data)}
                  style={{ width: "100%" }}
                >
                  {dynamicColumns}
                </DataTable>
              </div>
            </div>
          </div>
          <span>
            <b>{numGrupi}</b>
          </span>
        </Dialog>
      </form>
    </div>
  );
};

export default Grupi;
