import PropTypes from "prop-types";
import { useRef, useState, useEffect } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import ButtonBase from "@mui/material/ButtonBase";
import CardContent from "@mui/material/CardContent";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import IconButton from "@mui/material/IconButton";
import Popper from "@mui/material/Popper";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";

// project import
import ProfileTab from "./ProfileTab";
import SettingTab from "./SettingTab";
import Avatar from "../../../../../components/@extended/Avatar";
import MainCard from "../../../../../components/MainCard";
import Transitions from "../../../../../components/@extended/Transitions";

// assets
import LogoutOutlined from "@ant-design/icons/LogoutOutlined";
import SettingOutlined from "@ant-design/icons/SettingOutlined";
import UserOutlined from "@ant-design/icons/UserOutlined";
// import avatar1 from "../../../../../assets/images/users/avatar-1.png";
import avatar1 from "../../../../../assets/images/users/person.png";

// my modifications
import { useLocation } from "react-router-dom";

// import user Context .
import { useUser } from "../../../../../zustand/common";

// icons
import WbSunnyOutlinedIcon from "@mui/icons-material/WbSunnyOutlined";
import NightsStayOutlinedIcon from "@mui/icons-material/NightsStayOutlined";
import { useLogo } from "../../../../../components/context/LogoContext";

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`profile-tabpanel-${index}`}
      aria-labelledby={`profile-tab-${index}`}
      {...other}
    >
      {value === index && children}
    </div>
  );
}

function a11yProps(index) {
  return {
    id: `profile-tab-${index}`,
    "aria-controls": `profile-tabpanel-${index}`,
  };
}

// ==============================|| HEADER CONTENT - PROFILE ||============================== //

export default function Profile() {
  const { toggleLogo } = useLogo();
  const theme = useTheme();
  const { user, logout } = useUser();
  const username = user.username;
  const nipt = user.nipt;

  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const [themeBtn, setTheme] = useState("light"); // Default to light mode initially

  useEffect(() => {
    const savedTheme = localStorage.getItem("theme");
    if (savedTheme) {
      setTheme(savedTheme); // Set the theme from localStorage if available
      toggleTheme(savedTheme); // Apply the theme to the document
      toggleLogo(savedTheme);
    } else {
      setTheme("light"); // Default to light mode if no theme is saved
      toggleTheme("light");
      toggleLogo("light");
    }
  }, []); // Empty dependency array means this will run once when the component mounts

  const handleThemeToggle = () => {
    const newTheme = themeBtn === "light" ? "dark" : "light";
    setTheme(newTheme); // Update theme state
    toggleTheme(newTheme); // Apply theme to the document
    toggleLogo(newTheme);
    localStorage.setItem("theme", newTheme); // Save the selected theme to localStorage
  };

  const toggleTheme = (mode) => {
    if (mode === "dark") {
      document.documentElement.setAttribute("data-theme", "dark");
    } else {
      document.documentElement.removeAttribute("data-theme"); // Default to light mode
    }
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  };

  const [value, setValue] = useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const iconBackColorOpen = "grey.100";

  // my modifications
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const source = queryParams.get("source");
  // const user = queryParams.get("user");

  return (
    <Box sx={{ flexShrink: 0, ml: 0.75 }}>
      <ButtonBase
        sx={{
          p: 0.25,
          bgcolor: open ? iconBackColorOpen : "transparent",
          borderRadius: 1,

          "&:hover": { bgcolor: "secondary.lighter" },
          "&:focus-visible": {
            outline: `2px solid ${theme.palette.secondary.dark}`,
            outlineOffset: 2,
          },
        }}
        aria-label="open profile"
        ref={anchorRef}
        aria-controls={open ? "profile-grow" : undefined}
        aria-haspopup="true"
        onClick={handleToggle}
      >
        <Stack
          direction="row"
          // spacing={1.25}
          alignItems="center"
          sx={{ p: 0.5 }}
        >
          <Avatar alt="profile user" src={avatar1} size="sm" type="combined" />
          <Typography variant="subtitle1" sx={{ textTransform: "capitalize" }}>
            {/* <p>Source: {source}</p> */}
            {/* <p>{user}</p> */}
          </Typography>
        </Stack>
      </ButtonBase>
      <Popper
        placement="bottom-end"
        open={open}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
        popperOptions={{
          modifiers: [
            {
              name: "offset",
              options: {
                offset: [0, 9],
              },
            },
          ],
        }}
        sx={{
          zIndex: theme.zIndex.modal, // Ensure it stays on top
          position: "absolute", // Make it absolute
          width: 290, // Match Paper's width
          maxWidth: { xs: 250, md: 290 },
        }}
      >
        {({ TransitionProps }) => (
          <Transitions
            type="grow"
            position="top-right"
            in={open}
            {...TransitionProps}
          >
            <Paper
              sx={{
                boxShadow: theme.customShadows.z1,
                width: 290,
                minWidth: 240,
                maxWidth: { xs: 250, md: 290 },
              }}
            >
              <ClickAwayListener onClickAway={handleClose}>
                <MainCard elevation={0} border={false} content={false}>
                  <CardContent sx={{ px: 2.5, pt: 3 }}>
                    <Grid
                      container
                      justifyContent="space-between"
                      alignItems="center"
                    >
                      <Grid item>
                        <Stack
                          direction="row"
                          spacing={1.25}
                          alignItems="center"
                        >
                          <Avatar
                            alt="profile user"
                            src={avatar1}
                            sx={{ width: 32, height: 32 }}
                          />
                          <Stack>
                            <Typography variant="h6">{username}</Typography>
                            <Typography variant="body2" color="text.secondary">
                              {nipt}
                            </Typography>
                          </Stack>
                        </Stack>
                      </Grid>
                      {/* <Grid item>
                        <Tooltip title="Logout">
                          <IconButton
                            size="large"
                            sx={{ color: "text.primary" }}
                            onClick={() => {
                              setLogout(true); // Set setLogout to true
                            }}
                          >
                            <LogoutOutlined />
                          </IconButton>
                        </Tooltip>
                      </Grid> */}

                      <Grid item>
                        <Tooltip
                          title={
                            themeBtn === "light"
                              ? "Dark Mode ?"
                              : "Light Mode ?"
                          }
                        >
                          <IconButton
                            onClick={handleThemeToggle}
                            aria-label="toggle theme"
                            className="theme-toggle"
                            sx={{ marginLeft: "auto" }}
                          >
                            {themeBtn === "light" ? (
                              <NightsStayOutlinedIcon
                                style={{ color: "#00008B" }}
                              />
                            ) : (
                              <WbSunnyOutlinedIcon
                                style={{ color: "#DF9755" }}
                              />
                            )}
                          </IconButton>
                        </Tooltip>
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
                    <Tabs
                      variant="fullWidth"
                      value={value}
                      onChange={handleChange}
                      aria-label="profile tabs"
                    >
                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                        icon={
                          <UserOutlined
                            style={{ marginBottom: 0, marginRight: "10px" }}
                          />
                        }
                        label="Profili"
                        {...a11yProps(0)}
                      />

                      <Tab
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          justifyContent: "center",
                          alignItems: "center",
                          textTransform: "capitalize",
                        }}
                        icon={
                          <SettingOutlined
                            style={{ marginBottom: 0, marginRight: "10px" }}
                          />
                        }
                        label="Setting"
                        {...a11yProps(1)}
                      />
                    </Tabs>
                  </Box>
                  <TabPanel value={value} index={0} dir={theme.direction}>
                    <ProfileTab logout={logout} />
                  </TabPanel>
                  <TabPanel value={value} index={1} dir={theme.direction}>
                    <SettingTab />
                  </TabPanel>
                </MainCard>
              </ClickAwayListener>
            </Paper>
          </Transitions>
        )}
      </Popper>
    </Box>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  value: PropTypes.number,
  index: PropTypes.number,
  other: PropTypes.any,
};
