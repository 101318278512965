import React, { useState, useRef, useEffect } from "react";
import Button from "@mui/material/Button";
import { InputText } from "primereact/inputtext";
import mainAxios from "../../../../services/axios";
import { useToast } from "../../../../components/context/ToastContext";

const Kerko = ({ setDataArt, rowsArtikuj }) => {
  const showToast = useToast();
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setDataArt(rowsArtikuj);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      const responseData = response.data.map((item) => ({
        ...item,
        Tipi: "AR",
      }));
      setDataArt(responseData);
    } catch (error) {
      console.error(error);

      showToast("Artikulli nuk u gjend", {
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSearchClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchTerm]);
  return (
    <div>
      <span
        style={{ width: "20rem", display: "flex", alignItems: "center" }}
        className="p-input-icon-right"
      >
        <i className="pi pi-search" />
        <InputText
          placeholder="Search in Artikuj..."
          value={searchTerm}
          onChange={handleSearchInputChange}
        />
        <Button
          type="button"
          variant="contained"
          onClick={handleSearchClick}
          style={{
            marginLeft: "10px",
            display: "flex",
            alignItems: "center",
          }}
        >
          Kerko
        </Button>
      </span>
    </div>
  );
};

export default Kerko;
