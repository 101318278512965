import React, { useState } from "react";
import { Checkbox } from "primereact/checkbox";
import ButtonsFunction from "./ButtonsFunction";
import "../../paneliIartikujve/TePergjithshme.scss";

const LlogariButtons = ({
  isMobile,
  disabled,
  state,
  setState,
  handleChangeTarifa,
  updateRowData,
  isEditingAllowed,
}) => {
  const handleChangeVeprime = (key, value) => {
    const updatedRowData = { [key]: value };
    if (isEditingAllowed) {
      updateRowData(updatedRowData);
    }

    setState((state) => {
      return {
        ...state,
        [key]: key === "Veprime" ? (value ? 1 : 0) : value,
      };
    });
  };

  const handleChange = (key, value) => {
    if (isEditingAllowed) {
      let updatedRowData;
      if (value === "" || value === undefined || value === null) {
        updatedRowData = { [key]: null };
      } else {
        updatedRowData = { [key]: value };
      }

      if (state[key] === updatedRowData[key]) {
        updateRowData(updatedRowData);
      } else {
        updateRowData(updatedRowData);
      }
    } else {
      setState((state) => ({
        ...state,
        [key]: value,
      }));
    }
  };

  const [initialLength, setInitialLength] = useState(null);

  return (
    <div className={`second_div flex ${isMobile ? "flex-col" : ""} `}>
      <ButtonsFunction
        state={state}
        setState={setState}
        disabled={disabled}
        handleChange={handleChange}
        handleChangeTarifa={handleChangeTarifa}
        updateRowData={updateRowData}
        isEditingAllowed={isEditingAllowed}
        initialLength={initialLength}
        setInitialLength={setInitialLength}
      />

      <div className={`second-border-pergjithshme border flex gap-3 resizable`}>
        <div className="checkbox_box border">
          <div className="flex items-center">
            <Checkbox
              className="checkbox_artikuj"
              disabled={disabled}
              checked={state?.Veprime === 1}
              onChange={(e) =>
                handleChangeVeprime("Veprime", e.target.checked ? 1 : 0)
              }
            />
            <label
              style={{ color: "#1971c2", marginLeft: "10px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Veprime
            </label>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LlogariButtons;
