import React, { useEffect, useState, memo, useContext } from "react";
import PrimeGrid from "../../components/primeReact/PrimeGrid";
import { Panel } from "primereact/panel";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import TabFurnitore from "../fature-blerje/tabet/Furnitor/TabFurnitore";
import TabPaguar from "../fature-blerje/tabet/Paguar/TabPaguar";
import TabMagazina from "../fature-blerje/tabet/Magazina/TabMagazina";
import TabTjera from "../fature-blerje/tabet/Tjera/TabTjera";
import MainTab from "../fature-blerje/tabet/MainTab/MainTab";
import PaneliTabeve from "../fature-blerje/tabet/PaneliTabeve";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import PrintoPdf from "../../components/ReactPDF/PrintoPdf";
import "../fature-blerje/FatureBlerje.scss";
import PostAddIcon from "@mui/icons-material/PostAdd";
import PeopleIcon from "@mui/icons-material/People";
import AttachMoneyIcon from "@mui/icons-material/AttachMoney";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useLocation } from "react-router-dom";
import { useSelector } from "react-redux";
import Footer from "../../components/Navbar/Footer";
import Template from "../dynamicComponent/Template";
import { shtoArtikull, calculateData } from "../dynamicComponent/ArtikullCalc";
import { handleAdd, handleModify } from "./Function/handleAddFb";
import { getIndexFirstFtB, getNextKodiFsh } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import { useUser } from "../../zustand/common";
import { useFtbContext } from "../../components/context/FtbState";
import { handleFurnitorType } from "./Function/handleFurnitorType";
import { formatDate } from "../fatureShitje/Function/formatDate";
import { useToast } from "../../components/context/ToastContext";

const FatureBlerje = ({
  hidePupUp,
  setHidePupUp,
  currentAction,
  setCurrentAction,
  isOnline,
  isMobile,
  disabled,
  setDisabled,
  drawerOpen,
  setDrawerOpen,
}) => {
  const showToast = useToast();
  const { user } = useUser();
  const username = user.username;

  const {
    state,
    setState,
    defaultState,
    furnitor,
    monedhat,
    furnitorRedux,
    menyrapageses,
    arka,
    qytetet,
  } = useFtbContext();

  const dispatch = useDispatch();
  // const { globalSmallApi } = useGlobalSmallApi();
  const [tipiDTL, setTipiDTL] = useState("");
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [fisVerificationUrl, setFisVerificationUrl] = useState("");
  const [visible, setVisible] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [tipiArkes, setTipiArkes] = useState(null);

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = async () => {
    setCurrentAction("add");
    setCurrentId(lastId);
    const response = await dispatch(getNextKodiFsh("/fb/nextKodi"));

    if (response.payload && response.payload.new_Kodi) {
      setState((prevState) => ({
        ...prevState,
        Kodi: response.payload.new_Kodi,
      }));
    }
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    setCurrentAction("modify");
  };

  const [buttonClicked, setButtonClicked] = useState(false);

  // redux mer indexin e fatures se pare
  const firstFatureIndex = useSelector(
    (state) => state.globalSlice.getFirstFtB
  );

  // redux arka/banka (get requesti i Arka/Banka)
  const [selectedArkaMon, setSelectedArkaMon] = useState(
    arka && arka[0] ? arka[0].Mon : ""
  );
  const [rows, setRows] = useState([]);
  const [index, setIndex] = useState(0);

  const columnsSF = [
    { field: "Id", title: "Id" },
    { field: "Kodi", title: "Kodi" },
    { field: "Data", title: "Data" },
    { field: "KLFU_Kodi", title: "Furnitori" },
    { field: "Vlera", title: "Vlera" },
    { field: "Shenim", title: "Shenim" },
    { field: "Operator", title: "Operator" },
  ];

  const [columns, setColumns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BarKod", title: "BarKod" },
    { field: "Pershkrim", title: "Pershkrim", impMinWidth: true },
    { field: "Njesi_Kodi", title: "Njesi_Kodi", impMaxWidth: true },
    { field: "Sasia", title: "Sasia", allowSum: true, impMaxWidth: true },
    { field: "Skonto", title: "Skonto (%)", impMaxWidth: true },
    {
      field: "Skonto_Vlera",
      title: "Skonto_Vlera",
      allowSum: true,
    },
    { field: "Cmimi_Pa_Tvsh", title: "Cmimi_Pa_Tvsh" },
    {
      field: "Vlera_Pa_Tvsh",
      title: "Vlera_Pa_Tvsh",
      allowSum: true,
    },
    { field: "Tvsh", title: "Tvsh", impMaxWidth: true },
    {
      field: "Tvsh_Vlera",
      title: "Tvsh_Vlera",
      allowSum: true,
    },
    { field: "Cmimi", title: "Cmimi" },
    { field: "Vlera", title: "Vlera", allowSum: true },
    { field: "Tipi", title: "Tipi", impMaxWidth: true },
    { field: "Total", title: "Total", allowSum: true },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull, gridKey) => {
    shtoArtikull(artikull, rows, setRows, setTipiDTL, state, gridKey);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const updateDepKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Dep_Kodi: newKodi,
    }));
  };

  const [searchString, setSearchString] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);
  const [number, setNumber] = useState(1);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const location = useLocation();
  const { nmrFB, fromArka } = location.state || {};

  useEffect(() => {
    if (fromArka) {
      setNumber(nmrFB);
      fetchFBlerje(nmrFB);
    } else {
      fetchFBlerje(number);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchFBlerje = async (number) => {
    try {
      showLoading();
      const response = await mainAxios(`/last/faturab/${number}`);
      const responseTotalRows = await dispatch(
        getIndexFirstFtB("/first/faturab")
      );
      const total_data_num = responseTotalRows.payload.total_rows;

      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      const responseData = response.data;
      handleChangeValue(response.data.Id);

      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(
          `/fature/blerje/dtl/${responseData.Id}`
        );
        const dtlData = dtlResponse.data;

        setState(responseData);
        setResponseData(responseData);
        setLastObject(responseData);
        setFisVerificationUrl(responseData.fisVerificationUrl);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);

    if (type === "first") {
      setNumber(firstFatureIndex.total_rows);
      fetchFBlerje(firstFatureIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchFBlerje(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchFBlerje(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "last") {
      setNumber(1);
      fetchFBlerje(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/fature/blerje/index/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchFBlerje(numberIndex);
        showToast("Fatura u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e fatures.", { severity: "error" });
      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  //Butoni "Regjistro" perdor currentAction qe te vendosi, ca requesti te bej.
  const handleRegister = async () => {
    if (!state.KLFU_Kodi) {
      showToast("Cakto Furnitorin!");
      return;
    }

    if (tipiDTL === "AR") {
      if (!state.Magazina_Kodi) {
        showToast("Cakto Magazinen!", { hideProgressBar: true });
        return;
      }
    }

    if (state.Mon && selectedArkaMon && state.Mon !== selectedArkaMon) {
      showToast("Monedha e Arkes/Bankes e ndryshme nga monedha e fatures!", {
        hideProgressBar: true,
      });
      return;
    }

    if (rows.length === 0) {
      showToast("Shto Artikuj ose anullo faturen!");
      return;
    } else {
      try {
        for (const id of deletedRowIds) {
          await mainAxios.delete(`/fature/blerje/dtl/${id}`);
        }
      } catch (error) {
        console.error("Error deleting row:", error);
      } finally {
        setDeletedRowIds([]);
      }
    }

    if (currentAction === "add") {
      handleFunctionAdd();
    } else if (currentAction === "modify") {
      handleFunctionModify();
    }
  };

  const handleFunctionAdd = () => {
    handleAdd(
      showLoading,
      state,
      username,
      setState,
      defaultState,
      setDisabled,
      setButtonClicked,
      setSearchString,
      showToast,
      rows,
      tipiDTL,
      tipiArkes,
      fetchFBlerje,
      setCurrentAction,
      hideLoading,
      number,
      currentId,
      rows
    );
  };

  const handleFunctionModify = () => {
    handleModify(
      showLoading,
      state,
      username,
      setState,
      defaultState,
      setDisabled,
      setButtonClicked,
      setSearchString,
      showToast,
      rows,
      tipiDTL,
      tipiArkes,
      fetchFBlerje,
      setCurrentAction,
      hideLoading,
      number,
      currentId,
      rows
    );
  };

  const updateMagazinaKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Magazina_Kodi: newKodi,
    }));
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const handleFunctionFurnitor = (event, newValue) => {
    handleFurnitorType(
      event,
      newValue,
      setSearchString,
      furnitorRedux,
      setState
    );
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;

    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  useEffect(() => {
    // Update the Total for each row in the rows array
    const updatedRows = rows.map((row) => {
      return {
        ...row,
        Total: row.Vlera * state.Kursi,
      };
    });

    // Update the rows state with the updated rows
    setRows(updatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Kursi]);

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
    setSelectedArkaMon("");
  };

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      fatureDisabled={fatureDisabled}
      popupContextKey="FatureBlerje"
      contexDelete={"faturen"}
      onAddClick={onAddClick}
      onModifyClick={onModifyClick}
      handleRegister={handleRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchFBlerje={fetchFBlerje}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      number={number}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );

  const button6Text = isMobile ? "" : "Furnitor";
  const button7Text = isMobile ? "" : "Paguar";
  const button8Text = isMobile ? "" : "Mag";
  const button9Text = isMobile ? "" : "Tjera";

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      includeButton7={true}
      includeButton8={true}
      includeButton9={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<PeopleIcon />}
      button7Text={button7Text}
      button7Icon={<AttachMoneyIcon />}
      button8Text={button8Text}
      button8Icon={<WarehouseIcon />}
      button9Text={button9Text}
      isMobile={isMobile}
      button9Icon={<AutoAwesomeMotionIcon />}
    />
  );

  const TabiKryesor = (
    <MainTab
      formatDate={formatDate}
      includeDateField={true}
      includeNrOrigjinesField={true}
      includeKursiField={true}
      includeMonedhaSelect={true}
      includeKodiField={true}
      includeSerialNumerField={true}
      includeModalList={true}
      disabled={disabled}
      state={state}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
    />
  );

  const BtnPrint = (
    <PrintoPdf
      className="print"
      state={state}
      rows={rows}
      fisVerificationUrl={fisVerificationUrl}
      menyrapageses={menyrapageses}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab1 */}
      <TabFurnitore
        qytetFurnitor={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        state={state}
        handleChange={handleChange}
        setSearchString={setSearchString}
        searchString={searchString}
        handleFurnitorType={handleFunctionFurnitor}
        qytetet={qytetet}
        furnitor={furnitor}
      />

      {/* tab2 */}
      <TabPaguar
        fatureType="Fature_Blerje_Id"
        popupContextKey="FatureBlerje"
        setSelectedArkaMon={setSelectedArkaMon}
        setState={setState}
        formatDate={formatDate}
        setTipiArkes={setTipiArkes}
        toggleState={toggleState === 2}
        disabled={disabled}
        handleChange={handleChange}
        state={state}
        rows={rows}
        menyrapageses={menyrapageses}
        tipiArkes={tipiArkes}
        buttonClicked={buttonClicked}
        // pergjegjes={pergjegjes}
      />
      {/* /////*/}

      {/* tab3 */}
      <TabMagazina
        buttonClicked={buttonClicked}
        fleteHyrjeBtn={true}
        disabled={disabled}
        formatDate={formatDate}
        toggleState={toggleState === 3}
        state={state}
        qytetet={qytetet}
        handleChange={handleChange}
        updateMagazinaKodi={updateMagazinaKodi}
        number={number}
      />
      {/*///// */}

      {/* tab4 */}
      <TabTjera
        updateDepKodi={updateDepKodi}
        formatDate={formatDate}
        toggleState={toggleState === 4}
        disabled={disabled}
        state={state}
        handleChange={handleChange}
        includeButton6={true}
        includeButton5={false}
        fetchPost={fetchFBlerje}
      />
    </div>
  );

  return (
    <div className={`mainDiv_ftsh ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          <div className="main-container p-2">
            <div className="top_panel_fsh">
              <Panel
                headerTemplate={
                  <Template
                    options={{
                      collapsed: isPanelToggleable,
                      onTogglerClick: () =>
                        setIsPanelToggleable(!isPanelToggleable),
                    }}
                    createdBy={state.Operator}
                    isMobile={isMobile}
                    fatureName="Fature Blerje"
                    currentId={currentId}
                    nxtBtnDisable={nxtBtnDisable}
                    prvBtnDisable={prvBtnDisable}
                    shikoFaturen={shikoFaturen}
                    handleTestiChange={handleTestiChange}
                    columnsSF={columnsSF}
                    contextKey={"fature/blerje"}
                    visible={visible}
                    isOnline={isOnline}
                    setVisible={setVisible}
                    gridKey="FatureBlerje"
                  />
                }
                toggleable
                collapsed={isPanelToggleable}
                onToggle={(e) => setIsPanelToggleable(!isPanelToggleable)}
                style={{ height: "100%" }}
              >
                <div className="bg-white">
                  {!isMobile ? (
                    <>
                      <div className="paneli-tabeve">
                        <div className="paneli-tabeve1">{PaneliKryesor}</div>
                        <div className="paneli-tabeve2">{PaneliDytesor}</div>

                        <div className="paneli-fis-print">{BtnPrint}</div>
                      </div>
                      <div className={"below-tabs"}>
                        <div className="below-tabs1">{TabiKryesor}</div>
                        <div className="below-tabs2">{smallTabsKM}</div>
                        <div className="below-tabs3"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliKryesor}
                        {BtnPrint}
                      </div>
                      <div style={{ margin: "5px" }}>{TabiKryesor}</div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {" "}
                        {PaneliDytesor}
                      </div>
                      <div
                        style={{
                          margin: 5,
                        }}
                      >
                        {smallTabsKM}
                      </div>
                    </>
                  )}
                </div>
              </Panel>
            </div>

            <div className="middle_fsh"></div>

            <div className="dataTable_fsh">
              <PrimeGrid
                deletedRowIds={deletedRowIds}
                setDeletedRowIds={setDeletedRowIds}
                gridKey="FatureBlerje"
                contextKey={"/fature/blerje/dtl"}
                isEditable={true}
                columns={columns}
                defaultColumnsStorage={defaultColumnsStorage}
                setColumns={setColumns}
                disabled={disabled}
                rows={rows}
                setRows={setRows}
                calculateData={handleCalculateData}
                shtoArtikull={handleShtoArtikull}
                currentAction={currentAction}
                currentId={currentId}
              />
            </div>
          </div>
        </div>
        {/* {!isMobile && <Footer className="footer-position" />} */}
      </div>
    </div>
  );
};

export default memo(FatureBlerje);
