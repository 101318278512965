import React, { useState, useEffect, useRef } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import mainAxios from "../../../../services/axios";
import PrintoTurnin from "../../../../components/ReactPDF/PrintoTurnin";
import CloseIcon from "@mui/icons-material/Close";
import NewTableMbyljeTurni from "../DataTablePos/NewTableMbylljeTurni";

function MbylljeTurni({
  open,
  onClose,
  state,
  isFromMarket,
  fetchPosSupermarket,
  operator,
}) {
  const [mainData, setMainData] = useState("");
  // const [dataPosBak, setDataPosBak] = useState([]);
  const [disabled, setDisabled] = useState(false);
  const [data, setData] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);

  // Function to get the current date in the format "YYYY-MM-DD"
  function getFormattedDate() {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0");
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  const [ngaData, setNgaData] = useState("");
  const [deriData, setDeriData] = useState("");

  useEffect(() => {
    setNgaData(getFormattedDate());
    setDeriData(getFormattedDate());
    setMainData(getFormattedDate());
  }, []);

  const formatDateString = (dateString) => {
    const dateObject = new Date(dateString);

    // Check if the dateObject is a valid date
    if (isNaN(dateObject.getTime())) {
      return ""; // Return an empty string if the date is invalid
    }

    const day = dateObject.getDate().toString().padStart(2, "0");
    const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");
    const year = dateObject.getFullYear();

    return `${day}/${month}/${year}`;
  };

  const formattedMainDate = formatDateString(mainData);

  const mbyllXhiron = async () => {
    try {
      const response = await mainAxios.get(
        `/pos/fshngapos?nga='${formattedMainDate}'&&deri='${formattedMainDate}'&&o=${operator}&&voperator=${operator}&&dfsh='${formattedMainDate}'`
      );

      if (response.status === 200) {
        await fetchDataTabelaFaturat();
        setConfirmDialogOpen(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      fetchPosSupermarket();
    }
  };

  const fetchDataTabelaFaturat = async (page = 1, perPage = 100) => {
    setLoading(true);
    try {
      const dataPaMbyllura = await mainAxios.get(
        `/pos/faturat/e_pambyllura/operator?operator=${operator}&page=${page}&per_page=${perPage}`
      );
      if (dataPaMbyllura.data.length === 0) {
        setDisabled(true);
        setData([]);
        return;
      } else {
        setDisabled(false);
        setData(dataPaMbyllura.data);
      }
    } catch (error) {
      console.error("Error fetching perdorues:", error);
    } finally {
      setLoading(false);
    }
  };

  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(100);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="lg">
      {/* Updated styles for larger dialog */}
      <DialogTitle style={{ display: "flex", justifyContent: "space-between" }}>
        <div
          className="flex"
          style={{ justifyContent: "space-between", flexDirection: "column" }}
        >
          <span style={{ fontSize: "20px" }}>
            Mbyllje Turni per operatorin : {operator}
          </span>
          <span>Data: {formattedMainDate}</span>
        </div>

        <DialogActions>
          <Button variant="outlined" onClick={onClose} style={{ color: "red" }}>
            <CloseIcon />
          </Button>
        </DialogActions>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          <div style={{ height: "600px" }}>
            <NewTableMbyljeTurni
              isFromMarket={isFromMarket}
              operator={operator}
              data={data}
              setData={setData}
              fetchDataTabelaFaturat={fetchDataTabelaFaturat}
              currentPage={currentPage}
              setCurrentPage={setCurrentPage}
              itemsPerPage={itemsPerPage}
              loading={loading}
            />
            {/* Updated styles for smaller buttons and container */}

            <div
              className="flex"
              style={{
                justifyContent: "space-between",
              }}
            >
              <PrintoTurnin
                className="print"
                state={state}
                dataNga={ngaData}
                dataDeri={deriData}
                // rows2={rows2}
                operator={operator}
                fetchDataTabelaFaturat={fetchDataTabelaFaturat}
              />
              <Button
                disabled={disabled}
                variant="contained"
                style={{ color: "#fff", height: "50px" }}
                onClick={() => setConfirmDialogOpen(true)}
              >
                Mbyll Xhiron
              </Button>
              <Dialog
                open={confirmDialogOpen}
                onClose={() => setConfirmDialogOpen(false)}
              >
                <DialogTitle>Konfirmim</DialogTitle>
                <DialogContent>
                  <DialogContentText>
                    <h2>
                      Jeni të sigurtë për të mbyllur xhiron për operatorin{" "}
                      {operator}?
                    </h2>
                  </DialogContentText>
                </DialogContent>
                <DialogActions style={{ justifyContent: "space-between" }}>
                  <Button
                    variant="contained"
                    onClick={mbyllXhiron}
                    color="primary"
                  >
                    Po
                  </Button>
                  <Button
                    variant="contained"
                    onClick={() => setConfirmDialogOpen(false)}
                    color="primary"
                  >
                    Jo
                  </Button>
                </DialogActions>
              </Dialog>
            </div>
          </div>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  );
}

export default MbylljeTurni;
