import React, { useEffect, useRef, useState } from "react";
import "./NavStyles/SideBar.scss";
import "./NavStyles/NavBar.scss";
import "../../pages/fature-blerje/FatureBlerje.scss";
import "../../pages/celje/Celje.scss";
import { PanelMenu } from "primereact/panelmenu";
import { ScrollPanel } from "primereact/scrollpanel";
import Logoja from "../../assets/Logoja.png";
import { InputText } from "primereact/inputtext";
import nodes from "./SideBarItems/SideBarArray";
import { Link, useLocation } from "react-router-dom";
import ReportDialog from "./ReportDialog";
import ArkaDialog from "./DeklarimiArkes";
import CloseIcon from "@mui/icons-material/Close";
import Logout from "@mui/icons-material/Logout";
import { useUser } from "../../zustand/common";
import { useNavigate } from "react-router-dom";
import { FtshProvider } from "../context/FtshState";
import Xhiro from "../../pages/MbylljeTurni/Xhiro";
import FatureThjeshtuar from "../../pages/fatureShitje/FatureThjeshtuar/FatureThjeshtuar";
import EInvoice from "../../pages/fatureShitje/EInvoice/EInvoice";

const SideBarPhone = ({ sidebarOpen, onClose, setOpen }) => {
  const { logout, userAutomatikishtFromCntext, userGrupId } = useUser();
  const [search, setSearch] = useState("");
  const [dialogVisible, setDialogVisible] = useState(false);
  const [dialogContent, setDialogContent] = useState("");
  const [dialogText, setText] = useState("");
  const [showEInvoice, setShowEInvoice] = useState(false);
  const [selectedDialog, setSelectedDialog] = useState(null);
  const [dialogRaportButtonsKeys, setDialogRaportButtonsKeys] = useState([]);
  const [dialogArkaButtonsKeys, setDialogArkaButtonsKeys] = useState([]);
  const [showXhiro, setShowXhiro] = useState(false);
  const [showFtshThjeshtuar, setShowFtshThjeshtuar] = useState(false);
  const navigate = useNavigate();
  const sidebarRef = useRef();

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      onClose();
    }
  };

  useEffect(() => {
    if (sidebarOpen) {
      document.addEventListener("mousedown", handleClickOutside);
    } else {
      document.removeEventListener("mousedown", handleClickOutside);
    }

    // Cleanup listener on unmount or sidebar close
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [sidebarOpen]);

  const handleButtonClick = (element) => {
    if (element.RaportButtons) {
      setDialogContent(element.buttonRaport);
      setText(element.text);
      setDialogRaportButtonsKeys(element.RaportButtons.map((rb) => rb.key));
      setSelectedDialog("ReportDialog");
      openDialog(element.buttonRaport, element.RaportButtons);
      setDialogVisible(true);
    }
    if (element.ArkaButtons) {
      setDialogContent(element.buttonRaport);
      setDialogArkaButtonsKeys(element.ArkaButtons.map((ab) => ab.key));
      setSelectedDialog("ArkaDialog");
      openDialog(element.buttonRaport, element.ArkaButtons);
      setDialogVisible(true);
    }
  };

  useEffect(() => {
    const navBar = document.querySelector(".main-container");
    if (navBar) {
      if (sidebarOpen) {
        navBar.classList.add("with-opacity");
      } else {
        navBar.classList.remove("with-opacity");
      }
    }
  }, [sidebarOpen]);

  useEffect(() => {
    const navBar = document.querySelector(".main-container-c");
    if (navBar) {
      if (sidebarOpen) {
        navBar.classList.add("with-opacity");
      } else {
        navBar.classList.remove("with-opacity");
      }
    }
  }, [sidebarOpen]);

  useEffect(() => {
    const navBar = document.querySelector(".p-toggleable-content");
    if (navBar) {
      if (sidebarOpen) {
        navBar.classList.add("with-opacity");
      } else {
        navBar.classList.remove("with-opacity");
      }
    }
  }, [sidebarOpen]);

  useEffect(() => {
    const navBar = document.querySelector(".dataTable_fsh");
    if (navBar) {
      if (sidebarOpen) {
        navBar.classList.add("with-opacity");
      } else {
        navBar.classList.remove("with-opacity");
      }
    }
  }, [sidebarOpen]);

  const openDialog = (content, raportButtonsKeys) => {
    setDialogContent(content);
    setDialogRaportButtonsKeys(raportButtonsKeys.map((rb) => rb.key));
    setDialogVisible(true);
  };

  const searchTree = (element, matchingTitle) => {
    if (element.label.toLowerCase().includes(matchingTitle)) {
      return element;
    } else if (element?.items != null) {
      let matchedItems = [];
      element.items.forEach((item) => {
        const result = searchTree(item, matchingTitle);
        if (result) matchedItems.push(result);
      });
      if (matchedItems.length > 0) {
        return { ...element, items: matchedItems };
      }
    }
    return null;
  };

  const generateMenuModel = (items) => {
    return items.map((item) => {
      if (item.label === "Xhiro") {
        return {
          ...item,
          command: () => handleXhiroClick(),
        };
      }

      // if (item.label === "Fature Thjeshtuar") {
      //   return {
      //     ...item,
      //     command: () => handleFtshClick(),
      //   };
      // }
      if (item.label === "E Invoice") {
        return {
          ...item,
          command: () => handleEInvoice(),
        };
      }
      if (item.buttonRaport) {
        return {
          ...item,
          command: () => handleButtonClick(item),
        };
      } else if (item.path) {
        return {
          ...item,
          command: () => navigate(`${item.path}`),
        };
      } else if (item.items) {
        return {
          ...item,
          items: generateMenuModel(item.items),
        };
      }
      return item;
    });
  };

  const filteredNavLinks = search
    ? nodes
        .map((item) => searchTree(item, search.toLowerCase()))
        .filter(Boolean)
    : nodes;

  const filteredNodes = filteredNavLinks.map((node) => {
    if (node.items) {
      return {
        ...node,
        items: node.items.filter(
          (item) =>
            item.label !== "Market" && item.label !== "Fature Thjeshtuar"
        ),
      };
    }
    return node;
  });

  const handleClose = () => {
    setOpen(false);
    onClose();
    setDialogVisible(false);
  };

  const menuModel = generateMenuModel(filteredNodes);

  const handleXhiroClick = () => {
    setShowXhiro(true);
  };

  const handleFtshClick = () => {
    setShowFtshThjeshtuar(true);
  };
  const handleEInvoice = () => {
    setShowEInvoice(true);
  };
  return (
    <div
      ref={sidebarRef}
      className={`sidebar_phone ${sidebarOpen ? "open" : ""}`}
    >
      <div className="top-btn">
        {userAutomatikishtFromCntext === 1 || userGrupId === 1 ? (
          <Link
            to="/dashboard"
            className="flex"
            style={{ textAlign: "center" }}
          >
            <img src={Logoja} className="pss-logo ml-8 h-9 w-9" alt="" />
            <span
              className="ml-2 flex items-center"
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "14px",
              }}
            >
              Dashboard
            </span>
          </Link>
        ) : (
          <Link
            to="/fature-shitje"
            className="flex"
            style={{ textAlign: "center" }}
          >
            <img src={Logoja} className="pss-logo ml-8 h-9 w-9" alt="" />
          </Link>
        )}

        <button type="button" className="close-button" onClick={handleClose}>
          <CloseIcon />
        </button>
      </div>
      <ScrollPanel style={{ width: "94%" }}>
        {/* <div className="mb-2 border-b p-2">
          <span className="p-input-icon-right" style={{ width: "90%" }}>
            <i className="pi pi-search" />
            <InputText
              placeholder="Search"
              type="text"
              onChange={(e) => setSearch(e.target.value)}
            />
          </span>
        </div> */}

        <PanelMenu model={menuModel} />
      </ScrollPanel>
      {showXhiro && (
        <Xhiro
          homeNavbar={true}
          isFullscreen={true}
          open={showXhiro}
          onClose={() => setShowXhiro(false)}
        />
      )}

      {showFtshThjeshtuar && (
        <FtshProvider>
          <FatureThjeshtuar
            open={showFtshThjeshtuar}
            onClose={() => setShowFtshThjeshtuar(false)}
          />
        </FtshProvider>
      )}

      {showEInvoice && (
        <FtshProvider>
          <EInvoice
            open={showEInvoice}
            onClose={() => setShowEInvoice(false)}
          />
        </FtshProvider>
      )}

      {selectedDialog === "ReportDialog" ? (
        <ReportDialog
          buttonRaport={dialogContent}
          text={dialogText}
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          content={dialogContent}
          raportButtonsKeys={dialogRaportButtonsKeys}
        />
      ) : (
        <ArkaDialog
          visible={dialogVisible}
          onHide={() => setDialogVisible(false)}
          content={dialogContent}
          raportButtonsKeys={dialogArkaButtonsKeys}
        />
      )}
      {/* <div className=" sidebar-settings">
        <button
          className="item  ml-5"
          onClick={() => {
            logout();
            navigate("/");
          }}
        >
          <Logout fontSize="medium" />
          Logout
        </button>
      </div> */}
    </div>
  );
};

export default SideBarPhone;
