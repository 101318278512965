import React, { useState } from "react";
import { TabView, TabPanel } from "primereact/tabview";
import LlogariButtons from "./Buttons";
import "../../paneliIartikujve/Artikuj.scss";

const PaneliArtikuj = ({
  disabled,
  state,
  setState,
  formatDate,
  isMobile,
  handleChangeTarifa,
  isEditingAllowed,
  updateRowData,
}) => {
  const [selektNjesia, setSelektNjesia] = useState([]);

  return (
    <div className="card_artikuj2">
      <TabView className="tabview_div_artikuj">
        <TabPanel header="Te Pergjithshme" className="tabpanel_div_artikuj">
          <LlogariButtons
            isEditingAllowed={isEditingAllowed}
            updateRowData={updateRowData}
            handleChangeTarifa={handleChangeTarifa}
            isMobile={isMobile}
            state={state}
            setState={setState}
            disabled={disabled}
            selektNjesia={selektNjesia}
            setSelektNjesia={setSelektNjesia}
          />
        </TabPanel>
      </TabView>
    </div>
  );
};

export default PaneliArtikuj;
