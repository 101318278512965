import React, { useContext, useRef } from "react";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import mainAxios from "../../../../../services/axios";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";
import { getPosByOperator } from "../../../../../store/globalPos";
import { useDispatch } from "react-redux";
import { useUser } from "../../../../../zustand/common";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";

const ShtimButton = () => {
  const { user } = useUser();
  const operator = user.username;
  const { defaultState } = useMyContext();
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const dispatch = useDispatch();

  const handleShtimClick = async () => {
    try {
      showLoading();
      const response = await mainAxios.post("/pos", defaultState, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        dispatch(getPosByOperator(`/pos/filter?operator=${operator}`));
      }
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  return (
    <>
      <Button
        variant="contained"
        className="btn_shtim_supermarket"
        onClick={handleShtimClick}
        style={{ backgroundColor: "#0C2D57", justifyContent: "space-between" }}
      >
        <AddIcon />
        Shtim
      </Button>
    </>
  );
};

export default ShtimButton;
