// assets
import {
  DashboardOutlined,
  FileDoneOutlined,
  SettingOutlined,
  HomeOutlined,
} from "@ant-design/icons";

{
  /* <SolutionOutlined />
<FileDoneOutlined /> */
}

// icons
const icons = {
  DashboardOutlined,
  FileDoneOutlined,
  SettingOutlined,
  HomeOutlined,
};

// ==============================|| MENU ITEMS - DASHBOARD ||============================== //

const dashboard = {
  id: "group-dashboard",
  title: "Navigimi",
  type: "group",
  children: [
    {
      id: "dashboard",
      title: "Dashboard",
      type: "item",
      url: "/dashboard",
      icon: icons.DashboardOutlined,
      breadcrumbs: false,
    },
    {
      id: "fatureshitje",
      title: "Home",
      type: "item",
      url: "/fature-shitje",
      icon: icons.HomeOutlined,
      breadcrumbs: false,
    },
    // {
    //   id: "konfigurime",
    //   title: "Konfigurime",
    //   type: "item",
    //   url: "/konfigurime",
    //   icon: icons.SettingOutlined,
    //   breadcrumbs: false,
    // },
  ],
};

export default dashboard;
