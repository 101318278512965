import mainAxios from "../../../../../services/axios";
import { formatDate } from "../../../Restaurant/Main/DateUtils";

//FISKALIZIMI
const handleFiskalizimi = async (
  handlePrint2,
  fromLista,
  {
    setState,
    showLoading,
    hideLoading,
    showToast,
    idNumber,
    rows,
    setFromLista,
    state,
    fetchDataByOperator,
  }
) => {
  showLoading();

  if (!idNumber) {
    //if Id:null return
    const message = `Selektoni nje fature !`;
    showToast(message, { severity: "info" });
    return;
  }
  if (rows.length === 0) {
    //if rows:null return
    const message = `Nuk keni asnje artikull ne fature !`;
    showToast(message, { severity: "warn" });
    return;
  }
  try {
    const { ...putAfterFisError } = {
      Aktiv: 1,
      Mbyllur: null,
      E_Paguar: 0,
    };

    const { ...putBeforeFis } = {
      Aktiv: 0,
      Mbyllur: formatDate(state.Mbyllur, true),
      E_Paguar: 1,
    };

    const response = await mainAxios.put(`/pos/${idNumber}`, putBeforeFis, {
      headers: { "Content-Type": "application/json" },
    });

    if (response.status === 200) {
      //than add fiskalizimi NIVF on invoice
      const response2 = await mainAxios.post(`/pos/${idNumber}`);

      const firstItem = response2.data;

      if (firstItem.ErrorCode !== "0") {
        await mainAxios.put(`/pos/${idNumber}`, putAfterFisError, {
          headers: { "Content-Type": "application/json" },
        });

        const faultstring = firstItem.faultstring;
        showToast(faultstring, { severity: "error" });
        return;
      } else {
        try {
          const response3 = await mainAxios.get(`/pos/${idNumber}`);

          setState(response3.data);
          await handlePrint2();
        } catch (error) {
          console.error(error, "ERROR FIS");
        } finally {
          fetchDataByOperator();
        }

        if (fromLista) {
          setFromLista(true);
        }
      }
    }
  } catch (error) {
    console.error(error);

    showToast(error, { severity: "error" });
  } finally {
    hideLoading();
  }
};

export { handleFiskalizimi };
