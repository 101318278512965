// assets
import {
  AppstoreAddOutlined,
  AntDesignOutlined,
  BarcodeOutlined,
  BgColorsOutlined,
  FontSizeOutlined,
  LoadingOutlined,
  BankOutlined,
  FileProtectOutlined,
  SettingOutlined,
  ProductOutlined,
  DiffOutlined,
  FundOutlined,
  BarsOutlined,
  InsertRowBelowOutlined,
  LockOutlined,
  ShopOutlined,
  LayoutOutlined,
  BorderOutlined,
  ForkOutlined,
  PictureOutlined,
  ScanOutlined,
  SwitcherOutlined,
} from "@ant-design/icons";

// icons
const icons = {
  FontSizeOutlined,
  BgColorsOutlined,
  BarcodeOutlined,
  AntDesignOutlined,
  LoadingOutlined,
  AppstoreAddOutlined,
  BankOutlined,
  FileProtectOutlined,
  SettingOutlined,
  ProductOutlined,
  DiffOutlined,
  FundOutlined,
  BarsOutlined,
  InsertRowBelowOutlined,
  LockOutlined,
  ShopOutlined,
  LayoutOutlined,
  BorderOutlined,
  ForkOutlined,
  PictureOutlined,
  ScanOutlined,
  SwitcherOutlined,
};

// ==============================|| MENU ITEMS - UTILITIES ||============================== //

const utilities = {
  id: "utilities",
  title: "Konfigurime",
  type: "group",
  sx: { color: "#fff" },
  children: [
    {
      id: "ndermarrje",
      title: "Ndermarrje",
      type: "item",
      url: "/ndermarrje",
      sx: { color: "#fff" },
      icon: icons.BankOutlined,
      breadcrumbs: false,
    },
    {
      id: "tepergjithshme",
      title: "Te Pergjithshme",
      type: "item",
      url: "/te-pergjithshme",
      icon: icons.SettingOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigArtikuj",
      title: "Artikuj",
      type: "item",
      url: "/konfig-artikuj",
      icon: icons.ProductOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigblerje",
      title: "Blerje",
      type: "item",
      url: "/blerje",
      icon: icons.DiffOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigShitje",
      title: "Shitje",
      type: "item",
      url: "/shitje",
      icon: icons.FundOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigPosBar",
      title: "Pos / Bar",
      type: "item",
      url: "/pos-bar",
      icon: icons.ShopOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigLlogarite",
      title: "Llogarite konfigurim",
      type: "item",
      url: "/llogarite-konfig",
      icon: icons.BarsOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigTabelat",
      title: "Tabelat",
      type: "item",
      url: "/tabelat",
      icon: icons.InsertRowBelowOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigPeshore",
      title: "Peshore",
      type: "item",
      url: "/peshore",
      icon: icons.ScanOutlined,
      breadcrumbs: false,
    },
    {
      id: "fiskale",
      title: "Fiskale",
      type: "item",
      url: "/fiskale",
      icon: icons.FileProtectOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigBordero",
      title: "Bordero",
      type: "item",
      url: "/bordero",
      icon: icons.BorderOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigFjalekalimet",
      title: "Fjalekalimet",
      type: "item",
      url: "/fjalekalimet",
      icon: icons.LockOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigLogo",
      title: "Logo",
      type: "item",
      url: "/logo",
      icon: icons.PictureOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigformat",
      title: "Format Settings",
      type: "item",
      url: "/format-settings",
      icon: icons.LayoutOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigSinkronizim",
      title: "Sinkronizim",
      type: "item",
      url: "/sinkronizim",
      icon: icons.ForkOutlined,
      breadcrumbs: false,
    },
    {
      id: "konfigTeTjera",
      title: "Te Tjera",
      type: "item",
      url: "/te-tjera",
      icon: icons.SwitcherOutlined,
      breadcrumbs: false,
    },
    // {
    //   id: 'util-color',
    //   title: 'Color',
    //   type: 'item',
    //   url: '/color',
    //   icon: icons.BgColorsOutlined
    // },
    // {
    //   id: 'util-shadow',
    //   title: 'Shadow',
    //   type: 'item',
    //   url: '/shadow',
    //   icon: icons.BarcodeOutlined
    // }
  ],
};

export default utilities;
