import React, { useEffect, useState } from "react";
import QRCode from "qrcode"; // Import the original QRCode library

function SimpleTableSHDownload({
  state,
  fisVerificationUrl,
  menyrapageses,
  nipt,
  username,
  konfigurimeRedux,
}) {
  const [qrCodeImage, setQrCodeImage] = useState(null);

  // Generate the QR code as an image (base64)
  useEffect(() => {
    if (fisVerificationUrl) {
      QRCode.toDataURL(fisVerificationUrl, { width: 220, height: 200 })
        .then((url) => setQrCodeImage(url))
        .catch((err) => console.error("Error generating QR Code", err));
    }
  }, [fisVerificationUrl]);

  const pagesaPershkrim = menyrapageses.find(
    (menyraP) => menyraP.Id === state.Menyra_Pageses_ID
  )?.Pershkrim;

  const ndermarrje =
    Array.isArray(konfigurimeRedux) && konfigurimeRedux.length > 0
      ? konfigurimeRedux[0]
      : null;

  if (!ndermarrje) {
    console.error("konfigurimeRedux is either not an array or it is empty.");
    return null;
  }

  return (
    <table className="HeaderTable_download border">
      <thead>
        <tr className="kutiza_madhe_download">
          <td className="title">
            {state.NSLF ? <b>Fature Tatimore</b> : <b>Fature Porosi</b>}
            <br />
            <b>
              NR:{" "}
              {state?.fisInvoiceNumber ? state?.fisInvoiceNumber : state?.Kodi}
            </b>
          </td>
        </tr>
        <tr className="funtior_table_head_download">
          <tr className="funtior_table_head_below_download">
            <tr className="kutiza_madhe_dy_download">
              <td className="border-bottom-false kutiza_madhe_dy_download_majtas">
                <div className="solid-border">
                  Operator:{" "}
                  {state.fisOperatorCode
                    ? state.fisOperatorCode
                    : state.Operator}
                </div>
                <div className="solid-border">
                  Vendi i ushtrimit te biznesit: {ndermarrje.Adresa1}
                </div>
                {state.NSLF && (
                  <div className="solid-border">NSLF: {state.NSLF}</div>
                )}
                {state.NSLF && (
                  <div className="solid-border">NIVF: {state.NIVF}</div>
                )}
              </td>
              <td className="kutiza_madhe_dy_download_djathtas">
                <div className="solid-border">
                  Data: {state.Data ? state.Data.substring(0, 10) : "N/A"}
                </div>
                <div className="solid-border">NR: {state.Kodi}</div>
                <div className="solid-border">
                  Menyra e Pageses: {pagesaPershkrim}
                </div>
                <div className="solid-border">
                  Kartmonedha dhe monedha: {state.Mon}
                </div>
              </td>
            </tr>
          </tr>
          <td className="kutiza_qr_code_download">
            {qrCodeImage ? (
              <img src={qrCodeImage} alt="QR Code" className="qr-code-image" />
            ) : (
              <p></p>
            )}
          </td>
        </tr>
        <tr className="kutiza_madhe_tre_download border-true">
          <tr className="kutiza_madhe_dy_download">
            <td className="kutiza_madhe_tre_download_majtas border-true-right">
              <div className="solid-border">
                Shitesi: {ndermarrje.Pershkrim}
              </div>
              <div className="solid-border">NIPT: {nipt}</div>
            </td>
            <td className="kutiza_madhe_tre_download_djathtas_second">
              <div className="solid-border">
                Klienti: {state.KLFU_Pershkrim}
              </div>
              <div className="solid-border"> Adresa: {state.Qyteti_Kodi}</div>
              <div className="solid-border"> NIPT: {state.NIPT}</div>
            </td>
          </tr>
        </tr>
      </thead>
    </table>
  );
}

export default SimpleTableSHDownload;
