// material-ui
import { useLogo } from "../context/LogoContext";
/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //
const Logo = () => {
  const { currentLogo } = useLogo();

  return <img src={currentLogo} alt="Logo" width="100" />;
};

export default Logo;
