import { useState } from "react";

// material-ui
import List from "@mui/material/List";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";

// assets
import {
  CommentOutlined,
  LockOutlined,
  QuestionCircleOutlined,
  UserOutlined,
  UnorderedListOutlined,
} from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { useUser } from "../../../../../zustand/common";

// ==============================|| HEADER PROFILE - SETTING TAB ||============================== //

export default function SettingTab() {
  const [selectedIndex, setSelectedIndex] = useState(0);
  const navigate = useNavigate();
  const { userAutomatikishtFromCntext, userGrupId } = useUser();
  const [openDialog, setOpenDialog] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [selectedWhatsappNumber, setSelectedWhatsappNumber] = useState(null);

  const handleListItemClick = (index, path) => {
    setSelectedIndex(index);
    navigate(path); // Navigate to the given path
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleAction = (option) => {
    if (option === "gmail") {
      const email = "Oltionelezi17@gmail.com";
      const subject = "Kam nevoje per ndihme. Ne Pss.al";
      const body =
        "Pershendetje, une kam nevoje per ndihme ne programin tuaj pss.al .";
      const mailtoLink = `mailto:${email}?subject=${encodeURIComponent(
        subject
      )}&body=${encodeURIComponent(body)}`;
      window.location.href = mailtoLink;
      handleCloseDialog();
    } else if (option === "call") {
      const phoneNumber = "0682368584";
      if (/Mobi|Android|iPhone|iPad/i.test(navigator.userAgent)) {
        // Mobile device detected
        window.location.href = `tel:${phoneNumber}`;
        handleCloseDialog();
      } else {
        // On non-mobile devices
        alert(
          "Ky funksionalitet është i disponueshëm vetëm në pajisjet mobile."
        );
        handleCloseDialog();
      }
    } else if (option === "whatsapp") {
      // Show dropdown with options
      setShowDropdown(true);
    }
  };

  const handleWhatsappSelection = (selectedNumber) => {
    setSelectedWhatsappNumber(selectedNumber);
    setShowDropdown(false); // Close dropdown after selection

    const whatsappMessage = `Përshëndetje, mund të më ndihmoni në programin Pss.al ?`;

    // Open WhatsApp based on the selected number
    window.open(
      `https://wa.me/${selectedNumber}?text=${encodeURIComponent(
        whatsappMessage
      )}`,
      "_blank"
    );

    handleCloseDialog();
  };

  return (
    <>
      <List
        component="nav"
        sx={{ p: 0, "& .MuiListItemIcon-root": { minWidth: 32 } }}
      >
        <ListItemButton
          // selected={selectedIndex === 0}
          // onClick={(event) => handleListItemClick(event, 0)}
          // onClick={() => handleListItemClick(1, "/ndermarrje")}
          onClick={handleOpenDialog}
        >
          <ListItemIcon>
            <QuestionCircleOutlined />
          </ListItemIcon>
          <ListItemText primary="Support" />
        </ListItemButton>
        {(userAutomatikishtFromCntext === 1 || userGrupId === 1) && (
          <ListItemButton
            selected={selectedIndex === 1}
            onClick={() => handleListItemClick(1, "/ndermarrje")}
          >
            <ListItemIcon>
              <UserOutlined />
            </ListItemIcon>
            <ListItemText primary="Konfigurime" />
          </ListItemButton>
        )}
        {/* <ListItemButton selected={selectedIndex === 2} onClick={(event) => handleListItemClick(event, 2)}>
        <ListItemIcon>
          <LockOutlined />
        </ListItemIcon>
        <ListItemText primary="Privacy Center" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 3} onClick={(event) => handleListItemClick(event, 3)}>
        <ListItemIcon>
          <CommentOutlined />
        </ListItemIcon>
        <ListItemText primary="Feedback" />
      </ListItemButton>
      <ListItemButton selected={selectedIndex === 4} onClick={(event) => handleListItemClick(event, 4)}>
        <ListItemIcon>
          <UnorderedListOutlined />
        </ListItemIcon>
        <ListItemText primary="History" />
      </ListItemButton> */}
      </List>

      {/* Dialog for choosing the action */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Zgjidhni menyren e kontaktit</DialogTitle>
        <DialogContent>
          <Button
            variant="outlined"
            sx={{ marginBottom: 1 }}
            fullWidth
            onClick={() => handleAction("gmail")}
          >
            Dergo Email (Gmail)
          </Button>
          <Button
            variant="outlined"
            sx={{ marginBottom: 1 }}
            fullWidth
            onClick={() => handleAction("call")}
          >
            Telefononi ne numrat e meposhtem
          </Button>
          <Button
            variant="outlined"
            fullWidth
            onClick={() => handleAction("whatsapp")}
          >
            Fillo WhatsApp Chat
          </Button>
          {/* Show dropdown for selecting WhatsApp number */}
          {showDropdown && (
            <div>
              <select
                onChange={(e) => handleWhatsappSelection(e.target.value)}
                defaultValue=""
              >
                <option value="" disabled>
                  Zgjidhni nje numer te supportit
                </option>
                <option value="+355686005025">Support1: +355686005025</option>
                <option value="+355686005011">Support2: +355686005011</option>
              </select>
            </div>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Anullo
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
