import React, { useState, useEffect } from "react";
import { TreeTable } from "primereact/treetable";
import { Column } from "primereact/column";
import { NodeService } from "./NodesService";

export default function BasicDemo() {
  const [nodes, setNodes] = useState([]);

  useEffect(() => {
    NodeService.getTreeTableNodes().then((data) => setNodes(data));
  }, []);

  return (
    <div
      className="card_artikuj1"
      style={{ height: "67%", maxHeight: "68%", overflow: "auto" }}
    >
      <TreeTable
        value={nodes}
        tableStyle={{ minWidth: "50rem", color: "#000" }}
      >
        <Column field="name" header="Name" expander></Column>
        <Column field="size" header="Size"></Column>
        <Column field="type" header="Type"></Column>
      </TreeTable>
    </div>
  );
}
