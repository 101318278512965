import React, { useState } from "react";
import { DataTable } from "primereact/datatable"; // Import DataTable
import { CircularProgress } from "@mui/material";
import { Column } from "primereact/column"; // Import Column from primereact/column

export default function TableData({ data, loading }) {
  console.log(data, "data");
  const [columns] = useState([
    { field: "Kodi", title: "Kodi" },
    { field: "BARKOD", title: "BARKOD" },
    { field: "Klienti", title: "Klienti" },
    { field: "PikeTembartura", title: "Pike te mbartura", allowSum: true },
    { field: "TeFituara", title: "Te fituara", allowSum: true },
    { field: "PikeTotal", title: "PikeTotal", allowSum: true },
    { field: "Dhurata", title: "Dhurata", allowSum: true },
    { field: "Pike", title: "Pike", allowSum: true },
  ]);

  // Dynamically create column headers and corresponding fields
  const dynamicColumns = columns.map((col, index) => (
    <Column key={index} field={col.field} header={col.title} />
  ));

  return (
    <>
      {loading ? (
        <div
          className="squares-container"
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </div>
      ) : (
        <div
          className="card"
          style={{ height: "90%", width: "100%", overflow: "hidden" }}
        >
          <DataTable
            reorderablecolumns="true"
            // style={{ position: "relative" }}
            value={data}
            stripedRows
            dataKey="Id"
            autoLayout={true}
            filterDisplay="menu"
            emptyMessage="Nuk ka te dhena"
          >
            {dynamicColumns}
          </DataTable>
        </div>
      )}
    </>
  );
}
