import React, { useState, useEffect } from "react";
import Button from "@mui/material/Button";
import ClickAwayListener from "@mui/material/ClickAwayListener";
import Grow from "@mui/material/Grow";
import Paper from "@mui/material/Paper";
import Popper from "@mui/material/Popper";
import MenuItem from "@mui/material/MenuItem";
import MenuList from "@mui/material/MenuList";
import Stack from "@mui/material/Stack";
import PeopleIcon from "@mui/icons-material/People";
import TextField from "@mui/material/TextField";
import IconButton from "@mui/material/IconButton";
import ClearIcon from "@mui/icons-material/Clear";
import mainAxios from "../../../../../services/axios";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";
import { useSelector } from "react-redux";

// Custom hook for debouncing
function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const handler = setTimeout(() => {
      setDebouncedValue(value);
    }, delay);

    return () => {
      clearTimeout(handler);
    };
  }, [value, delay]);

  return debouncedValue;
}

export default function ShtoKlient() {
  const klienti = useSelector((state) => state.globalSlice.klienti);

  const [open, setOpen] = React.useState(false);
  const anchorRef = React.useRef(null);
  const [searchTerm, setSearchTerm] = useState("");
  const { state, updateState } = useMyContext();

  const debouncedSearchTerm = useDebounce(searchTerm, 500);

  const handleToggle = () => {
    setOpen((prevOpen) => !prevOpen);
  };

  const handleClose = (event) => {
    if (
      event &&
      anchorRef.current &&
      anchorRef.current.contains(event.target)
    ) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
      setOpen(false);
    } else if (event.key === "Escape") {
      setOpen(false);
    }
  }

  const handleSelectChange = async (value) => {
    if (value) {
      const postDataWithoutId = {
        KLFU_Kodi: value.Kodi,
        KLFU_Pershkrim: value.Pershkrim,
      };
      try {
        await mainAxios.put(`/pos/${state.Id}`, postDataWithoutId);
        updateState({
          KLFU_Kodi: value.Kodi,
          KLFU_Pershkrim: value.Pershkrim,
        });
      } catch (error) {
        console.error("There was an error updating the data!", error);
      }
    }
    handleClose();
  };

  const handleSearchChange = (e) => {
    setSearchTerm(e.target.value); // Update search term on each keystroke
  };

  const filteredKlienti = klienti.filter((klient) =>
    klient.Pershkrim?.toLowerCase().includes(debouncedSearchTerm.toLowerCase())
  );

  const handleClean = async () => {
    setSearchTerm(""); // Reset the search term
    const postDataWithoutId = {
      KLFU_Kodi: null,
      KLFU_Pershkrim: null,
    };
    try {
      await mainAxios.put(`/pos/${state.Id}`, postDataWithoutId);
      updateState({
        KLFU_Kodi: null,
        KLFU_Pershkrim: null,
      });
    } catch (error) {
      console.error("Error clearing the data:", error);
    }
    handleClose();
  };

  const prevOpen = React.useRef(open);
  React.useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  return (
    <Stack direction="row" spacing={2}>
      <div>
        <Button
          ref={anchorRef}
          id="composition-button"
          aria-controls={open ? "composition-menu" : undefined}
          aria-expanded={open ? "true" : undefined}
          aria-haspopup="true"
          onClick={handleToggle}
          style={{ position: "relative" }}
        >
          <div
            style={{
              fontSize: "10px",
              backgroundColor: "white",
              borderRadius: "10%",
              position: "absolute",
              top: "-25px",
              left: "25px",
              color: "black",
            }}
          >
            {state.KLFU_Pershkrim}
          </div>
          <PeopleIcon style={{ color: "#fff", fontSize: "40px" }} />
        </Button>
        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          placement="top-start"
          transition
          disablePortal
          modifiers={[
            {
              name: "preventOverflow",
              options: {
                boundary: "viewport",
              },
            },
          ]}
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: "left bottom",
              }}
            >
              <Paper>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    style={{
                      maxHeight: "200px",
                      overflowY: "auto",
                      position: "relative",
                    }}
                    autoFocusItem={open}
                    id="composition-menu"
                    aria-labelledby="composition-button"
                    onKeyDown={handleListKeyDown}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        gap: "8px",
                        position: "sticky",
                        top: 0,
                        backgroundColor: "white",
                        zIndex: 1,
                        padding: "0px 5px",
                      }}
                    >
                      <TextField
                        value={searchTerm}
                        onChange={handleSearchChange}
                        label="Search"
                        variant="outlined"
                        size="small"
                        fullWidth
                      />
                      <IconButton
                        onClick={handleClean}
                        style={{
                          color: "red",
                        }}
                      >
                        <ClearIcon />
                      </IconButton>
                    </div>

                    {filteredKlienti.length > 0 ? (
                      filteredKlienti.map((klient) => (
                        <MenuItem
                          key={klient.Kodi}
                          onClick={() => handleSelectChange(klient)}
                          style={{ fontSize: "0.9rem", padding: "4px 16px" }}
                        >
                          {klient.Pershkrim}
                        </MenuItem>
                      ))
                    ) : (
                      <MenuItem disabled>No results found</MenuItem>
                    )}
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    </Stack>
  );
}
