import { lazy } from "react";

// project import
import Loadable from "../components/Loadable";
import Dashboard from "../pages/dashboard/Dashboard";
// import Konfigurime from "../pages/konfigurime/Konfigurime";
// import ThemeCustomization from "../themes";
// import ScrollTop from "../components/ScrollTop";
import Fiskale from "../pages/konfigurimeDash/fiskale/Fiskale";
import Ndermarrje from "../pages/konfigurimeDash/ndermarrje/Ndermarrje";
import TePergjithshme from "../pages/konfigurimeDash/te-pergjithshme/TePergjithshme";
import KonfigArtikuj from "../pages/konfigurimeDash/konfigArtikuj/KonfigArtikuj";
import Blerje from "../pages/konfigurimeDash/blerje/Blerje";
import Shitje from "../pages/konfigurimeDash/shitje/Shitje";
import PosBar from "../pages/konfigurimeDash/PosBar/PosBar";
import LlogaritjeKonfig from "../pages/konfigurimeDash/LlogariteKonfig/LlogariteKonfig";
import Tabelat from "../pages/konfigurimeDash/Tabelat/Tabelat";
import Peshore from "../pages/konfigurimeDash/Peshore/Peshore";
import Bordero from "../pages/konfigurimeDash/Bordero/Bordero";
import Fjalkalimet from "../pages/konfigurimeDash/fjalekalimet/Fjalekalimet";
import Logo from "../pages/konfigurimeDash/Logo/Logo";
import FormatSettings from "../pages/konfigurimeDash/FormatSettings/FormatSettings";
import Sinkronizim from "../pages/konfigurimeDash/Sinkronizim/Sinkronizim";
import TeTjera from "../pages/konfigurimeDash/TeTjera/TeTjera";

const Color = Loadable(lazy(() => import("../pages/component-overview/color")));
const Typography = Loadable(
  lazy(() => import("../pages/component-overview/typography"))
);
const Shadow = Loadable(
  lazy(() => import("../pages/component-overview/shadows"))
);
const DashboardDefault = Loadable(
  lazy(() => import("../pages/dashboard/dashboard/index"))
);

const KonfigurimeDefault = Loadable(
  lazy(() => import("../pages/konfigurime/konfigurime/index"))
);

// render - sample page
const SamplePage = Loadable(
  lazy(() => import("../pages/extra-pages/sample-page"))
);

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: "/",
  element: <Dashboard />,
  children: [
    {
      path: "dashboard", // This remains the same, as it's the main dashboard route.
      element: <DashboardDefault />,
    },
    {
      path: "konfigurime", // Default nested route
      element: <KonfigurimeDefault />, // The content that goes inside Konfigurime, update with your desired component
    },
    {
      path: "ndermarrje", // Default nested route
      element: <Ndermarrje />, // The content that goes inside Konfigurime, update with your desired component
    },
    {
      path: "te-pergjithshme",
      element: <TePergjithshme />,
    },
    {
      path: "konfig-artikuj",
      element: <KonfigArtikuj />,
    },
    {
      path: "blerje",
      element: <Blerje />,
    },
    {
      path: "shitje",
      element: <Shitje />,
    },
    {
      path: "pos-bar",
      element: <PosBar />,
    },
    {
      path: "llogarite-konfig",
      element: <LlogaritjeKonfig />,
    },
    {
      path: "tabelat",
      element: <Tabelat />,
    },
    {
      path: "peshore",
      element: <Peshore />,
    },
    {
      path: "fiskale",
      element: <Fiskale />,
    },
    {
      path: "bordero",
      element: <Bordero />,
    },
    {
      path: "fjalekalimet",
      element: <Fjalkalimet />,
    },
    {
      path: "logo",
      element: <Logo />,
    },
    {
      path: "format-settings",
      element: <FormatSettings />,
    },
    {
      path: "sinkronizim",
      element: <Sinkronizim />,
    },
    {
      path: "te-tjera",
      element: <TeTjera />,
    },
    {
      path: "color",
      element: <Color />,
    },
    {
      path: "dashboard",
      children: [
        {
          path: "default",
          element: <DashboardDefault />,
        },
      ],
    },
    {
      path: "sample-page",
      element: <SamplePage />,
    },
    {
      path: "shadow",
      element: <Shadow />,
    },
    {
      path: "typography",
      element: <Typography />,
    },
  ],
};

export default MainRoutes;
