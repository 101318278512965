import mainAxios from "../../../../services/axios";

const shtoArtikull = async (
  artikull,
  rows,
  setRows,
  state,
  showToast,
  operator,
  showLoading,
  hideLoading
) => {
  if (state.NIVF) {
    showToast("Fatura eshte e fiskalizuar, Klikoni Shtim");
    return;
  }
  if (!artikull) return;

  let currObj = rows.find((row) => row.Kodi === artikull?.Kodi);

  // Common function to build the item data for both PUT and POST
  const buildItemData = (calculatedItem) => ({
    Master_Id: state.Id,
    Pershkrim: calculatedItem.Pershkrim,
    Vlera: calculatedItem.Vlera,
    Sasia: calculatedItem.Sasia,
    Tipi: calculatedItem.Tipi,
    SkontoAgjent: calculatedItem.SkontoAgjent,
    Cmimi: calculatedItem.Cmimi,
    Operator: String(operator),
    Vlera_Pa_Tvsh: calculatedItem.Vlera_Pa_Tvsh,
    Kodi: calculatedItem.Kodi,
    Tvsh: calculatedItem.Tvsh,
    DateGarancie: calculatedItem.DateGarancie,
    NrLL: calculatedItem.NrLL,
    Shenim2: calculatedItem.Shenim2,
    IdOld: calculatedItem.IdOld,
    Skonto_VleraAgjent: calculatedItem.Skonto_VleraAgjent,
    Art_ID: calculatedItem.Art_ID,
    Punonjes_Id: calculatedItem.Punonjes_Id,
    BarKod: calculatedItem.BarKod,
    Njesi_Kodi: calculatedItem.Njesi_Kodi,
    Cmimi_Pa_Tvsh: calculatedItem.Cmimi_Pa_Tvsh,
    Skonto_Vlera: calculatedItem.Skonto_Vlera,
    Total: calculatedItem.Total,
    Magazina_Kodi: calculatedItem.Magazina_Kodi,
    TipVlere_ID: calculatedItem.TipVlere_ID,
    Tvsh_Vlera: calculatedItem.Tvsh_Vlera,
    NrSerik: calculatedItem.NrSerik,
    Cmimi_Kosto: calculatedItem.Cmimi_Kosto,
    Skonto: calculatedItem.Skonto,
  });

  if (currObj) {
    // Increment Sasia by 1
    currObj.Sasia += 1;

    // Calculate the new item data based on the updated Sasia
    const updatedItem = calculateData({ Sasia: currObj.Sasia }, currObj);

    // Update the rows state with the modified item
    setRows((prevRows) =>
      prevRows.map((row) => (row.Kodi === currObj.Kodi ? updatedItem : row))
    );

    // Make the PUT request to update the item
    try {
      showLoading();
      const itemForUpdate = buildItemData(updatedItem);
      const response = await mainAxios.put(
        `/fature/shitje/dtl/${currObj.Id}`,
        itemForUpdate
      );
      if (response.status === 400) {
        currObj.Sasia -= 1; // Decrement Sasia back if there was an error
      }
    } catch (error) {
      console.error("Error updating item:", error);
    } finally {
      hideLoading();
    }

    return; // Exit the function early as the item has been updated
  }

  if (!state.Id) {
    showToast("Ska Fatura, klikoni Shtim", { severity: "info" });
    return;
  }

  // Calculate the initial item if currObj doesn't exist
  const newItem = {
    ...artikull,
    Tipi: "AR",
    Sasia: 1,
    Tvsh: artikull.Tvsh ?? 20,
    BarKod: artikull.Barkod,
    Cmimi: artikull.Cmimi ? artikull.Cmimi : 0,
    Skonto: 0,
    Vlera: 0,
    Cmimi_me_skonto: 0,
    Cmimi_Pa_Tvsh: artikull.Cmimi / artikull.Tvsh,
    Tvsh_Vlera: 0,
    Skonto_Vlera: 0,
    Vlera_Pa_Tvsh: 0,
    Total: (artikull.Cmimi ? artikull.Cmimi : 0) * 1,
  };

  // Calculate the new item data
  const calculatedItem = calculateData(
    { Cmimi: newItem.Cmimi, Barkod: newItem.BarKod },
    newItem
  );
  // setRows((rows) => [calculatedItem, ...rows]);
  setRows((rows) => [...rows, calculatedItem]);

  // Make the POST request to add the new item
  try {
    showLoading();
    const itemForPost = buildItemData(calculatedItem);
    const response = await mainAxios.post("/fature/shitje/dtl", itemForPost);
    if (response.status === 200) {
      // Update the Master_Id of the new row with the response.data.Id
      const newRows = [{ ...calculatedItem, Id: response.data.Id }, ...rows];
      setRows(newRows);
    }
  } catch (error) {
    console.error("Error Post item:", error);
  } finally {
    hideLoading();
  }
};

const calculateData = (field, item) => {
  let {
    Tvsh,
    Cmimi_Pa_Tvsh,
    Sasia,
    Skonto,
    Cmimi,
    Tvsh_Vlera,
    Skonto_Vlera,
    Cmimi_me_skonto,
    Vlera_Pa_Tvsh,
    Vlera,
    Total,
  } = item;

  let tvsh, VLSKONTO, cm_paTVSH, sasia;

  tvsh = (Tvsh + 100) / 100;

  const [[cell, cellVal]] = Object.entries(field);

  try {
    let CmimiPrv = Number(cell === "Cmimi" ? cellVal : Cmimi);
    let SkontoPrv = Number(cell === "Skonto" ? cellVal : Skonto);
    let SasiaPrv = Number(cell === "Sasia" ? cellVal : Sasia);
    cm_paTVSH = CmimiPrv / tvsh;
    sasia = SasiaPrv;
    VLSKONTO = (sasia * cm_paTVSH * SkontoPrv) / 100;

    if (cell === "Cmimi") {
      Cmimi = cellVal;
      cm_paTVSH = Cmimi / tvsh;
      Skonto_Vlera = (sasia * cm_paTVSH * Skonto) / 100;
      Vlera = (sasia * cm_paTVSH - Skonto_Vlera) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - Skonto_Vlera;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Sasia") {
      Sasia = cellVal;
      cm_paTVSH = Cmimi / tvsh;
      Skonto_Vlera = (Sasia * cm_paTVSH * Skonto) / 100;
      Vlera = (sasia * cm_paTVSH - Skonto_Vlera) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - Skonto_Vlera;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }
    if (cell === "Vlera") {
      Vlera = cellVal;
      Skonto = 0;
      Skonto_Vlera = 0;
      Cmimi = Vlera / Math.abs(sasia);
      cm_paTVSH = Cmimi / tvsh;
      VLSKONTO = 0;
      Tvsh_Vlera = Vlera - Vlera / tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
    }

    Total = Vlera * 1;
    return {
      ...item,
      Tvsh,
      Cmimi_Pa_Tvsh,
      Cmimi_me_skonto,
      Sasia,
      Skonto,
      Cmimi,
      Tvsh_Vlera,
      Skonto_Vlera,
      Vlera_Pa_Tvsh,
      Vlera,
      Total,
    };
  } catch (error) {
    Vlera = 0;
    return item;
  }
};

export { shtoArtikull, calculateData };
