const shtoArtikull = (artikull, rows, setRows, setTipiDTL, state, gridKey) => {
  if (!artikull) return;
  setTipiDTL(artikull.Tipi);

  // Determine the correct value for "Cmimi" based on gridKey
  const cmimiValue =
    gridKey === "FatureBlerje" ? artikull.Cmimi_Bleres : artikull.Cmimi;

  // Calculate the initial item
  const initialCmimi = cmimiValue || 0;
  const initialTotal = initialCmimi * state.Kursi;

  const newItem = {
    ...artikull,
    Sasia: 1,
    Tvsh: artikull.Tvsh ?? 20,
    Cmimi: initialCmimi,
    BarKod: artikull.Barkod,
    Skonto: 0,
    Cmimi_me_skonto: 0,
    Cmimi_Pa_Tvsh: 0,
    Vlera: 0,
    Tvsh_Vlera: 0,
    Skonto_Vlera: 0,
    Vlera_Pa_Tvsh: 0,
    Total: initialTotal,
  };

  // Calculate the new item data
  const calculatedItem = calculateData(
    { Cmimi: newItem.Cmimi },
    newItem,
    state
  );

  setRows((rows) => [...rows, calculatedItem]);
};

const calculateData = (field, item, state) => {
  let {
    Tvsh,
    Cmimi_Pa_Tvsh,
    Sasia,
    Skonto,
    Cmimi,
    Tvsh_Vlera,
    Skonto_Vlera,
    Vlera_Pa_Tvsh,
    Cmimi_me_skonto,
    Vlera,
    Total,
  } = item;

  let tvsh, VLSKONTO, cm_paTVSH, sasia;

  tvsh = state.Eksport === 1 ? 1 : (Tvsh + 100) / 100;

  const [[cell, cellVal]] = Object.entries(field);

  let CmimiPrv = Number(cell === "Cmimi" ? cellVal : Cmimi);
  let SkontoPrv = Number(cell === "Skonto" ? cellVal : Skonto);
  let SasiaPrv = Number(cell === "Sasia" ? cellVal : Sasia);

  cm_paTVSH = CmimiPrv / tvsh;
  sasia = SasiaPrv;
  VLSKONTO = (sasia * cm_paTVSH * SkontoPrv) / 100;

  switch (cell) {
    case "Skonto":
      Skonto = cellVal;
      Vlera = (sasia * cm_paTVSH - VLSKONTO) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Skonto_Vlera = (sasia * cm_paTVSH * Skonto) / 100;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      break;
    case "Cmimi":
      Cmimi = cellVal;
      cm_paTVSH = Cmimi / tvsh;
      Skonto_Vlera = (sasia * cm_paTVSH * Skonto) / 100;
      Vlera = (sasia * cm_paTVSH - Skonto_Vlera) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - Skonto_Vlera;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      break;
    case "Sasia":
      Sasia = cellVal;
      cm_paTVSH = Cmimi / tvsh;
      Skonto_Vlera = (Sasia * cm_paTVSH * Skonto) / 100;
      Vlera = (sasia * cm_paTVSH - Skonto_Vlera) * tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - Skonto_Vlera;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      break;
    case "Vlera":
      Vlera = cellVal;
      Skonto = 0;
      Skonto_Vlera = 0;
      Cmimi = Vlera / Math.abs(sasia);
      cm_paTVSH = Cmimi / tvsh;
      VLSKONTO = 0;
      Tvsh_Vlera = Vlera - Vlera / tvsh;
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Cmimi_Pa_Tvsh = cm_paTVSH;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      break;
    case "Skonto_Vlera":
      Skonto_Vlera = cellVal;
      VLSKONTO = Skonto_Vlera;
      Skonto = (VLSKONTO * 100) / (sasia * cm_paTVSH);
      Vlera_Pa_Tvsh = sasia * cm_paTVSH - VLSKONTO;
      Vlera = Vlera_Pa_Tvsh * tvsh;
      Tvsh_Vlera = Vlera - Vlera_Pa_Tvsh;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      break;
    case "Cmimi_Pa_Tvsh":
      Cmimi_Pa_Tvsh = cellVal;
      tvsh = Tvsh / 100;
      VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
      Vlera_Pa_Tvsh = Cmimi_Pa_Tvsh * Sasia;
      Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * (1 + tvsh);
      Cmimi = sasia !== 0 ? Math.abs(Vlera / Sasia) : Cmimi;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      Skonto_Vlera = VLSKONTO;
      Tvsh_Vlera = (Vlera_Pa_Tvsh - VLSKONTO) * tvsh;
      break;
    case "Tvsh":
      Tvsh = cellVal;
      tvsh = Tvsh / 100;
      VLSKONTO = (Sasia * Cmimi_Pa_Tvsh * Skonto) / 100;
      Cmimi = (Cmimi_Pa_Tvsh - VLSKONTO / Math.abs(Sasia)) * (1 + tvsh);
      Vlera = Sasia * Cmimi;
      Tvsh_Vlera = Vlera_Pa_Tvsh * tvsh;
      Cmimi_me_skonto = Cmimi * (1 - Skonto / 100);
      break;
    default:
    // No action needed for unknown fields
  }

  Total = Vlera * state.Kursi;

  return {
    ...item,
    Tvsh,
    Cmimi_Pa_Tvsh,
    Cmimi_me_skonto,
    Sasia,
    Skonto,
    Cmimi,
    Tvsh_Vlera,
    Skonto_Vlera,
    Vlera_Pa_Tvsh,
    Vlera,
    Total,
  };
};

export { shtoArtikull, calculateData };
