import React from "react";
import { Button } from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import { useToast } from "../../components/context/ToastContext";

const DeleteFature = ({ setDialogOpen, selectedId, currentFatura }) => {
  const showToast = useToast();

  const functionDelete = () => {
    if (selectedId === "") {
      showToast("Selekto nje Fature", {
        severity: "info",
      });
      return;
    }
    if (currentFatura.TIPI === "_BAK") {
      showToast("Fature eshte e mbyllur nuk mund te fshihet", {
        severity: "info",
      });
      return;
    } else {
      setDialogOpen(true);
    }
  };

  return (
    <Button
      disabled={!selectedId}
      variant="outlined"
      startIcon={<DeleteIcon style={{ color: "red" }} />}
      onClick={functionDelete}
      style={{ margin: "5px" }}
    >
      Fshi Faturen
    </Button>
  );
};

export default DeleteFature;
