import React, { useState, useRef, useEffect, useContext } from "react";
import Button from "@mui/material/Button";
import * as XLSX from "xlsx";
import { Dialog } from "primereact/dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import RaportButtons from "./RaportButtons";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import mainAxios from "../../services/axios";
import { useSelector } from "react-redux";
import { createSelector } from "reselect";
import "./PdfStyle.scss";
import CloseIcon from "@mui/icons-material/Close";

import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import CustomizedMenus from "./MenuPopupButton";
import { LoadingOverlayContext } from "../LoadingOverlay";
import { useToast } from "../context/ToastContext";

const ReportDialog = ({
  visible,
  onHide,
  content,
  label,
  raportButtonsKeys,
  buttonRaport,
}) => {
  const showToast = useToast();
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [selectedButtonKey, setSelectedButtonKey] = useState(null);
  // console.log(selectedButtonKey, "selectedButtonKey");
  const [searchValue, setSearchValue] = useState("");
  const [selectedValues, setSelectedValues] = useState({});
  const [ngaData, setNgaData] = useState("");
  const [deriDate, setDeriDate] = useState("");
  const [ngaNrFat, setNgaNrFat] = useState("");
  const [deriNrFat, setDeriNrFat] = useState("");
  const [dataCheck, setDataCheck] = useState(false);
  const [pdfDialogVisible, setPdfDialogVisible] = useState(false);
  const [pdfUrl, setPdfUrl] = useState("");

  const openPdfInDialog = (url) => {
    setPdfUrl(url);
    setPdfDialogVisible(true);
  };

  // Cleanup the URL object when the dialog is closed
  useEffect(() => {
    return () => {
      if (pdfUrl) {
        URL.revokeObjectURL(pdfUrl);
      }
    };
  }, [pdfUrl]);

  useEffect(() => {
    setDataCheck(!!ngaData && !!deriDate);
  }, [ngaData, deriDate]);

  const formatDate = (inputDate) => {
    const date = new Date(inputDate);
    const formattedDate = `${date.getDate().toString().padStart(2, "0")}/${(
      date.getMonth() + 1
    )
      .toString()
      .padStart(2, "0")}/${date.getFullYear()}`;
    return formattedDate;
  };

  const selectGlobalSlice = (state) => state.globalSlice;

  const memoizedSelector = createSelector(
    [selectGlobalSlice],
    (globalSlice) => {
      return globalSlice[selectedButton?.reduxGet] || [];
    }
  );

  const getTodayDate = () => {
    const today = new Date();
    const formattedToday = `${today.getFullYear()}-${(today.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${today.getDate().toString().padStart(2, "0")}`;
    return formattedToday;
  };

  useEffect(() => {
    setNgaData(getTodayDate());
    setDeriDate(getTodayDate());
  }, []);

  const keyLabelMap = {
    "2-5": "KLIENT",
    "2-5-1": "KLIENTI",
    "1-2": "MAGAZINA_KODI",
    "1-2-1": "MAGAZINA",
    "1-0": "KODI",
    "1-0-1": "ARTIKULL",
    "1-0-1-1": "ARTIKULLI",
    "1-3": "ART_KLS01_KODI",
    "1-3-1": "KLASIFIKIM1",
    "1-4": "Skema_Kodi",
    "1-4-1": "SKEMA",
    "1-5": "Tarifa_Kodi",
    "1-6": "GJENDJE",
    "1-6-1": "Hvaing",
    "1-7": "AAM",
    "1-7-1": "Aktiv",
    "2-1": "GJENDJE",
    "2-3": "MONEDHA",
    "2-4": "FURNITORI",
    "2-6": "TIPI",
    "2-7": "SHENIM",
    "2-7-1": "SHENIMI",
    "2-8": "Vlera",
    "2-9": "VEPRIMI",
    "2-9-1": "VEPRIMI",
    "3-0": "ARKA",
    "3-0-1": "ARKA",
    "3-1": "QYTETI",
    "3-2": "BANKA",
    "3-3": "TRANSPORTUES",
    "3-5": "TIPI",
    "3-6": "MENYRA",
    "3-6-1": "MENYRA",
    "3-7": "PUNONJES",
    "3-8": "Nipt",
    "3-9": "Nr.Dok",
    "4-0": "Nr.Serial",
    "4-1": "Vl. Paguar",
    "4-2": "Tot. Fature",
    "4-3": "OPERATOR",
    "4-4": "Nr.Fat",
    "4-5": "AGJENTI",
    "4-6": "START_VLERA",
    "4-7": "END_VLERA",
    "4-8": "BARKOD_DERI",
    "4-9": "BARKOD_NGA",
    "5-0": "VEPRIMI",
    "3-4": "TIPI",
  };

  useEffect(() => {
    setDataCheck(!!ngaData && !!deriDate);
  }, [ngaData, deriDate]);

  useEffect(() => {
    setNgaData(getTodayDate());
    setDeriDate(getTodayDate());
  }, []);

  const handleSubmit = async (submitHandler) => {
    try {
      showLoading();
      let payload = {};

      // Construct payload based on buttonRaport value
      if (buttonRaport) {
        switch (buttonRaport) {
          case "Gjendje_Magazine":
          case "Gjendje_Artikulli_Magazine":
            payload = {
              DATA_REPORTING: formatDate(deriDate),
              DATA_FILTER: formatDate(deriDate),
            };
            break;
          case "Ditari_I_Arkes":
          case "Gjendje_E_Furnitorve_Analitik":
          case "Ditari_I_Bankes":
            payload = {
              START_DATE: formatDate(ngaData),
              START_DATE2: formatDate(ngaData),
              END_DATE: formatDate(deriDate),
            };
            break;
          case "Blerje_Sipas_Furnitorve":
          case "Blerje_Periodike_Artikuj":
            payload = {
              START_DATE2: formatDate(ngaData),
              END_DATE: formatDate(deriDate),
            };
            break;

          case "Shitje_Periodike_Artikuj":
          case "Shitje_Sipas_Dates":
          case "Permbledhja_Shitjeve":
          case "Gjenjde_Arkave_Fiskal":
            payload = {
              START_DATA: formatDate(ngaData),
              END_DATA: formatDate(deriDate),
            };
            break;
          case "Shitje_Sipas_Klientave":
          case "Shitje_Sipas_Agjenteve":
            payload = {
              START_DATA: formatDate(ngaData),
              END_DATA: formatDate(deriDate),
              Start_NrFatures: ngaNrFat,
              End_NrFatures: deriNrFat,
            };
            break;
          default:
            payload = {
              START_DATE: formatDate(ngaData),
              END_DATE: formatDate(deriDate),
            };
            break;
        }

        // Add selected values to payload
        Object.keys(selectedValues).forEach((key) => {
          if (key !== "deriDate" && key !== "ngaData") {
            const label = keyLabelMap[key] || key;
            const value = selectedValues[key];

            if (value && (value.Kodi || value.Pershkrim || value.TCRCode)) {
              if (["3-6", "2-9"].includes(key)) {
                payload[label] = value.Kodi || ""; // Ensure empty string if undefined
              } else if (
                [
                  "2-6",
                  "2-7",
                  "2-7-1",
                  "4-7",
                  "4-6",
                  "4-8",
                  "4-9",
                  "1-7",
                  "1-6",
                  "1-6-1",
                  "2-1",
                  "1-7-1",
                ].includes(key)
              ) {
                payload[label] = value.Pershkrim || ""; // Ensure empty string if undefined
              } else if (key === "3-7" || key === "4-3") {
                payload[label] = [value.Pershkrim || ""]; // Ensure empty string if undefined
              } else if (key === "3-0-1") {
                payload[label] = [value.TCRCode || ""]; // Ensure empty string if undefined
              } else {
                payload[label] = [value.Kodi || ""]; // Ensure empty string if undefined
                // console.log(payload[label], "Oli");
              }
            } else {
              // Remove the label from payload if value is empty
              delete payload[label];
            }
          }
        });
      }
      // Determine request type based on the button clicked (HTML or PDF)
      let response;
      if (submitHandler === 1) {
        // HTML request
        response = await mainAxios.post(
          `/reports/html/${buttonRaport}`,
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        // Handle HTML response
        const newTabHtml = `<html><body>${response.data}</body></html>`;
        const newTab = window.open();
        newTab.document.write(newTabHtml);
      } else if (submitHandler === 3) {
        // PDF request
        response = await mainAxios.post(
          `/reports/pdf/${buttonRaport}`,
          payload,
          {
            headers: { "Content-Type": "application/json" },
          }
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], { type: "application/pdf" });
          const pdfUrl = URL.createObjectURL(blob);

          // Open a new tab
          const newTab = window.open();

          if (newTab) {
            // Create an iframe to load the PDF in the new tab
            const iframe = newTab.document.createElement("iframe");
            iframe.style.width = "100%";
            iframe.style.height = "100%";
            iframe.src = pdfUrl;

            // Append iframe to the new tab's body
            newTab.document.body.appendChild(iframe);
          } else {
            // Handle case where new tab is blocked by browser popup settings
            alert("Please allow popups to view the PDF.");
          }
        }
      } else if (submitHandler === 2) {
        // EXCEL request
        response = await mainAxios.post(
          `/reports/excel/${buttonRaport}`,
          payload,
          {
            headers: { "Content-Type": "application/json" },
            responseType: "blob",
          }
        );

        if (response.status === 200) {
          const blob = new Blob([response.data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
          });
          const excelUrl = URL.createObjectURL(blob);

          // Automatically trigger a download
          const link = document.createElement("a");
          link.href = excelUrl;
          link.download = `report_${buttonRaport}.xlsx`; // Set the file name
          document.body.appendChild(link);
          link.click();
          document.body.removeChild(link);
        } else {
          console.error("Failed to generate Excel file.");
        }
      }
    } catch (err) {
      console.error("Error posting data:", err);

      showToast("Kerkimi deshtoi.", {
        severity: "error",
      });
    } finally {
      hideLoading();
    }
  };

  const raportButtons = RaportButtons.filter((rb) =>
    raportButtonsKeys.includes(rb.key)
  );
  const selectedButton = raportButtons.find(
    (button) => button.key === selectedButtonKey
  );
  const dataFromRedux = useSelector(memoizedSelector);

  const mappedData = dataFromRedux.map(function (item) {
    const mappedItem = {
      Kodi: item.Kodi ? String(item.Kodi) : String(item.Id),
      Pershkrim: item.Pershkrim || item.Kodi,
    };

    if (buttonRaport === "Gjendje_Arkave_Fiskal") {
      mappedItem.TCRCode = item.TCRCode;
    }

    return mappedItem;
  });

  const filteredData = mappedData.filter(
    (item) =>
      (item.Kodi &&
        item.Kodi.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.Pershkrim &&
        item.Pershkrim.toLowerCase().includes(searchValue.toLowerCase()))
  );

  const handleRowClick = (item) => {
    if (selectedButtonKey) {
      setSelectedValues((prevValues) => ({
        ...prevValues,
        ngaData,
        deriDate,
        [selectedButtonKey]: item,
      }));
    }
    setSelectedButtonKey(null);
  };

  const handleInputChange = (key, value) => {
    setSelectedValues((prevValues) => {
      // Create a new object based on the previous state
      const updatedValues = { ...prevValues };

      // If value is empty, remove the key from the object
      if (!value) {
        delete updatedValues[key];
      } else {
        // Update or add the key with the new value
        updatedValues[key] = { ...updatedValues[key], Pershkrim: value };
      }

      return updatedValues;
    });
  };

  // const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  // useEffect(() => {
  //   const updateIsMobile = () => {
  //     setIsMobile(window.innerWidth <= 1200);
  //   };

  //   window.addEventListener("resize", updateIsMobile);
  //   updateIsMobile();
  //   return () => {
  //     window.removeEventListener("resize", updateIsMobile);
  //   };
  // }, []);

  const [dataArt, setDataArt] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setDataArt(filteredData);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      setDataArt(response.data);
    } catch (error) {
      console.error(error);

      showToast("Artikulli nuk u gjend", {
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSearchClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchTerm]);

  const dataToRender =
    (selectedButtonKey === "1-0" ||
      selectedButtonKey === "1-0-1" ||
      selectedButtonKey === "1-0-1-1") &&
    dataArt.length > 0
      ? dataArt
      : filteredData;

  return (
    <Dialog header={content} visible={visible} onHide={onHide}>
      <Dialog
        header="PDF Report"
        visible={pdfDialogVisible}
        style={{ width: "80vw", height: "80vh" }}
        onHide={() => setPdfDialogVisible(false)}
      >
        <iframe
          src={pdfUrl}
          style={{ width: "100%", height: "100%" }}
          title="PDF Viewer"
        />
      </Dialog>
      {/* <p>Index: {index}</p> */}
      <p>{label}</p>
      <Box sx={{ width: "100%" }}>
        <Grid container rowSpacing={1} columnSpacing={{ xs: 2, sm: 4, md: 1 }}>
          <div
            style={{
              width: "100%",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {buttonRaport === "Gjendje_Magazine" ||
            buttonRaport === "Gjendje_Artikulli_Magazine" ? (
              <TextField
                style={{ marginTop: "15px" }}
                label="Date"
                type="date"
                InputLabelProps={{ shrink: true }}
                value={deriDate}
                onChange={(e) => setDeriDate(e.target.value)}
              />
            ) : (
              <div
                className="flex"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "20px",
                }}
              >
                <TextField
                  label="Nga Data"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={ngaData}
                  onChange={(e) => setNgaData(e.target.value)}
                  style={{ marginLeft: "8px" }}
                />
                <TextField
                  label="Deri Date"
                  type="date"
                  InputLabelProps={{ shrink: true }}
                  value={deriDate}
                  onChange={(e) => setDeriDate(e.target.value)}
                />
              </div>
            )}
          </div>
        </Grid>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {raportButtons.map((button) => (
            <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
              <TextField
                id={button.key}
                label={button.label}
                type={button.type}
                style={{ width: "100%" }}
                className="textfieldRaport"
                value={selectedValues[button.key]?.Pershkrim || ""}
                onChange={(e) => handleInputChange(button.key, e.target.value)}
                InputProps={
                  button.reduxGet
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedButtonKey !== button.key ? (
                              <IconButton
                                edge="end"
                                onClick={() => setSelectedButtonKey(button.key)}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => setSelectedButtonKey(null)}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }
                    : null
                }
              />

              {selectedButtonKey === button.key && (
                <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
                  <Paper
                    style={{
                      position: "absolute",
                      width: "90%",
                      zIndex: 9999,
                      maxHeight: "300px",
                    }}
                  >
                    <Box px={2} py={1}>
                      {selectedButtonKey === "1-0" ||
                      selectedButtonKey === "1-0-1" ||
                      selectedButtonKey === "1-0-1-1" ? (
                        <div className="flex">
                          <TextField
                            id="filled-search"
                            placeholder="Search artikuj "
                            type="search"
                            variant="standard"
                            fullWidth
                            size="small"
                            value={searchTerm}
                            onChange={handleSearchInputChange}
                          />
                          <Button
                            type="button"
                            variant="contained"
                            onClick={handleSearchClick}
                            style={{
                              marginLeft: "10px",
                              display: "flex",
                              alignItems: "center",
                            }}
                          >
                            Kerko
                          </Button>
                        </div>
                      ) : (
                        <TextField
                          id="filled-search"
                          placeholder="Search "
                          type="search"
                          variant="standard"
                          fullWidth
                          size="small"
                          value={searchValue}
                          onChange={(e) => setSearchValue(e.target.value)}
                        />
                      )}
                    </Box>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: "300px", overflow: "auto" }}
                    >
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Kodi</TableCell>
                            <TableCell align="center">Pershkrim</TableCell>
                            {buttonRaport === "Gjendje_Arkave_Fiskal" && (
                              <TableCell align="center">TCR Code</TableCell>
                            )}
                          </TableRow>
                        </TableHead>

                        <TableBody>
                          {dataToRender.map((item, index) => (
                            <TableRow
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRowClick(item)}
                            >
                              <TableCell component="th" scope="row">
                                {item.Kodi}
                              </TableCell>
                              <TableCell align="center">
                                {item.Pershkrim}
                              </TableCell>
                              {buttonRaport === "Gjendje_Arkave_Fiskal" && (
                                <TableCell align="center">
                                  {item.TCRCode}
                                </TableCell>
                              )}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              )}
            </Grid>
          ))}
          {buttonRaport === "Shitje_Sipas_Klientave" ||
            (buttonRaport === "Shitje_Sipas_Agjenteve" && (
              <div
                className="flex"
                style={{
                  width: "100%",
                  justifyContent: "space-between",
                  marginTop: "10px",
                }}
              >
                <TextField
                  label="Nr Fature"
                  type="input"
                  InputLabelProps={{ shrink: true }}
                  value={ngaNrFat}
                  onChange={(e) => setNgaNrFat(e.target.value)}
                  style={{ marginLeft: "8px", width: "47%" }}
                />
                <TextField
                  label="Deri Nr Fature"
                  type="input"
                  InputLabelProps={{ shrink: true }}
                  value={deriNrFat}
                  onChange={(e) => setDeriNrFat(e.target.value)}
                  style={{ marginLeft: "8px", width: "47%" }}
                />
              </div>
            ))}
        </Grid>

        <CustomizedMenus
          handleSubmit={handleSubmit}
          dataCheck={dataCheck}
          ngaData={ngaData}
          deriDate={deriDate}
        />
      </Box>
    </Dialog>
  );
};

export default ReportDialog;
