import React, { useState, useRef, useEffect } from "react";
import { Dialog } from "primereact/dialog";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import {
  TextField,
  Button,
  InputAdornment,
  IconButton,
  Typography,
} from "@mui/material";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import mainAxios from "../../services/axios";
import CloseIcon from "@mui/icons-material/Close";
import { createSelector } from "reselect";
import { useDispatch, useSelector } from "react-redux";
import { useUser } from "../../zustand/common";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  Paper,
  TableHead,
} from "@mui/material";
import { getGjendjeArke } from "../../store/globalSlice";
import { useToast } from "../context/ToastContext";

const ArkaButtons = [
  {
    key: "3-0",
    label: "Arka (TCRCode)",
    type: "input",
    value: "",
    reduxGet: "arkaList",
  },
  {
    key: "2-9",
    label: "Veprimi",
    type: "input",
    value: "",
    reduxGet: "oper",
  },
  {
    key: "2-8",
    label: "Vlera",
    type: "input",
    value: "",
  },
  {
    key: "2-8-8-8",
    label: "Shenim",
    type: "input",
    value: "",
  },
  {
    key: "2-8-8-9",
    label: "Gjendje",
    type: "text",
    value: "",
  },
];
const ArkaDialog = ({
  visible,
  onHide,
  content,
  label,
  raportButtonsKeys,
  setArkaDashboard,
}) => {
  const showToast = useToast();
  const [gjendje, setGjendje] = useState("");
  const [selectedButtonKey, setSelectedButtonKey] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const dispatch = useDispatch();
  const [isTCRCodeVisible, setIsTCRCodeVisible] = useState(true);

  const { user } = useUser();
  const Operator = user.username;

  const arkaRedux = useSelector((state) => state.globalSlice.arkaList);
  const veprimi = useSelector((state) => state.globalSlice.oper);

  const [selectedValues, setSelectedValues] = useState({});

  const formatDateTime = (date) => {
    const d = new Date(date);

    const year = d.getFullYear();
    const month = String(d.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(d.getDate()).padStart(2, "0");
    const hours = String(d.getHours()).padStart(2, "0");
    const minutes = String(d.getMinutes()).padStart(2, "0");
    const seconds = String(d.getSeconds()).padStart(2, "0");

    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
  };

  const functionToSeeGjendjeArka = async (TCRCode) => {
    try {
      if (TCRCode !== undefined && TCRCode !== null && TCRCode !== "") {
        dispatch(getGjendjeArke(`/gjendje_arka/${TCRCode}`));
      } else {
        console.error("TCRCode is undefined or invalid");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const gjendjeRedux = useSelector((state) => state.globalSlice.gjendjeArke);

  useEffect(() => {
    const arkaValue = selectedValues["3-0"];
    functionToSeeGjendjeArka(arkaValue?.TCRCode);
    // setArkaDashboard(arkaValue?.TCRCode);
  }, [selectedValues["3-0"]]);

  useEffect(() => {
    if (gjendjeRedux) {
      setGjendje(gjendjeRedux.Vlera);
    } else {
      setGjendje("0");
    }
  }, [gjendjeRedux]);

  const handleSubmit = async () => {
    try {
      // Log each selectedValue dynamically
      // Object.keys(selectedValues).forEach((key) => {
      //   console.log(`"KEY: ${key}:`, "Selectedvalue", selectedValues[key]);
      // });

      // Extracting values from selectedValues
      const {
        "3-0": arkaValue,
        "2-9": veprimiValue,
        "2-8": vleraValue,
        // "2-7": shenimValue,
      } = selectedValues;

      // Constructing the query parameters string
      const queryParams = `vlera=${vleraValue?.Pershkrim || ""}&oper=${
        veprimiValue?.Kodi || ""
      }&TCRCode=${arkaValue?.TCRCode || ""}`;

      // Making the request with query parameters in the URL
      const response = await mainAxios.get(`/arka/pfissarka?${queryParams}`);

      setSelectedValues({});

      if (Array.isArray(response.data) && response.data.length > 0) {
        const firstItem = response.data[0];

        if (response.data[0]?.ErrorCode !== "0") {
          const faultstring = firstItem.faultstring;
          showToast(faultstring, { severity: "error" });
        } else {
          const message = `${veprimiValue?.Pershkrim || ""} u krye me sukses !`;
          showToast(message, { severity: "success" });
          try {
            // Extracting values from selectedValues
            const {
              "3-0": arkaValue, // TCRCode
              "2-9": veprimiValue, // Operation
              "2-8": vleraValue, // CashAmt
              "2-8-8-8": shenimValue, // Shenim
            } = selectedValues;

            // Constructing the payload
            const payload = {
              Operator, // Username from user
              CashAmt: vleraValue?.Pershkrim || "", // Vlera input
              Operation: veprimiValue?.Kodi || "", // Veprimi
              TCRCode: arkaValue?.TCRCode || "", // TCRCode
              Fiskalizuar: 1, // Always 1
              Shenim: shenimValue?.Pershkrim || "", // Shenim input
              SendDateTime: formatDateTime(new Date()),
            };

            // Sending the POST request
            const response = await mainAxios.post("/transaction", payload);
            if (response.status === 200) {
              onHide(true);
            }

            // Handling the success response
            // if (response.data && response.data.success) {
            //   const message = `${
            //     veprimiValue?.Pershkrim || ""
            //   } u krye me sukses!`;
            //   showToast(message, { severity: "success" });
            // } else {
            //   // Handle cases where the request didn't succeed
            //   const errorMessage = response.data?.message || "Operimi deshtoi.";
            //   showToast(errorMessage, { severity: "error" });
            // }
          } catch (err) {
            console.error("Error during submission:", err);
            showToast("Deklarimi deshtoi. Ju lutemi provoni përsëri.", {
              severity: "error",
            });
          }
        }
      } else {
        console.error("Response data is not an array or is empty.");
      }
    } catch (err) {
      console.error("Error posting data:", err);

      showToast("Deklarimi i Arkes deshtoi.", {
        severity: "error",
      });
      // Clear the form fields and response data when there is an error.
      // setSelectedValues({});
    } finally {
      const arkaValue = selectedValues["3-0"]; // Get TCRCode
      functionToSeeGjendjeArka(arkaValue?.TCRCode); // Call the function with TCRCode
      setArkaDashboard(arkaValue?.TCRCode);
    }
  };

  const selectGlobalSlice = (state) => state.globalSlice;

  const memoizedSelector = createSelector(
    [selectGlobalSlice],
    (globalSlice) => {
      // Your selector logic here
      return globalSlice[selectedButton?.reduxGet] || [];
    }
  );

  const raportButtons = ArkaButtons.filter((rb) =>
    raportButtonsKeys.includes(rb.key)
  );

  const selectedButton = raportButtons.find(
    (button) => button.key === selectedButtonKey
  );

  const dataFromRedux = useSelector(memoizedSelector);

  const mappedData = dataFromRedux.map((item) => ({
    Kodi: item.Kodi,
    Pershkrim: item.Pershkrim || item.Kodi,
    TCRCode: item.TCRCode || item.TCRCode,
  }));

  const filteredData = mappedData.filter(
    (item) =>
      (item.Kodi &&
        item.Kodi.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.Pershkrim &&
        item.Pershkrim.toLowerCase().includes(searchValue.toLowerCase())) ||
      (item.TCRCode &&
        item.TCRCode.toLowerCase().includes(searchValue.toLowerCase()))
  );

  const handleRowClick = (item) => {
    if (selectedButtonKey) {
      setSelectedValues((prevValues) => ({
        ...prevValues,

        [selectedButtonKey]: item,
      }));
      setSelectedButtonKey(null);
    }
  };

  const handleInputChange = (key, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [key]: { ...prevValues[key], Pershkrim: value },
    }));
  };

  const [isMobile, setIsMobile] = useState(window.innerWidth < 1200);

  useEffect(() => {
    const updateIsMobile = () => {
      setIsMobile(window.innerWidth <= 1200);
    };

    window.addEventListener("resize", updateIsMobile);
    updateIsMobile();
    return () => {
      window.removeEventListener("resize", updateIsMobile);
    };
  }, []);

  useEffect(() => {
    const hasTCRCode = mappedData.some((item) => item.TCRCode);
    setIsTCRCodeVisible(hasTCRCode);
  }, [mappedData]);

  // Making the second request with today's date when dialog opens
  useEffect(() => {
    if (visible) {
      // Find the button with key "3-0"
      const buttonWithKey = ArkaButtons.find((button) => button.key === "3-0");

      if (buttonWithKey) {
        // functionToSeeGjendjeArka();
        if (arkaRedux && arkaRedux.length > 0) {
          // Assuming you want to set the first item from arkaRedux as the default
          setSelectedValues((prevValues) => ({
            ...prevValues,
            "3-0": {
              TCRCode: arkaRedux[0]?.TCRCode || "",
              Pershkrim: arkaRedux[0]?.Pershkrim || "",
            },
          }));
          // setArkaDashboard(arkaRedux[0]?.TCRCode);
        }
        if (veprimi && veprimi.length > 0) {
          // Assuming you want to set the first item from arkaRedux as the default
          setSelectedValues((prevValues) => ({
            ...prevValues,
            "2-9": {
              Kodi: veprimi[0]?.Kodi || "",
              Pershkrim: veprimi[0]?.Pershkrim || "",
            },
          }));
        }
      } else {
        console.warn(`Button with key 3-0 not found`);
      }
    }
  }, [visible]);

  return (
    <Dialog
      header={content}
      visible={visible}
      onHide={onHide}
      style={!isMobile ? { width: "20%" } : { width: "80%" }}
    >
      <p>{label}</p>
      <Box sx={{ width: "100%", paddingTop: "5px" }}>
        <Grid
          container
          direction="column"
          justifyContent="center"
          alignItems="center"
          spacing={1}
        >
          {raportButtons.map((button) => (
            <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
              <TextField
                id={button.key}
                label={button.label}
                type={button.type}
                style={{ width: "100%" }}
                className="textfieldRaport"
                value={selectedValues[button.key]?.Pershkrim || ""}
                onChange={(e) => handleInputChange(button.key, e.target.value)}
                InputProps={
                  button.reduxGet
                    ? {
                        endAdornment: (
                          <InputAdornment position="end">
                            {selectedButtonKey !== button.key ? (
                              <IconButton
                                edge="end"
                                onClick={() => setSelectedButtonKey(button.key)}
                              >
                                <ArrowDropDownIcon />
                              </IconButton>
                            ) : (
                              <IconButton
                                edge="end"
                                onClick={() => setSelectedButtonKey(null)}
                              >
                                <CloseIcon />
                              </IconButton>
                            )}
                          </InputAdornment>
                        ),
                      }
                    : null
                }
              />
              {selectedButtonKey === button.key && (
                <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
                  <Paper
                    style={{
                      position: "absolute",
                      width: "90%",
                      zIndex: 9999,
                      maxHeight: "300px",
                      overflow: "auto",
                    }}
                  >
                    <Box px={2} py={1}>
                      <TextField
                        id="filled-search"
                        placeholder="Search "
                        type="search"
                        variant="standard"
                        fullWidth
                        size="small"
                        value={searchValue}
                        onChange={(e) => setSearchValue(e.target.value)}
                      />
                    </Box>
                    <TableContainer
                      component={Paper}
                      style={{ maxHeight: "300px", overflow: "auto" }}
                    >
                      <Table size="small" aria-label="simple table">
                        <TableHead>
                          <TableRow>
                            <TableCell>Kodi</TableCell>
                            <TableCell align="center">Pershkrim</TableCell>
                            {isTCRCodeVisible && (
                              <TableCell align="right">TCRCode</TableCell>
                            )}
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {filteredData.map((item, index) => (
                            <TableRow
                              key={index}
                              style={{ cursor: "pointer" }}
                              onClick={() => handleRowClick(item)}
                            >
                              <TableCell component="th" scope="row">
                                {item.Kodi}
                              </TableCell>
                              <TableCell align="center">
                                {item.Pershkrim}
                              </TableCell>
                              {isTCRCodeVisible ? (
                                <TableCell align="right">
                                  {item.TCRCode}
                                </TableCell>
                              ) : null}
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </Paper>
                </Grid>
              )}
            </Grid>
          ))}

          {ArkaButtons.map((button) => {
            if (button.key === "2-8-8-8") {
              return (
                <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
                  <TextField
                    key={button.key}
                    label={button.label}
                    value={selectedValues[button.key]?.Pershkrim || ""}
                    onChange={(e) =>
                      handleInputChange(button.key, e.target.value)
                    }
                    fullWidth
                    // sx={{ marginBottom: "10px" }}
                  />
                </Grid>
              );
            }

            if (button.key === "2-8-8-9") {
              return (
                <Grid item xs={4} key={button.key} style={{ width: "100%" }}>
                  <Typography
                    variant="body1"
                    sx={{
                      marginBottom: "10px",
                      fontWeight: "600",
                      fontSize: "18px",
                      color: "blue",
                      marginTop: "10px",
                    }}
                  >
                    Gjendje: {gjendje} Lek
                  </Typography>
                </Grid>
              );
            }

            return null; // Skip other fields unless explicitly handled
          })}
        </Grid>
        <Button
          style={{
            width: "100%",
            color: "white",
            marginTop: "10px",
            backgroundColor: "#1c80cf",
          }}
          onClick={handleSubmit}
        >
          Regjistrim
        </Button>
      </Box>
    </Dialog>
  );
};

export default ArkaDialog;
