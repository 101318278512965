// project import
import dashboard from "./dashboard";
import pages from "./page";
import utilities from "./utilities";
import support from "./support";

// ==============================|| MENU ITEMS ||============================== //

const menuItems = {
  // items: [dashboard, pages, utilities, support]
  items: [dashboard, utilities],
};

export default menuItems;
