import React, { useState, useEffect, useRef, useContext } from "react";
import PaneliArtikuj from "./PaneliArtikuj";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import "./Artikuj.scss";
import mainAxios from "../../services/axios";
import { useSelector } from "react-redux";
import { getArtikujPag } from "../../store/globalSlice";
import { useDispatch } from "react-redux";
import PopUpConfirm from "../fature-blerje/tabet/PopUpConfirm";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import PaneliTabeve from "./PaneliTabeve";

// function
import incrementKodi from "./functions/IncrementKodi";
import CheckboxHandler from "./functions/CheckboxHandler";
import ArtikujTable from "./ArtikujTable";
import { useToast } from "../../components/context/ToastContext";

const Artikuj = ({
  showHideColumns = true,
  currentAction,
  setCurrentAction,
  hidePupUp,
  setHidePupUp,
  isMobile,
  disabled,
  setDisabled,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);

  const [data, setdata] = useState([]);
  let numDataLength = data.length;
  const showToast = useToast();
  // const [currentAction, setCurrentAction] = useState("add");
  const [currentId, setCurrentId] = useState("");
  const dispatch = useDispatch();
  const [isEditingAllowed, setIsEditingAllowed] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [numPerPage, setNumPerPage] = useState();
  const [checkModify, setCheckModify] = useState(false);
  const [disabledBtnDelete, setDisabledBtnDelete] = useState(false);

  useEffect(() => {
    if (currentId) {
      setDisabledBtnDelete(false);
    } else {
      setDisabledBtnDelete(true);
    }
  }, [currentId]);

  const onAddClick = () => {
    setCurrentId("");
    setCurrentAction("add");
  };

  // redux artikuj (get requesti i artikujve)
  const artikujRedux = useSelector((state) => state.globalSlice.artikujPag);
  const artikuj = artikujRedux;

  // redux total artikuj (get requesti i numrit total artikujve)
  const totalArtRedux = useSelector((state) => state.globalSlice.totalArt);
  const artikujTotal = totalArtRedux;

  useEffect(() => {
    setdata(artikuj);
  }, [artikuj]);

  const fetchData = async () => {
    try {
      showLoading();
      await dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&per_page=${numPerPage}`
        )
      );
    } catch (error) {
      console.error(error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    // Ensure that `numPerPage` and `currentPage` are defined before dispatching
    if (numPerPage && currentPage) {
      fetchData();
    }
  }, [numPerPage, currentPage, dispatch]);

  const defaultState = {
    AAM_Jetegjatesia_Njesi_Kodi: "",
    AAM_Kls01_Kodi: "",
    AAM_Kls02_Kodi: "",
    AAM_Kls03_Kodi: "",
    AAM_Metoda_Amortizimi_Kodi: "",
    Aktiv: 1,
    Shumice: 1,
    Pos_NeList: 1,
    Pakice: 1,
    Distribucion: "",
    AAM: "",
    Art_Kls01_Kodi: "",
    Cmimi_Bleres: "",
    Art_Kls02_Kodi: "",
    Art_Kls03_Kodi: "",
    Art_Kls04_Kodi: "",
    Art_Kls05_Kodi: "",
    Art_Kls06_Kodi: "",
    Fjale_Kyce: "",
    Kodi: "",
    Operator: "",
    Njesi_Kodi: "",
    NrSerik: "",
    Perbere: "",
    Pershkrim: "",
    Peshore: "",
    Cmimi: "",
    Kodi1: "",
    Kodi2: "",
    Kodi3: "",
    Kodi4: "",
    Kodi5: "",
    Cmimi_1: "",
    Cmimi_2: "",
    Cmimi_3: "",
    Cmimi_4: "",
    Cmimi_5: "",
    Cmimi_6: "",
    Barkod: "",
    Tarifa_Kodi: "",
    Tvsh: "",
  };

  const [state, setState] = useState(defaultState);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [isModifying, setIsModifying] = useState(false);
  const [buttonClickedModifiko, setButtonClickedModifiko] = useState(false);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (selectedRowData) => {
    setState(selectedRowData);
    setCurrentId(selectedRowData.Id);
    setSelectedRowData(selectedRowData);
  };

  useEffect(() => {
    // Function to select the first row

    const selectFirstRow = () => {
      if (data && data.length > 0 && !state.Kodi) {
        handleRowClick(data[0]);
      }
    };

    selectFirstRow();
  }, [data, state]);

  const updateRowData = async (rowData) => {
    if (state.Kodi === "" || state.Kodi === null) {
      showToast("Shtoni Kodin ose anulloje.", {
        severity: "info",
      });

      return;
    }
    if (state.Pershkrim === "" || state.Pershkrim === null) {
      showToast("Shtoni pershkrimin ose anulloje.", {
        severity: "info",
      });

      return;
    }

    try {
      const response = await mainAxios.put(`/art/${currentId}`, rowData);
      if (response.status === 200) {
        // Directly update the DataTable without calling getArtikujPag
        setdata((prevData) =>
          prevData.map((item) =>
            item.Id === currentId ? { ...item, ...rowData } : item
          )
        );
      }
    } catch (error) {
      console.error("Error updating data:", error);

      showToast("Error updating data.", {
        severity: "error",
      });
    }
  };

  const handleChangeTarifa = (key, value, tvsh, rowData) => {
    if (isEditingAllowed) {
      const updatedRowData = {
        ...rowData,
        [key]: value,
        Tvsh: tvsh.Tvsh,
      };
      updateRowData(updatedRowData);
    }
    setState((state) => {
      return {
        ...state,
        Tarifa_Kodi: value,
        Tvsh: tvsh.Tvsh,
      };
    });
  };

  const [selectedProduct, setSelectedProduct] = useState(null);

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);

    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setdata(artikuj);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      setdata(response.data); // Update data state with search results
    } catch (error) {
      console.error(error);

      showToast("Artikulli nuk u gjend", {
        severity: "error",
      });
    }
  };

  const [first2, setFirst2] = useState(0);
  const [rows2, setRows2] = useState(10);

  const onCustomPage2 = (event) => {
    setFirst2(event.first);
    setRows2(event.rows);
  };

  const [firstnum, setFirstNum] = useState("");
  const [lastnum, setLastNum] = useState("");

  const handleConfirm = () => {
    try {
      setHidePupUp(false);
      setCurrentAction(null);
      setButtonClicked(false);
      setButtonClickedModifiko(false);
      setDisabled(true);
      setState("");
    } catch (error) {
      console.error(error);
    } finally {
      dispatch(
        getArtikujPag(
          `/artikull/pag?page=${currentPage}&&per_page=${numPerPage}`
        )
      );
    }
  };

  const handleCancel = () => {
    setHidePupUp(false);
  };

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
  };

  return (
    <div className={`mainDiv_ftsh ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setCurrentAction={setCurrentAction}
          setDisabled={setDisabled}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          {hidePupUp && (
            <PopUpConfirm onConfirm={handleConfirm} onCancel={handleCancel} />
          )}

          <div className="p-2 main-container">
            <div
              className="flex justify-between items-center"
              style={{ height: "5%" }}
            >
              {/* Paneli i tabeve do te vendoset ketu */}
              <PaneliTabeve
                incrementKodi={incrementKodi}
                defaultState={defaultState}
                onAddClick={onAddClick}
                setDisabled={setDisabled}
                setCheckModify={setCheckModify}
                setState={setState}
                setButtonClickedModifiko={setButtonClickedModifiko}
                setButtonClicked={setButtonClicked}
                buttonClicked={buttonClicked}
                isModifying={isModifying}
                isMobile={isMobile}
                disabledBtnDelete={disabledBtnDelete}
                anulloFature={anulloFature}
                disabled={disabled}
                checkModify={checkModify}
                setHidePupUp={setHidePupUp}
                setCurrentAction={setCurrentAction}
                state={state}
                currentPage={currentPage}
                currentId={currentId}
                numPerPage={numPerPage}
                currentAction={currentAction}
                handleRowClick={handleRowClick}
                showLoading={showLoading}
                hideLoading={hideLoading}
              />
              <CheckboxHandler
                setIsModifying={setIsModifying}
                isMobile={isMobile}
                setIsEditingAllowed={setIsEditingAllowed}
                setCheckModify={setCheckModify}
                setButtonClicked={setButtonClicked}
                setDisabled={setDisabled}
                setCurrentAction={setCurrentAction}
                buttonClickedModifiko={buttonClickedModifiko}
              />
            </div>

            <ArtikujTable
              state={state}
              searchTerm={searchTerm}
              handleSearchInputChange={handleSearchInputChange}
              handleSearchClick={handleSearchClick}
              showHideColumns={showHideColumns}
              isEditingAllowed={isEditingAllowed}
              artikujTotal={artikujTotal}
              firstnum={firstnum}
              lastnum={lastnum}
              numPerPage={numPerPage}
              currentPage={currentPage}
              setFirstNum={setFirstNum}
              setLastNum={setLastNum}
              setNumPerPage={setNumPerPage}
              setCurrentPage={setCurrentPage}
              numDataLength={numDataLength}
              first2={first2}
              isMobile={isMobile}
              rows2={rows2}
              onCustomPage2={onCustomPage2}
              selectedRowData={selectedRowData}
              handleRowClick={handleRowClick}
              data={data}
              disabled={disabled}
              selectedProduct={selectedProduct}
              setSelectedProduct={setSelectedProduct}
              updateRowData={updateRowData}
              setState={setState}
            />
            <div className="card_below_artikuj" style={{ height: "25%" }}>
              <PaneliArtikuj
                handleChangeTarifa={handleChangeTarifa}
                isMobile={isMobile}
                disabled={disabled}
                state={state}
                setState={setState}
                isEditingAllowed={isEditingAllowed}
                updateRowData={updateRowData}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {!isMobile && <Footer className="footer-position" />} */}
    </div>
  );
};

export default Artikuj;
