// Importing necessary dependencies
import { configureStore } from "@reduxjs/toolkit";
import globalSlice from "./globalSlice"; // Importing your Redux slice
import globalPosSlice from "./globalPos";
import globalReportsReducer from "./globalReports";
import { userReducer } from "./reducers/userReducers";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // LocalStorage or SessionStorage
import { combineReducers } from "redux";

// Retrieve user info from localStorage if it exists
const userInfoFromStorage = localStorage.getItem("account")
  ? JSON.parse(localStorage.getItem("account"))
  : null;

// Initial state
const initialState = {
  user: { userInfo: userInfoFromStorage },
};

// Persist configuration for the entire store
const persistConfig = {
  key: "root",
  storage,
  // No whitelist or blacklist, so this will persist the entire Redux store
};

// Combining reducers
const rootReducer = combineReducers({
  globalSlice,
  globalPosSlice,
  user: userReducer,
  globalReports: globalReportsReducer,
});

// Persisted reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// Creating and exporting the Redux store
export const store = configureStore({
  reducer: persistedReducer,
  preloadedState: initialState,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: false, // Disable state mutation checks in dev
      serializableCheck: false, // Disable serializability checks
    }),
});

// Persistor for handling the store persistence
export const persistor = persistStore(store);
