import React, { useState, useRef, useMemo, useEffect } from "react";
import { DataTable } from "primereact/datatable";
import { Column } from "primereact/column";
import { FilterMatchMode, FilterOperator } from "primereact/api";
import { InputText } from "primereact/inputtext";
import { Row } from "primereact/row";
import { ColumnGroup } from "primereact/columngroup";
import ModalList from "../ListaArtikujve/ModalList";
import { ContextMenu } from "primereact/contextmenu";
import useStorage from "../../hooks/useStorage";
import { Tooltip } from "primereact/tooltip";
import "./PrimeGrid.scss";
import MultiDemoSelect from "../../pages/fatureShitje/FatureThjeshtuar/Grid/MultiSelect";
import { useToast } from "../context/ToastContext";

// Component responsible for rendering a PrimeReact DataTable
const PrimeGrid = ({
  disabled,
  columns,
  contextKey,
  shtoArtikull,
  currentAction,
  currentId,
  rows,
  setRows,
  isEditable = true,
  showHideColumns = true,
  gridKey,
  calculateData,
  Total,
  defaultColumnsStorage,
  deletedRowIds,
  setDeletedRowIds,
  fromArka,
  fromFleteDalje,
  ...props
}) => {
  // State variables
  const [globalFilterValue1, setGlobalFilterValue1] = useState("");
  const [filters1, setFilters1] = useState(null);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonat" + gridKey
  );
  const [isLongPress, setIsLongPress] = useState(false);
  const cm = useRef();
  const showToast = useToast();

  // Set up a long press timer
  const handleTouchStart = (e) => {
    setIsLongPress(false);
    setTimeout(() => setIsLongPress(true), 600); // 600ms long press time
    e.preventDefault(); // Prevent default behavior, but not for focus
  };

  const handleTouchEnd = (e) => {
    // Only show the context menu if it's a long press and the target is not an input
    if (isLongPress && e.target.tagName !== "INPUT") {
      cm.current.show(e); // Show context menu on long press
    }
    setIsLongPress(false);
  };

  const handleCellClick = (e) => {
    // Do not prevent default touch behavior for input focus
    if (e.target.tagName !== "INPUT") {
      e.preventDefault(); // Prevent touch behavior only if it's not an input field
    }
  };

  const onRowSelectionChange = (e) => {
    const selectedRowData = e.value.length > 0 ? e.value[0].rowData : null;
    handleRowClick(selectedRowData);
  };

  // Event handler for global filter change
  const onGlobalFilterChange1 = (e) => {
    const value = e.target.value;
    let _filters1 = { ...filters1 };
    _filters1["global"].value = value;

    setFilters1(_filters1);
    setGlobalFilterValue1(value);
  };

  // Function to clear filters
  const clearFilter1 = () => {
    initFilters1();
  };

  // Function to initialize filters
  const initFilters1 = () => {
    setFilters1({
      global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      columns: {
        operator: FilterOperator.AND,
        constraints: [{ value: null, matchMode: FilterMatchMode.STARTS_WITH }],
      },
    });
    setGlobalFilterValue1("");
  };

  // Event handler for cell edit completion
  const onCellEditComplete = (e) => {
    const { rowData, newValue, rowIndex, field } = e;

    // Allow editing only in "Pershkrim" column as a string
    if (field === "Pershkrim") {
      const newRowData = { ...rowData, [field]: newValue }; // Update the "Pershkrim" field with the new value
      const allRows = [...rows];
      allRows[rowIndex] = newRowData;
      setRows(allRows); // Update the state with the new row data
      return; // Exit early for "Pershkrim"
    }

    // Handle other columns (e.g., "Total") as numbers
    if (field === "Total") return;

    let cellData = null;
    if (typeof rowData[field] === "number") {
      cellData = parseFloat(newValue);
    } else {
      cellData = newValue;
    }

    const newRowData = calculateData({ [field]: cellData }, { ...rowData });
    const allRows = [...rows];
    allRows[rowIndex] = newRowData;
    setRows(allRows); // Update the state with the new row data
  };

  // Functional component for the text editor
  const TextEditor = ({ value, editorCallback, field }) => {
    const inputRef = useRef(null);

    useEffect(() => {
      if (inputRef.current) {
        inputRef.current.focus();
        inputRef.current.select();
      }
    }, []);

    return (
      <InputText
        disabled={disabled}
        ref={inputRef}
        id="text-box"
        keyfilter={field === "Pershkrim" ? "text" : "int"} // Conditional keyfilter for text or integers
        placeholder={field === "Pershkrim" ? "Place Text" : "Integers"} // Conditional placeholder
        type="text"
        value={value}
        onChange={(e) => editorCallback(e.target.value)} // Call the editorCallback when typing
      />
    );
  };

  // Function to render cell editor
  const cellEditor = (options) => {
    const { column, value, editorCallback } = options;

    if (column?.props?.editColumn === false) {
      return <Column key={column.field} colSpan={1}></Column>;
    }

    return <TextEditor value={value} editorCallback={editorCallback} />;
  };

  // Event handler for column toggle
  const onColumnToggle = (event) => {
    let selectedColumns = event?.value;
    let orderedSelectedColumns = columns
      ?.filter((col) =>
        selectedColumns?.some((sCol) => sCol?.field === col?.field)
      )
      .map((col) => col.field);
    setselectedcolumns(orderedSelectedColumns);
  };

  // Filter columns based on showHideColumns prop
  const filterdColumns = showHideColumns
    ? columns?.filter((column) =>
        selectedColumns?.some((field) => field === column?.field)
      )
    : columns;

  // Header component
  const header = (
    <div
      style={{
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      {!fromArka && (
        <>
          <span className="flex " style={{ width: "8rem", height: "2rem" }}>
            <ModalList
              disabled={disabled}
              setRows={setRows}
              gridKey={gridKey}
              contextKey={contextKey}
              rows={rows}
              shtoArtikull={!disabled && shtoArtikull}
              currentAction={currentAction}
              currentId={currentId}
            />
          </span>
          <span
            style={{ width: "15rem", height: "2rem" }}
            className="p-input-icon-left "
          >
            <i className="pi pi-search" />
            <InputText
              value={globalFilterValue1}
              onChange={onGlobalFilterChange1}
              placeholder="Kërko me pershkrim"
              onClick={clearFilter1}
            />
          </span>
        </>
      )}
      <MultiDemoSelect
        filterdColumns={filterdColumns}
        columns={columns}
        onColumnToggle={onColumnToggle}
      />
    </div>
  );

  // Dynamically generate columns based on filteredColumns
  const dynamicColumns = filterdColumns.map((col, index) => {
    const uniqueKey = col?.field || `column-${index + 1}`;

    // Determine column style based on provided properties
    const columnStyle = col?.impMinWidth
      ? { minWidth: "150px" }
      : col?.impMaxWidth
      ? { width: "77px", minWidth: "77px", maxWidth: "77px" }
      : {};

    return (
      <Column
        key={uniqueKey}
        style={columnStyle}
        field={col?.field}
        header={col?.title}
        decimalPlaces={col?.decimalPlaces || 0}
        calculateData={calculateData}
        {...(isEditable && {
          onCellEditComplete: (e) => onCellEditComplete(e),
          editor: (options) =>
            col?.field === "Pershkrim" ? (
              <TextEditor
                disabled={disabled}
                field="Pershkrim"
                value={options.value} // Bind the input value to the row data
                editorCallback={options.editorCallback} // Update the value when typing
              />
            ) : (
              cellEditor(options) // Use the default editor for other fields
            ),
        })}
        body={(rowData) => {
          const value = rowData[col?.field];

          // Format value based on type
          let formattedValue;
          if (typeof value === "number") {
            formattedValue = Number.isInteger(value)
              ? value.toLocaleString()
              : value.toFixed(2);
          } else {
            formattedValue = value;
          }

          // Return formatted cell content
          return (
            <span
              style={{
                width: "100%",
                textAlign: typeof value === "number" ? "right" : "left",
                justifyContent: typeof value === "number" ? "right" : "left",
              }}
            >
              {formattedValue}
            </span>
          );
        }}
      />
    );
  });

  // Function to calculate column sum
  const columnSum = useMemo(() => {
    return (field) => {
      let Total = 0;
      for (let row of rows) {
        if (row[field]) {
          Total += Number(row[field]);
        }
      }
      return Total > 0 ? Total.toFixed(2) : "0.00";
    };
  }, [rows]);

  // // Footer text
  // const footer = `${rows ? rows.length : 0} Rekorde`;

  // Function to generate footer group
  const footerGroup = () => {
    let checkCols = filterdColumns?.some((column) => {
      return column?.allowSum;
    });
    if (!checkCols) return <></>;

    return (
      <ColumnGroup>
        <Row>
          {filterdColumns?.map((column, i) => {
            if (column?.allowSum) {
              const uniqueKey = i + 1;
              return (
                <Column
                  key={uniqueKey}
                  colSpan={1}
                  footer={() => columnSum(column?.field)}
                  footerStyle={{ textAlign: "right" }}
                />
              );
            }
            return <Column key={`empty_${i}`} colSpan={1} />;
          })}
        </Row>
      </ColumnGroup>
    );
  };

  let footerGr = footerGroup();

  // Event handler for column reorder
  const onColReorder = (event) => {
    const { dragIndex, dropIndex } = event;

    let columnsCopy = [...selectedColumns];
    const tempColumn = { ...selectedColumns[dragIndex] };
    columnsCopy[dragIndex] = selectedColumns[dropIndex];
    columnsCopy[dropIndex] = tempColumn;

    setselectedcolumns(columnsCopy);

    showToast("Column Reordered", {
      severity: "success",
    });
  };

  // Context menu model
  const menuModel = [
    {
      label: "Fshije",
      icon: "pi pi-fw pi-times",
      className: "custom-icon-red",
      command: () => deleteRow(selectedProduct),
    },
    {
      label: "ExportCSV",
      icon: "pi pi-file",
      className: "custom-icon-black",
      command: () => exportCSV(false),
    },
    {
      label: "ExportExel",
      icon: "pi pi-file-excel",
      className: "custom-icon-black",
      command: () => exportExcel(),
    },
  ];

  const [selectedProduct, setSelectedProduct] = useState(null);

  // Function to delete a row
  const deleteRow = async (row) => {
    let filteredRows = [...rows];
    filteredRows = filteredRows.filter((p) => p.Id !== row.Id);

    showToast("Rreshti u fshi", {
      severity: "info",
    });
    setRows(filteredRows);
    setDeletedRowIds([...deletedRowIds, row.Id]);
  };

  const dt = useRef(null);

  // Function to export CSV
  const exportCSV = (selectionOnly) => {
    dt.current.exportCSV({ selectionOnly });
  };

  // Function to export Excel
  const exportExcel = () => {
    import("xlsx").then((xlsx) => {
      const worksheet = xlsx.utils.json_to_sheet(rows);
      const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] };
      const excelBuffer = xlsx.write(workbook, {
        bookType: "xlsx",
        type: "array",
      });
      saveAsExcelFile(excelBuffer, "rows");
    });
  };

  // Function to save Excel file
  const saveAsExcelFile = (buffer, fileName) => {
    import("file-saver").then((module) => {
      if (module && module.default) {
        let EXCEL_TYPE =
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
        let EXCEL_EXTENSION = ".xlsx";
        const data = new Blob([buffer], {
          type: EXCEL_TYPE,
        });

        module.default.saveAs(
          data,
          fileName + "_export_" + new Date().getTime() + EXCEL_EXTENSION
        );
      }
    });
  };

  const [selectionRow, setSelectionRow] = useState(null);
  const [selectedRowData, setSelectedRowData] = useState(null);

  const handleRowClick = (selectedRowData) => {
    // console.log(selectedRowData, "id");
    setSelectionRow(selectedRowData);
    setSelectedRowData(selectedRowData);
  };

  // Return the rendered JSX
  return (
    <div className="dataTablePrimeGrid">
      <ContextMenu model={menuModel} ref={cm} />
      <Tooltip target=".export-buttons>button" position="bottom" />
      <div className="card">
        <div className="prime-react">
          <DataTable
            className={"data-table-ftsh"}
            reorderablecolumnsdatatable="true"
            cellSelection
            selection={selectionRow}
            onSelectionChange={onRowSelectionChange}
            onContextMenu={
              disabled ? undefined : (e) => cm.current.show(e.originalEvent)
            }
            selectionMode="multiple"
            style={{
              position: "relative",
              touchAction: "auto", // Allow scrolling and interaction with form elements
            }}
            ref={dt}
            contextMenuSelection={selectedProduct}
            scrollable
            contextselection={selectedProduct}
            onContextMenuSelectionChange={(e) => setSelectedProduct(e.value)}
            onColReorder={onColReorder}
            resizableColumns
            columnResizeMode="fit"
            responsiveLayout="scroll"
            size="small"
            showGridlines
            value={rows}
            stripedRows
            dataKey="Id"
            filters={filters1}
            autoLayout={true}
            header={header}
            rowClassName={(rowData) =>
              selectedRowData && selectedRowData.Id === rowData.Id
                ? "selected-row"
                : "unselected-row"
            }
            footerColumnGroup={footerGr}
            emptyMessage="Nuk ka te dhena"
            {...props}
            onTouchStart={handleTouchStart}
            onTouchEnd={handleTouchEnd}
            onClick={handleCellClick}
          >
            {dynamicColumns}
          </DataTable>
        </div>
      </div>
    </div>
  );
};

export default PrimeGrid;
