import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import { LoadingOverlayProvider } from "./components/LoadingOverlay";
import "./index.scss";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.css";
import { store, persistor } from "./store/store";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { LogoProvider } from "./components/context/LogoContext";
import { ToastProvider } from "./components/context/ToastContext";

// import reportWebVitals from "./reportWebVitals";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <LoadingOverlayProvider>
        <BrowserRouter>
          <LogoProvider>
            <ToastProvider>
              <App />
            </ToastProvider>
          </LogoProvider>
        </BrowserRouter>
      </LoadingOverlayProvider>
    </PersistGate>
  </Provider>,
  rootElement
);

// Reporting web vitals
// reportWebVitals(console.log);
