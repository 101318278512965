const columns = [
  { field: "Kodi", title: "Kodi" },
  { field: "Pershkrim", title: "Pershkrim", impMinWidth: true },
  { field: "Mon", title: "Monedha", allowSum: true, impMaxWidth: true },
  { field: "Kursi", title: "Kursi", impMaxWidth: true },
  {
    field: "Debi",
    title: "Debi",
    allowSum: true,
  },
  { field: "Kredi", title: "Kredi", allowSum: true },
  { field: "NrLL", title: "Llogaria", impMaxWidth: true },
  {
    field: "Total",
    title: "Total",
    allowSum: true,
  },
  { field: "Data", title: "Data" },
  {
    field: "Shenim",
    title: "Shenim",
  },
  { field: "Tipi", title: "Tipi" },
  { field: "Dep_Kodi", title: "Departament" },
];

const columnsSF = [
  { field: "Id", title: "Id" },
  { field: "Kodi", title: "Kodi" },
  { field: "Data", title: "Data" },
  { field: "Operator", title: "Operator" },
];

export { columns, columnsSF };
