// NewDesign.jsx
import React, { useEffect, useState, useRef, useContext } from "react";
import "./NewDesign.scss";
import NavBarSuperMarket from "./NavBarSuperMarket/NavBarSuperMarket";
import TabelaSupermarket from "./Tabela/TabelaSupermarket";
import ButtonsSup from "./Buttons/ButtonsSup";
import Faturat from "./FaturateFundit/Faturat";
import { Button } from "@mui/material";
import mainAxios from "../../../../services/axios";
import SearchArtikuj from "./ArtikujSupermarket/SearchArtikuj";
import ListaFaturave from "../../Restaurant/DialogButtons/ListaFaturave";
import { LoadingOverlayContext } from "../../../../components/LoadingOverlay";
import MbylljeTurni from "../../Restaurant/DialogButtons/MbylljeTurni";
import { useApi } from "../../../../components/context/NdermarrjeInfo";
import {
  getArkaList,
  getPerdorues,
  getMenyraPageses,
  getKlienti,
} from "../../../../store/globalSlice";

import { getArtikujPos, getPosByOperator } from "../../../../store/globalPos";
import { useDispatch, useSelector } from "react-redux";
import Clock from "./Clock/Clock";
import {
  shtoArtikull,
  calculateData,
} from "./NavBarSuperMarket/CalculateData/CalcData";

import { handleFiskalizimi } from "./handleFis/FiskalizimiHandler";
import { useUser } from "../../../../zustand/common";
import { useMyContext } from "../../../../components/context/MyStatePOSContext";
import { useToast } from "../../../../components/context/ToastContext";

const NewDesign = ({ userAutomatikisht, setUserAutomatikisht }) => {
  const { state, setState, defaultState } = useMyContext();

  const { konfigurimeRedux } = useApi();
  const ndermarrje = konfigurimeRedux[0];
  const { user } = useUser();
  const nipt = user.nipt;
  const operator = user.username;
  const [currentId, setCurrentId] = useState("");
  const [itemDetails, setItemDetails] = useState([]);
  const [rows, setRows] = useState([]);
  const [fromListaa, setFromLista] = useState(false);
  const [itemsData, setItemsData] = useState([]);
  const [selectedIndex, setSelectedIndex] = useState("");
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const showToast = useToast();
  const dispatch = useDispatch();

  // redux artikuj (get requesti i artikujve)
  const artikujPos = useSelector((state) => state.globalPosSlice.artikujPos);
  const [art, setArt] = useState([]);

  useEffect(() => {
    // Filter and set data only if artikujPos has data
    if (artikujPos.length > 0) {
      try {
        setArt(artikujPos);
      } catch (error) {
        console.error("Error filtering data:", error);
      }
    }
  }, [artikujPos]);

  const arkaList = useSelector((state) => state.globalSlice.arkaList);
  const [arka, setArka] = useState([]);

  useEffect(() => {
    // Filter and set data only if artikujPos has data
    if (arkaList.length > 0) {
      try {
        const filteredArka = arkaList.filter((item) => item.TCRCode);
        setArka(filteredArka);
      } catch (error) {
        console.error("Error filtering data:", error);
      }
    }
  }, [arkaList]);

  const menyraPageses = useSelector((state) => state.globalSlice.menyraPageses);
  const [menyra, setMenyra] = useState([]);

  useEffect(() => {
    // Filter and set data only if menyraPageses has data
    if (menyraPageses.length > 0) {
      try {
        setMenyra(menyraPageses);
      } catch (error) {
        console.error("Error filtering data:", error);
      }
    }
  }, [menyraPageses]);

  const handleOpenListaFaturave = (event, index) => {
    setSelectedIndex(index);
  };

  // redux operator faturat(get requesti i faturave x operator by Id)
  const perdoruesData = useSelector(
    (state) => state.globalPosSlice.posByOperator
  );

  const fetchDataByOperator = async () => {
    try {
      showLoading();
      await dispatch(getArkaList("/arka/list"));
      const response = await dispatch(
        getPosByOperator(`/pos/filter?operator=${operator}`)
      );

      if (response.payload.status === 204) {
        handleShtim();
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    } finally {
      hideLoading();
    }
  };

  useEffect(() => {
    fetchDataByOperator();
  }, [dispatch, operator]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        await dispatch(getPerdorues("/get/perdoruesi"));
        await dispatch(getMenyraPageses("/menyra-pageses"));
        await dispatch(getArtikujPos("/pos/art?page=1&page_size=100"));
        await dispatch(getArkaList("/arka/list"));
        await dispatch(getKlienti("/klienti"));
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        hideLoading();
      }
    };

    fetchData();
  }, [dispatch, operator]);

  // New useEffect to call fetchPosSupermarket when perdoruesData is populated
  useEffect(() => {
    if (perdoruesData && perdoruesData.length > 0) {
      fetchPosSupermarket();
    }
  }, [perdoruesData]);

  let idNumber = currentId;

  const handleShtim = async () => {
    try {
      showLoading();
      const response = await mainAxios.post("/pos", defaultState, {
        headers: { "Content-Type": "application/json" },
      });
      if (response.status === 200) {
        dispatch(getPosByOperator(`/pos/filter?operator=${operator}`));
      }
    } catch (error) {
      console.error(error);
      showToast("Problem me krijimin e fatures", { severity: "error" });
    } finally {
      hideLoading();
    }
  };

  const fetchPosSupermarket = () => {
    try {
      showLoading();
      // Pass on dataID (FATURAT E FUNDIT)
      const dataID = perdoruesData.map((item) => item.Id);
      setItemsData(dataID);

      handleItemClick(dataID[0]);
    } catch (error) {
      console.error("Error in fetching pos data", error);
    } finally {
      hideLoading();
    }
  };

  const updateItemDetails = (updatedItemDetails) => {
    setItemDetails(updatedItemDetails);
  };

  // Function to handle item click
  const handleItemClick = async (itemId) => {
    try {
      showLoading();
      setCurrentId(itemId);
      // Make GET request with the specific itemId
      const response = await mainAxios(`/pos/${itemId}`);
      if (response.status === 200) {
        setState(response.data);

        const artikuj = await mainAxios(`/posdtl/master/${itemId}`);
        setRows(artikuj.data);
      }
    } catch (error) {
      console.error("Error in fetching item data", error);
    } finally {
      hideLoading();
    }
  };

  const handleFiskalizimiFunc = (handlePrint2, fromLista) => {
    handleFiskalizimi(handlePrint2, fromLista, {
      fetchDataByOperator,
      showLoading,
      setState,
      hideLoading,
      showToast,
      idNumber,
      rows,
      setFromLista,
      state,
      handleItemClick,
      fetchPosSupermarket,
    });
  };

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, currentId, showToast, operator);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item);
  };

  return (
    <>
      <NavBarSuperMarket
        nipt={nipt}
        operator={operator}
        userAutomatikisht={userAutomatikisht}
        setUserAutomatikisht={setUserAutomatikisht}
      />
      <div className="flex main_div_inside_supermarket">
        <TabelaSupermarket
          newDesign={true}
          itemDetails={itemDetails}
          rows={rows}
          setRows={setRows}
          calculateData={handleCalculateData}
        />
        <SearchArtikuj
          shtoArtikull={handleShtoArtikull}
          art={art}
          setArt={setArt}
          artikujPos={artikujPos}
        />

        <div className="flex rightbar_supermarket">
          <div>
            <ButtonsSup
              fetchDataByOperator={fetchDataByOperator}
              idNumber={currentId}
              setCurrentId={setCurrentId}
              updateItemDetails={updateItemDetails}
              itemDetails={itemDetails}
              setRows={setRows}
              handleShtim={handleShtim}
              setItemDetails={setItemDetails}
              state={state}
              setState={setState}
              rows={rows}
              fetchPosSupermarket={fetchPosSupermarket}
              operator={operator}
              nipt={nipt}
              showToast={showToast}
              handleFiskalizimi={handleFiskalizimiFunc}
              menyra={menyra}
              arka={arka}
              konfigurimeRedux={ndermarrje}
            />
          </div>

          <div className="faturat_supermarket">
            <Faturat
              fetchDataByOperator={fetchDataByOperator}
              fetchPosSupermarket={fetchPosSupermarket}
              setCurrentId={setCurrentId}
              itemDetails={itemDetails}
              currentId={currentId}
              setItemDetails={setItemDetails}
              setRows={setRows}
              setState={setState}
              handleItemClick={handleItemClick}
              itemsData={itemsData}
            />
          </div>
          <div className="id_supermarket">
            <h2>Fatura ID: {idNumber ? idNumber : "N/A"}</h2>
          </div>
        </div>
      </div>
      <ListaFaturave
        fetchDataByOperator={fetchDataByOperator}
        operator={operator}
        open={selectedIndex === 1}
        nipt={nipt}
        onClose={() => {
          setSelectedIndex("");
          setState(defaultState);
        }}
        handleFiskalizimi={handleFiskalizimiFunc}
        menyra={menyra}
        state={state}
        setState={setState}
        fromListaa={fromListaa}
        setFromLista={setFromLista}
      />

      <MbylljeTurni
        fetchPosSupermarket={fetchDataByOperator}
        isFromMarket={true}
        operator={operator}
        open={selectedIndex === 0}
        onClose={() => {
          setSelectedIndex("");
          setState(defaultState);
        }}
        state={state}
      />
      <div className="footer_supermarket">
        <div className="flex">
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "5px" }}
            onClick={(event) => {
              handleOpenListaFaturave(event, 1);
            }}
          >
            Faturat
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "5px" }}
            onClick={(event) => {
              handleOpenListaFaturave(event, 0);
            }}
          >
            Mbyllje Turni
          </Button>
        </div>
        <Clock />
      </div>
    </>
  );
};

export default NewDesign;
