import React, { useRef } from "react";
import Dialog from "@mui/material/Dialog";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { useState } from "react";
// import ChevronRightIcon from "@mui/icons-material/ChevronRight";
// import OpenInFullIcon from "@mui/icons-material/OpenInFull";
// import CloseFullscreenIcon from "@mui/icons-material/CloseFullscreen";

import "../../fatureShitje/EInvoice/EInvoice.scss";
import List from "../../fatureShitje/EInvoice/List";
import { useToast } from "../../../components/context/ToastContext";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function RivlersimiKursit({ open, onClose, isMobile }) {
  const showToast = useToast();
  //   const [isFullscreen, setIsFullscreen] = useState(false);

  const [rows, setRows] = useState([]);

  //   const toggleFullscreen = () => {
  //     if (!document.fullscreenElement) {
  //       document.documentElement.requestFullscreen().then(() => {
  //         setIsFullscreen(true);
  //       });
  //     } else {
  //       document.exitFullscreen().then(() => {
  //         setIsFullscreen(false);
  //       });
  //     }
  //   };

  return (
    <React.Fragment>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
      >
        <AppBar
          sx={{
            position: "relative",
            backgroundColor: "#ececec",
            boxShadow: "none",
            color: "#000",
            height: "6%",
          }}
        >
          <Toolbar style={{ justifyContent: "space-between" }}>
            <Typography
              sx={{
                ml: 2,
                display: "flex",
                flexDirection: "row",
                color: "#000",
                fontWeight: "600",
              }}
              component="div"
            >
              <h2>Rivlersimi Kursit</h2>
            </Typography>

            {/* {isMobile ? (
              ""
            ) : (
              <IconButton
                onClick={() => {
                  toggleFullscreen();
                }}
                sx={{ mr: 1 }}
              >
                {!isFullscreen ? (
                  <OpenInFullIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                    }}
                  />
                ) : (
                  <CloseFullscreenIcon
                    sx={{
                      width: 25,
                      height: 25,
                      color: "#000",
                      backgroundColor: "#dbdbdbaf",
                      borderRadius: "10%",
                    }}
                  />
                )}
              </IconButton>
            )} */}
            <IconButton
              edge={false}
              color="inherit"
              onClick={onClose}
              aria-label="close"
              sx={{
                width: 30,
                height: 30,
                color: "#000",
                backgroundColor: "#dbdbdbaf",
                borderRadius: "10%",
              }}
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div className="mainComponent_fature_thjeshtuar">
          <div className="main_fature_thjeshtuar">
            <List showToast={showToast} setRows={setRows} rows={rows} />
          </div>
        </div>
      </Dialog>
    </React.Fragment>
  );
}
