// project import
import NavCard from "./NavCard";
import Navigation from "./Navigation";
import SimpleBar from "../../../../components/third-party/SimpleBar";

// ==============================|| DRAWER CONTENT ||============================== //

export default function DrawerContent() {
  return (
    <>
      <SimpleBar
        style={{
          maxHeight: "100%", // Ensures the content fits within the viewport height
          overflowY: "auto", // Enables vertical scrolling
        }}
        sx={{
          "& .simplebar-content": {
            display: "flex",
            flexDirection: "column",
          },
        }}
      >
        <Navigation />
        <NavCard />
      </SimpleBar>
    </>
  );
}
