import React, { useContext } from "react";
import { Button } from "@mui/material";
import RemoveCircleIcon from "@mui/icons-material/RemoveCircle";
import mainAxios from "../../../services/axios";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useToast } from "../../../components/context/ToastContext";

const AnullimFature = ({ selectedId, currentFatura, functionPost }) => {
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const showToast = useToast();
  const handleFis = async (Id) => {
    showLoading();
    try {
      const response = await mainAxios.post(`/pos/${Id}`);

      const firstItem = response.data;
      if (firstItem?.ErrorCode !== "0") {
        const faultstring = firstItem.faultstring;

        showToast(faultstring, {
          severity: "error",
        });
      }
    } catch (error) {
      console.error("Error fiskalizimi:", error);
    } finally {
      hideLoading();
      functionPost();
    }
  };

  const handleAnullim = async (selectedId) => {
    try {
      const response = await mainAxios(`/pos/anullim/${selectedId}`);
      if (response.status === 201) {
        await handleFis(response.data.Id); // Ensure handleFis is awaited
      }
    } catch (error) {
      console.error("An error occurred:", error);

      showToast("Gabim gjate anullimit", {
        severity: "info",
      });
    }
  };

  const functionToAnullim = () => {
    if (selectedId === "") {
      showToast("Selekto nje Fature", {
        severity: "info",
      });
      return;
    }
    if (!currentFatura.NIVF) {
      showToast("Fatura eshte e pa fiskalizuar", {
        severity: "info",
      });
      return;
    }
    if (currentFatura.TIPI === "_BAK") {
      showToast("Fature eshte e mbyllur nuk mund te anullohet", {
        severity: "info",
      });
      return;
    } else {
      handleAnullim(selectedId);
    }
  };

  return (
    <Button
      disabled={!selectedId}
      variant="contained"
      startIcon={<RemoveCircleIcon style={{ color: "#fff" }} />}
      onClick={functionToAnullim}
      style={{ margin: "5px" }}
    >
      Anullo Faturen
    </Button>
  );
};

export default AnullimFature;
