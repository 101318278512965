import React, { useEffect, useState, memo, useContext } from "react";
import PrimeGrid from "../../../components/primeReact/PrimeGrid";
import mainAxios from "../../../services/axios";
import NavBar from "../../../components/Navbar/NavBar";
import SideBar from "../../../components/Navbar/SideBar";
import MainTab from "../../fature-blerje/tabet/MainTab/MainTab";
import PaneliTabeve from "../../fature-blerje/tabet/PaneliTabeve";
import PaneliTab2 from "../../fature-blerje/tabet/PaneliTab2";
import { useDispatch } from "react-redux";
import PrintoPdf1 from "../../../components/ReactPDF/PrintoPdf1";
import "../../fature-blerje/FatureBlerje.scss";
import PostAddIcon from "@mui/icons-material/PostAdd";
import AutoAwesomeMotionIcon from "@mui/icons-material/AutoAwesomeMotion";
import DeleteIcon from "@mui/icons-material/Delete";
import ClearIcon from "@mui/icons-material/Clear";
import AppRegistrationIcon from "@mui/icons-material/AppRegistration";
import ModeEditIcon from "@mui/icons-material/ModeEdit";
import { LoadingOverlayContext } from "../../../components/LoadingOverlay";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import {
  shtoArtikull,
  calculateData,
} from "../../dynamicComponent/ArtikullCalc";
import {
  getNextKodiVeprimeKontabel,
  getIndexFirstVeprimeKontabel,
  getMonedha,
} from "../../../store/globalSlice";
import { formatDate } from "../../fatureShitje/Function/formatDate";
import { handleAdd, handleModify } from "../../fatureShitje/Function/handleAdd";
import { handleKlientiType } from "../../fatureShitje/Function/handleKlienti";
import { useFtshContext } from "../../../components/context/FtshState";
import { useUser } from "../../../zustand/common";
import { columns, columnsSF } from "./Columns";
import TopPanelFsh from "../../fatureShitje/Panel/TogPanelFsh";
import handleRegister from "../../fatureShitje/Function/RegisterButton";
import { TextField, createFilterOptions, Autocomplete } from "@mui/material";

const VeprimeKontabel = ({
  showToast,
  hidePupUp,
  setHidePupUp,
  currentAction,
  setCurrentAction,
  isMobile,
  disabled,
  setDisabled,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { user } = useUser();
  const username = user.username;
  const nipt = user.nipt;
  const {
    state,
    setState,
    defaultState,
    klienti,
    monedhat,
    klientiRedux,
    menyrapageses,
    arka,
    qytetet,
    shtetet,
  } = useFtshContext();
  const dispatch = useDispatch();
  const [bankaInfo, setBankaInfo] = useState({});
  const [fatureDisabled, setFatureDisabled] = useState(true);
  const [fisVerificationUrl, setFisVerificationUrl] = useState("");
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [tipiDTL, setTipiDTL] = useState("");
  const [rightDrawerOpen, setRightDrawerOpen] = useState(false);
  const [visible, setVisible] = useState(false);
  const [nxtBtnDisable, setNxtBtnDisable] = useState(false);
  const [prvBtnDisable, setPrvBtnDisable] = useState(false);
  const [isPanelToggleable, setIsPanelToggleable] = useState(false);
  const [currentId, setCurrentId] = useState("");
  const [lastId, setLastId] = useState("");
  const [deletedRowIds, setDeletedRowIds] = useState([]);
  const [tipiArkes, setTipiArkes] = useState(null);

  const location = useLocation();
  const { nmrFB, fromArka } = location.state || {};

  useEffect(() => {
    if (fromArka) {
      setNumber(nmrFB);
      fetchFShitje(nmrFB);
    } else {
      fetchFShitje(number);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCloseRightDrawer = () => {
    setRightDrawerOpen(false);
  };

  // redux klienti (get requesti i klientit)
  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  // redux mer indexin e fatures se pare
  const firstFatureIndex = useSelector(
    (state) => state.globalSlice.getFirstVeprimeKontabel
  );

  const [selectedArkaMon, setSelectedArkaMon] = useState(
    arka && arka[0] ? arka[0].Mon : ""
  );

  // Ky funksion therritet kur butoni "Shtim" klikohet
  const onAddClick = async () => {
    if (!monedhat || monedhat.length === 0) {
      dispatch(getMonedha("/monedha"));
    }

    setCurrentAction("add");
    setCurrentId(lastId);
    const response = await dispatch(
      getNextKodiVeprimeKontabel("/veprimekontabel/nextKodi")
    );

    if (response.payload && response.payload.new_Kodi) {
      setState((prevState) => ({
        ...prevState,
        Kodi: response.payload.new_Kodi,
      }));
    }
  };

  // Ky funksion therritet kur butoni "Modifiko" klikohet
  const onModifyClick = () => {
    setCurrentAction("modify");
  };

  const [rows, setRows] = useState([]);

  const [index, setIndex] = useState(0);

  const defaultColumnsStorage = columns.map((column) => column.title);

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(artikull, rows, setRows, setTipiDTL, state);
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const [number, setNumber] = useState(1);
  const [searchString, setSearchString] = useState("");
  const [responseData, setResponseData] = useState([]);
  const [lastObject, setLastObject] = useState(null);

  const handleChangeValue = (newValue) => {
    setCurrentId(newValue);
    if (newValue) {
      setFatureDisabled(false);
    } else {
      setFatureDisabled(true);
    }
  };

  const fetchFShitje = async (number) => {
    try {
      showLoading();

      const response = await mainAxios(`/last/veprimekontabel/${number}`);
      const responseTotalRows = await dispatch(
        getIndexFirstVeprimeKontabel("/first/veprimekontabel")
      );
      const total_data_num = responseTotalRows.payload.total_rows;

      // nqs ska data empty [] make default display no data
      if (!response.data || Object.keys(response.data).length === 0) {
        // If there's no data in the response, handle it accordingly
        setFatureDisabled(true);
        setRows([]);
        setCurrentId(null);
        if (total_data_num === 0 || total_data_num === 1) {
          setNxtBtnDisable(true);
          setPrvBtnDisable(true);
        }
        return;
      }

      const responseData = response.data;
      handleChangeValue(response.data.Id);

      if (responseData.NIVF === null) {
        setFatureDisabled(false);
      } else {
        setFatureDisabled(true);
      }
      if (number > 1) {
        setPrvBtnDisable(false);
      } else if (number <= 1) {
        setPrvBtnDisable(true);
      }
      if (number === 1) {
        setPrvBtnDisable(true);
      }
      if (number !== total_data_num) {
        setNxtBtnDisable(false);
      } else {
        setNxtBtnDisable(true);
      }

      if (responseData) {
        // Fetch detailed data here
        const dtlResponse = await mainAxios(
          `/veprimekontabel/dtl/${responseData.Id}`
        );
        const dtlData = dtlResponse.data;

        // setState(responseData || "");
        setState({ ...responseData });
        setResponseData(responseData);
        setLastObject(responseData);
        setFisVerificationUrl(responseData.fisVerificationUrl);
        setRows(dtlData);
        setNumber(number);
        if (number === 1) {
          setLastId(response.data.Id + 1);
        }
      }
    } catch (err) {
      console.error(err);
    } finally {
      hideLoading();
    }
  };

  const shikoFaturen = (type) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }

    const currentNumber = Number(number);
    if (type === "first") {
      setNumber(firstFatureIndex.total_rows);
      fetchFShitje(firstFatureIndex.total_rows);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "increment") {
      setNumber(number + 1);
      const nextNumber = currentNumber + 1;
      fetchFShitje(nextNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "decrement") {
      setNumber(number - 1);
      const prevNumber = currentNumber - 1;
      fetchFShitje(prevNumber);
      setDisabled(true);
      setButtonClicked(false);
    }

    if (type === "last") {
      setNumber(1);
      fetchFShitje(1);
      setDisabled(true);
      setButtonClicked(false);
    }
  };

  let numberIndex;
  const handleTestiChange = async (newTesti) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim.");
      return;
    }
    try {
      //gjendet numri i fatures me Id = x
      const indexResponse = await mainAxios.get(
        `/veprimekontabel/index/${newTesti.Id}`
      );

      if (indexResponse.status === 200) {
        numberIndex = indexResponse.data.index;
        fetchFShitje(numberIndex);
        showToast("Fatura u zgjodh", { hideProgressBar: true });
      }
    } catch (error) {
      showToast("Error ne zgjedhjen e fatures.", { severity: "error" });
      console.error("Error fetching data:", error);
    } finally {
      setVisible(false);
    }
  };

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const handleFunctionKlienti = (event, newValue) => {
    handleKlientiType(event, newValue, setSearchString, klientiRedux, setState);
  };

  const handleFunctionAdd = () => {
    handleAdd(
      showLoading,
      state,
      username,
      setState,
      defaultState,
      setDisabled,
      setButtonClicked,
      setSearchString,
      showToast,
      rows,
      tipiDTL,
      tipiArkes,
      fetchFShitje,
      setCurrentAction,
      hideLoading,
      number,
      currentId,
      rows
    );
  };

  const handleFunctionModify = () => {
    handleModify(
      showLoading,
      state,
      username,
      setState,
      defaultState,
      setDisabled,
      setButtonClicked,
      setSearchString,
      showToast,
      rows,
      tipiDTL,
      tipiArkes,
      fetchFShitje,
      setCurrentAction,
      hideLoading,
      number,
      currentId,
      rows
    );
  };

  const handleFunctionRegister = () => {
    handleRegister(
      state,
      showToast,
      tipiDTL,
      selectedArkaMon,
      rows,
      deletedRowIds,
      currentAction,
      handleFunctionAdd,
      handleFunctionModify,
      setDeletedRowIds
    );
  };

  const updateDepKodi = (newKodi) => {
    setState((prevState) => ({
      ...prevState,
      Dep_Kodi: newKodi,
    }));
  };

  const [toggleState, setToggleState] = useState(4);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const takeMonedha = monedhat.map((item) => item);

  const handleKursiType = (e) => {
    const value = e.target.value;
    const kursi = takeMonedha.find((item) => item.Kodi === value).Kursi;
    setState((state) => {
      return {
        ...state,
        Mon: value,
        Kursi: kursi,
      };
    });
  };

  useEffect(() => {
    // Update the Total for each row in the rows array
    const updatedRows = rows.map((row) => {
      return {
        ...row,
        Total: row.Vlera * state.Kursi,
      };
    });

    // Update the rows state with the updated rows
    setRows(updatedRows);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state.Kursi]);

  const anulloFature = () => {
    let excludeData = ["Data"];
    let hasValue = Object.keys(state)?.find(
      (key) => !excludeData.includes(key) && state[key] !== ""
    );
    if (hasValue) setHidePupUp(true);
    setDeletedRowIds([]);
    setSelectedArkaMon("");
  };

  // redux skema-veprimi (get requesti i veprimit)
  const veprimiRedux = useSelector((state) => state.globalSlice.veprimi);

  // Filter veprimiRedux based on Tabela value
  const fleteHyrjeObjects = veprimiRedux.filter(
    (item) => item.Tabela === "Flete_Hyrje"
  );

  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  const button1Text = isMobile ? "" : "Shtim";
  const button2Text = isMobile ? "" : "Modifikim";
  const button3Text = isMobile ? "" : "Fshije";
  const button4Text = isMobile ? "" : "Anullim";
  const button5Text = isMobile ? "" : "Regjistro";

  const PaneliKryesor = (
    <PaneliTabeve
      popupContextKey="Veprime Kontabel"
      contexDelete={"faturen"}
      onAddClick={onAddClick}
      fatureDisabled={fatureDisabled}
      onModifyClick={onModifyClick}
      handleRegister={handleFunctionRegister}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      currentId={currentId}
      setRows={setRows}
      index={index}
      setIndex={setIndex}
      responseData={responseData}
      setResponseData={setResponseData}
      lastObject={lastObject}
      setLastObject={setLastObject}
      fetchFShitje={fetchFShitje}
      setHidePupUp={setHidePupUp}
      hidePupUp={hidePupUp}
      toggleTab={toggleTab}
      anulloFature={anulloFature}
      buttonClicked={buttonClicked}
      setButtonClicked={setButtonClicked}
      button1Text={button1Text}
      button1Icon={<PostAddIcon />}
      button2Text={button2Text}
      button2Icon={<ModeEditIcon />}
      button3Text={button3Text}
      button3Icon={<DeleteIcon />}
      button4Text={button4Text}
      button4Icon={<ClearIcon />}
      button5Text={button5Text}
      button5Icon={<AppRegistrationIcon />}
      number={number}
      isMobile={isMobile}
      setCurrentAction={setCurrentAction}
    />
  );

  const button6Text = isMobile ? "" : "Tjera";

  const PaneliDytesor = (
    <PaneliTab2
      includeButton6={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<AutoAwesomeMotionIcon />}
      isMobile={isMobile}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab4 */}
      <div className={"content active-content"}>
        <div className="grid grid-cols-2 gap-2">
          {/* <Form.Select
              disabled={disabled}
              label="Veprimi"
              className="bg-white"
              value={state?.Veprimi}
              onChange={(e) => {
                handleChange("Veprimi", e.target.value);
              }}
            >
              <option label="Veprim" />
              {veprimi.map((kodiValue) => {
                return (
                  <option key={kodiValue} value={kodiValue}>
                    {kodiValue}
                  </option>
                );
              })}
            </Form.Select> */}

          <Autocomplete
            disablePortal
            disabled={disabled}
            id="combo-box-demo13"
            options={fleteHyrjeObjects}
            filterOptions={filterOptions}
            getOptionLabel={(option) =>
              option ? option.Kodi + " - " + option.Pershkrim : ""
            }
            onChange={(e, newValue) =>
              handleChange("Veprimi", newValue ? newValue.Kodi : "")
            }
            value={
              fleteHyrjeObjects.find(
                (qytet) => qytet.Kodi === state?.Veprimi
              ) || null
            }
            size="small"
            style={{ width: "100%" }}
            renderInput={(params) => <TextField {...params} label="Veprimi" />}
            renderOption={(props, option) => (
              <div
                style={{ display: "flex", justifyContent: "space-between" }}
                {...props}
              >
                <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
              </div>
            )}
          />

          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim 1"
            value={state?.Shenim_1 || ""}
            onChange={(e) => handleChange("Shenim_1", e.target.value)}
            size="small"
          />

          <TextField
            disabled={disabled}
            type="text"
            variant="outlined"
            label="Shenim 2"
            value={state?.Shenim_2 || ""}
            onChange={(e) => handleChange("Shenim_2", e.target.value)}
            size="small"
          />
        </div>
      </div>
    </div>
  );

  const TabiKryesor = (
    <MainTab
      formatDate={formatDate}
      includeNrOrigjinesField={false}
      includeDateField={true}
      includeKursiField={true}
      includeMonedhaSelect={true}
      includeKodiField={true}
      includeSerialNumerField={true}
      includeModalList={true}
      disabled={disabled}
      state={state}
      handleChange={handleChange}
      handleKursiType={handleKursiType}
      fatureShitje={true}
    />
  );

  const BtnPrint = (
    <PrintoPdf1
      className="print"
      state={state}
      showToast={showToast}
      bankaInfo={bankaInfo}
      konfigurimeRedux={konfigurimeRedux}
      fromFShitje={true}
      rowsData={rows}
      fisVerificationUrl={fisVerificationUrl}
      menyrapageses={menyrapageses}
      nipt={nipt}
      username={username}
    />
  );

  return (
    <div className={`mainDiv_ftsh ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className={"template"}>
          <div className="main-container p-2">
            <TopPanelFsh
              fromVPK={false}
              smallTabsKM={smallTabsKM}
              gridKey="VeprimeKontabel"
              contextKey={"veprimekontabel"}
              fatureName="Veprime Kontabel"
              isPanelToggleable={isPanelToggleable}
              setIsPanelToggleable={setIsPanelToggleable}
              isMobile={isMobile}
              setBankaInfo={setBankaInfo}
              currentId={currentId}
              nxtBtnDisable={nxtBtnDisable}
              prvBtnDisable={prvBtnDisable}
              shikoFaturen={shikoFaturen}
              handleTestiChange={handleTestiChange}
              columnsSF={columnsSF}
              visible={visible}
              setVisible={setVisible}
              toggleState={4}
              disabled={disabled}
              state={state}
              handleChange={handleChange}
              setSearchString={setSearchString}
              searchString={searchString}
              handleFunctionKlienti={handleFunctionKlienti}
              qytetet={qytetet}
              shtetet={shtetet}
              setState={setState}
              klienti={klienti}
              setTipiArkes={setTipiArkes}
              setSelectedArkaMon={setSelectedArkaMon}
              rows={rows}
              menyrapageses={menyrapageses}
              tipiArkes={tipiArkes}
              buttonClicked={buttonClicked}
              formatDate={formatDate}
              updateDepKodi={updateDepKodi}
              fetchFShitje={fetchFShitje}
              PaneliKryesor={PaneliKryesor}
              PaneliDytesor={PaneliDytesor}
              BtnPrint={BtnPrint}
              TabiKryesor={TabiKryesor}
            />

            <div className={"middle_fsh"}></div>

            <div className="dataTable_fsh">
              <PrimeGrid
                deletedRowIds={deletedRowIds}
                setDeletedRowIds={setDeletedRowIds}
                gridKey="VeprimeKontabel"
                isEditable={true}
                columns={columns}
                disabled={disabled}
                defaultColumnsStorage={defaultColumnsStorage}
                rows={rows}
                setRows={setRows}
                calculateData={handleCalculateData}
                shtoArtikull={handleShtoArtikull}
                currentAction={currentAction}
                currentId={currentId}
              />
            </div>
          </div>
        </div>
        {/* {!isMobile && <Footer className="footer-position" />} */}
      </div>
    </div>
  );
};

export default memo(VeprimeKontabel);
