import React from "react";
import { TextField, Autocomplete, createFilterOptions } from "@mui/material";
import "./Fiskalizimi.scss";
import { Checkbox } from "primereact/checkbox";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import QRCode from "qrcode.react";

const FiskalizimiDalje = ({
  disabled,
  state,
  setState,
  formatDate,
  fisVerificationUrl,
  handleChange,
}) => {
  const filterOptions = createFilterOptions({
    matchFrom: "any",
    stringify: (option) => `${option.Kodi} ${option.Pershkrim}`,
  });

  // QR code to fis URL
  const doubleClick = async (fisVerificationUrl) => {
    try {
      if (fisVerificationUrl === null) {
        return;
      } else {
        window.open(fisVerificationUrl, "_blank");
      }
    } catch (error) {
      console.error("Error :", error);
    }
  };

  const handleChangeAktiv = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "fisIsGoodsFlammable" ? (value ? 1 : 0) : value,
      };
    });
  };

  const handleChangeShoqerimi = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: key === "fisIsEscortRequired" ? (value ? 1 : 0) : value,
      };
    });
  };

  const handleChangeFisIsCorrectiveInvoice = (fieldName, value) => {
    setState((prevState) => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  const lloji = [
    {
      Kodi: "0",
      Pershkrim: "Fature Shoqeruese Pa ndryshuar pronesine",
    },
    { Kodi: "1", Pershkrim: "Fature Shoqeruese me ndryshime pronesie" },
  ];

  const transaksioni = [
    {
      Kodi: "0",
      Pershkrim: "Shitje",
    },
    { Kodi: "1", Pershkrim: "Ekzaminim" },
    { Kodi: "2", Pershkrim: "Transferim" },
    { Kodi: "3", Pershkrim: "Shitje dere me dere" },
  ];

  const startPoint = [
    {
      Kodi: "0",
      Pershkrim: "Magazine",
    },
    { Kodi: "1", Pershkrim: "Ekspozite" },
    { Kodi: "2", Pershkrim: "Dyqan" },
    { Kodi: "3", Pershkrim: "Pika e shitjes" },
    { Kodi: "4", Pershkrim: "Magazina e nje personi tjeter" },
    { Kodi: "5", Pershkrim: "Magazina doganore" },
    { Kodi: "6", Pershkrim: "Tjetër" },
  ];

  const destPoint = [
    {
      Kodi: "0",
      Pershkrim: "Magazinë",
    },
    { Kodi: "1", Pershkrim: "Ekspozitë" },
    { Kodi: "2", Pershkrim: "Dyqan" },
    { Kodi: "3", Pershkrim: "Pika e shitjes" },
    { Kodi: "4", Pershkrim: "Tjetër" },
  ];

  return (
    <>
      <Grid
        container
        direction="column"
        justifyContent="center"
        alignItems="center"
        sx={{ width: "100%", height: "100%" }}
        rowSpacing={7}
        columnSpacing={{ xs: 3 }}
      >
        <Grid container item xs={12} rowSpacing={1} columnSpacing={{ xs: 1 }}>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo26"
                sx={{ width: "100%" }}
                options={lloji}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisWtnType",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  state?.fisWtnType === null
                    ? null
                    : lloji.find(
                        (qytet) =>
                          Number(qytet.Kodi) === Number(state?.fisWtnType)
                      ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Lloji"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    {...props}
                  >
                    <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                    <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                  </div>
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo27"
                sx={{ width: "100%" }}
                options={transaksioni}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisTransaction",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  state?.fisTransaction === null
                    ? null
                    : transaksioni.find(
                        (qytet) =>
                          Number(qytet.Kodi) === Number(state?.fisTransaction)
                      ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Transaksioni"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    {...props}
                  >
                    <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                    <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo28"
                sx={{ width: "100%" }}
                options={startPoint}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisStartPoint",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  state?.fisStartPoint === null
                    ? null
                    : startPoint.find(
                        (qytet) =>
                          Number(qytet.Kodi) === Number(state?.fisStartPoint)
                      ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Sart Point"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    {...props}
                  >
                    <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                    <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              label="Start Address"
              variant="outlined"
              size="small"
              value={state?.fisStartAddress || ""}
              onChange={(e) =>
                handleChangeFisIsCorrectiveInvoice(
                  "fisStartAddress",
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              label="Start City"
              variant="outlined"
              size="small"
              value={state?.fisStartCity || ""}
              onChange={(e) =>
                handleChangeFisIsCorrectiveInvoice(
                  "fisStartCity",
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disablePortal
                disabled={disabled}
                id="combo-box-demo29"
                sx={{ width: "100%" }}
                options={destPoint}
                filterOptions={filterOptions}
                getOptionLabel={(option) => (option ? option.Pershkrim : "")}
                onChange={(e, newValue) =>
                  handleChangeFisIsCorrectiveInvoice(
                    "fisDestinationPoint",
                    newValue ? newValue.Kodi : null
                  )
                }
                value={
                  state?.fisDestinationPoint === null
                    ? null
                    : destPoint.find(
                        (qytet) =>
                          Number(qytet.Kodi) ===
                          Number(state?.fisDestinationPoint)
                      ) || null
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="Dest Point"
                    variant="outlined"
                    size="small"
                  />
                )}
                renderOption={(props, option) => (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-between",
                    }}
                    {...props}
                  >
                    <div style={{ textAlign: "start" }}>{option.Kodi}</div>
                    <div style={{ textAlign: "end" }}>{option.Pershkrim}</div>
                  </div>
                )}
              />
            </div>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              label="Dest Address"
              variant="outlined"
              size="small"
              value={state?.fisDestinationAddress || ""}
              onChange={(e) =>
                handleChangeFisIsCorrectiveInvoice(
                  "fisDestinationAddress",
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              label="Dest City"
              variant="outlined"
              size="small"
              value={state?.fisDestinationCity || ""}
              onChange={(e) =>
                handleChangeFisIsCorrectiveInvoice(
                  "fisDestinationCity",
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid
            container
            direction="row"
            justifyContent="center"
            alignItems="center"
            xs={12}
            style={{ marginTop: 5 }}
            rowSpacing={2}
            columnSpacing={{ xs: 2 }}
          >
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={disabled}
                type="date"
                label="Start Date"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={formatDate(state?.fisStartDateTime)}
                onChange={(e) =>
                  handleChange("fisStartDateTime", e.target.value)
                }
                size="small"
              />
            </Grid>
            <Grid item xs={6}>
              <TextField
                fullWidth
                disabled={disabled}
                type="date"
                label="End Date"
                InputLabelProps={{
                  shrink: true,
                }}
                variant="outlined"
                value={formatDate(state?.fisDestinationDateTime)}
                onChange={(e) =>
                  handleChange("fisDestinationDateTime", e.target.value)
                }
                size="small"
              />
            </Grid>
          </Grid>

          <Grid item xs={12}>
            <TextField
              fullWidth
              disabled={disabled}
              label="Pack type"
              variant="outlined"
              size="small"
              value={state?.fisPackType || ""}
              onChange={(e) =>
                handleChangeFisIsCorrectiveInvoice(
                  "fisPackType",
                  e.target.value
                )
              }
            />
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={disabled}
              checked={state?.fisIsGoodsFlammable === 1}
              onChange={(e) =>
                handleChangeAktiv(
                  "fisIsGoodsFlammable",
                  e.target.checked ? 1 : 0
                )
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Materiale Djegese
            </label>
          </Grid>
          <Grid item xs={12}>
            <Checkbox
              disabled={disabled}
              checked={state?.fisIsEscortRequired === 1}
              onChange={(e) =>
                handleChangeShoqerimi(
                  "fisIsEscortRequired",
                  e.target.checked ? 1 : 0
                )
              }
            ></Checkbox>
            <label
              style={{ color: "#1971c2", marginLeft: "5px" }}
              htmlFor="cb2"
              className="p-checkbox-label"
            >
              Shoqerimi i mallrave
            </label>
          </Grid>

          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disabled={true}
                freeSolo
                value={state?.fisWtnic || ""}
                id="combo-box-demo23"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="fisWtnic"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className="Item">
              <Autocomplete
                disabled={true}
                freeSolo
                value={state?.fisFwtnic || ""}
                id="combo-box-demo24"
                sx={{ width: "100%" }}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label="fisFwtnic"
                    variant="outlined"
                    size="small"
                  />
                )}
              />
            </div>
          </Grid>
          <div style={{ margin: "20px 0 0 100px" }}>
            <Paper onClick={() => doubleClick(fisVerificationUrl)}>
              <QRCode value={fisVerificationUrl} />
            </Paper>
          </div>
        </Grid>
      </Grid>
    </>
  );
};

export default FiskalizimiDalje;
