import React, { useState, useRef } from "react";
import "./Ndermarrje.scss";
import { Button, ButtonGroup } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import mainAxios from "../../../services/axios";
import Checkbox from "@mui/material/Checkbox";
import { getKonfigurime } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useToast } from "../../../components/context/ToastContext";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const Ndermarrje = ({ isMobile }) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const [image, setImage] = useState(null);
  // const [age, setAge] = React.useState("");

  const konfigurimeRedux = useSelector(
    (state) => state.globalSlice.konfigurime
  );

  // const handleChangeDropDown = (event) => {
  //   setAge(event.target.value);
  // };

  const currentId = {
    Id: konfigurimeRedux[0]?.Id || "",
  };

  const [formData, setFormData] = useState({
    Nipt: konfigurimeRedux[0]?.Nipt || "",
    Pershkrim: konfigurimeRedux[0]?.Pershkrim || "",
    Logo: konfigurimeRedux[0].Logo || null,
    NrAmzes: konfigurimeRedux[0]?.NrAmzes || "",
    Adresa1: konfigurimeRedux[0]?.Adresa1 || "",
    Tel: konfigurimeRedux[0]?.Tel || "",
    Cel: konfigurimeRedux[0]?.Cel || "",
    Fax: konfigurimeRedux[0]?.Fax || "",
    www: konfigurimeRedux[0]?.www || "",
    Email: konfigurimeRedux[0]?.Email || "",
    Mon: konfigurimeRedux[0]?.Mon || "",
    Metvsh: konfigurimeRedux[0]?.Metvsh || false,
    PrintAccent: konfigurimeRedux[0]?.PrintAccent || false,
    BurimeNjerezore: konfigurimeRedux[0]?.BurimeNjerezore || "",
    BurimeNjerezoreEmail: konfigurimeRedux[0]?.BurimeNjerezoreEmail || "",
    Financier: konfigurimeRedux[0]?.Financier || "",
    FinancierEmail: konfigurimeRedux[0]?.FinancierEmail || "",
    Administrator: konfigurimeRedux[0]?.Administrator || "",
    AdministratorEmail: konfigurimeRedux[0]?.AdministratorEmail || "",
  });

  const handleChangeNdermarrje = async () => {
    const payload = {
      ...formData,
    };

    try {
      const response = await mainAxios.put(
        `/konfigurim/${currentId.Id}`,
        payload
      );

      if (response.status === 200) {
        showToast("U dergua me sukses", {
          severity: "success",
        });
        dispatch(getKonfigurime("/konfigurim"));
      }
    } catch (error) {
      console.error(error);
      showToast("Gabim ne dergimin e te dhenave", {
        severity: "error",
      });
    }
  };

  // Handle image upload and convert to base64
  // const handleImageUpload = (e) => {
  //   const file = e.target.files[0];
  //   if (file) {
  //     const reader = new FileReader();
  //     reader.onloadend = () => {
  //       const base64String = reader.result;

  //       // Remove the "data:image/png;base64," part of the base64 string
  //       const cleanedBase64 = base64String.replace(
  //         /^data:image\/\w+;base64,/,
  //         ""
  //       );

  //       // Set the cleaned base64 string to your state
  //       setImage(cleanedBase64);
  //       setFormData({ ...formData, Logo: cleanedBase64 });
  //     };
  //     reader.readAsDataURL(file);
  //   }
  // };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleMonedhaChange = (event) => {
    setFormData({ ...formData, Mon: event.target.value });
  };

  return (
    <>
      <div className="ndermarrje_dashboard">
        <Grid xs={8} container rowSpacing={4.5} columnSpacing={2.75}>
          {/* row 1 */}
          <Grid item xs={12} sx={{ mb: -3.25 }}>
            <Typography variant="h5">Ndermarrje</Typography>
          </Grid>

          <Grid item>
            <Grid container direction="row" sx={{ marginTop: 5 }}>
              {" "}
              <Box sx={{ flexGrow: 2 }}>
                <Grid container direction="column" spacing={1.5}>
                  <Grid item xs={8}>
                    <TextField
                      label="Pershkrim"
                      variant="outlined"
                      className="text_field_ndermarrje"
                      size="large"
                      value={formData.Pershkrim}
                      onChange={(e) =>
                        handleChange("Pershkrim", e.target.value)
                      }
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="NIPT"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.Nipt}
                      onChange={(e) => handleChange("Nipt", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Nr.Amzes"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.NrAmzes}
                      onChange={(e) => handleChange("NrAmzes", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="Adresa"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.Adresa1}
                      onChange={(e) => handleChange("Adresa1", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="tel"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.Tel}
                      onChange={(e) => handleChange("Tel", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      label="cel"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.Cel}
                      onChange={(e) => handleChange("Cel", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      required
                      label="fax"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.Fax}
                      onChange={(e) => handleChange("Fax", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      required
                      label="web"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.www}
                      onChange={(e) => handleChange("www", e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={8}>
                    <TextField
                      required
                      label="email"
                      variant="outlined"
                      size="large"
                      className="text_field_ndermarrje"
                      value={formData.Email}
                      onChange={(e) => handleChange("Email", e.target.value)}
                    />
                    <div className="flex justify-between mt-3 monedha_baze_checkbox">
                      <div className="flex">
                        <FormControl className="form_control_monedha">
                          <InputLabel id="monedha-label">
                            Monedha Baze
                          </InputLabel>
                          <Select
                            labelId="monedha-label"
                            value={formData.Mon}
                            onChange={handleMonedhaChange}
                            renderValue={(selected) =>
                              selected || "Select Monedha"
                            }
                          >
                            <MenuItem value="EUR">EUR</MenuItem>
                            <MenuItem value="USD">USD</MenuItem>
                            <MenuItem value="ALL">LEK</MenuItem>
                          </Select>
                        </FormControl>
                      </div>
                      <div className="flex checkbox_ndermarrje">
                        Biznes me tvsh{" "}
                        <Checkbox
                          {...label}
                          checked={formData.Metvsh}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              Metvsh: e.target.checked,
                            })
                          }
                        />
                      </div>
                    </div>
                    <div className="flex justify-between mt-2">
                      <div className="flex checkbox_ndermarrje">
                        Printo me 58mm{" "}
                        <Checkbox
                          {...label}
                          checked={formData.PrintAccent}
                          onChange={(e) =>
                            setFormData({
                              ...formData,
                              PrintAccent: e.target.checked,
                            })
                          }
                        />
                      </div>
                      <div></div>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            </Grid>
          </Grid>
        </Grid>
        <Grid xs={12} container rowSpacing={4.5} columnSpacing={2.75}>
          <Grid item xs={8} sx={{ mb: -3.25 }}>
            <Typography variant="h6" style={{ color: "blue" }}>
              Logo
            </Typography>

            <Grid item>
              <Grid container direction="row" sx={{ marginTop: 5 }}>
                {" "}
                <Box sx={{ flexGrow: 2 }}>
                  <Grid container direction="column" spacing={1.5}>
                    <Grid item xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px",
                        }}
                        className="text_field_ndermarrje"
                      >
                        {/* <input
                          type="file"
                          accept="image/*"
                          onChange={handleImageUpload}
                          style={{ flexGrow: 1 }}
                        /> */}

                        {/* Display image or empty box */}
                        <Box
                          sx={{
                            width: 200,
                            height: 200,
                            border: "1px solid #000",
                            backgroundImage: image ? `url(${image})` : "none",
                            backgroundSize: "cover",
                            backgroundPosition: "center",
                          }}
                        >
                          {formData.Logo && (
                            <img
                              src={`data:image/png;base64,${formData.Logo}`}
                              alt="Company Logo"
                              style={{
                                // width: "100%", // Make the image full width
                                // height: "100%", // Make the image full height
                                objectFit: "contain",
                              }}
                            />
                          )}
                        </Box>
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>

            <Typography variant="h6" style={{ color: "blue", marginTop: 5 }}>
              Administrata
            </Typography>

            <Grid item>
              <Grid container direction="row" sx={{ marginTop: 5 }}>
                {" "}
                <Box sx={{ flexGrow: 2 }}>
                  <Grid container direction="column" spacing={1.5}>
                    <Grid item xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px", // Space between the fields
                        }}
                        className="text_field_ndermarrje"
                      >
                        <TextField
                          label="Administrator"
                          variant="outlined"
                          size="large"
                          sx={{ flexGrow: 1 }} // Ensures equal width
                          value={formData.Administrator}
                          onChange={(e) =>
                            handleChange("Administrator", e.target.value)
                          }
                        />
                        <TextField
                          label="AdministratorEmail"
                          variant="outlined"
                          size="large"
                          sx={{ flexGrow: 1 }} // Ensures equal width
                          value={formData.AdministratorEmail}
                          onChange={(e) =>
                            handleChange("AdministratorEmail", e.target.value)
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px", // Space between the fields
                        }}
                        className="text_field_ndermarrje"
                      >
                        <TextField
                          label="Financier"
                          variant="outlined"
                          size="large"
                          sx={{ flexGrow: 1 }} // Ensures equal width
                          value={formData.Financier}
                          onChange={(e) =>
                            handleChange("Financier", e.target.value)
                          }
                        />
                        <TextField
                          label="FinancierEmail"
                          variant="outlined"
                          size="large"
                          sx={{ flexGrow: 1 }} // Ensures equal width
                          value={formData.FinancierEmail}
                          onChange={(e) =>
                            handleChange("FinancierEmail", e.target.value)
                          }
                        />
                      </Box>
                    </Grid>
                    <Grid item xs={8}>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          gap: "2px", // Space between the fields
                        }}
                        className="text_field_ndermarrje"
                      >
                        <TextField
                          label="Burime Njerzore"
                          variant="outlined"
                          size="large"
                          sx={{ flexGrow: 1 }} // Ensures equal width
                          value={formData.BurimeNjerezore}
                          onChange={(e) =>
                            handleChange("BurimeNjerezore", e.target.value)
                          }
                        />
                        <TextField
                          label="BurimeNjerezoreEmail"
                          variant="outlined"
                          size="large"
                          sx={{ flexGrow: 1 }} // Ensures equal width
                          value={formData.BurimeNjerezoreEmail}
                          onChange={(e) =>
                            handleChange("BurimeNjerezoreEmail", e.target.value)
                          }
                        />
                      </Box>
                    </Grid>
                  </Grid>
                </Box>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </div>
      <footer>
        <Grid item xs={8}>
          <ButtonGroup sx={!isMobile ? { marginTop: 5 } : { marginTop: 5 }}>
            <Button
              sx={{ marginRight: 6 }}
              variant="contained"
              component="span"
              onClick={handleChangeNdermarrje}
            >
              Ruaje
            </Button>
          </ButtonGroup>
        </Grid>
      </footer>
    </>
  );
};

export default Ndermarrje;
