import "./print.scss";
import React from "react";
import QRCode from "qrcode.react";

function SimpleTableSH({
  state,
  fisVerificationUrl,
  menyrapageses,
  nipt,
  username,
  konfigurimeRedux,
}) {
  // Find the Menyra Pageses entry by ID and return its description
  const pagesaPershkrim = menyrapageses.find(
    (menyraP) => menyraP.Id === state.Menyra_Pageses_ID
  )?.Pershkrim;

  // Safely access the first element of konfigurimeRedux
  const ndermarrje =
    Array.isArray(konfigurimeRedux) && konfigurimeRedux.length > 0
      ? konfigurimeRedux[0]
      : null;

  // Handle the case where ndermarrje might be null or undefined
  if (!ndermarrje) {
    console.error("konfigurimeRedux is either not an array or it is empty.");
    return null;
  }

  return (
    <table className="HeaderTable border">
      <thead>
        <tr className="funtior_table_head">
          <tr className="funtior_table_head_below">
            <tr className="kutiza_madhe">
              <td className="logo"></td>
              <td className="title">
                {state.NSLF ? <b>Fature Tatimore</b> : <b>Fature Porosi</b>}
                <br />
                <b>
                  NR:{" "}
                  {state?.fisInvoiceNumber
                    ? state?.fisInvoiceNumber
                    : state?.Kodi}
                </b>
              </td>
            </tr>
            <tr className="kutiza_madhe_dy">
              <td className="border-bottom-false kutiza_pare">
                <div className="solid-border">
                  Operator:{" "}
                  {state.fisOperatorCode
                    ? state.fisOperatorCode
                    : state.Operator}
                </div>
                <div className="solid-border">
                  Vendi i ushtrimit te biznesit: {ndermarrje.Adresa1}
                </div>
                {state.NSLF && (
                  <div className="solid-border">NSLF: {state.NSLF}</div>
                )}
                {state.NSLF && (
                  <div className="solid-border">NIVF: {state.NIVF}</div>
                )}
              </td>
              <td className="kutiza_dyte">
                <div className="solid-border">
                  Data: {state.Data ? state.Data.substring(0, 10) : "N/A"}
                </div>
                <div className="solid-border">NR: {state.Kodi}</div>
                <div className="solid-border">
                  Menyra e Pageses: {pagesaPershkrim}
                </div>
                <div className="solid-border">
                  Kartmonedha dhe monedha: {state.Mon}
                </div>
              </td>
            </tr>
          </tr>
          <div className="kutiza_qr_code">
            {state.NSLF && (
              <QRCode
                // style={{ width: 100, height: 100, padding: 1 }}
                value={fisVerificationUrl}
              />
            )}
          </div>
        </tr>
        <tr className="kutiza_madhe_dy border-true">
          {/* <tr className="kutiza_madhe_dy"> */}
          {/* Shitesi */}
          <td className="border-true-right kutiza_pare">
            <div className="solid-border">Shitesi: {ndermarrje.Pershkrim}</div>
            {/* <div className="solid-border">Adresa:</div> */}
            <div className="solid-border">NIPT: {nipt}</div>
            {/* <div className="solid-border">Telefon:</div> */}
          </td>
          {/* Klienti */}
          <td className=" kutiza_dyte">
            <div className="solid-border">Klienti: {state.KLFU_Pershkrim}</div>
            <div className="solid-border"> Adresa: {state.Qyteti_Kodi}</div>
            <div className="solid-border"> NIPT: {state.NIPT}</div>
            {/* <div className="solid-border"> Telefon:</div> */}
          </td>
          {/* </tr> */}
        </tr>
      </thead>
    </table>
  );
}

export default SimpleTableSH;
