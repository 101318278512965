import React from "react";
import { useNavigate } from "react-router-dom";
import { Sidebar } from "primereact/sidebar";
import { useUser } from "../../zustand/common";
import { useToast } from "../../components/context/ToastContext";

const SidebarCelje = ({ visible, onHide, currentAction }) => {
  const showToast = useToast();
  let navigate = useNavigate();
  const { userAutomatikishtFromCntext, userGrupId } = useUser();

  const changeTo = (path) => {
    if (currentAction === "modify" || currentAction === "add") {
      showToast("Kliko Anullim ose Rregjistrim", {
        severity: "info",
      });
      return;
    }

    navigate(path);
    onHide(); // Close the sidebar after navigation
  };

  const menuItems = [
    { label: "Klient", path: "/celje/klienti" },
    { label: "Furnitor", path: "/celje/furnitori" },
    { label: "Transportuesi", path: "/celje/transportuesi" },
    { label: "Magazina", path: "/celje/magazina" },
    { label: "Arka", path: "/celje/arka" },
    { label: "Banka", path: "/celje/banka" },
    { label: "Agjent", path: "/celje/agjent" },
  ];

  const filteredMenuItems =
    userAutomatikishtFromCntext === 1 || userGrupId === 1
      ? [...menuItems, { label: "Perdorues", path: "/celje/perdorues" }]
      : menuItems;

  return (
    <Sidebar visible={visible} position="right" onHide={onHide}>
      <h3 className="mt-4 tracking-wider font-normal text-lg border-b pb-1">
        Celje
      </h3>
      <div>
        {filteredMenuItems.map((item, index) => (
          <div className="flex justify-content-center mt-3 gap-2" key={index}>
            <button
              onClick={() => changeTo(item.path)}
              className="w-50 mr-2 border p-1 hover:bg-slate-400 bg-slate-200 hover:text-white transition-all rounded-md mt-2 cursor-pointer"
            >
              {item.label}
            </button>
          </div>
        ))}
      </div>
    </Sidebar>
  );
};

export default SidebarCelje;
