import React from "react";
import { Panel } from "primereact/panel";
import Template from "../../dynamicComponent/Template";
import TabsContainer from "../Tabs/TabsContainer";

const TopPanelFsh = ({
  isPanelToggleable,
  setIsPanelToggleable,
  isMobile,
  currentId,
  nxtBtnDisable,
  prvBtnDisable,
  shikoFaturen,
  handleTestiChange,
  columnsSF,
  visible,
  setVisible,
  toggleState,
  disabled,
  setBankaInfo,
  state,
  handleChange,
  setSearchString,
  searchString,
  handleFunctionKlienti,
  qytetet,
  shtetet,
  setState,
  klienti,
  setTipiArkes,
  setSelectedArkaMon,
  rows,
  menyrapageses,
  tipiArkes,
  buttonClicked,
  formatDate,
  updateDepKodi,
  fetchFShitje,
  PaneliKryesor,
  PaneliDytesor,
  BtnPrint,
  BtnFiskal,
  TabiKryesor,
  fatureName,
  contextKey,
  gridKey,
  fromVPK,
  smallTabsKM,
}) => {
  return (
    <div className="top_panel_fsh">
      <Panel
        headerTemplate={
          <Template
            options={{
              collapsed: isPanelToggleable,
              onTogglerClick: () => setIsPanelToggleable(!isPanelToggleable),
            }}
            isMobile={isMobile}
            fatureName={fatureName}
            currentId={currentId}
            createdBy={state.Operator}
            nxtBtnDisable={nxtBtnDisable}
            prvBtnDisable={prvBtnDisable}
            shikoFaturen={shikoFaturen}
            handleTestiChange={handleTestiChange}
            columnsSF={columnsSF}
            contextKey={contextKey}
            visible={visible}
            setVisible={setVisible}
            gridKey={gridKey}
            fatureshitje={true}
          />
        }
        toggleable
        collapsed={isPanelToggleable}
        onToggle={() => setIsPanelToggleable(!isPanelToggleable)}
        style={{ height: "100%" }}
      >
        <div className="bg-white" style={{ height: "100%" }}>
          {!isMobile ? (
            <>
              <div className="paneli-tabeve">
                <div className="paneli-tabeve1">{PaneliKryesor}</div>
                <div className="paneli-tabeve2">
                  {fromVPK ? smallTabsKM : PaneliDytesor}
                </div>
                <div className="paneli-fis-print">
                  {BtnPrint}
                  {BtnFiskal}
                </div>
              </div>
              <div className="below-tabs">
                <div className="below-tabs1">{TabiKryesor}</div>
                <div className="below-tabs2">
                  <TabsContainer
                    toggleState={toggleState}
                    disabled={disabled}
                    state={state}
                    setBankaInfo={setBankaInfo}
                    handleChange={handleChange}
                    setSearchString={setSearchString}
                    searchString={searchString}
                    handleFunctionKlienti={handleFunctionKlienti}
                    qytetet={qytetet}
                    shtetet={shtetet}
                    setState={setState}
                    klienti={klienti}
                    setTipiArkes={setTipiArkes}
                    setSelectedArkaMon={setSelectedArkaMon}
                    rows={rows}
                    menyrapageses={menyrapageses}
                    tipiArkes={tipiArkes}
                    buttonClicked={buttonClicked}
                    formatDate={formatDate}
                    updateDepKodi={updateDepKodi}
                    fetchFShitje={fetchFShitje}
                  />
                </div>
                <div className="below-tabs3"></div>
              </div>
            </>
          ) : (
            <>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px",
                }}
              >
                {PaneliKryesor}
                <div style={{ display: "flex" }}>{BtnFiskal}</div>
              </div>
              <div style={{ margin: "5px" }}>{TabiKryesor}</div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  margin: "5px",
                }}
              >
                {PaneliDytesor}
                {BtnPrint}
              </div>
              <div style={{ margin: 5 }}>
                <TabsContainer
                  toggleState={toggleState}
                  disabled={disabled}
                  state={state}
                  setBankaInfo={setBankaInfo}
                  handleChange={handleChange}
                  setSearchString={setSearchString}
                  searchString={searchString}
                  handleFunctionKlienti={handleFunctionKlienti}
                  qytetet={qytetet}
                  shtetet={shtetet}
                  klienti={klienti}
                  setState={setState}
                  setTipiArkes={setTipiArkes}
                  setSelectedArkaMon={setSelectedArkaMon}
                  rows={rows}
                  menyrapageses={menyrapageses}
                  tipiArkes={tipiArkes}
                  buttonClicked={buttonClicked}
                  formatDate={formatDate}
                  updateDepKodi={updateDepKodi}
                  fetchFShitje={fetchFShitje}
                />
              </div>
            </>
          )}
        </div>
      </Panel>
    </div>
  );
};

export default TopPanelFsh;
