import React from "react";
import TableListaFaturave from "../../POS/Restaurant/DataTablePos/TableListaFaturave";
import { Button } from "@mui/material";
// import TableSecondListaFaturave from "../../POS/Restaurant/DataTablePos/TableSecondListaFaturave";

function DataTable({
  data,
  setSelectedId,
  setFaturaNIVF,
  totalRows,
  selectedOperator,
  handleNextPage,
  handlePreviousPage,
  setData2,
  currentPage,
  totalPages,
  perPage,
  setState,
  loading,
  setDataCheck,
  selectedId,
  state,
  showLoading,
  hideLoading,
}) {
  const tableContainerStyle = {
    display: "flex",
    marginTop: "5px",
    flexDirection: "column",
    height: "99%",
    overflow: "hidden",
  };

  const tableStyle = {
    height: "94%",
  };

  const tableButtons = {
    // height: "6%",
    display: "flex",
    padding: "5px",
    border: "1px solid rgba(173, 173, 173, 0.692)",
    borderRadius: "5px",
    justifyContent: "space-between",
    backgroundColor: "#fff",
    color: "#fff",
  };

  let currentNumber = currentPage * totalPages;
  return (
    <>
      <div style={tableContainerStyle}>
        <div style={tableStyle}>
          <TableListaFaturave
            rows={data}
            setData2={setData2}
            loading={loading}
            setSelectedId={setSelectedId}
            setDataCheck={setDataCheck}
            setFaturaNIVF={setFaturaNIVF}
            operator={selectedOperator}
            setState={setState}
            state={state}
            selectedId={selectedId}
            showLoading={showLoading}
            hideLoading={hideLoading}
          />
        </div>
        {/* <div style={tableStyle2}>
        <TableSecondListaFaturave rows={data2} loading={loading} />
      </div> */}
        <div style={tableButtons}>
          <Button
            variant="contained"
            onClick={handlePreviousPage}
            disabled={currentPage === 1}
          >
            Previous
          </Button>
          <div
            style={{
              display: "flex",
              color: "#000",
              fontSize: "15px",
              fontWeight: "500",
              textAlign: "center",
              justifyContent: "space-between",
              alignItems: "center",
              width: "200px",
            }}
          >
            <div className="flex">
              Faqa{" "}
              <div
                style={{
                  color: "#fff",
                  backgroundColor: "#000",
                  width: "20px",
                  height: "20px",
                  marginLeft: "5px",
                  textAlign: "center",
                  justifyContent: "center",
                  display: "flex",
                  borderRadius: "50%",
                }}
              >
                {currentPage}
              </div>
            </div>

            <span
              style={{ color: "#000", fontSize: "13px", fontWeight: "400" }}
            >
              Per Faqe {totalPages} Totali {totalRows}
            </span>
          </div>
          <Button
            variant="contained"
            onClick={handleNextPage}
            disabled={currentNumber >= totalRows}
          >
            Next
          </Button>
        </div>
      </div>
    </>
  );
}

export default DataTable;
