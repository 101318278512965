import React, { createContext, useContext, useRef } from "react";
import { Toast } from "primereact/toast";

const ToastContext = createContext();

export const ToastProvider = ({ children }) => {
  const toastRef = useRef(null);

  const showToast = (message, options = {}) => {
    if (toastRef.current) {
      toastRef.current.show({
        severity: options.severity || "info",
        summary: message,
        detail: options.detail || "",
        life: options.life || 1500,
        closable: options.closable || false,
        ...options,
      });
    }
  };

  return (
    <ToastContext.Provider value={showToast}>
      <Toast ref={toastRef} position="bottom-right" />
      {children}
    </ToastContext.Provider>
  );
};

export const useToast = () => {
  return useContext(ToastContext);
};
