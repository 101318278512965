import React, { useRef, useState, useEffect } from "react";
import { Button } from "@mui/material";
import PopupState from "material-ui-popup-state";
import { useReactToPrint } from "react-to-print";
import "./print80Skontrino.scss";

import AnullueseDynamic from "./AnullueseDynamic";
import AnullueseUrdhri from "./AnullueseUrdhri";

const Anulluese = ({
  handleUpdateAllItems,
  rowsPrint1,
  state,
  operator,
  menyrapageses,
  nipt,
  konfigurimeRedux,
}) => {
  const componentsRef4 = useRef(null);

  const handlePrint4 = useReactToPrint({
    content: () => componentsRef4.current,
  });

  const [art, setArt] = useState([]);
  const [isFirstLoad, setIsFirstLoad] = useState(true);
  const [diference, setDiference] = useState([]); // State to hold the difference

  // console.log(rowsPrint1, "rowprint1");
  // console.log(art, "art");
  // console.log(diference, "diference");

  // Use effect to update 'art' only on the first load
  useEffect(() => {
    if (isFirstLoad) {
      const newArt = rowsPrint1.map((item) => ({
        ...item,
      }));
      setArt(newArt);
      setIsFirstLoad(false);
    }
  }, [isFirstLoad, rowsPrint1]);

  useEffect(() => {
    // Calculate the difference between art and rowsPrint1
    const newDiference = art
      .map((item, index) => {
        const rowArt = art[index];
        const row = rowsPrint1[index];
        const newPershkrim = item.Pershkrim;
        const newSasia = (row?.Sasia || 0) - item.Sasia; // Calculate difference

        // Only return the row if Sasia has changed
        if (newSasia !== 0) {
          return {
            ...rowArt,
            Pershkrim: newPershkrim,
            Sasia: newSasia, // Add only the changed Sasia
          };
        }
        return null; // Return null for unchanged items
      })
      .filter((item) => item !== null); // Remove null values from the array

    setDiference(newDiference); // Set the difference in state
  }, [rowsPrint1, art]);

  const regjistro = async (handlePrint4) => {
    try {
      await handleUpdateAllItems(handlePrint4, {
        fromRegister: true,
      });
    } catch (error) {
      console.error("Error updating state:", error);
    }
  };

  return (
    <>
      <PopupState variant="popover" popupId="demo-popup-popover">
        {(popupState) => (
          <>
            <Button
              variant="contained"
              className="btn_fiskal flex"
              onClick={() => {
                regjistro(handlePrint4);
              }}
              style={{
                borderRadius: "20px",
                padding: "10px",
              }}
            >
              Regjistro
            </Button>
          </>
        )}
      </PopupState>

      <div ref={componentsRef4} className="print">
        <AnullueseUrdhri
          konfigurimeRedux={konfigurimeRedux}
          operator={operator}
          state={state}
          menyrapageses={menyrapageses}
          nipt={nipt}
        />
        <AnullueseDynamic rows={diference} state={state} />
      </div>
    </>
  );
};

export default Anulluese;
