const columns = [
  { field: "Kodi", title: "Kodi" },
  { field: "BarKod", title: "BarKod" },
  { field: "Pershkrim", title: "Pershkrim", impMinWidth: true },
  { field: "Njesi_Kodi", title: "Njesi_Kodi", impMaxWidth: true },
  { field: "Sasia", title: "Sasia", allowSum: true, impMaxWidth: true },
  { field: "Skonto", title: "Skonto (%)", impMaxWidth: true },
  {
    field: "Skonto_Vlera",
    title: "Skonto_Vlera",
    allowSum: true,
  },
  { field: "Cmimi_Pa_Tvsh", title: "Cmimi_Pa_Tvsh" },
  {
    field: "Vlera_Pa_Tvsh",
    title: "Vlera_Pa_Tvsh",
    allowSum: true,
  },
  { field: "Tvsh", title: "Tvsh", impMaxWidth: true },
  {
    field: "Tvsh_Vlera",
    title: "Tvsh_Vlera",
    allowSum: true,
  },
  { field: "Cmimi", title: "Cmimi" },
  { field: "Vlera", title: "Vlera", allowSum: true },
  { field: "Tipi", title: "Tipi", impMaxWidth: true },
  { field: "Total", title: "Total", allowSum: true },
];

const columnsSF = [
  { field: "Id", title: "Id" },
  { field: "Kodi", title: "Kodi" },
  { field: "Shenim", title: "Shenim" },
  { field: "Data", title: "Data" },
  { field: "Vlera", title: "Vlera" },
  { field: "KLFU_Pershkrim", title: "Pershkrim" },
  { field: "Operator", title: "Operator" },
];

export { columns, columnsSF };
