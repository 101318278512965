import React, { useEffect, useState, memo, useContext, useRef } from "react";
import Button from "@mui/material/Button";
import useStorage from "../../hooks/useStorage";
import { Panel } from "primereact/panel";
import { Ripple } from "primereact/ripple";
import mainAxios from "../../services/axios";
import NavBar from "../../components/Navbar/NavBar";
import SideBar from "../../components/Navbar/SideBar";
import PaneliTab2 from "../fature-blerje/tabet/PaneliTab2";
import "./Celje.scss";
import { LoadingOverlayContext } from "../../components/LoadingOverlay";
import { useDispatch, useSelector } from "react-redux";
import MenuOpenIcon from "@mui/icons-material/MenuOpen";
import TabMainCelje from "./PaneliTabeveCelje/TabMainCelje";
import TabTjeraCelje from "./PaneliTabeveCelje/TabTjera";
import SmallButtonGroupC from "./SmallButtonGroupC";
import { getAgjenti } from "../../store/globalSlice";
import Footer from "../../components/Navbar/Footer";
import PrimeGridKF from "./PrimeGridKlient";
import PersonIcon from "@mui/icons-material/Person";
import SidebarCelje from "./SideBarCelje";
import { useToast } from "../../components/context/ToastContext";

const AgjentiC = ({
  isMobile,
  disabled,
  setDisabled,
  currentAction,
  setCurrentAction,
  drawerOpen,
  setDrawerOpen,
}) => {
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const [buttonClicked, setButtonClicked] = useState(false);
  const [data, setdata] = useState([]);
  const showToast = useToast();
  const [lastData, setLastData] = useState({});
  const [lastKodi, setLastKodi] = useState("");

  // Redux: Get client data from global slice
  const agjentiRedux = useSelector((state) => state.globalSlice.agjenti);

  // Function to increment the lastKodi string
  function incrementString(str) {
    const match = str.match(/\d+$/);
    const number = match ? parseInt(match[0]) : 0;
    const total = number + 1;
    const length = match ? match[0].length : 0;

    return str.replace(/\d+$/, total.toString().padStart(length, "0"));
  }

  // Fetch client data with loading indicators
  useEffect(() => {
    const fetchData = async () => {
      try {
        showLoading();
        setdata(agjentiRedux);
        if (agjentiRedux.length > 0) {
          const lastKodi = agjentiRedux[agjentiRedux.length - 1].Kodi;
          const newKodi = incrementString(lastKodi);
          setLastKodi(newKodi);
        } else {
          setLastKodi("Agj01"); // Start with Agj01 if there are no existing entries
        }
      } catch (error) {
        // Log and handle errors
        console.error("Error fetching data:", error);
        hideLoading();
      } finally {
        hideLoading();
      }
    };
    fetchData();
  }, [agjentiRedux, showLoading, hideLoading, setdata]);

  const dispatch = useDispatch();

  const [rows, setRows] = useState([]);

  const [columns, setColumns] = useState([
    { field: "ID", title: "ID" },
    { field: "Kodi", title: "Kodi" },
    { field: "Pershkrim", title: "Pershkrim", impMinWidth: true },
    { field: "BarKod", title: "BarKod" },
    { field: "NIPT", title: "Nipt" },
    { field: "Tip_Cmimi", title: "Me cmimin" },
    { field: "Cel", title: "Cel" },
    { field: "EmerKontakti", title: "Emer Kontakti" },
    { field: "Nrll", title: "Llogari" },
    { field: "Skonto", title: "Skonto" },
    { field: "Aktiv", title: "Aktiv" },
  ]);

  const defaultColumnsStorage = columns.map((column) => column.title);
  const [selectedColumns, setselectedcolumns] = useStorage(
    defaultColumnsStorage,
    "kolonatAgjent"
  );
  let defaultState = {
    Adresa: "",
    Adresa2: null,
    Aktiv: "",
    Barkod: "",
    BillingAddres1: null,
    BillingAddres2: null,
    BillingCity: null,
    BillingCountry: null,
    BillingPhone: null,
    Cel: "",
    Data_Regjistrimit: null,
    Email: "",
    Emer: null,
    EmerKontakti: "",
    Inserted: null,
    Klfu_Kodi: null,
    Klfu_Tipi: null,
    Kodi: `${lastKodi}`,
    KomisionFee: null,
    KomisionPerqindje: null,
    KomisionTipi: null,
    KomisionVlera: null,
    Kompania: null,
    Mbiemer: null,
    NIPT: "",
    Nrll: "411",
    Operator: null,
    OperatorInsert: null,
    Origjina_Kodi: null,
    Pershkrim: "",
    Pseudonimi: null,
    Qyteti_Kodi: "",
    Shenime: null,
    ShippingAddres1: null,
    ShippingAddres2: null,
    ShippingCity: null,
    ShippingCountry: null,
    Shteti_Kodi: "",
    Skonto: "",
    Updated: null,
    UserRole_Kodi: null,
  };

  const [searchString, setSearchString] = useState("");
  const [state, setState] = useState(defaultState);

  const handleChange = (key, value) => {
    setState((state) => {
      return {
        ...state,
        [key]: value,
      };
    });
  };

  const [toggleState, setToggleState] = useState(1);

  const toggleTab = (index) => {
    setToggleState(index);
  };

  const [visibleRight, setVisibleRight] = useState(false);

  const template = (options) => {
    const toggleIcon = options.collapsed
      ? "pi pi-chevron-down"
      : "pi pi-chevron-up";
    const className = `${options.className} flex justify-content-between`;
    const titleClassName = `${options.titleClassName} pl-1`;
    return (
      <div className={className}>
        <button
          className={options.togglerClassName}
          onClick={options.onTogglerClick}
        >
          <span className={toggleIcon}></span>
          <Ripple />
        </button>
        <span className={titleClassName}>
          Celje Agjent
          <br />
        </span>
        <Button
          size="small"
          onClick={() => setVisibleRight(true)}
          className="mr-2 border"
        >
          <MenuOpenIcon size="small" />
        </Button>
        <SidebarCelje
          currentAction={currentAction}
          visible={visibleRight}
          onHide={() => setVisibleRight(false)}
          position="right"
        />
      </div>
      // 11-10-2023 - Fundi i shtimit te sidebar nga e djathta - Arseni
    );
  };

  const deleteMethod = async (e) => {
    try {
      const response = await mainAxios.delete(`/agjenti/${state.ID}`);

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getAgjenti("/agjenti"));

        showToast("U fshi", {
          severity: "info",
        });
      }
    } catch (error) {
      console.error(error);

      showToast("Ndodhi problem" + error.message, {
        severity: "error",
      });
    }
  };

  const submitHanlder = async (e) => {
    if (currentAction === "add") {
      handleAdd();
    } else if (currentAction === "modify") {
      handleModify();
    }
  };
  const currentDateTime = new Date().toISOString();
  const handleAdd = async (e) => {
    if (!state.Kodi.trim() || !state.Pershkrim.trim()) {
      showToast(
        "Plotesoni fushat: " +
          (!state.Kodi.trim() ? "Kodi " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim " : ""),
        {
          severity: "info",
        }
      );
      return;
    }
    try {
      const response = await mainAxios.post(`/agjenti`, {
        ...state,
        Adresa: state.Adresa,
        Aktiv: Number(state.Aktiv),
        Barkod: state.Barkod,
        Cel: state.Cel,
        Email: state.Email,
        Kodi: state.Kodi,
        NIPT: state.NIPT,
        Nrll: state.Nrll,
        Pershkrim: state.Pershkrim,
        Qyteti_Kodi: state.Qyteti_Kodi,
        Shteti_Kodi: state.Shteti_Kodi,
        Skonto: Number(state.Skonto),
        EmerKontakti: state.EmerKontakti,
        Inserted: currentDateTime,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getAgjenti("/agjenti"));
        setCurrentAction(null);

        showToast("U rregjistrua", {
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);

      showToast("Nuk u regjistrua." + error.message, {
        severity: "success",
      });
    }
  };

  const handleModify = async (e) => {
    if (!state.Kodi.trim() || !state.Pershkrim.trim()) {
      showToast(
        "Plotesoni fushat: " +
          (!state.Kodi.trim() ? "Kodi " : "") +
          (!state.Pershkrim.trim() ? "Pershkrim " : ""),
        {
          severity: "info",
        }
      );
      return;
    }
    try {
      const response = await mainAxios.put(`/agjenti/${state.ID}`, {
        ...state,
        Adresa: state.Adresa,
        Aktiv: Number(state.Aktiv),
        Barkod: state.Barkod,
        Cel: state.Cel,
        Email: state.Email,
        Kodi: state.Kodi,
        NIPT: state.NIPT,
        Nrll: state.Nrll,
        Pershkrim: state.Pershkrim,
        Qyteti_Kodi: state.Qyteti_Kodi,
        Shteti_Kodi: state.Shteti_Kodi,
        Skonto: Number(state.Skonto),
        EmerKontakti: state.EmerKontakti,
      });

      if (response && response.status === 200) {
        setState(defaultState);
        setDisabled(true);
        setButtonClicked(false);
        dispatch(getAgjenti("/agjenti"));
        setCurrentAction(null);

        showToast("U modifikua", {
          severity: "success",
        });
      }
    } catch (error) {
      console.error(error);

      showToast("Nuk u modifikua." + error.message, {
        severity: "error",
      });
    }
  };

  const PaneliKryesor = (
    <div className="d-flex justify-content-between">
      {/* {isChecked && ( */}
      <SmallButtonGroupC
        isMobile={isMobile}
        lastData={lastData}
        buttonClicked={buttonClicked}
        setButtonClicked={setButtonClicked}
        setDisabled={setDisabled}
        disabled={disabled}
        setState={setState}
        defaultState={defaultState}
        submitHandler={submitHanlder}
        selectedRowId={state.ID}
        deleteMethod={deleteMethod}
        state={state}
        setCurrentAction={setCurrentAction}
      />
    </div>
  );

  // Paneli Dytesor
  const button6Text = isMobile ? "" : "Te Dhenat";

  const PaneliDytesor = (
    <PaneliTab2
      isFromCelje={true}
      includeButton6={true}
      disabled={disabled}
      setDisabled={setDisabled}
      toggleState={toggleState}
      state={state}
      defaultState={defaultState}
      setState={setState}
      rows={rows}
      toggleTab={toggleTab}
      button6Text={button6Text}
      button6Icon={<PersonIcon />}
    />
  );

  const TabiKryesor = (
    <TabMainCelje
      isFromAgent={true}
      state={state}
      TextEmri={true}
      TextNIPT={true}
      TextKodi={true}
      fromAgjenti={false}
      TextSkonto={true}
      disabled={disabled}
      setState={setState}
      handleChange={handleChange}
      setSearchString={setSearchString}
      searchString={searchString}
    />
  );

  const smallTabsKM = (
    <div>
      {/* tab2 */}
      <TabTjeraCelje
        isAgjent={true}
        TextCel={true}
        setState={setState}
        TextAdresa={true}
        TextEmail={true}
        TextQyteti={true}
        TextShteti={true}
        toggleState={toggleState === 1}
        disabled={disabled}
        handleChange={handleChange}
        includeButton5={true}
        includeButton6={false}
        state={state}
      />
    </div>
  );

  return (
    <div className={`mainDiv_ftsh-c ${!drawerOpen ? "drawer-closed" : ""}`}>
      {!isMobile && <SideBar drawerOpen={drawerOpen} />}
      <div className="right-column">
        <NavBar
          currentAction={currentAction}
          setDrawerOpen={setDrawerOpen}
          drawerOpen={drawerOpen}
        />
        <div className="template">
          <div className="p-1 main-container-c">
            <div className="top_panel_fsh-c">
              <Panel headerTemplate={template} toggleable>
                <div className="bg-white" style={{ height: "100%" }}>
                  {!isMobile ? (
                    <>
                      <div className="paneli-tabeve-c">
                        <div className="paneli-tabeve1-c">{PaneliKryesor}</div>
                        <div className="paneli-tabeve2-c">{PaneliDytesor}</div>

                        <div className="paneli-fis-print-c"></div>
                      </div>
                      <div className={"below-tabs-c"}>
                        <div className="below-tabs1-c">{TabiKryesor}</div>
                        <div className="below-tabs2-c">{smallTabsKM}</div>
                        <div className="below-tabs3-c"></div>
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "5px",
                        }}
                      >
                        {PaneliKryesor}
                      </div>
                      <div style={{ margin: "5px" }}>{TabiKryesor}</div>

                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          margin: "10px 5px",
                        }}
                      >
                        {PaneliDytesor}
                      </div>
                      <div style={{ margin: "5px" }}>{smallTabsKM}</div>
                    </>
                  )}
                </div>
              </Panel>
            </div>

            <div className="middle_fsh-c"></div>

            <div className="dataTable_fsh-c">
              <PrimeGridKF
                gridKey="Agjenti"
                isMobile={isMobile}
                data={data}
                defaultState={defaultState}
                isEditable={true}
                defaultColumnsStorage={defaultColumnsStorage}
                columns={columns}
                setColumns={setColumns}
                disabled={disabled}
                setdata={setdata}
                setState={setState}
                rows={rows}
                setLastData={setLastData}
                state={state}
                setRows={setRows}
                selectedColumns={selectedColumns}
                setselectedcolumns={setselectedcolumns}
                fromAgjenti={true}
                currentAction={currentAction}
              />
            </div>
          </div>
        </div>
      </div>
      {/* {!isMobile && <Footer className="footer-position" />} */}
    </div>
  );
};

export default memo(AgjentiC);
