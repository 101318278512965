import React, { useState, useEffect, useContext, useRef, memo } from "react";
import "./MainComponent.scss";
import { Button } from "@mui/material";
import MainBotComponent from "../MainBotComponent/MainBotComponent";
import MainTopComponent from "../MainTopComponent/MainTopcomponent";
import mainAxios from "../../../../../services/axios";
import LeftBar from "../LeftBar/LeftBar";
import SearchIcon from "@mui/icons-material/Search";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
// import TabelaRestaurant from "../DataTableArt/TabelaRestaurant"; DUHET PER KALKULIMIN E TE DHENAVE NQS DO BEHET SI NE DESKTOP
import Badge from "@mui/material/Badge";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { styled } from "@mui/material/styles";
import Paper from "@mui/material/Paper";
import getColor from "../VariableColors/GetColor";
import BottomAppBar from "../../NavBarPOS/NavBarPos";
import ReplayIcon from "@mui/icons-material/Replay";
import CircularProgress from "@mui/material/CircularProgress";
import PrintoFisPos from "../../../../../components/ReactPDF/PrintoFisPos";
import ShtoKlient from "../ShtoKlient/ShtoKlient";
import { shtoArtikull, calculateData } from "./ShtoArtikullFunction";
import { useMyContext } from "../../../../../components/context/MyStatePOSContext";
import { useApi } from "../../../../../components/context/NdermarrjeInfo";
import { LoadingOverlayContext } from "../../../../../components/LoadingOverlay";
import { useUser } from "../../../../../zustand/common";
import { useArtContext } from "../../../../../components/context/ArtikullContextPosBar";
import { useToast } from "../../../../../components/context/ToastContext";

const StyledBadge = styled(Badge)(({ theme }) => ({
  "& .MuiBadge-badge": {
    right: -8,
    top: 1,
    border: `1px solid ${theme.palette.background.paper}`,
    padding: "0 4px",
  },
}));
const MainComponent = ({
  isMobile,
  newId,
  numTav,
  mbylleTav,
  handleClose,
  error,
  setError,
  kls01,
  menyra,
}) => {
  const showToast = useToast();
  const [loading, setLoading] = useState(false);
  const { user } = useUser();
  const operator = user.username;
  const nipt = user.nipt;
  const { showLoading, hideLoading } = useContext(LoadingOverlayContext);
  const { state, dtlState, setDtlState, loadingLeftBar } = useMyContext();
  const { konfigurimeRedux } = useApi();
  const [fromSearch, setFromSearch] = useState(false);
  const { artData, setArtData, artikujPos, originalArtData, artikujTotal } =
    useArtContext();

  const rows = dtlState;
  const [currentPorosia, setCurrentPorosia] = useState("0");
  const [printPorosia, setPrintPorosia] = useState("0");
  const [numArt] = useState(artikujTotal.total_rows);
  const [isMainTopOpen, setIsMainTopOpen] = useState(false);
  const [isDataTableOpen, setDataTableOpen] = useState(false);
  const [messageDisplay, setMessageDisplay] = useState(false);

  useEffect(() => {
    async function mbyllTavIfError(error) {
      if (error) {
        await handleClose(true);
        setError(false);
      }
    }
    mbyllTavIfError(error);
  }, [error, handleClose, setError]);

  useEffect(() => {
    showLoading();
    async function updateArtData() {
      setArtData(artData);
      hideLoading();
    }
    updateArtData();
  }, [artData, showLoading, hideLoading]);

  //On Click Ca Perfshijne grupet (artikujt e tyre)
  const kategoriBtnfunction = async (kodi) => {
    setLoading(true);
    try {
      const response = await mainAxios.get(`/art/by/klasifikim/${kodi}`);
      setArtData(response.data);
    } catch (error) {
      console.error("kategori function error :", error);
    } finally {
      setLoading(false);
    }
  };

  const handleShtoArtikull = (artikull) => {
    shtoArtikull(
      artikull,
      showLoading,
      dtlState,
      setDtlState,
      newId,
      currentPorosia,
      operator,
      showToast,
      hideLoading,
      state
    );
  };

  const handleCalculateData = (field, item) => {
    return calculateData(field, item, state);
  };

  const theme = {};
  const ColoredItem = styled(Paper)(({ theme, pershkrimcolor }) => ({
    alignItems: "center",
    justifyContent: "center",
    marginTop: "5px",
    marginRight: "10px",
    padding: theme.spacing(1.5),
    textAlign: "center",
    cursor: "pointer",
    color: theme.palette.text.secondary,
    backgroundColor: getColor(theme, pershkrimcolor),
    maxHeight: "90%",
  }));

  const [searchTerm, setSearchTerm] = useState("");

  const handleSearchInputChange = (event) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    // If the search term is empty, reset data to the original `artikuj` data
    if (newSearchTerm.length === 0) {
      setArtData(originalArtData); // Reset to original data
      setFromSearch(false);
    } else {
      // Optionally, you can filter the originalArtData if desired
      const filteredData = originalArtData.filter(
        (item) => item.someProperty.includes(newSearchTerm) // Change 'someProperty' to the property you want to search
      );
      setArtData(filteredData);
      setFromSearch(true);
    }
  };

  const handleSearchClick = async () => {
    if (searchTerm.length === 0) {
      return;
    }
    try {
      const response = await mainAxios.get(`/art/search/${searchTerm}`);
      const rowsArtikuj = response.data.map((item) => ({
        ...item,
        Tipi: "AR",
      }));
      setArtData(rowsArtikuj);
      setFromSearch(true);
    } catch (error) {
      console.error(error);
      showToast("Artikulli nuk gjendet", {
        severity: "error",
      });
    }
  };

  useEffect(() => {
    const handleKeyDown = (event) => {
      if (event.key === "Enter") {
        handleSearchClick();
      }
    };

    window.addEventListener("keydown", handleKeyDown);
    return () => {
      window.removeEventListener("keydown", handleKeyDown);
    };
  }, [searchTerm]);

  const toggleMainTop = () => {
    setIsMainTopOpen(!isMainTopOpen);
  };

  const toggleDataTable = () => {
    setDataTableOpen(!isDataTableOpen);
  };

  const handleAllButton = () => {
    setArtData(artikujPos);
  };

  useEffect(() => {
    if (dtlState.length === 0) {
      setMessageDisplay(true);
    }
  }, [dtlState]);

  return (
    <>
      <div className="rectangle1">
        {!isMobile && (
          <div className="rectangle2">
            {loadingLeftBar ? (
              <div
                className="squares-container"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <LeftBar
                printPorosia={printPorosia}
                setCurrentPorosia={setCurrentPorosia}
                isMobile={isMobile}
                onItemClick={handleShtoArtikull}
                toggleDataTable={toggleDataTable}
                isDataTableOpen={isDataTableOpen}
                showLoading={showLoading}
                hideLoading={hideLoading}
                messageDisplay={messageDisplay}
                currentID={newId}
                konfigurimeRedux={konfigurimeRedux}
                handleClose={handleClose}
                mbylleTav={mbylleTav}
                setPrintPorosia={setPrintPorosia}
                rows={rows}
                operator={operator}
                menyra={menyra}
                nipt={nipt}
                ShtoKlient={ShtoKlient}
                PrintoFisPos={PrintoFisPos}
              />
            )}
          </div>
        )}

        <div
          className="mainPoss"
          style={{
            display: "flex",
            flexDirection: "column",
            ...(isMobile && { width: "100%", height: "100%" }),
          }}
        >
          <div
            className="header_Top"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Button
              className="menu_Top flex"
              sx={{ color: "gray" }}
              onClick={toggleMainTop}
            >
              Menu {isMainTopOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
            </Button>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "space-between",
              }}
            >
              {!isMobile && (
                <>
                  <TextField
                    sx={{ m: 1, width: "25ch" }}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <SearchIcon sx={{ color: "gray" }} />
                        </InputAdornment>
                      ),
                    }}
                    variant="standard"
                    value={searchTerm}
                    onChange={handleSearchInputChange}
                  />

                  <Button
                    type="button"
                    variant="contained"
                    onClick={handleSearchClick}
                    style={{
                      marginLeft: "10px",
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    Kerko
                  </Button>
                </>
              )}
            </div>
          </div>

          <MainTopComponent
            kategoriBtnfunction={kategoriBtnfunction}
            kls01={kls01}
            isMainTopOpen={isMainTopOpen}
          />

          <div
            className="hr_Buttons"
            style={{ height: `${isMainTopOpen ? "10%" : ""}` }}
          >
            <Button
              className="btn_length"
              sx={{
                backgroundColor: "#dbdbdbaf",
                color: "black",
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                justifyContent: "center",
                marginTop: "5px",
                marginRight: "10px",
                boxShadow: "0px 2px 4px rgba(0, 0, 0, 0.2)",
              }}
              onClick={handleAllButton}
            >
              <StyledBadge badgeContent={numArt} color="error">
                {numArt === "" ? <ReplayIcon /> : "ALL"}
              </StyledBadge>
            </Button>

            {!isMainTopOpen &&
              kls01.map((item) => (
                <ColoredItem
                  key={item.Kodi}
                  theme={theme}
                  pershkrimcolor={item.Pershkrim}
                  onClick={() => kategoriBtnfunction(item.Kodi, item)}
                >
                  {item.Pershkrim}
                </ColoredItem>
              ))}
          </div>

          <MainBotComponent
            loading={loading}
            clickedItems={dtlState}
            onItemClick={handleShtoArtikull}
            showLoading={showLoading}
            hideLoading={hideLoading}
            fromSearch={fromSearch}
          />

          {isMobile && (
            <footer>
              <BottomAppBar
                printPorosia={printPorosia}
                setCurrentPorosia={setCurrentPorosia}
                isMobile={isMobile}
                onItemClick={handleShtoArtikull}
                toggleDataTable={toggleDataTable}
                isDataTableOpen={isDataTableOpen}
                showLoading={showLoading}
                hideLoading={hideLoading}
                messageDisplay={messageDisplay}
                newId={newId}
                konfigurimeRedux={konfigurimeRedux}
                handleClose={handleClose}
                mbylleTav={mbylleTav}
                setPrintPorosia={setPrintPorosia}
                rows={rows}
                operator={operator}
                menyra={menyra}
                nipt={nipt}
                ShtoKlient={ShtoKlient}
                PrintoFisPos={PrintoFisPos}
                handleSearchInputChange={handleSearchInputChange}
                handleSearchClick={handleSearchClick}
                searchTerm={searchTerm}
                handleShtoArtikull={handleShtoArtikull}
                state={state}
                clickedItems={dtlState}
                setClickedItems={setDtlState}
                numTav={numTav}
                pos={true}
                posBar={true}
                fatureThjesht={true}
                className="print"
              />
            </footer>
          )}
        </div>

        {/* <div
          className="dataTable_Pos"
          style={{
            overflow: "hidden",
            height: isDataTableOpen ? "50%" : "0",
            transition: "height 1s",
          }}
        >
          <TabelaRestaurant
            rows={dtlState}
            setRows={setDtlState}
            shtoArtikull={handleShtoArtikull}
            calculateData={handleCalculateData}
          />
        </div> */}
      </div>
    </>
  );
};

export default memo(MainComponent);
