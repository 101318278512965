import React, { useState, useEffect, useRef } from "react";
import Typography from "@mui/material/Typography";
import "./Fiskale.scss";
import { Button, ButtonGroup } from "@mui/material";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import Grid from "@mui/material/Grid";
import mainAxios from "../../../services/axios";
import CertificateDownloadButton from "./CertificateDownload";
import { getCert } from "../../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { useToast } from "../../../components/context/ToastContext";

const Fiskale = ({ isMobile, currentAction }) => {
  const dispatch = useDispatch();
  const showToast = useToast();
  const [open, setOpen] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [currentId, setCurrentId] = useState(null);
  const [formData, setFormData] = useState({
    NIPT: "",
    TCRCode: "",
    OperatorCode: "",
    BusinessUnitCode: "",
    sellerAddress: "",
    sellerTown: "",
    CertPassword: "",
    CertFile: "",
  });

  // redux arka
  const certData = useSelector((state) => state.globalSlice.cert);

  const filename = "certificate.pfx";

  useEffect(() => {
    const fetchData = async () => {
      const data = certData[0];
      setCurrentId(data.ID);
      setFormData({
        NIPT: data.NIPT || "",
        TCRCode: data.TCRCode || "",
        OperatorCode: data.OperatorCode || "",
        BusinessUnitCode: data.BusinessUnitCode || "",
        sellerAddress: data.sellerAddress || "",
        sellerTown: data.sellerTown || "",
        CertPassword: data.CertPassword || "",
        CertFile: data.CertFile || "",
      });
    };

    fetchData();
  }, []);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleSave = async () => {
    // Check if all required fields are filled
    if (
      formData.NIPT &&
      formData.OperatorCode &&
      formData.BusinessUnitCode &&
      formData.sellerAddress &&
      formData.sellerTown &&
      formData.CertPassword
    ) {
      // Function to send the PUT request
      const sendRequest = async (certFileBase64) => {
        // Create the request data object without the CertFile field by default
        const requestData = {
          NIPT: formData.NIPT,
          TCRCode: formData.TCRCode,
          OperatorCode: formData.OperatorCode,
          BusinessUnitCode: formData.BusinessUnitCode,
          sellerAddress: formData.sellerAddress,
          sellerTown: formData.sellerTown,
          CertPassword: formData.CertPassword,
        };

        // Add CertFile only if it's provided
        if (certFileBase64) {
          requestData.CertFile = certFileBase64;
        }

        try {
          const response = await mainAxios.put(
            `/cert/${currentId}`,
            requestData
          );
          if (response.status === 200) {
            showToast("U dergua me sukses", { severity: "success" });

            dispatch(getCert(`/cert`));
          } else {
            console.error("Failed to save data:", response.statusText);
          }
        } catch (error) {
          showToast("Ndodih problem me dergimin.", {
            severity: "error",
          });

          console.error("Error saving data:", error.message);
        }
      };

      // If CertFile is provided, read it and send the request
      if (selectedFile) {
        const reader = new FileReader();
        reader.readAsDataURL(selectedFile);

        reader.onload = async () => {
          const base64Data = reader.result.split(",")[1];
          await sendRequest(base64Data); // Send request with CertFile
        };

        reader.onerror = (error) => {
          console.error("Error reading the file:", error);
        };
      } else {
        // If no CertFile is provided, send the request without CertFile
        await sendRequest(null); // Send request without CertFile
      }
    } else {
      showToast("u lutem plotesoni te gjitha fushat e kerkuara.", {
        severity: "warn",
      });
    }
  };

  const handleChange = (field, value) => {
    setFormData({
      ...formData,
      [field]: value,
    });
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Grid container rowSpacing={4.5} columnSpacing={2.75}>
      {/* row 1 */}
      <Grid item xs={12} sx={{ mb: -3.25 }}>
        <Typography variant="h5">Fiskale</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={4} lg={3}>
        <Grid container direction="row" sx={{ marginTop: 2 }}>
          <Box sx={{ flexGrow: 2 }}>
            <Grid container direction="column" spacing={2}>
              <Grid item xs={8}>
                <TextField
                  label="NIPT"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.NIPT}
                  onChange={(e) => handleChange("NIPT", e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="TCR Code"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.TCRCode}
                  onChange={(e) => handleChange("TCRCode", e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Operator Code"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.OperatorCode}
                  onChange={(e) => handleChange("OperatorCode", e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Business Unit Code"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.BusinessUnitCode}
                  onChange={(e) =>
                    handleChange("BusinessUnitCode", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Seller Address"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.sellerAddress}
                  onChange={(e) =>
                    handleChange("sellerAddress", e.target.value)
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  label="Seller Town"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.sellerTown}
                  onChange={(e) => handleChange("sellerTown", e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <TextField
                  required
                  label="CertPassword"
                  variant="outlined"
                  size="large"
                  className="text_field_ndermarrje"
                  value={formData.CertPassword}
                  onChange={(e) => handleChange("CertPassword", e.target.value)}
                />
              </Grid>
              <Grid item xs={8}>
                <ButtonGroup className="button_group_fiskal">
                  <input
                    accept="*"
                    style={{ display: "none" }}
                    id="file-upload"
                    type="file"
                    onChange={handleFileChange}
                  />
                  <label htmlFor="file-upload">
                    <Button
                      sx={{ marginRight: 6 }}
                      variant="contained"
                      component="span"
                    >
                      Ngarko Certifikaten
                    </Button>
                  </label>
                  <CertificateDownloadButton
                    certBase64={formData.CertFile}
                    filename={filename}
                  />
                  <Button
                    sx={{ marginLeft: 6 }}
                    variant="outlined"
                    onClick={handleSave}
                  >
                    Ruaje
                  </Button>
                </ButtonGroup>
                {selectedFile && (
                  <div style={{ marginTop: 16 }}>
                    <p>Selected file: {selectedFile.name}</p>
                  </div>
                )}
              </Grid>
            </Grid>
          </Box>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default Fiskale;
